import { observer } from "mobx-react";
import { Text, View } from "react-native";
import { appStyles, spacings } from "../../defaultStyles";
import { css } from "../../lib/glamor-native";

export const LabeledText: React.FC<{
  label: string;
  text: string;
  style?: object;
}> = observer(({ label, text, style = {} }) => {
  return (
    <View
      style={css({
        flexDirection: "column",
        backgroundColor: "transparent",
        marginTop: spacings.regular,
        ...style,
      })}
    >
      <Text
        style={css({
          ...appStyles.text.smallFaded,
          marginBottom: spacings.slim,
        })}
      >
        {label}
      </Text>
      <Text style={css(appStyles.text.body)}>{text}</Text>
    </View>
  );
});
