// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyCBTswTxZUqbnbxo9mAeE5eVBKufAzStAc",
  authDomain: "thenewus-49bb5.firebaseapp.com",
  databaseURL: "https://thenewus-49bb5-default-rtdb.firebaseio.com",
  projectId: "thenewus-49bb5",
  storageBucket: "thenewus-49bb5.appspot.com",
  messagingSenderId: "271405563490",
  appId: "1:271405563490:web:ed6bfc7804dd6dde6bdb3b",
  measurementId: "G-60YLB82L2V",
};
