import React from "react";
import { observer } from "mobx-react";
import Search from "../../store/utils/Search";
import { View } from "../Themed";
import { css } from "../../lib/glamor-native";
import Input from "./Input";

import Icon from "./Icon";
import { TouchableHighlight } from "react-native";
import { colors, spacings } from "../../defaultStyles";

const SearchBox: React.FC<{
  search: Search;
  placeholder?: string;
  style?: React.CSSProperties;
}> = ({ placeholder, search, style = {} }) => {
  return (
    <View style={css({ backgroundColor: "transparent", ...style })}>
      <View
        style={css({
          backgroundColor: "transparent",
          position: "absolute",
          top: 33,
          left: 10,
          zIndex: 20,
        })}
      >
        <Icon name="search" size={16} color={colors.onBackground} />
      </View>
      <View
        style={css({
          backgroundColor: "transparent",
          position: "relative",
          width: "100%",
        })}
      >
        <Input
          placeholder={placeholder || "Search"}
          label="Search"
          inputTextStyle={{
            paddingLeft: 40,
            paddingBottom: spacings.slim,
            paddingTop: spacings.regular,
            paddingRight: 40,
          }}
          onChangeText={(text) => {
            search.setTerm(text);
          }}
          value={search.term}
        />
      </View>
      {search.hasSearchTerm && (
        <View
          style={css({
            backgroundColor: "transparent",
            position: "absolute",
            top: 10,
            right: -8,
          })}
        >
          <TouchableHighlight
            activeOpacity={0.6}
            underlayColor="transparent"
            onPress={() => {
              search.clear();
            }}
          >
            <Icon
              name="close"
              size={20}
              style={{ padding: 20 }}
              color={colors.onBackground}
            />
          </TouchableHighlight>
        </View>
      )}
    </View>
  );
};

export default observer(SearchBox);
