import { makeAutoObservable } from "mobx";
import {
  getStorage,
  ref as storageRef,
  getDownloadURL,
} from "firebase/storage";

export const GET_FIREBASE_STORAGE_IMAGE_PROFILE_PATH = (email: string) =>
  `users/avatars/${email}.jpg`;

export const GET_FIREBASE_STORAGE_RESIZED_IMAGE_PROFILE_PATH = (
  email: string
) => `users/avatars/thumbnails/${email}_200x200.jpg`;

export type MembershipOptionsType = "free" | "premium";

export interface UserProfileType {
  displayName?: string;
  email?: string;
  membership?: MembershipOptionsType;
  supportModeEnabled?: boolean;
  profileImageUrl?: string;
  createdAt?: number;
}

export default class UserProfile {
  displayName: string;
  email: string;
  membership: MembershipOptionsType;
  supportModeEnabled: boolean;
  profileImageUrl?: string;
  createdAt?: number;

  constructor(userProfile: UserProfileType) {
    this.email = userProfile.email || "Guest";
    this.displayName = userProfile.displayName || userProfile.email || "Guest";
    this.membership = userProfile.membership || "free";
    this.supportModeEnabled = userProfile.supportModeEnabled || false;
    this.profileImageUrl = undefined;
    this.createdAt = userProfile.createdAt;

    this.loadProfileImage();
    makeAutoObservable(this);
  }

  setDisplayName = (value: string) => {
    this.displayName = value;
  };

  setCreatedAt = (value: number) => {
    this.createdAt = value;
  };

  setEmail = (value: string) => {
    this.email = value;
  };

  setMembership = (value: MembershipOptionsType) => {
    this.membership = value;
  };

  setSupportModeEnabled = (value: boolean) => {
    this.supportModeEnabled = value;
  };

  setProfileImage = (value?: string) => {
    this.profileImageUrl = value;
  };

  loadProfileImage = async () => {
    try {
      // only load image if image has not been already loaded
      if (this.profileImageUrl === undefined && this.email !== "Guest") {
        const imageUrl = await this.getProfileImageUrl(this.email);
        this.setProfileImage(imageUrl);
      }
    } catch (err) {
      console.log({ err });
    }
  };

  getProfileImageUrl = async (userId: string) => {
    const storage = getStorage();
    const imageRefUrl = GET_FIREBASE_STORAGE_RESIZED_IMAGE_PROFILE_PATH(userId);
    const sRef = storageRef(storage, imageRefUrl);
    return await getDownloadURL(sRef);
  };

  static getProfileImage = async (userId: string) => {
    const storage = getStorage();
    const imageRefUrl = GET_FIREBASE_STORAGE_RESIZED_IMAGE_PROFILE_PATH(userId);
    const sRef = storageRef(storage, imageRefUrl);
    return await getDownloadURL(sRef);
  };
}
