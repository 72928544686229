import { action, computed, makeObservable, observable } from "mobx";
import Apis from "./Api";
import List from "./common/List";
import { Firestore } from "./Firestore";
import Task, { TaskType } from "./Task";

const SEARCH_FIELDS = ["title", "description"];

export default class TaskList extends List {
  api: Firestore;
  groupsLoaded: { [groupId: string]: true };

  constructor(api: Firestore) {
    super(SEARCH_FIELDS, { fieldId: "createdAt", value: "date" });
    this.groupsLoaded = {};
    this.api = api;

    makeObservable(this, {
      // observable
      groupsLoaded: observable,
      api: observable,

      // computed
      byGroup: computed,

      // actions
      loadTasksForGroup: action,
      setGroupLoaded: action,
      setTask: action,
      loadTasksForGroups: action,
    });
  }

  get byGroup(): { [groupBy: string]: Task[] } {
    const groups: { [groupBy: string]: Task[] } = {};
    this.values.forEach((value) => {
      if (groups[value.groupId] !== undefined) {
        groups[value.groupId] = [...groups[value.groupId], value];
      } else {
        groups[value.groupId] = [value];
      }
    });
    return groups;
  }

  loadTasksForGroup = async (groupId: string, forceUpdate?: boolean) => {
    this.setRefreshing(true);

    // don't reload tasks if not needed
    if (this.groupsLoaded[groupId] === true && forceUpdate !== true) {
      this.setRefreshing(false);
      return null;
    }
    const tasks = await Apis.fetchTasksForGroupId(this.api.db, groupId);

    tasks?.forEach((doc) => {
      const data = doc.data() as TaskType;
      this.setTask(data);
    });

    // this.setGroupLoaded(groupId);
    this.setRefreshing(false);
  };

  setGroupLoaded = (groupId: string) => {
    this.groupsLoaded[groupId] = true;
  };

  setTask = (task: TaskType) => {
    const t = new Task(task);
    this.setItem("taskId", t);
    this.setUpdated();
  };

  loadTasksForGroups = (groupIds: string[]) => {
    groupIds.forEach((groupId) => {
      this.loadTasksForGroup(groupId);
    });
  };

  _removeTask = async (taskId: string) => {
    Apis.removeTask(this.api.db, taskId);
  };
}
