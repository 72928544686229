import { observer } from "mobx-react";
import { Pressable, View } from "react-native";
import { css } from "../../lib/glamor-native";
import Icon from "./Icon";

export const ManualRefresh: React.FC<{ onRefresh: () => void }> = observer(
  ({ onRefresh }) => {
    return (
      <View style={css({ backgroundColor: "transparent" })}>
        <Pressable onPress={onRefresh}>
          <Icon name="refresh" />
        </Pressable>
      </View>
    );
  }
);
