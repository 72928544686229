import { observer } from "mobx-react";
import { Text } from "react-native";
import { appStyles, colors, spacings } from "../../defaultStyles";
import { css } from "../../lib/glamor-native";
import { useAppStore } from "../../store/AppStore";
import { FeedbackVariantType } from "../../store/Feedback";
import { View } from "../Themed";
import FadeInOutView from "./FadeInOutView";

export const Feedback: React.FC = observer(() => {
  const store = useAppStore();
  const feedback = store.feedback;
  const variant = feedback.variant;
  const message = feedback.message;
  const open = feedback.open;
  const backgroundColor = getBackgroundColorForMessageType(feedback.variant);
  if (open && message) {
    return (
      <View
        style={css({
          backgroundColor: "transparent",
          position: "absolute",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          zIndex: 100,
        })}
      >
        <FadeInOutView
          style={{
            duration: 10,
          }}
        >
          <View
            style={css({
              backgroundColor,
              position: "relative",
              padding: spacings.relaxed,
              borderRadius: spacings.roundBorder,
              marginTop: spacings.relaxed,
              maxWidth: 300,
              flexDirection: "row",
              flexWrap: "wrap",
            })}
          >
            <Text
              style={css({
                textAlign: "center",
                ...appStyles.text.body,
                color: getTextColorForMessageType(variant),
              })}
            >
              {message}
            </Text>
          </View>
        </FadeInOutView>
      </View>
    );
  }
  return null;
});

const getBackgroundColorForMessageType = (variant: FeedbackVariantType) => {
  switch (variant) {
    case "regular":
      return colors.info;
    case "error":
      return colors.error;
    case "success":
      return colors.success;
    case "warning":
      return colors.warning;
    case "regular":
      return colors.info;
    default:
      return colors.info;
  }
};

const getTextColorForMessageType = (variant: FeedbackVariantType) => {
  switch (variant) {
    case "regular":
      return colors.onInfo;
    case "error":
      return colors.onError;
    case "success":
      return colors.onSuccess;
    case "warning":
      return colors.onWarning;
    case "regular":
      return colors.onInfo;
    default:
      return colors.onInfo;
  }
};
