import { makeAutoObservable } from "mobx";

export interface CommentType {
  id: string;
  userId: string;
  text: string;
  createdAt: number;
}
export default class Comment {
  id: string;
  userId: string;
  text: string;
  createdAt: number;

  constructor(comment: CommentType) {
    this.id = comment.id;
    this.userId = comment.userId;
    this.text = comment.text;
    this.createdAt = comment.createdAt;
    makeAutoObservable(this);
  }
}
