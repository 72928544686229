import { observer } from "mobx-react";
import { useRef } from "react";
import { View, Text, Animated, Pressable } from "react-native";
import { appStyles, colors, setAlpha, spacings } from "../defaultStyles";
import { css } from "../lib/glamor-native";
import { useAppStore } from "../store/AppStore";
import CompletedTask from "../store/CompletedTask";
import Button from "./common/Button";
import Icon from "./common/Icon";
import { fetcherScrollerAware } from "./common/scroll/FetcherScrollerAware";
import { useNavigation } from "@react-navigation/native";
import { Octicons } from "@expo/vector-icons";

const containerStyle = {
  position: "absolute",
  right: 0,
  backgroundColor: "transparent",
  flexDirection: "column",
  alignItems: "center",
  zIndex: 100,
};
export const Reactions: React.FC<{ completedTask: CompletedTask }> = observer(
  ({ completedTask }) => {
    if (!completedTask) {
      return null;
    }

    const fadeAnim = useRef(new Animated.Value(0)).current;
    const fetched = fetcherScrollerAware.fetched[completedTask.id];
    const fadeIn = () => {
      // Will change fadeAnim value to 1 in 300ms
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: 300,
        useNativeDriver: false,
      }).start();
    };

    const fadeOut = () => {
      // Will change fadeAnim value to 0 in 300ms
      Animated.timing(fadeAnim, {
        toValue: 0,
        duration: 300,
        useNativeDriver: false,
      }).start();
    };

    fadeIn();

    if (!fetched) {
      return (
        <View
          style={css({ ...containerStyle, paddingRight: spacings.regular })}
        >
          <Animated.View
            style={[
              { opacity: 1 },
              {
                // Bind opacity to animated value
                opacity: fadeAnim,
              },
            ]}
          >
            <Icon name="heart-o" />
          </Animated.View>
        </View>
      );
    }
    return (
      <View style={css({ ...containerStyle, flexDirection: "column" })}>
        <Likes completedTask={completedTask} />
        <Comments completedTask={completedTask} />
      </View>
    );
  }
);

const Comments: React.FC<{ completedTask: CompletedTask }> = observer(
  ({ completedTask }) => {
    const store = useAppStore();
    const navigation = useNavigation();
    const messages = completedTask.commentsList.length;
    return (
      <Pressable
        onPress={() => {
          let ct = store.feed.records[completedTask.id];
          if (ct) {
            ct.fetchComments(store.api);
          }
          ct = store.feed.items.items[completedTask.id];
          if (ct) {
            ct.fetchComments(store.api);
          }
          navigation.navigate("CommentView", {
            completedTaskId: completedTask.id,
          });
        }}
      >
        <View
          style={css({
            backgroundColor: "transparent",
            flexDirection: "column",
            alignItems: "center",
            marginTop: spacings.regular,
          })}
        >
          <Octicons
            name="comment"
            size={28}
            color={setAlpha(colors.onBackground, 0.4)}
          />
          {messages > 0 && (
            <Text
              style={css({
                ...appStyles.text.bodyBold,
                color: setAlpha(colors.onBackground, 0.4),
              })}
            >
              {messages}
            </Text>
          )}
        </View>
      </Pressable>
    );
  }
);

const Likes: React.FC<{ completedTask: CompletedTask }> = observer(
  ({ completedTask }) => {
    const store = useAppStore();
    const liked = completedTask.reactions.isLikedByUser(store.user.email);
    const likes = completedTask.reactions.likesCount;
    return (
      <View
        style={css({
          backgroundColor: "transparent",
          flexDirection: "column",
          alignItems: "center",
        })}
      >
        <Button
          variant="tertiary"
          onPress={() => {
            store.user.email &&
              store.toggleLike(completedTask, store.user.email);
          }}
          iconProps={{
            name: liked ? "heart" : "heart-o",
            style: {
              color: liked
                ? colors.secondary
                : setAlpha(colors.onBackground, 0.4),
            },
          }}
        />
        {likes > 0 && (
          <Text
            style={css({
              ...appStyles.text.bodyBold,
              marginTop: -spacings.regular,
              color: setAlpha(colors.onBackground, 0.4),
            })}
          >
            {likes}
          </Text>
        )}
      </View>
    );
  }
);
