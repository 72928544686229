import { makeAutoObservable } from "mobx";
import moment from "moment";
import { Time } from "./common/Time";
import { GroupOptionsType } from "./Group";

export interface CompletedTaskForReassignmentType {
  id: string;
  groupId: undefined;
  score: number;
  title: undefined;
  description: undefined;
  taskId: undefined;
  createdBy: string;
  createdAt: number;
  updatedAt?: string;
  notes?: string;
  allowScoreOverwrite?: undefined;
}

export interface TaskType {
  id?: string;
  groupId: string;
  score: number;
  title: string;
  description: string;
  taskId: string;
  createdBy: string;
  updatedAt?: string;
  notes?: string;
  allowScoreOverwrite?: boolean;
}

export default class Task {
  id: string;
  groupId: string;
  score: number;
  title: string;
  description: string;
  taskId: string;
  createdBy: string;
  updatedAt?: string;
  notes?: string;
  allowScoreOverwrite: boolean;

  constructor(task: TaskType) {
    this.id = task.id || task.taskId;
    this.groupId = task.groupId;
    this.taskId = task.taskId;
    this.score = 0;
    if (typeof task.score === "string") {
      this.score = parseFloat(task.score);
    } else {
      this.score = task.score || 0;
    }

    this.title = task.title;
    this.description = task.description;
    this.createdBy = task.createdBy;
    this.updatedAt = task.updatedAt;
    this.notes = task.notes;
    this.allowScoreOverwrite = task.allowScoreOverwrite || false;
    makeAutoObservable(this);
  }

  setScore = (value: number) => {
    this.score = value;
  };

  setNotes = (value: string) => {
    this.notes = value;
  };

  setTitle = (value: string) => {
    this.title = value;
  };

  setDescription = (value: string) => {
    this.description = value;
  };

  setGroupId = (value: string) => {
    this.groupId = value;
  };

  setAllowScoreOverwrite = (value?: boolean) => {
    if (value === undefined) {
      this.allowScoreOverwrite = false;
    } else {
      this.allowScoreOverwrite = value;
    }
  };

  update = (task: TaskType) => {
    this.id = task.id || task.taskId;
    this.groupId = task.groupId;
    this.taskId = task.taskId;
    this.score = 0;
    if (typeof task.score === "string") {
      this.score = parseFloat(task.score);
    } else {
      this.score = task.score || 0;
    }

    this.title = task.title;
    this.description = task.description;
    this.createdBy = task.createdBy;
    this.updatedAt = task.updatedAt;
    this.notes = task.notes;
    this.allowScoreOverwrite = task.allowScoreOverwrite || false;
  };
}

export const getInputLabelForGroupType = (
  groupType: GroupOptionsType,
  unit?: string
) => {
  if (groupType === "currency") {
    return "$ Amount";
  }
  if (groupType === "points") {
    return "Points";
  }
  if (groupType === "time") {
    return "Time";
  }
  if (groupType === "timestamp") {
    return "Date and Time";
  }
  if (groupType === "numericValue") {
    return "Numeric Value";
  }

  if (groupType === "measurement") {
    return unit || "Unknown measurement unit";
  }

  if (groupType === "date") {
    return "Date";
  }
  return groupType;
};

export const getChartLabelForGroupType = (
  groupType: GroupOptionsType,
  points: number
) => {
  if (groupType === "currency") {
    return `$ ${points || 0}`;
  }
  if (groupType === "points") {
    return `${points} Point(s)`;
  }
  if (groupType === "time") {
    return `${points} Hour(s)`;
  }
  if (groupType === "timestamp") {
    return "Date";
  }
  if (groupType === "numericValue") {
    return "Numeric Value";
  }
  return groupType;
};

export const getLabelForGroupType = (
  points: number,
  type: GroupOptionsType,
  short?: boolean,
  unit?: string
): string => {
  if (type === "numericValue") {
    return points.toString();
  }
  if (type === "currency") {
    let p = points;
    if (typeof points === "string") {
      p = parseFloat(points);
    }
    return points === 0 ? "Amount" : `$ ${p.toFixed(2)}`;
  }
  if (type === "points") {
    return points === 1 ? `${points} Point` : `${points} Points `;
  }
  if (type === "time") {
    return Time.getStringValue(points, short);
  }
  if (type === "date") {
    return "Date";
  }
  if (type === "measurement") {
    return `${points} ${unit || "Unknown unit"}`;
  }
  if (type === "timestamp") {
    if (points === 1) {
      return moment(new Date().getTime()).fromNow();
    }
  }

  return type;
};

export const getVerbForGroupType = (type: GroupOptionsType): string => {
  if (type === "numericValue") {
    return "logged";
  }
  if (type === "measurement") {
    return "logged";
  }
  if (type === "currency") {
    return "spent";
  }
  if (type === "points") {
    return "earned";
  }
  if (type === "time") {
    return "logged";
  }
  if (type === "timestamp") {
    return "logged";
  }
  if (type === "date") {
    return "logged";
  }
  return type;
};

export const getTimeLabel = (timeInSeconds: number): string => {
  return `${timeInSeconds} seconds`;
};
