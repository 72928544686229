import {
  getAuth,
  createUserWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  updateProfile,
  sendPasswordResetEmail,
} from "firebase/auth";
import { getDatabase, ref, onValue } from "firebase/database";
import { makeAutoObservable } from "mobx";
import { currentLocale } from "./common/i18n/Locale";
import { DocumentTypeOptions } from "./Firestore";
import UserProfile, {
  GET_FIREBASE_STORAGE_IMAGE_PROFILE_PATH,
  GET_FIREBASE_STORAGE_RESIZED_IMAGE_PROFILE_PATH,
} from "./UserProfile";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

import ImagePickerHandler from "./common/ImagePicker";

// export const GET_FIREBASE_STORAGE_IMAGE_PROFILE_PATH_URI = (
//   email: string,
//   token: string
// ) => {
//   return `https://firebasestorage.googleapis.com/v0/b/${
//     firebaseConfig.storageBucket
//   }/o/${encodeURIComponent(
//     GET_FIREBASE_STORAGE_IMAGE_PROFILE_PATH(email)
//   )}?alt=media&token=${token}`;
// };
export interface UserType {
  displayName: string;
  language: string;
  email?: string;
  photoURL?: string;
  uid?: string;
}

class User {
  authenticated: boolean;
  authorized: boolean;
  displayName: string;
  language: string;
  email?: string;
  errorCode?: string;
  errorMessage?: string;
  firebaseUser: any;
  photoURL?: string;
  uid?: string;
  firebase: any;
  documentRefs: { id: string; type: DocumentTypeOptions }[];
  allowDiscovery: boolean;
  analytics: any;
  connected: boolean;
  lastUpdated?: number;
  profile: UserProfile;
  profilePhotoImagePicker: ImagePickerHandler;
  profilePhotoToken?: string;
  onLogout: () => void;

  constructor(onLogout: () => void) {
    this.authenticated = false; // NOTE: You can be authenticated but not authorized
    this.authorized = false;
    this.language = currentLocale.locale;
    this.allowDiscovery = false;
    this.displayName = "";
    this.documentRefs = [];
    this.connected = false;
    this.lastUpdated = undefined;
    this.email = undefined;
    this.profile = new UserProfile({});
    this.onLogout = onLogout;
    this.profilePhotoImagePicker = new ImagePickerHandler(
      this.uploadProfileImageAsync,
      this.onDeleteImageProfile
    );
    this.profilePhotoToken = undefined;

    makeAutoObservable(this);
  }

  get hasAccess(): boolean {
    return this.authenticated && this.authorized;
  }

  get isPremiumMember(): boolean {
    return this.profile.membership === "premium";
  }

  initFirebase = () => {
    // Attach observer
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = auth.currentUser?.uid;
        this.setAuthorized(true);
        this.setFirebaseUser(user);
        this.setDisplayName(user?.displayName || undefined);
        this.setPhotoURL(user?.photoURL || undefined);
        this.setUid(uid);
        this.setEmail(user?.email || undefined);
        this.setAuthenticated(true);

        this.detectConnectionState();
      } else {
        // User is signed out
        this.setAuthorized(false);
        this.setAuthenticated(true);
      }
    });
  };

  setFirebase = (firebase: any) => {
    this.firebase = firebase;
  };

  setFirebaseUser = (user: any) => {
    this.firebaseUser = user;
  };

  setAuthorized = (value: boolean) => {
    this.authorized = value;
  };

  setLanguage = (value: string) => {
    this.language = value;
    currentLocale.setStringsForLocale(value);
  };

  setEmail = (value?: string) => {
    this.email = value;
  };

  setDisplayName = (value?: string) => {
    this.displayName = value || "";
  };

  setUid = (value?: string) => {
    this.uid = value;
  };

  setErrorCode = (value?: string) => {
    this.errorCode = value;
  };

  setErrorMessage = (value?: string) => {
    this.errorMessage = value;
  };

  setAuthenticated = (value: boolean) => {
    this.authenticated = value;
  };

  setPhotoURL = (value?: string) => {
    this.photoURL = value;
  };

  static getProfileImageUrl = async (userId: string) => {
    const storage = getStorage();
    const imageRefUrl = GET_FIREBASE_STORAGE_RESIZED_IMAGE_PROFILE_PATH(userId);
    const sRef = storageRef(storage, imageRefUrl);
    return await getDownloadURL(sRef);
  };

  setAllowDiscovery = (value?: boolean) => {
    this.allowDiscovery = value || false;
  };

  sendPasswordResetEmail = (email: string) => {
    const auth = getAuth();
    return sendPasswordResetEmail(auth, email);
  };

  signupWithEmailAndPassword = async (email: string, password: string) => {
    this.setAuthenticated(false);
    // using firebase v8
    const auth = getAuth();
    await createUserWithEmailAndPassword(auth, email.toLowerCase(), password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        this.setUid(user.uid);
        this.setEmail(user?.email || undefined);
        this.setAuthenticated(true);
        this.setAuthorized(true);

        // CREATE USER PROFILE WHEN SIGNING UP
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;

        this.setAuthenticated(true);
        this.setAuthorized(false);
        this.setErrorMessage(errorMessage);
        this.setErrorCode(errorCode);
      });
  };

  loadUserProfileImage = async (userEmail?: string | null) => {
    if (userEmail) {
      const imageToken = await this.profile.getProfileImageUrl(userEmail);
      this.profilePhotoImagePicker.setImageUrl(imageToken);
    }
  };

  signIn = async (email: string, password: string): Promise<boolean> => {
    this.setAuthenticated(false);
    // using firebase v8
    const auth = getAuth();

    const signedIn = await signInWithEmailAndPassword(
      auth,
      email.toLocaleLowerCase(),
      password
    )
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        this.setUid(user.uid);
        this.setEmail(user?.email || undefined);
        this.setAuthenticated(true);
        this.setAuthorized(true);
        this.setErrorMessage(undefined);
        this.setErrorCode(undefined);

        this.loadUserProfileImage(user?.email);
        return true;
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        this.setAuthenticated(true);
        this.setAuthorized(false);
        this.setErrorMessage(errorMessage);
        this.setErrorCode(errorCode);
        return false;
      });
    return signedIn;
  };

  updateDisplayName = (displayName: string) => {
    if (this.firebaseUser) {
      // Updates the user attributes:
      updateProfile(this.firebaseUser, {
        displayName,
      }).then(
        () => {
          // Profile updated successfully!
          const displayName = this.firebaseUser.displayName;
          this.setDisplayName(displayName);
        },
        (error: any) => {
          // An error happened.
          console.log({ error });
        }
      );
    }
  };

  updatePhotoUrl = (photoURL: string) => {
    if (this.firebaseUser) {
      // Updates the user attributes:
      this.firebaseUser
        .updateProfile({
          photoURL,
        })
        .then(
          () => {
            // Profile updated successfully!
            const photoURL = this.firebaseUser.photoURL;
            this.setPhotoURL(photoURL);
          },
          (error: any) => {
            // An error happened.
            console.log({ error });
          }
        );
    }
  };

  addToDocumentRef = (documentId: string, type: DocumentTypeOptions) => {
    // only keep last edited documents
    // TODO create queue and push and pop
    let found = false;
    // don't allow to add duplicates
    this.documentRefs.forEach((dRef) => {
      if (dRef.id === documentId) {
        found = true;
      }
    });
    if (found) {
      return;
    }
    this.documentRefs = [...this.documentRefs, { id: documentId, type }];
  };

  logout = () => {
    this.onLogout();
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        this.setUid(undefined);
        this.setAuthorized(false);
        this.setAuthenticated(true);
        this.setEmail(undefined);
        this.setDisplayName(undefined);
        this.setPhotoURL(undefined);
      })
      .catch((error) => {
        // An error happened.
      });
  };

  detectConnectionState() {
    const db = getDatabase();
    const connectedRef = ref(db, ".info/connected");
    onValue(connectedRef, (snap) => {
      if (snap.val() === true) {
        this.setConnected(true);
      } else {
        this.setConnected(false);
      }
    });
  }

  setConnected = (value: boolean) => {
    this.connected = value;
  };

  setLastUpdated = (value: number) => {
    this.lastUpdated = value;
  };

  uploadProfileImageAsync = async (uri: string) => {
    try {
      console.log("UPLOADING IMAGE TO FIREBASE STORAGE", uri);
      const auth = getAuth();
      // Get current username
      const currentUser = auth.currentUser;
      // Why are we using XMLHttpRequest? See:
      // https://github.com/expo/expo/issues/2402#issuecomment-443726662
      const blob = await ImagePickerHandler.getBlobFromUri(uri);
      this.profilePhotoImagePicker.setImageBlob(blob);
      const imageProfilePath = GET_FIREBASE_STORAGE_IMAGE_PROFILE_PATH(
        currentUser?.email || "Guest"
      );
      const fileRef = storageRef(getStorage(), imageProfilePath);
      await uploadBytes(fileRef, blob);
      // await uploadString(fileRef, imageProfilePath);
      return await getDownloadURL(fileRef);
    } catch (err) {
      console.log({ err });
    }
  };

  onDeleteImageProfile = async () => {
    const email = this.email;
    if (email) {
      const storage = getStorage();

      // Create a reference to the file to delete
      const desertRef = storageRef(
        storage,
        GET_FIREBASE_STORAGE_IMAGE_PROFILE_PATH(email)
      );

      // Delete the file
      await deleteObject(desertRef);
    }
  };
}

export default User;
