import { TouchableHighlight } from "react-native";
import { View } from "../components/Themed";
import { observer } from "mobx-react";
import { useAppStore } from "../store/AppStore";
import { css } from "../lib/glamor-native";
import { colors, spacings } from "../defaultStyles";
import { useNavigation } from "@react-navigation/native";
import { ButtonLogTask } from "../components/Buttons";
import Group from "../store/Group";
import Dev from "../components/Dev";
import {
  GroupCardHeader,
  MembersListCompact,
} from "../screens/group/GroupSummary";

const GroupCard: React.FC<{ group: Group }> = observer(({ group }) => {
  const store = useAppStore();
  const navigation = useNavigation();
  if (!group) {
    return null;
  }
  const groupId = group.groupId;
  return (
    <TouchableHighlight
      activeOpacity={0.6}
      underlayColor="transparent"
      onPress={async () => {
        const groupId = group.groupId;
        store.feed.setSelectedGroupId(groupId);
        store.ui.setSelectedGroupId(groupId);
        navigation.navigate("Group", {
          groupId,
        });
        store.tasks.loadTasksForGroup(groupId);
      }}
    >
      <View
        style={{
          backgroundColor: colors.surface,
          flexDirection: "column",
          padding: spacings.relaxed,
          borderRadius: spacings.roundBorderXl,
          marginBottom: spacings.tiny,
        }}
      >
        <Dev messages={[{ value: groupId, label: "groupId" }]} />
        <GroupCardHeader group={group} />
        <View
          style={css({
            backgroundColor: "transparent",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          })}
        >
          <MembersListCompact groupId={groupId} />
          <GroupCardActions group={group} />
        </View>
      </View>
    </TouchableHighlight>
  );
});

export default GroupCard;

const GroupCardActions: React.FC<{ group: Group }> = observer(({ group }) => {
  const store = useAppStore();
  const groupId = group.groupId;
  return (
    <ButtonLogTask
      size={40}
      groupId={groupId}
      onPress={async () => {
        await store.tasks.loadTasksForGroup(groupId);
        store.initCompletedTaskForm(groupId);
      }}
      variant="tertiary"
    />
  );
});
