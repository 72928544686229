import { Alert } from "react-native";

export interface AlertButton {
  text: string;
  onPress: () => void;
  style: "default" | "cancel" | "destructive" | undefined;
}

export interface AlertType {
  title: string;
  message: string;
  buttons: AlertButton[];
}

export const AlertMessage = (alert: AlertType) =>
  Alert.alert(alert.title, alert.message, alert.buttons);
