import moment from "moment";
import Colorify from "./components/common/graphics/utils";
import { isValidEmail, UserProfilesType } from "./store/AppStore";
import { StartEndTimeRangeType, Time } from "./store/common/Time";
import CompletedTask from "./store/CompletedTask";
import Group, { GroupOptionsType } from "./store/Group";
import UserProfile from "./store/UserProfile";

export type ProfileDisplayNameMappingType = { [displayName: string]: string[] };

export function isGroupTrackedByCreationDate(
  groupType: GroupOptionsType
): boolean {
  return groupType === "timestamp" || groupType === "date";
}

export function isGroupTrackedByDate(groupType: GroupOptionsType) {
  return groupType === "timestamp" || groupType === "date";
}

export function isGroupCountable(groupType: GroupOptionsType): boolean {
  return (
    groupType === "time" ||
    groupType === "currency" ||
    groupType === "points" ||
    groupType === "measurement"
  );
}

/**
 * showTimestampInCard is used to track if we want to display a stamp
 * in the event card, we don't want to display this stamp if the group
 * already has the date as the loging type, otherwise the information
 * becomes redundant
 * @param groupType the group type
 * @returns boolean
 */
export function showTimestampInCard(groupType: GroupOptionsType): boolean {
  return !isGroupTrackedByCreationDate(groupType);
}

export function showPointsTag(groupType: GroupOptionsType): boolean {
  return groupType === "currency" || groupType === "points";
}

export function allowPotentialScoreOverwrite(
  groupType: GroupOptionsType
): boolean {
  return !isGroupTrackedByCreationDate(groupType);
}

export function showInputForScoreOverwrite(groupType: GroupOptionsType) {
  return (
    groupType === "points" ||
    groupType === "currency" ||
    groupType === "numericValue"
  );
}
export function showTimebasedInput(groupType: GroupOptionsType): boolean {
  return groupType === "time";
}

export function isGroupTrackedByTime(groupType: GroupOptionsType): boolean {
  return groupType === "time";
}

export const getColorForUser = (totalInGroup: number, rank: number) => {
  const t = rank / totalInGroup;
  return Colorify.colorRanking(t);
};

export const getStartTime = (date: number) => {
  console.log(
    "start time",

    moment(date).startOf("day").toDate()
  );
  return moment(date).startOf("day").milliseconds();
};

export const getEndTime = (date: number) => {
  console.log(
    "end time",

    moment(date).endOf("day").toDate()
  );
  return moment(date).endOf("day").milliseconds();
};

export const getRangeLabel = (range: StartEndTimeRangeType) => {
  switch (range) {
    case "today":
      return "Past 24h";
    case "recents":
      return "Past Week";
    case "pastMonth":
      return "Past Month";
    case "comingUp":
      return "Coming Up";
    case "inRange":
      return "In date range";
    default:
      return "All";
  }
};

export const getCurrentUTCTimeStamp = (date?: number) => {
  if (date) {
    const currentUTCString = new Date(date).toUTCString();
    return new Date(currentUTCString).getTime();
  }

  const currentUTCString = new Date().toUTCString();
  return new Date(currentUTCString).getTime();
};

export const getFeedTitleForMainGroup = (
  range: StartEndTimeRangeType
): string => {
  if (range === "today") {
    return "Past 24h";
  }

  if (range === "recents") {
    return "Past week";
  }

  if (range === "pastMonth") {
    return "Past month";
  }

  if (range === "inRange") {
    return "Selected dates";
  }

  if (range === "comingUp") {
    return "Coming up";
  }

  return "All";
};

export const getMeasurementsLabel = (group: Group, value?: any) => {
  const units = group.unit;
  if (!value) {
    return units || "Unknown";
  }

  return `${value} ${units}`;
};

/**
 *
 * @param uri image url string
 * @returns This function returns an array of extensions. You can just use the first item of the array.
 */
export function getImgXtension(
  uri: string
): RegExpExecArray | null | undefined {
  let basename = uri.split(/[\\/]/).pop();
  if (basename) {
    return /[.]/.exec(basename) ? /[^.]+$/.exec(basename) : undefined;
  }
  return undefined;
}

export function isUserInGroup(currentUserEmail: string, members: string[]) {
  return members.indexOf(currentUserEmail) >= 0;
}

export function isUserOnlyAdmin(userEmail: string, groupAdmins: string[]) {
  return groupAdmins.length === 1 && groupAdmins[0] === userEmail;
}

export const isInRange = (
  range: StartEndTimeRangeType,
  createdAt: number,
  start?: number,
  end?: number
) => {
  if (range === "all") {
    return true;
  }

  let { startTime, endTime } = Time.getStartEndTimeFrom(range);

  if (start) {
    startTime = start;
  }

  if (end) {
    endTime = end;
  }
  return createdAt >= startTime && createdAt <= endTime;
};

export const updateCountForPopularTask = (
  mostPopular: { [id: string]: number },
  completedTask: CompletedTask
) => {
  if (mostPopular[completedTask.taskId] === undefined) {
    mostPopular[completedTask.taskId] = 1;
  } else {
    mostPopular[completedTask.taskId] = mostPopular[completedTask.taskId] + 1;
  }

  return mostPopular;
};

export const updateCountForRecentlyUpdatedGroups = (
  mostRecentGroups: { [id: string]: number },
  completedTask: CompletedTask
) => {
  if (mostRecentGroups[completedTask.groupId] === undefined) {
    mostRecentGroups[completedTask.groupId] = 1;
  } else {
    mostRecentGroups[completedTask.groupId] =
      mostRecentGroups[completedTask.groupId] + 1;
  }

  return mostRecentGroups;
};

export function getDaysDuration(from: number, to: number) {
  return (
    Math.abs(
      moment(new Date(from), "YYYY-MM-DD")
        .startOf("day")
        .diff(moment(new Date(to), "YYYY-MM-DD").startOf("day"), "days")
    ) + 1
  );
}

export function getTagsFromString(str: string): string[] {
  const tags: string[] = [];
  const lines = str.split("\n");
  lines.forEach((line) => {
    const words = line?.split(" ") || [];
    words.forEach((word) => {
      if (word.indexOf("@") === 0) {
        // tag found

        // the tag might be an email and it also has an @ sign, so we want to make sure
        // we keep it

        // let's remove the @ in the beggining to keep just the tag
        const tag = word.slice(1);
        tags.push(tag);
      }
    });
  });

  return tags;
}

export function getUserTagsFromString(
  str: string,
  userProfiles: UserProfilesType
): string[] {
  const tags: string[] = [];
  const lines = str.split("\n");
  lines.forEach((line) => {
    const words = line?.split(" ") || [];
    words.forEach((word) => {
      if (word.indexOf("@") === 0) {
        // tag found

        // the tag might be an email and it also has an @ sign, so we want to make sure
        // we keep it

        // let's remove the @ in the beggining to keep just the tag
        const tag = word.slice(1);
        tags.push(tag);
      }
    });
  });

  const profileTags: string[] = [];
  tags.forEach((tag) => {
    console.log({ tag, userProfiles: userProfiles[tag] });
    if (userProfiles[tag] !== undefined) {
      profileTags.push(tag);
    }
  });

  return profileTags;
}

export function getHashtagsFromString(str: string): string[] {
  const tags: string[] = [];

  const lines = str.split("\n");
  lines.forEach((line) => {
    const words = line.split(" ");
    words.forEach((word) => {
      if (word.indexOf("#") === 0) {
        // tag found
        const tag = word.split("#");
        if (tag.length > 0) {
          tags.push(tag[1]);
        }
      }
    });
  });

  return tags;
}

export function getProfileNameMapping(
  value: string,
  profiles: UserProfilesType
): string[] {
  const profileList: string[] = [];

  const words = value.split(" ");
  words.forEach((word) => {
    if (word.startsWith("@")) {
      const emailTag = word.slice(1);
      const email = removePunctuationAtTheEnd(emailTag);
      if (isValidEmail(email)) {
        const matchingProfile = profiles[email];

        if (matchingProfile !== undefined) {
          profileList.push(email);
        }
      }
    }
  });
  return profileList;
}

export function removePunctuationAtTheEnd(word: string): string {
  const punctuation = ["!", ",", ".", "?"];
  const wordLength = word.length;
  const letters = word.split("");
  let cleared: string[] = [];
  let i = wordLength - 1;
  let halt = false;
  while (i >= 0 && halt === false) {
    const currentLetter = letters[i];
    // as soon as current letter is not punctuation halt the loop and join the prefix
    if (punctuation.indexOf(currentLetter) >= 0) {
      // don't keep it
    } else {
      cleared = letters.splice(0, i + 1);
      halt = true;
    }
    i = i - 1;
  }

  return cleared.join("");
}

export function updateProfileMapping(
  current: string[],
  newValue: UserProfile
): string[] {
  return [...current, newValue.email];
}

/**
 * Revert string with profile display names to contain emails
 * @param str string that contains tags with display name
 * @param mapping array that contains the mapping with a list of emails in the sequence in which the tags appear
 * @returns string that contains emails as tags
 */
export function revertProfileDisplayNameMappingToEmail(
  str: string,
  mapping: string[],
  profiles: UserProfilesType
): { updatedString: string; updatedMapping: string[] } {
  let sentences: string[] = [];
  const consumedMappedKeys: string[] = [];
  const lines = str.split("\n");
  lines.forEach((line) => {
    const words = line.split(" ");

    const constructed: string[] = [];
    let potentialTag: string[] = [];

    let iterator = 0;
    let mapIterator = 0;
    let markStart: undefined | number = undefined;

    while (iterator < words.length) {
      if (words[iterator].startsWith("@")) {
        // if potential tag has not been cleared yet, it means it has not found a match for the tag
        // let's append it to the constructed
        if (potentialTag.length > 0) {
          constructed.push(potentialTag.join(" "));
          // clear potential tag
          potentialTag = [];
        }
        // we found a tag, let's mark it's begining
        markStart = iterator;
      }
      const removed = words[iterator];

      if (markStart === undefined) {
        // no tag detected, consume word and continue
        if (removed !== undefined) {
          constructed.push(removed);
        }
      } else {
        // tag has been detected, let's accumulate
        if (removed !== undefined) {
          potentialTag.push(removed);
          const potentialDisplayName = potentialTag.join(" ").slice(1);

          //let's check to see if the tag matches any display names
          if (mapIterator < mapping.length) {
            const currentTagEmail = mapping[mapIterator];

            const displayName = profiles[currentTagEmail]?.displayName;

            if (displayName && displayName === potentialDisplayName) {
              // we found a match!
              // let's consume it and reset marks
              constructed.push(`@${currentTagEmail}`);
              markStart = undefined;
              potentialTag = [];
              // update map iterator
              mapIterator = mapIterator + 1;

              // mark as consumed
              // remove from the beggining of the array
              consumedMappedKeys.push(currentTagEmail);
            } else {
              // we didn't find a match
              // user may have the wrong order of tags after a deletion, so let's
              // try to find the tag in another direction

              mapping.forEach((mapped, i) => {
                const displayName = profiles[mapped]?.displayName;
                if (displayName === potentialDisplayName) {
                  // we found a match further along the array, let's remove items up until found index
                  const email = profiles[mapped]?.email;
                  if (email) {
                    consumedMappedKeys.push(email);
                    mapIterator = i + 1;
                  }
                }
              });
            }
          }
        }
      }

      iterator = iterator + 1;
    }

    if (markStart !== undefined && potentialTag.length > 0) {
      // concatanate constructed with potential as no tag has been found
      const newConstructed = [...constructed, ...potentialTag];
      sentences = [...sentences, newConstructed.join(" ")];
    } else {
      sentences = [...sentences, constructed.join(" ")];
    }
  });

  return {
    updatedString: sentences.join("\n"),
    updatedMapping: consumedMappedKeys,
  };
}

/**
 * revertProfileEmailMappingToDisplayName
 * @param str string that contains tags as emails
 * @param profiles
 * @returns string with tags as display name
 */
export function revertProfileEmailMappingToDisplayName(
  str: string,
  profiles: UserProfilesType
): string {
  if (str === "") {
    return "";
  }
  const replacedWords: string[] = [];
  const lines = str.split("\n");
  lines.forEach((line) => {
    const words = line.split(" ");

    words.forEach((word) => {
      if (word.startsWith("@")) {
        const user: string = word.slice(1);
        const profile = profiles[user];
        if (profile) {
          replacedWords.push(`@${profile?.displayName || profile.email}`);
        } else {
          replacedWords.push(word);
        }
      } else {
        replacedWords.push(word);
      }
    });
  });

  return replacedWords.join(" ");
}

export function removeStaleTagsIfNeeded(
  text: string,
  currentTags: string[]
): string[] {
  return [];
}

/**
 *
 * @param text
 * @returns returns the number of occurrences of the '@' sign in the begining of a word in the string provided
 */
export function getTagCount(text: string): number {
  let count = 0;
  const lines = text.split("\n");
  lines.forEach((line) => {
    const words = line.split(" ");
    words.forEach((word) => {
      if (word.startsWith("@")) {
        count = count + 1;
      }
    });
  });
  return count;
}

/**
 *
 * @param text
 * @returns returns the number of occurrences of the '@' sign in the begining of a word in the string provided
 */
export function getUserTagCount(
  text: string,
  profiles: UserProfilesType
): number {
  let count = 0;
  const lines = text.split("\n");
  lines.forEach((line) => {
    const words = line.split(" ");
    words.forEach((word) => {
      if (word.startsWith("@")) {
        const tag = word.slice(1);
        const email = removePunctuationAtTheEnd(tag);
        if (isValidEmail(email)) {
          if (profiles[tag] !== undefined) {
            count = count + 1;
          }
        }
      }
    });
  });
  return count;
}
