import { makeAutoObservable } from "mobx";

export default class Search {
  term: string;

  constructor() {
    this.term = "";
    makeAutoObservable(this);
  }

  get hasSearchTerm(): boolean {
    return this.term !== "" && this.term !== undefined;
  }

  setTerm = (value: string) => {
    this.term = value;
  };

  clear = () => {
    this.setTerm("");
  };
}
