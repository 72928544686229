import { useRoute } from "@react-navigation/native";
import { observer } from "mobx-react-lite";
import {
  View,
  ScrollView,
  RefreshControl,
  KeyboardAvoidingView,
  Platform,
} from "react-native";
import { appStyles } from "../defaultStyles";
import { css } from "../lib/glamor-native";
import { useAppStore } from "../store/AppStore";
import GlobalAppNotificationsView from "./GlobalAppNotificationsView";
import PendingInvitationsView from "./PendingInvitationsView";

function NotificationsScreen() {
  const store = useAppStore();
  const route = useRoute();
  //@ts-ignore
  const notificationOption = route.params?.option;
  const isPendingInvitations = notificationOption === "groupActivity";
  const isGlobalAppNotifications = notificationOption === "global";

  return (
    <View style={css({ ...appStyles.viewWrapper, ...appStyles.container })}>
      <ScrollView contentContainerStyle={css(appStyles.scrollview)}>
        <KeyboardAvoidingView
          behavior={Platform.OS === "ios" ? "padding" : "height"}
          style={css(appStyles.container)}
        >
          {isPendingInvitations && <PendingInvitationsView />}
          {isGlobalAppNotifications && <GlobalAppNotificationsView />}
        </KeyboardAvoidingView>
      </ScrollView>
    </View>
  );
}

export default observer(NotificationsScreen);
