import { Platform } from "react-native";

export const appName = "Colem";
export const AD_MOB_UNIT_ID = "ca-app-pub-4509698308712224/8709194103";
export const AD_MOB_FEED_BANNER_UNIT_ID =
  "ca-app-pub-4509698308712224/1755583571";

// Google Test Id
export const TEST_ID_ADD_MOB_BANNER = "ca-app-pub-3940256099942544/2934735716";

// Is a real device and running in production.
export const adUnitTestID = Platform.select({
  // https://developers.google.com/admob/ios/test-ads
  ios: "ca-app-pub-3940256099942544/2934735716",
  // https://developers.google.com/admob/android/test-ads
  android: "ca-app-pub-3940256099942544/6300978111",
});

export const feedCardAdUnitId = Platform.select({
  ios: "ca-app-pub-4509698308712224/8709194103",
});

export const feedTopBannerAdUnitId = Platform.select({
  ios: "ca-app-pub-4509698308712224/1755583571",
});

export const adUnitId = adUnitTestID;

// Links

export const APP_DOMAIN_HOST = "https://colem.webflow.io";
export const TERMS_AND_CONDITIONS_LINK = `${APP_DOMAIN_HOST}/utility/legal`;
export const PRIVACY_POLICY_LINK = `${APP_DOMAIN_HOST}/company/privacy`;
export const CONTACT_US_LINK = `${APP_DOMAIN_HOST}/contact-us`;
export const HELP_CENTER_LINK = `${APP_DOMAIN_HOST}/help-center`;
export const PRICING_LINK = `${APP_DOMAIN_HOST}/pricing/pricing`;
