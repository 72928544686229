import { StatusBar } from "expo-status-bar";
import { observer } from "mobx-react-lite";
import { ScrollView, KeyboardAvoidingView, Platform } from "react-native";
import { Text, View } from "../components/Themed";
import { appStyles, spacings } from "../defaultStyles";
import { css } from "../lib/glamor-native";
import { useAppStore } from "../store/AppStore";
import { NoAccessScreen } from "./NoAccessScreen";

function UpdateMembership() {
  return (
    <View style={css({ ...appStyles.viewWrapper, ...appStyles.container })}>
      <ScrollView contentContainerStyle={css(appStyles.scrollview)}>
        <KeyboardAvoidingView
          behavior={Platform.OS === "ios" ? "padding" : "height"}
          style={css(appStyles.container)}
        >
          <SettingsView />
        </KeyboardAvoidingView>
      </ScrollView>
    </View>
  );
}

export default observer(UpdateMembership);

const SettingsView: React.FC = observer(() => {
  const store = useAppStore();
  if (!store.user.hasAccess) {
    return <NoAccessScreen />;
  }
  return (
    <View style={css(appStyles.container)}>
      {/* Use a light status bar on iOS to account for the black space above the modal */}
      <View
        style={css({
          backgroundColor: "transparent",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: spacings.large,
        })}
      >
        <Text style={css({ ...appStyles.heading.level0, textAlign: "center" })}>
          Membership upgrades are not available at this time
        </Text>
        <Text style={css({ ...appStyles.text.body, textAlign: "center" })}>
          We are happy to see you are enjoying the app.
        </Text>

        <Text style={css({ ...appStyles.text.body, textAlign: "center" })}>
          We are trying to figure out how to use apple pay to make this work.
          lol
        </Text>
        <Text
          style={css({
            ...appStyles.heading.level2,
            textAlign: "center",
            marginTop: spacings.default,
          })}
        >
          Check again later for updates
        </Text>
      </View>
      <StatusBar style={Platform.OS === "ios" ? "light" : "auto"} />
    </View>
  );
});
