import { useNavigation } from "@react-navigation/native";
import { autorun } from "mobx";
import { observer } from "mobx-react";
import { useState } from "react";
import {
  ScrollView,
  KeyboardAvoidingView,
  Platform,
  Linking,
} from "react-native";
import Button from "../components/common/Button";
import CheckboxButton from "../components/common/CheckboxButton";
import { Feedback } from "../components/common/Feedback";
import ColmLogo from "../components/common/graphics/ColmLogo";
import Icon from "../components/common/Icon";
import Input from "../components/common/Input";
import { Separator } from "../components/common/Separator";
import { Text, View } from "../components/Themed";
import { TERMS_AND_CONDITIONS_LINK } from "../constants/AppDefaults";
import { appStyles, colors, sizes, spacings } from "../defaultStyles";
import { css } from "../lib/glamor-native";
import { MIN_PASSWORD_LENGTH, useAppStore } from "../store/AppStore";
import { FormOptions } from "../store/common/form/FormStore";
import { strings } from "../store/common/i18n/strings";
import { RootStackScreenProps } from "../types";

function LoginScreen({ navigation }: RootStackScreenProps<"Login">) {
  const store = useAppStore();

  return (
    <View style={css({ ...appStyles.viewWrapper, ...appStyles.container })}>
      <Feedback />
      <ScrollView contentContainerStyle={css(appStyles.scrollview)}>
        <KeyboardAvoidingView
          behavior={Platform.OS === "ios" ? "padding" : "height"}
          style={css(appStyles.container)}
        >
          {!store.ui.showSignUpForm && <Login />}
          {store.ui.showSignUpForm && <SignUp />}
        </KeyboardAvoidingView>
      </ScrollView>
    </View>
  );
}

export default observer(LoginScreen);

const SignUp: React.FC = observer(() => {
  const store = useAppStore();
  const navigation = useNavigation();
  const signUpForm = store.forms.getForm(FormOptions.signUp);
  if (!signUpForm) {
    return null;
  }

  const password = signUpForm.fields.password.value;
  const passwordConfirmation = signUpForm.fields.passwordConfirmation.value;
  const passwordsCompleted: boolean =
    password !== undefined &&
    passwordConfirmation !== undefined &&
    password !== "" &&
    passwordConfirmation !== "";
  const passwordsMatch =
    password === passwordConfirmation && passwordsCompleted;
  const passwordLength = password.split("").length;
  const hasMinCharacters =
    passwordsMatch && passwordLength >= MIN_PASSWORD_LENGTH;

  return (
    <View style={css({ ...appStyles.container })}>
      <ColmLogo id="signup-logo" size={70} />
      <View style={css({ ...appStyles.card })}>
        <Text
          style={css({
            ...appStyles.heading.level0,
            marginTop: spacings.relaxed,
            marginBottom: 0,
          })}
        >
          Create an account.
        </Text>
        <Text
          style={css({
            ...appStyles.heading.level0,
            marginTop: 0,
            color: colors.bodyFaded,
            marginBottom: spacings.default,
          })}
        >
          Sign up and start creating immediately.
        </Text>

        <Input
          label="Email"
          onChangeText={(value) => {
            signUpForm.fields.email.set(value);
          }}
          value={signUpForm.fields.email.value}
          placeholder="Email"
          required
        />

        <Input
          secureTextEntry={true}
          label="Password"
          onChangeText={(value) => {
            signUpForm.fields.password.set(value);
          }}
          value={signUpForm.fields.password.value}
          placeholder="Password"
          required
        />
        <Input
          secureTextEntry={true}
          label="Verify password"
          onChangeText={(value) => {
            signUpForm.fields.passwordConfirmation.set(value);
          }}
          value={signUpForm.fields.passwordConfirmation.value}
          placeholder="Password"
          required
        />
        {passwordsCompleted && (
          <Text
            style={css({
              fontWeight: "bold",
              fontSize: sizes.font.body,
              marginBottom: spacings.default,
              color: passwordsMatch ? colors.success : colors.error,
            })}
          >
            {passwordsMatch ? (
              <Icon name="check" color={colors.success} />
            ) : (
              <Icon name="close" color={colors.error} />
            )}{" "}
            {passwordsMatch ? "Password verified" : "Passwords don't match"}
          </Text>
        )}

        {passwordsMatch && !hasMinCharacters && (
          <Text
            style={css({
              ...appStyles.text.bodyBold,
              marginBottom: spacings.default,
              color: colors.error,
            })}
          >
            <Icon name="close" color={colors.error} /> Password must have at
            least 6 characters
          </Text>
        )}

        <Button
          disabled={!signUpForm.isValid}
          onPress={() => {
            const formData = signUpForm.getData;
            store.user
              .signupWithEmailAndPassword(
                formData.email.toLowerCase(),
                formData.password
              )
              .then((result) => {
                // User signed up for the first time, let's create a user profile
                store._createUserProfile(formData.email, []);
              });
            const disposer = autorun(() => {
              if (store.user.hasAccess) {
                navigation.navigate("Root");
                disposer();
              }
            });
          }}
          label="Create Account"
        />
        <View
          style={css({
            backgroundColor: "transparent",
            flexDirection: "row",
            alignItems: "center",
          })}
        >
          <CheckboxButton
            label="I have read and agree to the"
            selected={signUpForm.fields.agreeWithTerms.value === true}
            onPress={() =>
              signUpForm.fields.agreeWithTerms.set(
                !signUpForm.fields.agreeWithTerms.value
              )
            }
          />
          <Button
            variant="tertiary"
            label="Terms & Conditions"
            onPress={() => {
              Linking.openURL(TERMS_AND_CONDITIONS_LINK);
            }}
            style={{
              marginLeft: 0,
              paddingLeft: 0,
              paddingTop: 12,
            }}
            textStyle={{
              fontSize: sizes.font.h3,
            }}
          />
        </View>
      </View>

      <Separator />
      <View
        style={css({
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: spacings.default,
          backgroundColor: "transparent",
        })}
      >
        <Text
          style={css({
            ...appStyles.text.bodyFaded,
            marginBottom: spacings.relaxed,
          })}
        >
          Already have an account?
        </Text>
        <Button
          variant="tertiary"
          label="Login"
          onPress={() => {
            store.initLoginForm();
            store.ui.setShowSignUpForm(false);
          }}
        />
      </View>
    </View>
  );
});

const Login: React.FC = observer(() => {
  const [forgot, setForgot] = useState(false);

  const store = useAppStore();
  const navigation = useNavigation();
  const loginForm = store.forms.getForm(FormOptions.login);
  if (!loginForm) {
    return null;
  }
  const isValidEmail = loginForm.validations.email(
    loginForm.fields.email.value
  );
  return (
    <View style={css({ ...appStyles.container })}>
      <ColmLogo id="log-in" size={70} />

      <View style={css({ ...appStyles.card })}>
        {
          <Text
            style={css({
              ...appStyles.heading.level0,
              marginTop: spacings.relaxed,
              marginBottom: 0,
            })}
          >
            {forgot ? "Recover password." : "Welcome."}
          </Text>
        }
        <Text
          style={css({
            ...appStyles.heading.level0,
            marginTop: 0,
            color: colors.bodyFaded,
            marginBottom: spacings.default,
          })}
        >
          {forgot
            ? strings.messages.forgotPasswordLogin
            : strings.messages.login}
        </Text>

        <Input
          label="Email"
          onChangeText={(value) => {
            loginForm.fields.email.set(value);
          }}
          value={loginForm.fields.email.value}
          placeholder="Email"
          required
        />

        {!forgot && (
          <Input
            secureTextEntry={true}
            label="Password"
            onChangeText={(value) => {
              loginForm.fields.password.set(value);
            }}
            value={loginForm.fields.password.value}
            placeholder="Password"
            required
          />
        )}
        {!forgot && (
          <Button
            disabled={!loginForm.isValid}
            onPress={async () => {
              const formData = loginForm.getData;
              const { email, password } = formData;
              const lowercaseEmail = email.toLowerCase();
              store.user.signIn(lowercaseEmail, password).then((result) => {
                if (result === false) {
                  store.feedback.setFeedback({
                    message: "Incorrect email or password. Try again.",
                    variant: "error",
                  });
                } else {
                  store.loadCurrentUserProfile(lowercaseEmail);
                  navigation.navigate("Root");
                }
              });
            }}
            label="Login"
          />
        )}

        {forgot && (
          <View
            style={css({
              backgroundColor: "transparent",
              flexDirection: "column",
            })}
          >
            <Button
              variant="secondary"
              label="Sent reset password email"
              disabled={isValidEmail}
              style={{ marginTop: spacings.default }}
              onPress={() => {
                store.feedback.setFeedback({
                  message: "Sending reset password email...",
                  variant: "info",
                });
                const formData = loginForm.getData;
                store.user
                  .sendPasswordResetEmail(formData.email)
                  .then(() => {
                    store.feedback.setFeedback({
                      message:
                        "A reset password email has been sent to your email address",
                      variant: "success",
                    });
                    setForgot(false);
                  })
                  .catch((error) => {
                    const errorCode = error.code;

                    let message = errorCode;
                    if (errorCode === "auth/too-many-requests") {
                      message =
                        "Too many attempts trying to reset your password.";
                    } else {
                      message =
                        "This email address has not been previously registered with Colem. Create a Colem account to sign in with that email.";
                    }
                    store.feedback.setFeedback({
                      message,
                      variant: "error",
                    });
                  });
              }}
            />
          </View>
        )}
      </View>
      {!forgot && (
        <Button
          variant="tertiary"
          label="Forgot password"
          onPress={() => {
            setForgot(true);
          }}
        />
      )}

      {forgot && (
        <Button
          variant="tertiary"
          label="Login"
          onPress={() => setForgot(false)}
          style={{ marginTop: spacings.large }}
        />
      )}
      <Separator />
      <SignUpInvitation />
    </View>
  );
});

export const SignUpInvitation: React.FC = observer(() => {
  const store = useAppStore();
  const navigation = useNavigation();
  return (
    <View
      style={css({
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: spacings.slim,
        backgroundColor: "transparent",
      })}
    >
      <Text
        style={css({
          ...appStyles.text.bodyFaded,
          marginBottom: spacings.relaxed,
        })}
      >
        Don't have an account?
      </Text>
      <Button
        variant="tertiary"
        label="Create Account"
        onPress={() => {
          store.initSignUpForm();
          store.ui.setShowSignUpForm(true);
          navigation.navigate("Login");
        }}
      />
    </View>
  );
});
