import { observer } from "mobx-react-lite";
import { RefreshControl, View } from "react-native";
import { appStyles, spacings } from "../defaultStyles";
import { useAppStore } from "../store/AppStore";
import { css } from "../lib/glamor-native";
import { NoAccessScreen } from "./NoAccessScreen";
import { useNavigation, useRoute } from "@react-navigation/native";
import {
  NoRecentEntriesInFeed,
  PaginationComponent,
} from "../components/FeedList";
import { AdMobBanner } from "expo-ads-admob";
import { adUnitId } from "../constants/AppDefaults";
import { FeedCard } from "../store/common/FeedCard";
import { Heading } from "../components/common/Heading";
import { descending } from "d3";
import Task from "../store/Task";
import Button from "../components/common/Button";
import { FormOptions } from "../store/common/form/FormStore";
import ScrollProvider from "../components/common/scroll/ScrollProvider";

// OPENS FROM TASK CARD
const CompletedTasksByTaskId: React.FC<{ showAds?: boolean }> = ({
  showAds = true,
}) => {
  const store = useAppStore();
  const navigation = useNavigation();
  const route = useRoute();
  //@ts-ignore
  const taskId = route.params?.taskId;

  const selectedTask = store.tasks.items[taskId];

  if (!store.user?.hasAccess) {
    return <NoAccessScreen />;
  }

  if (!selectedTask) {
    return null;
  }

  const group = store.groups.records[selectedTask.groupId];
  const recents = store.feed.items.filteredValues;

  const hasRecents = recents.length > 0;
  return (
    <View
      style={css({
        ...appStyles.viewWrapper,
        ...appStyles.container,
        paddingTop: 0,
      })}
    >
      {!store.user.isPremiumMember && showAds && (
        <View
          style={css({
            backgroundColor: "transparent",
            marginBottom: spacings.regular,
            justifyContent: "center",
            flexDirection: "row",
          })}
        >
          <AdMobBanner
            bannerSize="smartBannerPortrait"
            adUnitID={adUnitId}
            servePersonalizedAds // true or false
            onDidFailToReceiveAdWithError={(error) => {
              console.log("Error", error);
            }}
          />
        </View>
      )}

      <ScrollProvider
        contentContainerStyle={appStyles.scrollview}
        refreshControl={
          <RefreshControl
            refreshing={store.feed.items.refreshing}
            onRefresh={() => {
              store.ui.setEditTaskId(undefined);
              store.feed.loadRecentCompletedTasksForTaskIdinGroupId(
                taskId,
                group.groupId,
                30
              );
            }}
          />
        }
      >
        <CompletedTasksByTaskIdList
          groupId={selectedTask.groupId}
          task={selectedTask}
        />
        <Button
          variant="tertiary"
          label={hasRecents ? "Add another" : "Log event"}
          onPress={() => {
            store.initCompletedTaskForm(selectedTask.groupId, selectedTask);
            const form = store.forms.getForm(FormOptions.completeTask);
            const fields = form.fields;
            fields.taskId.set(selectedTask.id);
            store.ui.setEditTaskId(selectedTask.taskId);
            store.ui.setShowMenuForCompletedTaskId(undefined);
            store.ui.setShowEditTaskMenuId(undefined);
            navigation.navigate("CompleteTaskEditor", {
              formId: FormOptions.completeTask,
              taskId: selectedTask.taskId,
              createOnSave: true,
              goBackOnSave: true,
            });
          }}
        />
      </ScrollProvider>
    </View>
  );
};

export default observer(CompletedTasksByTaskId);

const NUMBER_OF_FEEDCARDS_BEFORE_ANOTHER_AD_SHOWS = 10;
const CompletedTasksByTaskIdList: React.FC<{
  groupId?: string;
  userId?: string;
  task: Task;
}> = observer(({ groupId, userId, task }) => {
  const store = useAppStore();
  const recents = store.feed.items.filteredValues;

  const hasRecents = recents.length > 0;

  return (
    <View style={css({ backgroundColor: "transparent" })}>
      <RecentlyLoggedFeedListHeader task={task} />
      {recents
        .sort((a, b) => descending(a.createdAt, b.createdAt))
        .map((completedTask, i) => {
          if (!completedTask) {
            return null;
          }
          if (groupId && completedTask.groupId !== groupId) {
            return null;
          }

          if (userId && completedTask.createdBy !== userId) {
            return null;
          }
          if (
            i % NUMBER_OF_FEEDCARDS_BEFORE_ANOTHER_AD_SHOWS === 0 &&
            i !== 0
          ) {
            // show card with an ad
            return (
              <View
                key={`coming-up-feed-${completedTask.id}`}
                style={css({ backgroundColor: "transparent" })}
              >
                <FeedCard index={i} completedTask={completedTask} />
                {!store.user.isPremiumMember && (
                  <View
                    style={css({
                      backgroundColor: "transparent",
                      marginBottom: spacings.regular,
                      justifyContent: "center",
                      flexDirection: "row",
                    })}
                  >
                    <AdMobBanner
                      bannerSize="smartBannerPortrait"
                      adUnitID={adUnitId} // Test ID, Replace with your-admob-unit-id
                      servePersonalizedAds // true or false
                      onDidFailToReceiveAdWithError={(error) => {
                        console.log("Error", error);
                      }}
                    />
                  </View>
                )}
              </View>
            );
          } else {
            return (
              <FeedCard
                index={i}
                key={`coming-up-feed-${completedTask.id}`}
                completedTask={completedTask}
              />
            );
          }
        })}

      <PaginationComponent pagination={store.feed.items.pagination} />

      {!hasRecents && <NoRecentEntriesInFeed comingUp={false} />}
    </View>
  );
});

const RecentlyLoggedFeedListHeader: React.FC<{ task: Task }> = observer(
  ({ task }) => {
    const store = useAppStore();
    const count = store.feed.items.filteredValues.length;
    const t = store.tasks.items[task.taskId];

    return (
      <View
        style={css({
          backgroundColor: "transparent",
          marginBottom: spacings.regular,
          marginTop: spacings.regular,
        })}
      >
        <Heading
          level={2}
          title={`${t?.title} (${count})`}
          description={`Recently logged ${t?.title}`}
        />
      </View>
    );
  }
);
