import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Pressable, Text, View } from "react-native";
import { appStyles, borderRadius, colors, spacings } from "../../defaultStyles";
import { css } from "../../lib/glamor-native";
import { useAppStore } from "../../store/AppStore";
import { TagglableInputEditor } from "../../store/common/TagglableInputEditor";
import UserProfile from "../../store/UserProfile";
import { getTagsFromString, updateProfileMapping } from "../../utils";
import { CachedProfileImage } from "./ImagePickerComponent";
import Input from "./Input";

export const TaggableInput: React.FC<{
  editor: TagglableInputEditor;
  label: string;
}> = observer(({ editor, label }) => {
  const tempValue = editor.text;
  const [selection, setSelection] = useState<{
    start: number;
    end: number;
  }>({ start: 0, end: 0 });

  const lastWord = editor.lastWord;

  return (
    <View style={css({ backgroundColor: "transparent" })}>
      <Suggestions
        selection={selection}
        lastWord={lastWord}
        onSelected={(tag: string, selectedUser: UserProfile) => {
          const newValue = tempValue.replace(tag, selectedUser.displayName);
          const mapping = updateProfileMapping(
            editor.profileMapping,
            selectedUser
          );

          editor.setProfileMapping(mapping);
          editor.setText(`${newValue} `);
        }}
      />
      <Input
        onSelectionChange={(e) => {
          const selection = e.nativeEvent.selection;
          setSelection(selection);
        }}
        label={label}
        multiline={true}
        numberOfLines={3}
        value={tempValue}
        onChangeText={(text) => {
          if (text === "") {
            // user cleared the text
            // reset profile mapping
            editor.setProfileMapping([]);
          }
          editor.setText(text);
        }}
      />
    </View>
  );
});

export const Suggestions: React.FC<{
  selection: {
    start: number;
    end: number;
  };
  lastWord: string;
  onSelected: (tag: string, selectedUser: UserProfile) => void;
}> = observer(({ lastWord, onSelected, selection }) => {
  const store = useAppStore();
  const profiles = store.userProfiles;

  if (!lastWord) {
    return null;
  }
  if (lastWord.charAt(0) !== "@") {
    return null;
  }
  const tags = getTagsFromString(lastWord);
  const lastTagIndex = tags.length - 1;
  const lastTag = tags[lastTagIndex];

  const profileOptions = Object.keys(profiles)
    .map((key) => store.userProfiles[key])
    .filter((profile) => {
      const name = profile?.displayName || profile?.email;
      if (name !== undefined) {
        if (name?.toLocaleLowerCase().includes(lastTag.toLocaleLowerCase())) {
          return true;
        }
      }
    });

  // Don't show dropdown with options if there are no matching profiles
  // or there are at least 3 characters in the word
  if (profileOptions.length === 0 || lastWord.length < 3) {
    return null;
  }

  return (
    <View
      style={css({
        position: "absolute",
        zIndex: 1,
        bottom: 80,
        left: selection.start,
        padding: spacings.slim,
        borderRadius: borderRadius.size1,
        backgroundColor: colors.surface100,
        shadowColor: "#171717",
        shadowOffset: { width: -2, height: 4 },
        shadowOpacity: 0.2,
        shadowRadius: 3,
      })}
    >
      {profileOptions.map((profile, i) => {
        return (
          <Pressable
            key={profile?.email || i}
            onPress={() => {
              if (profile) {
                onSelected(lastTag, profile);
              }
            }}
            style={css({
              backgroundColor: "transparent",
              padding: spacings.regular,
              // borderTopWidth: 1,
              // borderTopColor: setAlpha(colors.onBackground, 0.1),
            })}
          >
            <View
              style={css({
                backgroundColor: "transparent",
                flexDirection: "row",
              })}
            >
              <CachedProfileImage
                imageUrl={profile?.profileImageUrl}
                size={18}
              />
              <Text
                style={css({
                  ...appStyles.text.body,
                  marginLeft: spacings.slim,
                  color: colors.onSurface200,
                })}
              >
                {profile?.displayName}
              </Text>
            </View>
          </Pressable>
        );
      })}
    </View>
  );
});
