import { useNavigation } from "@react-navigation/native";
import { observer } from "mobx-react";
import { View } from "react-native";
import { colors, spacings } from "../../defaultStyles";
import { css } from "../../lib/glamor-native";

import Button from "./Button";

export const BackButton: React.FC = observer(() => {
  const navigation = useNavigation();
  if (!navigation.canGoBack()) {
    return null;
  }
  return (
    <View
      style={css({
        backgroundColor: "transparent",
        flexDirection: "row",
        justifyContent: "flex-start",
        marginTop: spacings.slim,
        marginBottom: spacings.default,
      })}
    >
      <Button
        variant="secondary"
        label="Back"
        iconProps={{
          name: "chevron-left",
          position: "left",
          size: 14,
          color: colors.onBackground,
        }}
        onPress={() => {
          navigation.goBack();
        }}
        style={{
          borderWidth: 0,
        }}
        textStyle={{
          color: colors.onBackground,
          fontSize: 16,
        }}
      />
    </View>
  );
});

export const DismissButton: React.FC = observer(() => {
  const navigation = useNavigation();
  if (!navigation.canGoBack()) {
    return null;
  }
  return (
    <View
      style={css({
        backgroundColor: "transparent",
        flexDirection: "row",
        justifyContent: "flex-end",
        marginBottom: spacings.default,
      })}
    >
      <Button
        variant="secondary"
        label="Cancel"
        iconProps={{ name: "remove", position: "left", size: 14 }}
        onPress={() => {
          navigation.goBack();
        }}
      />
    </View>
  );
});
