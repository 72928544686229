import { useNavigation, useRoute } from "@react-navigation/native";
import { observer } from "mobx-react";
import { Pressable, useWindowDimensions, View } from "react-native";
import Button from "../../components/common/Button";
import { appStyles, colors, setAlpha, spacings } from "../../defaultStyles";
import { css } from "../../lib/glamor-native";
import { useAppStore } from "../../store/AppStore";

export const GroupMemberActions: React.FC = observer(() => {
  const store = useAppStore();
  const route = useRoute();
  const navigation = useNavigation();
  const windowDimensions = useWindowDimensions();
  const params = route.params;
  if (!params) {
    return null;
  }

  //@ts-ignore
  const groupId = params.groupId;
  //@ts-ignore
  const email = params.email;

  const groups = store.groups;
  const group = groups.records[groupId];
  if (!group) {
    return null;
  }

  return (
    <Pressable
      onPress={() => {
        navigation.goBack();
      }}
      style={css({
        position: "absolute",
        bottom: 0,
        zIndex: 2000,
        width: windowDimensions.width,
        height: windowDimensions.height,
        backgroundColor: setAlpha(colors.background, 0.6),
      })}
    >
      <View
        style={css({
          ...appStyles.actionContainer,
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
          backgroundColor: colors.background,
          height: windowDimensions.height / 2.5,
        })}
      >
        <View
          style={css({
            backgroundColor: "transparent",
            flexDirection: "row",
            justifyContent: "flex-end",
          })}
        >
          <Button
            iconProps={{ name: "remove", color: colors.onBackground }}
            variant="tertiary"
            onPress={() => {
              navigation.goBack();
            }}
          />
        </View>

        <Button
          variant="tertiary"
          label="Report user"
          onPress={() => {
            store.initReportForm("user", email);
            navigation.goBack();
            navigation.navigate("ReportUser", { userId: email });
          }}
          style={{ marginBottom: spacings.regular }}
        />
        {group.permissions.canRemoveUser && (
          <Button
            variant="tertiary"
            label="Remove user"
            onPress={() => {
              groups.removeUserFromGroup(groupId, email);
              navigation.goBack();
            }}
            style={{ marginBottom: spacings.regular }}
          />
        )}
        {group.permissions.canRevokeAdminAccess && group.isUserAdmin(email) && (
          <Button
            variant="tertiary"
            label="Revoke admin priviledges"
            onPress={() => {
              groups.revokeAdminAccess(groupId, email);
              navigation.goBack();
            }}
            style={{ marginBottom: spacings.default }}
          />
        )}

        {group.permissions.canAddAdmin && !group.isUserAdmin(email) && (
          <Button
            variant="tertiary"
            label="Make admin"
            onPress={() => {
              groups.giveAdminAccess(group.groupId, [email]);
              navigation.goBack();
            }}
          />
        )}
      </View>
    </Pressable>
  );
});
