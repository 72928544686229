import { useNavigation } from "@react-navigation/native";
import { observer } from "mobx-react";
import { Platform, Pressable, TouchableHighlight, View } from "react-native";
import { colors, spacings } from "../defaultStyles";
import { css } from "../lib/glamor-native";
import { useAppStore } from "../store/AppStore";
import Group from "../store/Group";
import Task from "../store/Task";
import { ConfirmDeleteGroup, ConfirmLeaveGroup } from "./alerts/Confirmation";
import Button, { ButtonVariantType } from "./common/Button";
import Icon from "./common/Icon";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { strings } from "../store/common/i18n/strings";
import { MaterialIcons } from "@expo/vector-icons";
import { Ionicons } from "@expo/vector-icons";

export const IconButonDeleteGroup: React.FC<{ groupId: string }> = observer(
  ({ groupId }) => {
    const store = useAppStore();
    return (
      <TouchableHighlight
        activeOpacity={0.6}
        underlayColor="transparent"
        onPress={() => {
          const userEmail = store.user.email;
          if (userEmail) {
            if (Platform.OS === "web") {
              confirm(strings.confirmation.deleteCompletedTask);
            } else {
              ConfirmDeleteGroup(
                () => {
                  store.deleteGroup(userEmail, groupId);
                },
                () => {}
              );
            }
          }
        }}
      >
        <Icon name="trash" variant="secondary" />
      </TouchableHighlight>
    );
  }
);

export const HeaderButtonLogTask: React.FC = observer(() => {
  const store = useAppStore();
  const navigation = useNavigation();
  const groupId = store.ui.selectedGroupId;
  return (
    <Button
      icon={
        <MaterialIcons name="add-task" size={28} color={colors.secondary} />
      }
      variant="tertiary"
      onPress={async () => {
        store.initCompletedTaskForm(groupId);
        if (groupId) {
          navigation.navigate("LogTask", { groupId });
        } else {
          await store.loadAllTasksForAllGroups();
          navigation.navigate("LogTask");
        }
      }}
    />
  );
});

export const ButtonLogTask: React.FC<{
  size?: number;
  onPress?: () => void;
  task?: Task;
  label?: string;
  groupId?: string;
  variant?: ButtonVariantType;
}> = ({
  size = 50,
  onPress,
  task,
  groupId,
  label = "Log event",
  variant = "primary",
}) => {
  const store = useAppStore();
  const navigation = useNavigation();

  return (
    <Button
      label={label}
      icon={
        <MaterialIcons name="add-task" size={28} color={colors.secondary} />
      }
      variant={variant}
      onPress={async () => {
        store.initCompletedTaskForm(groupId, task);
        if (groupId) {
          await store.tasks.loadTasksForGroup(groupId);
          navigation.navigate("LogTask", { groupId });
        } else {
          await store.loadAllTasksForAllGroups();
          navigation.navigate("LogTask");
        }

        onPress && onPress();
      }}
    />
  );
};

export const ButtonLeaveGroup: React.FC<{ groupId: string }> = observer(
  ({ groupId }) => {
    const store = useAppStore();
    const navigation = useNavigation();
    return (
      <Button
        variant="tertiary"
        label="Leave Group"
        onPress={() => {
          ConfirmLeaveGroup(
            () => {
              store.leaveGroup(groupId);
              navigation.canGoBack() && navigation.goBack();
            },
            () => {}
          );
        }}
        style={{
          marginBottom: spacings.default,
        }}
      />
    );
  }
);

export const ButtonDeleteGroup: React.FC<{ groupId: string }> = observer(
  ({ groupId }) => {
    const store = useAppStore();
    const navigation = useNavigation();
    return (
      <Button
        label="Delete Group"
        variant="tertiary"
        onPress={() => {
          const userEmail = store.user.email;
          if (userEmail) {
            ConfirmDeleteGroup(
              () => {
                store.deleteGroup(userEmail, groupId);
                navigation.canGoBack() && navigation.goBack();
              },
              () => {}
            );
          }
        }}
      />
    );
  }
);

export const IconButtonEditGroup: React.FC<{ groupId: string }> = observer(
  ({ groupId }) => {
    const store = useAppStore();
    const navigation = useNavigation();
    return (
      <TouchableHighlight
        activeOpacity={0.6}
        underlayColor="transparent"
        onPress={() => {
          const group = store.groups.records[groupId];
          if (group) {
            store.initEditGroupForm(group);
            navigation.navigate("EditGroup");
          }
        }}
      >
        <Icon name="pencil" />
      </TouchableHighlight>
    );
  }
);

export const ButtonEditGroup: React.FC<{ groupId: string }> = observer(
  ({ groupId }) => {
    const store = useAppStore();
    const navigation = useNavigation();
    return (
      <Button
        label="Edit Group"
        variant="primary"
        onPress={() => {
          const group = store.groups.records[groupId];

          if (group) {
            store.initEditGroupForm(group);
            navigation.navigate("EditGroup");
          }
        }}
      />
    );
  }
);

export const ButtonIconSelectedGroupInfo: React.FC = observer(() => {
  const store = useAppStore();
  const navigation = useNavigation();
  const groupId = store.ui.selectedGroupId;
  let selectedGroup: Group | undefined = undefined;
  if (groupId) {
    selectedGroup = store.groups.records[groupId];
  }

  if (!selectedGroup) {
    return null;
  }
  return (
    <Pressable
      onPress={async () => {
        if (selectedGroup) {
          await selectedGroup.loadRemaingMetadata(store.api);
        }

        store.initEditGroupForm(selectedGroup);
        {
          selectedGroup &&
            navigation.navigate("GroupDetails", {
              groupId: selectedGroup?.groupId,
            });
        }
      }}
    >
      <View
        style={css({
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "transparent",
          marginRight: spacings.tiny,
        })}
      >
        <Ionicons
          name="ellipsis-horizontal"
          size={28}
          color={colors.onBackground}
        />
      </View>
    </Pressable>
  );
});

export const IconButtonAddMembersToGroup: React.FC<{ groupId: string }> =
  observer(({ groupId }) => {
    const store = useAppStore();
    const navigation = useNavigation();
    return (
      <TouchableHighlight
        style={{ marginLeft: spacings.default }}
        activeOpacity={0.6}
        underlayColor="transparent"
        onPress={() => {
          store.showGroupMembersEditor(groupId, false);
          navigation.navigate("AddMember");
        }}
      >
        <Icon name="user-plus" variant="secondary" />
      </TouchableHighlight>
    );
  });

export const IconButtonGroupInfo: React.FC<{ group: Group }> = observer(
  ({ group }) => {
    const store = useAppStore();
    const navigation = useNavigation();
    return (
      <TouchableHighlight
        activeOpacity={0.6}
        underlayColor="transparent"
        onPress={async () => {
          await group.loadRemaingMetadata(store.api);
          store.initEditGroupForm(group);

          {
            group &&
              navigation.navigate("GroupDetails", {
                groupId: group.groupId,
              });
          }
        }}
      >
        <View
          style={css({
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: spacings.default,
            backgroundColor: "transparent",
            width: 40,
          })}
        >
          <MaterialCommunityIcons
            name="information-outline"
            size={30}
            color={colors.onBackground}
          />
        </View>
      </TouchableHighlight>
    );
  }
);

export const GroupActions: React.FC<{ groupId: string }> = observer(
  ({ groupId }) => {
    return (
      <View
        style={css({
          flexDirection: "row",
          alignItems: "center",
          marginLeft: spacings.default,
          flex: 0.3,
          backgroundColor: "transparent",
        })}
      >
        <IconButtonEditGroup groupId={groupId} />
        <IconButtonAddMembersToGroup groupId={groupId} />
        <IconButonDeleteGroup groupId={groupId} />
      </View>
    );
  }
);

export const ButtonCreateTask: React.FC<{ groupId?: string }> = observer(
  ({ groupId }) => {
    const store = useAppStore();
    const navigation = useNavigation();
    return (
      <Button
        label="Create event"
        onPress={() => {
          store.initUpdateTasksForm(groupId);
          navigation.navigate("AddTask");
        }}
        style={{ marginTop: spacings.default }}
      />
    );
  }
);

export const TertiaryButtonLogTask: React.FC<{
  groupId?: string;
  label?: string;
}> = observer(({ groupId, label = "Log task" }) => {
  const store = useAppStore();
  const navigation = useNavigation();
  return (
    <Button
      variant="tertiary"
      label={label}
      onPress={() => {
        store.initCompletedTaskForm(groupId);
        navigation.navigate("LogTask");
      }}
      style={{ marginTop: spacings.default }}
    />
  );
});
export const TertiaryButtonCreateTask: React.FC<{
  groupId?: string;
  label?: string;
  onCreated?: () => void;
}> = observer(({ groupId, label, onCreated }) => {
  const store = useAppStore();
  const navigation = useNavigation();
  return (
    <Button
      variant="tertiary"
      label={label || "Create New"}
      onPress={() => {
        store.initUpdateTasksForm(groupId, undefined, onCreated);
        navigation.navigate("AddTask");
      }}
      iconProps={{
        name: "plus",
        size: 14,
        position: "left",
      }}
      style={{
        padding: 0,
        margin: 0,
        marginTop: 0,
        marginBottom: 0,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
      }}
    />
  );
});

export const ButtonLogout: React.FC = observer(() => {
  const store = useAppStore();
  const navigation = useNavigation();
  return (
    <Button
      label="Logout"
      variant="tertiary"
      onPress={() => {
        store.reset();
        store.user.logout();

        navigation.navigate("Root");
      }}
    />
  );
});
