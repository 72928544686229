import { observer } from "mobx-react-lite";
import moment from "moment";
import {
  View,
  ScrollView,
  KeyboardAvoidingView,
  Platform,
  Text,
  Pressable,
  Linking,
} from "react-native";
import ColmLogo from "../components/common/graphics/ColmLogo";
import {
  appName,
  CONTACT_US_LINK,
  HELP_CENTER_LINK,
  PRICING_LINK,
  PRIVACY_POLICY_LINK,
  TERMS_AND_CONDITIONS_LINK,
} from "../constants/AppDefaults";
import { appStyles, spacings, tableStyles } from "../defaultStyles";
import { css } from "../lib/glamor-native";
import { useAppStore } from "../store/AppStore";

function Settings() {
  return (
    <View style={css({ ...appStyles.viewWrapper, ...appStyles.container })}>
      <ScrollView contentContainerStyle={css(appStyles.scrollview)}>
        <KeyboardAvoidingView
          behavior={Platform.OS === "ios" ? "padding" : "height"}
          style={css(appStyles.container)}
        >
          <SettingsView />
        </KeyboardAvoidingView>
      </ScrollView>
    </View>
  );
}

export default observer(Settings);

const SettingsView: React.FC = observer(() => {
  return (
    <View style={css({ ...appStyles.container })}>
      <AppVersion />
      <Help />
    </View>
  );
});

const AppVersion: React.FC = observer(() => {
  const store = useAppStore();
  const settings = store.settings;
  const appVersion = settings.appVersion;
  const publishedOn = settings.versionPublishedOn;

  const strDate = moment(publishedOn).format("MMM DD, YYYY h:mm A");

  return (
    <View
      style={css({
        ...appStyles.card,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      })}
    >
      <ColmLogo id="settings-logo" size={100} />
      <Text style={css(appStyles.heading.level2)}>About this app</Text>
      {appVersion && (
        <View style={css({ backgroundColor: "transparent" })}>
          <Text style={css(appStyles.text.bodyBold)}>
            {appName} App Version {appVersion}
          </Text>
          <Text
            style={css({
              ...appStyles.text.smallFaded,
              fontStyle: "italic",
              marginTop: spacings.regular,
            })}
          >
            Published on {`${strDate} UMST ` || ""}
          </Text>
        </View>
      )}
    </View>
  );
});

const Help: React.FC = observer(() => {
  const helpItems = [
    {
      id: "help-center",
      label: "Help Center",
      action: () => {
        Linking.openURL(HELP_CENTER_LINK);
      },
    },
    {
      id: "pricing",
      label: "Pricing",
      action: () => {
        Linking.openURL(PRICING_LINK);
      },
    },
    {
      id: "contact-us",
      label: "Contact Us",
      action: () => {
        Linking.openURL(CONTACT_US_LINK);
      },
    },
    {
      id: "terms-and-conditions",
      label: "Terms and Conditions",
      action: () => {
        Linking.openURL(TERMS_AND_CONDITIONS_LINK);
      },
    },
    {
      id: "privacy-policy",
      label: "Privacy Policy",
      action: () => {
        Linking.openURL(PRIVACY_POLICY_LINK);
      },
    },
    { id: "licenses", label: "Licenses", action: () => {} },
  ];
  return (
    <View
      style={css({
        ...appStyles.card,
        flexDirection: "column",
        paddingLeft: 0,
        paddingRight: 0,
      })}
    >
      <Text
        style={css({
          ...appStyles.heading.level2,
          marginLeft: spacings.default,
        })}
      >
        Help
      </Text>
      {helpItems.map((item) => {
        return <Row key={item.id} item={item} />;
      })}
    </View>
  );
});

const Row: React.FC<{
  item: { id: string; label: string; action?: () => void };
}> = observer(({ item }) => {
  return (
    <Pressable
      style={({ pressed }) =>
        css({
          ...tableStyles.row(pressed),
        })
      }
      onPress={item.action}
    >
      <Text style={css(appStyles.text.body)}>{item.label}</Text>
    </Pressable>
  );
});
