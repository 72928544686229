import { useNavigation } from "@react-navigation/native";
import { observer } from "mobx-react";
import { Text, View } from "react-native";
import Button from "../../components/common/Button";
import { Heading } from "../../components/common/Heading";
import { appStyles, colors, spacings } from "../../defaultStyles";
import { css } from "../../lib/glamor-native";
import { useAppStore } from "../../store/AppStore";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import GroupCard from "../../components/GroupCard";

export const Favorites: React.FC = observer(() => {
  const store = useAppStore();
  const navigation = useNavigation();
  const favs = store.groups.favorites;
  const favorites = Object.keys(favs);
  const hasFavorites = favorites.length > 0;
  const favoritesTitle = hasFavorites
    ? `Favorites (${favorites.length})`
    : "Favorites";
  return (
    <View style={css({ ...appStyles.viewWrapper, ...appStyles.container })}>
      <View
        style={css({
          backgroundColor: "transparent",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-start",
        })}
      >
        <Heading
          title={favoritesTitle}
          level={1}
          description="Your favorite groups"
        />
        <Button
          icon={
            <MaterialCommunityIcons
              name="dots-horizontal"
              size={24}
              color={colors.onBackground}
            />
          }
          variant="tertiary"
          onPress={() => {
            navigation.navigate("FavoriteGroupSelector");
          }}
        />
      </View>

      {hasFavorites &&
        favorites?.map((groupId, i) => {
          const group = store.groups.records[groupId];
          if (!group) {
            return null;
          }
          return <GroupCard key={`${group.groupId}-${i}`} group={group} />;
        })}
      {!hasFavorites && <NoFavoritesFound />}
    </View>
  );
});

const NoFavoritesFound: React.FC = observer(() => {
  const navigation = useNavigation();
  return (
    <View style={css({ ...appStyles.card, flexDirection: "column" })}>
      <Text style={css(appStyles.text.body)}>
        Add favorite groups here to have quick access at any time, without
        having to search
      </Text>
      <Button
        variant="secondary"
        label="Add favorites"
        onPress={() => {
          navigation.navigate("FavoriteGroupSelector");
        }}
        style={{
          marginTop: spacings.regular,
          marginBottom: spacings.regular,
        }}
      />
    </View>
  );
});
