import { makeAutoObservable } from "mobx";
import * as Linking from "expo-linking";
import { StartEndTimeRangeType } from "./common/Time";
import {
  DocumentData,
  DocumentReference,
  onSnapshot,
  Query,
  QuerySnapshot,
} from "firebase/firestore";
import { Firestore } from "./Firestore";
import SubscriptionManager from "./common/SubscriptionManager";

export interface GroupPermissionsType {
  canEditMetadata: boolean;
  canAddMembers: boolean;
  canAddAdmin: boolean;
  canRemoveUser: boolean;
  canRevokeAdminAccess: boolean;
}

export type GroupOptionsType =
  | "currency"
  | "points"
  | "time" // will be saved in seconds
  | "timestamp"
  | "date"
  | "numericValue"
  | "measurement"
  | string;

export interface GroupType {
  members: string[];
  admins: string[];
  groupId: string;
  title: string;
  description: string;
  type: GroupOptionsType;
  createdBy: string;
  createdAt: number;
  allowJoinWithLink: boolean;
  public: boolean;
  pendingMembers?: string[];
  unit: string;
  blocked?: string[];
  favorite?: boolean;
}
export default class Group {
  members: string[];
  admins: string[];
  groupId: string;
  title: string;
  description: string;
  type: GroupOptionsType;
  createdBy: string;
  refreshing: boolean;
  range: StartEndTimeRangeType;
  createdAt: number;
  allowJoinWithLink: boolean;
  currentUserEmail: string;
  public: boolean;
  unit: string;
  pendingMembers: string[];
  blocked: string[];
  favorite: boolean;
  onAddedMembers: (members: string[]) => void;
  subscriptions: SubscriptionManager;

  constructor(
    group: GroupType,
    currentUserEmail: string,
    onAddedMembers: (members: string[]) => void
  ) {
    this.members = group.members;
    this.title = group.title;
    this.admins = group.admins;
    this.description = group.description;
    this.groupId = group.groupId;
    this.type = group.type;
    this.createdBy = group.createdBy;
    this.refreshing = false;
    this.range = "today";
    this.currentUserEmail = currentUserEmail;
    this.createdAt = group.createdAt;
    this.allowJoinWithLink = group.allowJoinWithLink;
    this.public = group.public;
    this.pendingMembers = group.pendingMembers || [];
    this.blocked = group.blocked || [];
    this.onAddedMembers = onAddedMembers;
    this.unit = group.unit || "";
    this.subscriptions = new SubscriptionManager();
    this.favorite = group.favorite || false;

    makeAutoObservable(this);
  }

  get isAdmin(): boolean {
    return this.admins.indexOf(this.currentUserEmail) >= 0;
  }

  get permissions(): GroupPermissionsType {
    let isAdmin = this.isAdmin;
    if (this.public === true) {
      // this group is public, anyone can do anything they want
      isAdmin = true;
    }
    return {
      canEditMetadata: isAdmin,
      canAddMembers: isAdmin,
      canAddAdmin: isAdmin,
      canRemoveUser: isAdmin,
      canRevokeAdminAccess: isAdmin,
    };
  }

  get hasMoreThanOneMember(): boolean {
    if (this.members && this.members !== null) {
      return this.members.length > 1;
    }
    return false;
  }

  get hasNonAdminMembers(): boolean {
    let nonAdminCount = 0;
    this.members.forEach((member) => {
      if (this.admins.indexOf(member) < 0) {
        nonAdminCount = nonAdminCount + 1;
      }
    });

    return nonAdminCount > 0;
  }

  get isCreator(): boolean {
    return this.currentUserEmail === this.createdBy;
  }

  get hasAdminsOtherThanCreator(): boolean {
    return this.admins.filter((m) => m !== this.createdBy).length > 0;
  }

  get pendingMembersById(): { [userId: string]: true } {
    let pendingMembers: { [userId: string]: true } = {};
    if (this.pendingMembers.length > 0) {
      this.pendingMembers.forEach((member) => {
        pendingMembers[member] = true;
      });
    }
    return pendingMembers;
  }

  getInvitationLink = () => {
    return Linking.createURL("GroupInvitation", {
      queryParams: { groupId: this.groupId },
    });
  };

  setGroupTitle = (value: string) => {
    this.title = value;
  };

  setGroupDescription = (value: string) => {
    this.description = value;
  };

  update = (group: GroupType) => {
    this.groupId = group.groupId;

    this.allowJoinWithLink = group.allowJoinWithLink;
    this.createdAt = group.createdAt;
    this.createdBy = group.createdBy;
    this.description = group.description;
    this.public = group.public;
    this.title = group.title;
    this.type = group.type;
    this.unit = group.unit || "";
    this.favorite = group.favorite || false;

    this.admins = group.admins;
    this.blocked = group.blocked || [];
    this.members = group.members;
    this.pendingMembers = group.pendingMembers || [];
  };

  setRange = (value: StartEndTimeRangeType) => {
    this.range = value;
  };

  setUnit = (value: string) => {
    this.unit = value;
  };

  setRefreshing = (value: boolean) => {
    this.refreshing = value;
  };

  setType = (value: GroupOptionsType) => {
    this.type = value;
  };

  setTitle = (value: string) => {
    this.title = value;
  };

  setDescription = (value: string) => {
    this.description = value;
  };

  setGroupId = (value: string) => {
    this.groupId = value;
  };

  setAllowJoinWithLink = (value: boolean) => {
    this.allowJoinWithLink = value;
  };

  setCreatedAt = (value: number) => {
    this.createdAt = value;
  };

  setCreatedBy = (value: string) => {
    this.createdBy = value;
  };

  addMember = (email: string) => {
    this.members = [...(this.members || []), email];
  };

  addPendingMember = (email: string) => {
    this.pendingMembers = [...new Set([...(this.pendingMembers || []), email])];
  };

  setMembers = (members: string[]) => {
    this.members = members;
    this.onAddedMembers(members);
  };

  setAdmins = (members: string[]) => {
    this.admins = members;
  };

  setBlocked = (members: string[]) => {
    this.blocked = members;
  };

  setPendingMembers = (members: string[]) => {
    this.pendingMembers = members;
  };
  addAdmin = (newMemberEmail: string) => {
    this.admins = [...new Set([...this.admins, newMemberEmail])];
  };

  makeAllMembersAdmins = (): void => {
    this.members.forEach((member) => {
      this.admins.push(member);
    });
  };

  removeAdmin = (member: string) => {
    this.admins = this.admins.filter((m) => m !== member);
  };

  removeMember = (member: string) => {
    this.members = this.members.filter((m) => m !== member);
  };

  removePendingMember = (member: string) => {
    this.pendingMembers = this.pendingMembers.filter((m) => m !== member);
  };

  revokeAdminPreviledgesToAllMembersExceptOwner = () => {
    this.admins = [this.createdBy];
  };

  isUserAdmin = (email: string) => {
    return this.admins.indexOf(email) >= 0;
  };

  onMembersChange = () => {
    console.log("members changed");
  };

  markFavorite = (api: Firestore, value: boolean) => {
    this.setFavorite(value);
    if (value === true) {
      this._markFavorite(api);
    } else {
      this._removeFavorite(api);
    }
  };

  setFavorite = (value: boolean) => {
    this.favorite = value;
  };

  load = (api: Firestore) => {
    this.fetchGroupMetadata(api);
    this.fetchGroupMembers(api);
  };

  loadRemaingMetadata = (api: Firestore) => {
    this.fetchGroupAdmins(api);
    this.fetchGroupBlocked(api);
    this.fetchGroupPendingMembers(api);
  };

  loadAllMetadata = (api: Firestore) => {
    this.fetchGroupMetadata(api);
    this.fetchGroupMembers(api);
    this.fetchGroupAdmins(api);
    this.fetchGroupBlocked(api);
    this.fetchGroupPendingMembers(api);
  };

  fetchGroupMetadata = (api: Firestore) => {
    const groupId = this.groupId;
    const groupMetadataQuery = api.fetchDocSnapshot("groups", groupId);

    if (groupMetadataQuery) {
      this.listenToQueryGroupMetadataChanges(groupMetadataQuery);
    }
  };

  listenToQueryGroupMetadataChanges = (
    querySnapshot: DocumentReference<DocumentData>
  ) => {
    if (querySnapshot) {
      const unsubscribe = onSnapshot(querySnapshot, (doc) => {
        const data = doc.data();
        if (data) {
          this.setAllowJoinWithLink(data.allowJoinWithLink);
          this.setCreatedAt(data.createdAt);
          this.setCreatedBy(data.createdBy);
          this.setGroupDescription(data.description || "");
          this.setGroupTitle(data.title || "");
          this.setType(data.type);
          this.setUnit(data.unit || "");
        }
      });

      this.subscriptions.setSubscriptions("groupMetadata", unsubscribe);
    }
  };

  fetchGroupMembers = (api: Firestore) => {
    const groupId = this.groupId;
    const query = api.fetchDocsQuery(`groups/${groupId}/members`);

    if (query) {
      this.listenToQueryMemberChanges(query);
    }
  };

  listenToQueryMemberChanges = (query: Query<DocumentData>) => {
    if (query) {
      const snap = (querySnapshot: QuerySnapshot<DocumentData>) => {
        let members: string[] = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          members.push(data.userId);
        });
        this.setMembers(members);
      };
      const unsubscribe = onSnapshot(query, snap);
      this.subscriptions.setSubscriptions("members", unsubscribe);
    }
  };

  fetchGroupAdmins = (api: Firestore) => {
    const groupId = this.groupId;
    const query = api.fetchDocsQuery(`groups/${groupId}/admins`);
    if (query) {
      this.listenToQueryGroupAdminChanges(query);
    }
  };

  listenToQueryGroupAdminChanges = (query: Query<DocumentData>) => {
    if (query) {
      const unsubscribe = onSnapshot(query, (querySnapshot) => {
        let admins: string[] = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          admins.push(data.userId);
        });

        this.setAdmins(admins);
        this.onAddedMembers(admins);
      });

      this.subscriptions.setSubscriptions("amdins", unsubscribe);
    }
  };

  fetchGroupBlocked = (api: Firestore) => {
    const groupId = this.groupId;
    const query = api.fetchDocsQuery(`groups/${groupId}/blocked`);
    if (query) {
      this.listenToQueryBlockedChanges(query);
    }
  };

  listenToQueryBlockedChanges = (query: Query<DocumentData>) => {
    if (query) {
      const unsubscribe = onSnapshot(query, (querySnapshot) => {
        let users: string[] = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          users.push(data.userId);
        });

        this.setBlocked(users);
        this.onAddedMembers(users);
      });

      this.subscriptions.setSubscriptions("blockedUsers", unsubscribe);
    }
  };

  fetchGroupPendingMembers = (api: Firestore) => {
    const groupId = this.groupId;
    const query = api.fetchDocsQuery(`groups/${groupId}/pendingMembers`);
    if (query) {
      this.listenToQueryPendingMembersChanges(query);
    }
  };

  listenToQueryPendingMembersChanges = (query: Query<DocumentData>) => {
    if (query) {
      const unsubscribe = onSnapshot(query, (querySnapshot) => {
        let users: string[] = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          users.push(data.userId);
        });

        this.setPendingMembers(users);
        this.onAddedMembers(users);
      });

      this.subscriptions.setSubscriptions("pendingMembers", unsubscribe);
    }
  };

  _markFavorite = (api: Firestore) => {
    api.set("users", `${this.currentUserEmail}/favorites/${this.groupId}`, {
      groupId: this.groupId,
      name: this.title || "",
    });
  };
  _removeFavorite = (api: Firestore) => {
    api.delete("users", `${this.currentUserEmail}/favorites/${this.groupId}`);
  };
}
