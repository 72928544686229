import { colors, setAlpha } from "../../defaultStyles";

import { Entypo } from "@expo/vector-icons";
export const Dot: React.FC<{ size: number }> = ({ size }) => {
  return (
    <Entypo
      name="dot-single"
      size={size}
      color={setAlpha(colors.onBackground, 0.6)}
    />
  );
};
