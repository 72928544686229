import { observer } from "mobx-react-lite";
import {
  View,
  ScrollView,
  RefreshControl,
  KeyboardAvoidingView,
  Platform,
  Text,
  Pressable,
} from "react-native";
import { Heading } from "../components/common/Heading";
import { Ionicons } from "@expo/vector-icons";
import { appStyles, NEON_GREEN, spacings, tableStyles } from "../defaultStyles";
import { css } from "../lib/glamor-native";
import { useAppStore } from "../store/AppStore";
import Group from "../store/Group";
import Search from "../components/common/SearchBox";
import { hasSearchTerm } from "../store/utils/formatters";

function FavoriteGroupSelector() {
  const store = useAppStore();

  return (
    <View style={css({ ...appStyles.viewWrapper, ...appStyles.container })}>
      <Search placeholder="Search groups" search={store.search} />
      <ScrollView contentContainerStyle={css(appStyles.scrollview)}>
        <KeyboardAvoidingView
          behavior={Platform.OS === "ios" ? "padding" : "height"}
          style={css(appStyles.container)}
        >
          <FavoriteGroupSelectorView />
        </KeyboardAvoidingView>
      </ScrollView>
    </View>
  );
}

export default observer(FavoriteGroupSelector);

const FavoriteGroupSelectorView: React.FC = observer(() => {
  const store = useAppStore();
  const favorites = Object.keys(store.groups.favorites);
  const groups = Object.keys(store.groups.records).filter(
    (key) => !favorites.includes(key)
  );
  return (
    <View style={css({ ...appStyles.container })}>
      <Heading level={2} title="Favorites" />
      <Groups groupIds={favorites} />
      <Heading level={2} title="Groups" />
      <Groups groupIds={groups} />
    </View>
  );
});

const Groups: React.FC<{ groupIds: string[] }> = observer(({ groupIds }) => {
  return (
    <View
      style={css({
        ...appStyles.card,
        flexDirection: "column",
        paddingLeft: 0,
        paddingRight: 0,
      })}
    >
      {groupIds.map((groupId) => {
        return <GroupRow key={groupId} groupId={groupId} />;
      })}
    </View>
  );
});

const GroupRow: React.FC<{
  groupId: string;
}> = observer(({ groupId }) => {
  const store = useAppStore();
  const group = store.groups.records[groupId];
  const isSearching = store.search.hasSearchTerm;
  if (!group) {
    return null;
  }
  if (isSearching) {
    const searchTerm = store.search.term;
    if (
      !hasSearchTerm(searchTerm, group.title) &&
      !hasSearchTerm(searchTerm, group.description)
    ) {
      return null;
    }
  }
  const isFavorite = group.favorite;
  return (
    <Pressable
      style={({ pressed }) =>
        css({
          ...tableStyles.row(pressed),
        })
      }
      onPress={() => store.markFavorite(groupId)}
    >
      <View
        style={css({
          backgroundColor: "transparent",
          alignItems: "flex-start",
          flexDirection: "row",
        })}
      >
        {isFavorite && <Ionicons name="remove-circle" size={24} color="red" />}
        {!isFavorite && (
          <Ionicons name="add-circle" size={24} color={NEON_GREEN} />
        )}
        <GroupName group={group} />
      </View>
    </Pressable>
  );
});

const GroupName: React.FC<{ group: Group }> = ({ group }) => {
  return (
    <View
      style={css({
        backgroundColor: "transparent",
        flexDirection: "column",
        marginLeft: spacings.regular,
        flex: 1,
      })}
    >
      <Text style={css(appStyles.text.bodyBold)}>{group.title}</Text>
      <Text style={css(appStyles.text.smallFaded)}>{group.description}</Text>
    </View>
  );
};
