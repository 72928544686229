import { useNavigation, useRoute } from "@react-navigation/native";
import { observer } from "mobx-react";
import {
  Pressable,
  RefreshControl,
  ScrollView,
  Text,
  View,
} from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import BarChart from "../../components/common/graphics/BarChart";
import DateChart from "../../components/common/graphics/DateChart";
import { getLabelColorFromForegroundColor } from "../../components/common/graphics/utils";
import Icon from "../../components/common/Icon";
import { CachedProfileImage } from "../../components/common/ImagePickerComponent";
import FeedList from "../../components/FeedList";
import { appStyles, colors, spacings } from "../../defaultStyles";
import { css } from "../../lib/glamor-native";
import { useAppStore } from "../../store/AppStore";
import Group from "../../store/Group";
import { getChartLabelForGroupType } from "../../store/Task";
import { getColorForUser, getRangeLabel, isGroupCountable } from "../../utils";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import MarkFavoriteButton from "../../components/MarkFavoriteButton";
import FilterPill from "../../components/common/FilterPill";
import SearchBox from "../../components/common/SearchBox";
import { Entypo } from "@expo/vector-icons";
import QueryDateRangeSelector from "../../store/common/QueryDateRangeSelector";

const GroupSummary: React.FC = observer(() => {
  const store = useAppStore();
  const route = useRoute();
  //@ts-ignore
  const groupId = route.params?.groupId;
  //@ts-ignore
  if (!groupId) {
    return null;
  }

  const group = store.groups.records[groupId];
  if (!group) {
    return null;
  }

  return (
    <KeyboardAwareScrollView
      style={css({
        ...appStyles.viewWrapper,
        ...appStyles.container,
      })}
      enableAutomaticScroll={true}
      extraScrollHeight={200}
      refreshControl={
        <RefreshControl
          refreshing={store.feed.items.refreshing}
          onRefresh={() => {
            store.feed.load(groupId, store.feed.range);
          }}
        />
      }
    >
      <View style={css({ ...appStyles.container })}>
        <View
          style={css({
            backgroundColor: "transparent",
            flexDirection: "row",
            marginBottom: spacings.slim,
          })}
        >
          <QueryRangeSelector group={group} />
          {store.feed.range === "inRange" && <QueryDateRangeSelector />}
        </View>

        <ScrollView contentContainerStyle={css(appStyles.scrollview)}>
          <View
            style={css({
              alignItems: "flex-start",
              backgroundColor: colors.surface,
              borderRadius: spacings.roundBorderXl,
              flexDirection: "column",
              justifyContent: "flex-start",
              marginBottom: spacings.default,
              padding: spacings.regular,
            })}
          >
            {!store.feed.items.refreshing && <CardHeaderTitle group={group} />}
            {!store.feed.items.refreshing && <Stats groupId={groupId} />}

            <CompletedTasksCounter groupId={groupId} />
          </View>
          <SearchBox search={store.search} />
          <FeedList groupId={groupId} />
        </ScrollView>
      </View>
    </KeyboardAwareScrollView>
  );
});

export default GroupSummary;

export const QueryRangeSelector: React.FC<{ group?: Group }> = observer(
  ({ group }) => {
    const store = useAppStore();
    const navigation = useNavigation();

    let range = store.feed.range;

    return (
      <View
        style={css({
          backgroundColor: "transparent",
          flexDirection: "column",
          alignItems: "flex-start",
          marginBottom: spacings.regular,
        })}
      >
        <View
          style={css({
            backgroundColor: "transparent",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          })}
        >
          {group?.groupId && (
            <FilterPill
              onPress={() => {
                navigation.navigate("Filters", { groupId: group?.groupId });
              }}
              label={getRangeLabel(range)}
            />
          )}

          <Pressable
            onPress={() => {
              store.feed.setRange("inRange");
              navigation.navigate("Calendar");
            }}
            style={{
              marginLeft: spacings.slim,
            }}
          >
            <MaterialCommunityIcons
              name="filter-variant-plus"
              size={24}
              color={colors.secondary}
            />
          </Pressable>
        </View>
      </View>
    );
  }
);

const Stats: React.FC<{ groupId: string }> = observer(({ groupId }) => {
  const store = useAppStore();
  const groupType = store.groups.records[groupId]?.type;
  if (!groupType) {
    return null;
  }
  if (!isGroupCountable(groupType)) {
    if (groupType === "timestamp") {
      const data = store.feed.items.filteredValues.map((completedTask) => ({
        id: completedTask.id,
        value: completedTask.createdAt,
        label: completedTask.createdBy,
        color: colors.secondary,
        action: () => {
          console.log({ name: completedTask.id });
        },
      }));
      return (
        <DateChart id={`chart-${groupId}`} data={data} round={1} unit="time" />
      );
    }
    return null;
  }
  const ranking = store.userRankingByCompletedTasksByGroup[groupId];
  if (!ranking) {
    return null;
  }
  const navigation = useNavigation();
  const userProfiles = store.userProfiles;
  let data = ranking
    .map((rankedUser, i) => {
      let value = rankedUser.points;
      // if we are tracking time, let's change this value to reflect
      // hours and not seconds
      if (groupType === "time") {
        value = rankedUser.points / (60 * 60);
      }

      return {
        label:
          userProfiles[rankedUser.user]?.displayName?.substring(0, 8) ||
          rankedUser.user.substring(0, 8),
        value,
        color: getColorForUser(ranking.length, i),
        action: () =>
          navigation.navigate("UserRecents", {
            email: rankedUser.user,
            groupId,
          }),
      };
    })
    .filter((item) => item.value > 0);
  if (data.length > 8) {
    // only show top 8 if greater than 8
    data = data.slice(0, 8);
  }

  return (
    <View
      style={css({
        marginBottom: spacings.relaxed,
        backgroundColor: "transparent",
        flexDirection: "row",
        justifyContent: "center",
      })}
    >
      <BarChart
        id={`chart-${groupId}`}
        data={data}
        round={1}
        groupType={groupType}
      />
    </View>
  );
});

export const GroupCardHeader: React.FC<{ group: Group; isLink?: boolean }> =
  observer(({ group, isLink = false }) => {
    const store = useAppStore();
    const navigation = useNavigation();

    return (
      <View
        style={css({
          backgroundColor: "transparent",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-start",
        })}
      >
        <View
          key={`header-${group.groupId}`}
          style={css({
            backgroundColor: "transparent",
            flexDirection: "column",
            flex: 1,
          })}
        >
          <CardHeaderTitle group={group} isLink={true} />
          <Text
            style={{
              ...appStyles.text.bodyFaded,
              marginBottom: spacings.regular,
            }}
          >
            {group.description}
          </Text>
        </View>
        <Pressable
          onPress={async () => {
            group?.loadRemaingMetadata(store.api);

            store.initEditGroupForm(group);
            {
              group &&
                navigation.navigate("GroupDetails", {
                  groupId: group?.groupId,
                });
            }
          }}
        >
          <Entypo
            name="dots-three-horizontal"
            size={16}
            color={colors.onSurface300}
          />
        </Pressable>
      </View>
    );
  });

export const CardHeaderTitle: React.FC<{
  group: Group;
  isLink?: boolean;
}> = observer(({ group, isLink = false }) => {
  return (
    <View style={css({ backgroundColor: "transparent", flex: 1 })}>
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          backgroundColor: "transparent",
          alignItems: "center",
        }}
      >
        <Text style={css({ ...appStyles.heading.level2, marginTop: 0 })}>
          {group.title}
        </Text>
        <MarkFavoriteButton group={group} />
      </View>
    </View>
  );
});

export const MembersListCompact: React.FC<{ groupId: string }> = observer(
  ({ groupId }) => {
    const store = useAppStore();
    const usersRanking = store.userRankingByCompletedTasksByGroup[groupId];

    if (!usersRanking) {
      return null;
    }

    return (
      <View
        style={css({
          flexDirection: "row",
          backgroundColor: "transparent",
          marginBottom: spacings.regular,
        })}
      >
        {usersRanking?.map((member, i) => {
          const backgroundColor = getColorForUser(usersRanking.length, i);
          const userProfile = store.userProfiles[member.user];

          const profileImageUrl = userProfile?.profileImageUrl || undefined;
          if (!profileImageUrl) {
            return (
              <UserWithNoProfileImageIcon
                key={member.user}
                userId={member.user}
                color={backgroundColor}
                size={38}
              />
            );
          }
          return (
            <View
              key={member.user}
              style={css({
                alignItems: "center",
                backgroundColor: "transparent",
                borderRadius: spacings.roundBorderXl,
                flexDirection: "row",
                height: 40,
                justifyContent: "center",
                marginRight: -10,
                width: 40,
              })}
            >
              <CachedProfileImage
                imageUrl={profileImageUrl}
                color={backgroundColor}
                size={38}
                membershipType={userProfile?.membership}
              />
            </View>
          );
        })}
      </View>
    );
  }
);

export const CompletedTasksCounter: React.FC<{ groupId: string }> = observer(
  ({ groupId }) => {
    const store = useAppStore();

    const completedTasksCount = store.feed.list.length;
    return (
      <View
        style={css({
          flexDirection: "row",
          backgroundColor: "transparent",
          marginBottom: spacings.regular,
        })}
      >
        <Text style={css(appStyles.text.body)}>
          {completedTasksCount} logged events during this period
        </Text>
      </View>
    );
  }
);

export const UserWithNoProfileImageIcon: React.FC<{
  color: string;
  userId: string;
  size?: number;
}> = observer(({ color, userId, size = 40 }) => {
  return (
    <View
      style={css({
        backgroundColor: colors.onBackground,
        width: size + 3,
        height: size + 3,
        borderRadius: size + 3,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginRight: -10,
      })}
    >
      <View
        key={userId}
        style={css({
          alignItems: "center",
          backgroundColor: color,
          flexDirection: "row",
          justifyContent: "center",
          width: size,
          height: size,
          borderRadius: size,
        })}
      >
        <Icon name="user" size={35} />
        <View
          style={css({
            backgroundColor: color,
            flexDirection: "row",
            height: 20,
            width: 20,
            borderRadius: spacings.roundBorderXl,
            marginRight: spacings.slim,
            alignItems: "center",
            position: "absolute",
            bottom: 0,
            right: 0,
            borderWidth: 2,
            justifyContent: "center",
          })}
        >
          <Text
            style={css({
              color: getLabelColorFromForegroundColor(color),
              fontWeight: "bold",
            })}
          >
            {userId.charAt(0)?.toUpperCase()}
          </Text>
        </View>
      </View>
    </View>
  );
});
