import { makeAutoObservable } from "mobx";

const MAX_PER_PAGE = 50;
const PAGE_INDEX_START = 1;

export class Pagination {
  pageNumber: number;
  currentIndex: number;
  listSize: number;

  constructor() {
    this.pageNumber = PAGE_INDEX_START;
    this.currentIndex = MAX_PER_PAGE;
    this.listSize = 0;

    makeAutoObservable(this);
  }

  get listIndexes(): { start: number; end: number } {
    const start = (this.pageNumber - 1) * MAX_PER_PAGE;
    let end = MAX_PER_PAGE + start - 1;
    if (end >= this.listSize) {
      end = this.listSize - 1;
    }
    return {
      start,
      end,
    };
  }

  get infiniteScrollIndex(): { start: number; end: number } {
    const start = (this.pageNumber - 1) * MAX_PER_PAGE;
    let end = MAX_PER_PAGE + start - 1;
    if (end >= this.listSize && this.listSize >= 1) {
      end = this.listSize;
    }
    if (this.listSize === 0) {
      end = 0;
    }
    const indexes = {
      start: 0,
      end,
    };
    return indexes;
  }

  get showLoadPrevious(): boolean {
    return this.pageNumber > PAGE_INDEX_START;
  }

  get showLoadMore(): boolean {
    return this.pageNumber < getMaxPagesAllowed(this.listSize, MAX_PER_PAGE);
  }

  get totalPages(): number {
    return getMaxPagesAllowed(this.listSize, MAX_PER_PAGE);
  }

  setCurrentIndex = (value: number) => {
    this.currentIndex = value;
  };

  setListSize = (value: number) => {
    this.listSize = value;
    this.reset();
  };

  setPageNumber = (value: number) => {
    this.pageNumber = value;
  };

  nextPage = (value?: number) => {
    if (value) {
      if (
        value >= PAGE_INDEX_START &&
        value < getMaxPagesAllowed(this.listSize, MAX_PER_PAGE)
      ) {
        this.setPageNumber(value);
      }
    } else {
      if (this.pageNumber < getMaxPagesAllowed(this.listSize, MAX_PER_PAGE)) {
        this.setPageNumber(this.pageNumber + 1);
      }
    }
  };

  previousPage = (value?: number) => {
    if (value) {
      if (
        value >= PAGE_INDEX_START &&
        value < getMaxPagesAllowed(this.listSize, MAX_PER_PAGE)
      ) {
        this.setPageNumber(value);
      }
    } else {
      if (this.pageNumber > PAGE_INDEX_START) {
        this.setPageNumber(this.pageNumber - 1);
      }
    }
  };

  loadMore = () => {
    this.nextPage();
  };

  reset = () => {
    this.setPageNumber(PAGE_INDEX_START);
  };
}

export const getMaxPagesAllowed = (listSize: number, maxItems: number) => {
  return Math.ceil(listSize / maxItems);
};
