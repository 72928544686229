import { makeAutoObservable } from "mobx";
export interface ReactionsType {
  likes: string[];
  taskId: string;
}
export default class Reactions {
  taskId: string;
  likes: string[];

  constructor(taskId: string) {
    this.likes = [];
    this.taskId = taskId;
    makeAutoObservable(this);
  }

  get likesCount(): number {
    return this.likes.length;
  }

  setLikes = (likes: string[]) => {
    this.likes = likes;
  };

  isLikedByUser = (userId?: string): boolean => {
    if (!userId) {
      return false;
    }
    return this.likes?.indexOf(userId) >= 0 || false;
  };

  toggleLike = (userId?: string): boolean => {
    if (!userId) {
      return false;
    }
    if (this.isLikedByUser(userId)) {
      // remove like
      const updatedLikes = this.likes.filter((like) => like !== userId);
      this.setLikes(updatedLikes);
      return false;
    } else {
      this.setLikes([...new Set([...this.likes, userId])]);
      return true;
    }
  };
}
