import { observer } from "mobx-react-lite";
import { ScrollView, RefreshControl, View } from "react-native";
import { appStyles, spacings } from "../defaultStyles";
import { useAppStore } from "../store/AppStore";
import { css } from "../lib/glamor-native";
import { NoAccessScreen } from "./NoAccessScreen";
import { useRoute } from "@react-navigation/native";
import FeedList, { NoRecentEntriesInFeed } from "../components/FeedList";
import { QueryRangeSelector } from "./group/GroupSummary";
import { AdMobBanner } from "expo-ads-admob";
import { adUnitId } from "../constants/AppDefaults";
import { ButtonLogTask } from "../components/Buttons";

// ROOT - OPENS FROM BOTTOM TAB
const Feed: React.FC = () => {
  const store = useAppStore();
  const route = useRoute();

  //@ts-ignore
  const groupId = route.params?.groupId;
  //@ts-ignore
  const email = route.params?.email;

  if (!store.user?.hasAccess) {
    return <NoAccessScreen />;
  }

  let hasTasks = false;
  let range = store.feed.range;
  let group = undefined;

  if (groupId) {
    // We want to filter tasks by group id
    range = store.groups.records[groupId]?.range || range;
    group = store.groups.records[groupId];
    hasTasks = store.tasks.byGroup[groupId]?.length > 0;
  } else {
    hasTasks = store.tasks?.length > 0;
  }

  if (!hasTasks) {
    return <NoRecentEntriesInFeed />;
  }

  return (
    <View
      style={css({
        ...appStyles.viewWrapper,
        ...appStyles.container,
        paddingTop: 0,
        marginTop: 0,
      })}
    >
      {!store.user.isPremiumMember && (
        <View
          style={css({
            backgroundColor: "transparent",
            marginBottom: spacings.regular,
            justifyContent: "center",
            flexDirection: "row",
          })}
        >
          <AdMobBanner
            bannerSize="smartBannerPortrait"
            adUnitID={adUnitId}
            servePersonalizedAds // true or false
            onDidFailToReceiveAdWithError={(error) => {
              console.log("ERror", error);
            }}
          />
        </View>
      )}

      {group && <QueryRangeSelector group={group} />}

      {hasTasks && (
        <ScrollView
          contentContainerStyle={appStyles.scrollview}
          refreshControl={
            <RefreshControl
              refreshing={store.feed.items.refreshing}
              onRefresh={() => {
                store.ui.setEditTaskId(undefined);
                store.loadFeedForDate(range, groupId);
              }}
            />
          }
        >
          <FeedList userId={email} groupId={groupId} />
          <ButtonLogTask
            variant="tertiary"
            label="Log another"
            groupId={groupId}
          />
        </ScrollView>
      )}
    </View>
  );
};

export default observer(Feed);
