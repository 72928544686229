import { View } from "../components/Themed";
import { observer } from "mobx-react";
import { useAppStore } from "../store/AppStore";
import { css } from "../lib/glamor-native";
import { appStyles, colors, setAlpha, spacings } from "../defaultStyles";
import { CreateGroupFromTemplateCard } from "../components/CreateGroupFromTemplateCard";
import { Text, TouchableHighlight } from "react-native";
import Icon from "../components/common/Icon";
import { FontAwesomeIcon } from "../@types/fontAwesomeIcons";
import { templates } from "../store/data/templates";
import { useNavigation } from "@react-navigation/native";
import { MaterialCommunityIcons } from "@expo/vector-icons";

function EmptyGroupsScreen() {
  const store = useAppStore();
  if (store.ui.loading || store.groups.refreshing) {
    return null;
  }
  return (
    <View
      style={css({
        ...appStyles.container,
      })}
    >
      <CreateGroupFromTemplateCard />
      <PopularTemplates />
    </View>
  );
}

export default observer(EmptyGroupsScreen);

export const PopularTemplates: React.FC = observer(() => {
  return (
    <View
      style={css({
        ...appStyles.container,
        flexDirection: "column",
        marginTop: spacings.default,
      })}
    >
      <Text
        style={css({
          ...appStyles.heading.level1,
          flex: 1,
          textAlign: "left",
        })}
      >
        Most Popular Templates
      </Text>

      <PopularTemplateCard />
    </View>
  );
});

export const PopularTemplateCard: React.FC = observer(() => {
  const navigation = useNavigation();
  return (
    <View style={css({ ...appStyles.cardList })}>
      <View
        style={css({
          ...appStyles.container,
        })}
      >
        {templates.map((template) => {
          return (
            <TouchableHighlight
              key={template.id}
              activeOpacity={0.6}
              underlayColor="transparent"
              onPress={() => {
                navigation.navigate("TemplateDetails", {
                  templateId: template.id,
                });
              }}
            >
              <PopularTemplateCardRow
                iconName={template.iconName}
                title={template.title}
                description={template.description}
              />
            </TouchableHighlight>
          );
        })}
      </View>
    </View>
  );
});

export const PopularTemplateCardRow: React.FC<{
  title: string;
  description: string;
  iconName: FontAwesomeIcon;
}> = observer(({ title, description, iconName }) => {
  return (
    <View
      style={css({
        backgroundColor: "transparent",

        borderBottomWidth: 1,
        borderBottomColor: setAlpha(colors.onBackground, 0.1),
        paddingBottom: spacings.relaxed,
        marginBottom: spacings.relaxed,
        flexDirection: "row",
      })}
    >
      <View
        style={css({
          backgroundColor: "transparent",
          alignItems: "center",
          justifyContent: "flex-start",
          paddingLeft: spacings.relaxed,
          paddingRight: spacings.relaxed,
        })}
      >
        <Icon name={iconName} />
      </View>
      <View
        style={css({
          backgroundColor: "transparent",
          flex: 1,
        })}
      >
        <Text style={css(appStyles.heading.level2)}>{title}</Text>
        <Text style={css(appStyles.text.bodyFaded)}>{description}</Text>
      </View>
      <View
        style={css({
          backgroundColor: "transparent",
          paddingRight: spacings.regular,
        })}
      >
        <MaterialCommunityIcons
          name="information"
          size={30}
          color={colors.onBackground}
        />
      </View>
    </View>
  );
});
