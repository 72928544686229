import { makeAutoObservable } from "mobx";
import * as ImagePicker from "expo-image-picker";
import { Platform, Share } from "react-native";
import { CacheManager } from "react-native-expo-image-cache";

export type ImageUploadType = Blob | Uint8Array | ArrayBuffer;
export type OnUploadType = (uri: string) => Promise<string | undefined>;
export type onDeleteType = () => Promise<void>;

export default class ImagePickerHandler {
  imageUrl?: string;
  filename?: string;
  imageBlob: ImageUploadType | undefined;
  uploading: boolean;
  onUpload: OnUploadType;
  onDelete: onDeleteType;

  constructor(
    onUpload: OnUploadType,
    onDelete: onDeleteType,
    imageUrl?: string
  ) {
    this.imageBlob = undefined;
    this.imageUrl = imageUrl;
    this.uploading = false;
    this.filename = undefined;
    this.onUpload = onUpload;
    this.onDelete = onDelete;

    makeAutoObservable(this);
  }

  get hasUpdates(): boolean {
    return (this.imageUrl && this.imageUrl?.indexOf("https://") < 0) || false;
  }

  setUploading = (value: boolean) => {
    this.uploading = value;
  };

  setImageUrl = (value?: string) => {
    this.imageUrl = value;
  };

  setImageBlob = (value?: ImageUploadType) => {
    this.imageBlob = value;
  };

  share = () => {
    Share.share({
      message: this.imageUrl,
      title: "Check out this photo",
      url: this.imageUrl || "unknown",
    });
  };

  deletePhoto = (deleteOnDelete?: boolean, onDone?: () => void) => {
    this.setImageUrl(undefined);
    this.setImageBlob(undefined);
    onDone && onDone();
    if (deleteOnDelete) {
      this.onDelete();
    }
  };

  takePhoto = async (uploadOnDone?: boolean, onDone?: () => void) => {
    if (Platform.OS !== "web") {
      const { status } =
        await ImagePicker.requestMediaLibraryPermissionsAsync();

      if (status !== "granted") {
        alert("Sorry, we need camera roll permissions to make this work!");
      } else {
        // permission to access photos granted
        let pickerResult = await ImagePicker.launchCameraAsync({
          allowsEditing: true,
          aspect: [4, 3],
        });

        this._handleImagePicked(pickerResult, uploadOnDone);
        onDone && onDone();
      }
    }
  };

  pickImage = async (uploadOnDone?: boolean, onDone?: () => void) => {
    if (Platform.OS !== "web") {
      const { status } = await ImagePicker.requestCameraPermissionsAsync();

      if (status !== "granted") {
        alert("Sorry, we need camera roll permissions to make this work!");
      } else {
        // permission to accesss photos granted
        let pickerResult: ImagePicker.ImagePickerResult =
          await ImagePicker.launchImageLibraryAsync({
            allowsEditing: true,
            aspect: [4, 3],
          });
        this._handleImagePicked(pickerResult, uploadOnDone);
        onDone && onDone();
      }
    }
  };

  private _handleImagePicked = async (
    pickerResult: ImagePicker.ImagePickerResult,
    uploadOnDone?: boolean
  ) => {
    try {
      this.setUploading(true);

      if (!pickerResult.cancelled) {
        if (pickerResult.uri) {
          if (uploadOnDone === true) {
            const uploadUrl = await this.onUpload(pickerResult.uri);
            if (uploadUrl) {
              const blob = await ImagePickerHandler.getBlobFromUri(uploadUrl);
              this.setImageBlob(blob);
              this.setImageUrl(uploadUrl);
            }
          } else {
            this.setImageUrl(pickerResult.uri);
          }
        }
      }
    } catch (e) {
      alert("Upload failed, sorry :(");
    } finally {
      this.setUploading(false);
    }
  };

  static getBlobFromUri = async (uri: string) => {
    const blob: ImageUploadType = await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        resolve(xhr.response);
      };
      xhr.onerror = function (e) {
        console.log(e);
        reject(new TypeError("Network request failed"));
      };
      xhr.responseType = "blob";
      xhr.open("GET", uri, true);
      xhr.send(null);
    });
    return blob;
  };
  /**
   * Clear local cache
   */
  static clearLocalImageCache = async () => {
    await CacheManager.clearCache();
  };

  static clearRemoteCache = async (uri: string) => {
    const downloadOptions = {};
    const path = await CacheManager.get(uri, downloadOptions).getPath();
    // if path is undefined, the image download has failed
  };
}
