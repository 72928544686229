import { StyleSheet, View } from "react-native";
import { css } from "../../lib/glamor-native";

export function Separator() {
  return <View style={styles.separator} />;
}

const styles = StyleSheet.create({
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
    backgroundColor: "rgba(255,255,255,0.1)",
  },
});

export function SeparatorSlim() {
  return (
    <View
      style={css({
        marginVertical: 10,
        height: 1,
        width: "100%",
        backgroundColor: "rgba(255,255,255,0.1)",
      })}
    />
  );
}
