import { TemplateTaskType } from "../templates";

export const importantDates: TemplateTaskType[] = [
  {
    id: "birthday",
    score: 0,
    allowScoreOverwrite: true,
    title: "Birthday",
    description: "A new bundle of joy joined this world",
  },
  {
    id: "graduation",
    score: 0,
    allowScoreOverwrite: true,
    title: "Graduation",
    description: "Someone graduated",
  },
  {
    id: "engagement",
    score: 0,
    allowScoreOverwrite: true,
    title: "Engaged",
    description: "Someone got engaged",
  },
  {
    id: "wedding-date",
    score: 0,
    allowScoreOverwrite: true,
    title: "Wedding date",
    description: "Two loving birds tied the knot",
  },
  {
    id: "death",
    score: 0,
    allowScoreOverwrite: true,
    title: "Passed",
    description: "A loving one has left this earth",
  },
];
