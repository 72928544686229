import { TemplateTaskType } from "../templates";

export const businessExpenses: TemplateTaskType[] = [
  {
    id: "travel",
    score: 0,
    allowScoreOverwrite: true,
    title: "Travel",
    description: "Expense with travel",
  },
  {
    id: "meal",
    score: 0,
    allowScoreOverwrite: true,
    title: "Meal",
    description: "Expenses with a meal",
  },
  {
    id: "purchase",
    score: 0,
    allowScoreOverwrite: true,
    title: "Purchase",
    description: "Items purchased",
  },
  {
    id: "air-transportation",
    score: 0,
    allowScoreOverwrite: true,
    title: "Air Transportation",
    description: "Expenses with air transportation",
  },
  {
    id: "groud-transportation",
    score: 0,
    allowScoreOverwrite: true,
    title: "Ground Transportation",
    description: "Expenses with ground transportation",
  },
  {
    id: "transportation",
    score: 0,
    allowScoreOverwrite: true,
    title: "Transportation",
    description: "Expenses with transportation",
  },
  {
    id: "lodging",
    score: 0,
    allowScoreOverwrite: true,
    title: "Lodging",
    description: "Expenses with Lodging",
  },
  {
    id: "other",
    score: 0,
    allowScoreOverwrite: true,
    title: "Other",
    description: "Other expenses related to business travel",
  },
];
