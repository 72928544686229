/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import {
  NavigationContainer,
  useNavigation,
  useNavigationState,
} from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import * as React from "react";
import { ColorSchemeName, Platform, Pressable, Text, View } from "react-native";
import ProfileScreen from "../screens/ProfileScreen";
import NotFoundScreen from "../screens/NotFoundScreen";
import HomeScreen from "../screens/HomeScreen";
import {
  RootStackParamList,
  RootTabParamList,
  RootTabScreenProps,
  TAB_INDEXES,
} from "../types";
import LinkingConfiguration from "./LinkingConfiguration";
import AddTaskScreen from "../screens/AddTaskScreen";
import { useAppStore, UserProfilesType } from "../store/AppStore";
import { observer } from "mobx-react-lite";
import EditGroupScreen from "../screens/EditGroupScreen";
import LoginScreen from "../screens/LoginScreen";
import AddMemberScreen from "../screens/AddMemberScreen";
import LogTaskScreen from "../screens/LogTaskScreen";
import GroupDetailsScreen from "../screens/GroupDetailsScreen";
import { css } from "../lib/glamor-native";
import Feed from "../screens/Feed";
import {
  AppDarkTheme,
  AppLightTheme,
  appStyles,
  colors,
  setAlpha,
  spacings,
} from "../defaultStyles";
import GroupScreen from "../screens/GroupScreen";
import GroupSummary from "../screens/group/GroupSummary";
import { GroupMemberActions } from "../screens/actions/GroupMemberActions";
import {
  ButtonIconSelectedGroupInfo,
  HeaderButtonLogTask,
} from "../components/Buttons";
import UpdateMembership from "../screens/UpdateMembership";
import { strings } from "../store/common/i18n/strings";
import { Actions } from "../screens/actions/Actions";
import { CachedProfileImage } from "../components/common/ImagePickerComponent";
import GroupInvitation from "../screens/group/GroupInvitation";
import TemplateDetailsScreen from "../screens/TemplateDetailsScreen";
import TemplatesBrowserScreen from "../screens/TemplatesBrowserScreen";
import { BackButton } from "../components/common/BackButton";
import ReportUser from "../screens/ReportUser";
import ReportGroup from "../screens/ReportGroup";
import ReportPost from "../screens/ReportPost";
import NotificationsScreen from "../screens/NotificationsScreen";
import { NoAccessScreen } from "../screens/NoAccessScreen";
import Calendar from "../screens/Calendar";
import Settings from "../screens/Settings";
import ColmLogo from "../components/common/graphics/ColmLogo";
import { Feather } from "@expo/vector-icons";
import { SimpleLineIcons } from "@expo/vector-icons";
import PickDate from "../screens/PickDate";
import PickTime from "../screens/PickTime";
import CompleteTaskEditor from "../components/CompleteTaskEditor";
import Filters from "../screens/Filters";
import ComingUp from "../screens/ComingUp";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { MaterialIcons } from "@expo/vector-icons";
import GroupsScreen from "../screens/GroupsScreen";
import FavoritesGroupSelector from "../screens/FavoritesGroupSelector";
import RecoverCompletedTask from "../screens/RecoverCompletedTask";
import DeleteCompletedTask from "../screens/DeleteCompletedTask";
import AssignCompletedTaskToTask from "../screens/AssignCompletedTaskToTask";
import FilterSelectionOptions from "../components/FilterSelectionOptions";
import Recents from "../screens/Recents";
import CompletedTasksByTaskId from "../screens/CompletedTasksByTaskId";
import { CommentView } from "../screens/Comment";
import Tagged from "../screens/Tagged";

let loadedFeed = false;
let loadedTagged = false;
const Navigation = observer(
  ({ colorScheme }: { colorScheme: ColorSchemeName }) => {
    return (
      <NavigationContainer
        linking={LinkingConfiguration}
        theme={colorScheme === "dark" ? AppDarkTheme : AppLightTheme}
        // theme={AppDarkTheme}
      >
        <RootNavigator />
      </NavigationContainer>
    );
  }
);

export default Navigation;

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<RootStackParamList>();

const RootNavigator = observer(() => {
  const navigationState = useNavigationState((state) => state);
  const store = useAppStore();

  React.useEffect(() => {
    // run some code every time `location` change.
    if (navigationState?.routes.length > 0) {
      const tabIndex: number | undefined =
        navigationState.routes[0].state?.index;
      if (tabIndex && tabIndex < TAB_INDEXES.length) {
        const tabId = TAB_INDEXES[tabIndex];

        switch (tabId) {
          case "Recents": {
            if (loadedFeed === false) {
              const currentUser = store.user.email;
              if (currentUser) {
                store.loadFeedForDate("recents", undefined, currentUser);
                loadedFeed = true;
              }
            }

            break;
          }
          case "Tagged": {
            if (loadedTagged === false) {
              const currentUser = store.user.email;
              if (currentUser) {
                store.loadRecentlyTagged("recents");
                loadedTagged = true;
              }
            }

            break;
          }
          default: {
            break;
          }
        }
      }
    }
    // clear all search terms so search doesn't spill over screens
    store.feed.items.search.setTerm("");
    store.tasks.search.setTerm("");
    store.search.setTerm("");
  }, [navigationState]);

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Root"
        component={BottomTabNavigator}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="NotFound"
        component={NotFoundScreen}
        options={{ title: "Oops!" }}
      />
      <Stack.Group screenOptions={{ presentation: "modal" }}>
        <Stack.Screen
          name="NoAccessScreen"
          component={NoAccessScreen}
          options={{ headerShown: false }}
        />
      </Stack.Group>
      <Stack.Group screenOptions={{ presentation: "modal" }}>
        <Stack.Screen
          name="Profile"
          component={ProfileScreen}
          options={{ headerLeft: () => <BackButton /> }}
        />
      </Stack.Group>

      <Stack.Group screenOptions={{ presentation: "modal" }}>
        <Stack.Screen
          name="AddTask"
          component={AddTaskScreen}
          options={{ title: "Event", headerLeft: () => <BackButton /> }}
        />
      </Stack.Group>

      <Stack.Group screenOptions={{ presentation: "modal" }}>
        <Stack.Screen
          name="EditGroup"
          component={EditGroupScreen}
          options={{ title: "Group", headerLeft: () => <BackButton /> }}
        />
      </Stack.Group>

      <Stack.Group screenOptions={{ presentation: "modal" }}>
        <Stack.Screen
          name="Login"
          component={LoginScreen}
          options={{ title: "Login", headerLeft: () => <BackButton /> }}
        />
      </Stack.Group>

      <Stack.Group screenOptions={{ presentation: "modal" }}>
        <Stack.Screen
          name="AddMember"
          component={AddMemberScreen}
          options={{ title: "Member", headerLeft: () => <BackButton /> }}
        />
      </Stack.Group>

      <Stack.Group screenOptions={{ presentation: "modal" }}>
        <Stack.Screen
          name="LogTask"
          component={LogTaskScreen}
          options={{
            title: "What are you logging today?",
            headerLeft: () => <BackButton />,
          }}
        />
      </Stack.Group>

      <Stack.Group screenOptions={{ presentation: "modal" }}>
        <Stack.Screen
          name="GroupDetails"
          component={GroupDetailsScreen}
          options={{ title: "Group Details", headerLeft: () => <BackButton /> }}
        />
      </Stack.Group>

      <Stack.Group screenOptions={{ presentation: "modal" }}>
        <Stack.Screen
          name="Notifications"
          component={NotificationsScreen}
          options={{
            title: "Notifications",
            headerLeft: () => <BackButton />,
          }}
        />
      </Stack.Group>
      <Stack.Group screenOptions={{ presentation: "modal" }}>
        <Stack.Screen
          name="Settings"
          component={Settings}
          options={{
            title: "About",
            headerLeft: () => <BackButton />,
          }}
        />
      </Stack.Group>

      <Stack.Screen
        name="Group"
        component={GroupScreen}
        options={{
          title: "Group",
          headerBackButtonMenuEnabled: true,
          headerRight: () => {
            return (
              <View
                style={css({
                  backgroundColor: "transparent",
                  flexDirection: "row",
                  alignItems: "center",
                })}
              >
                <HeaderButtonLogTask />
                <ButtonIconSelectedGroupInfo />
              </View>
            );
          },
        }}
      />

      <Stack.Screen
        name="UpdateMembership"
        component={UpdateMembership}
        options={{
          title: strings.screens.title.upgradeMembership,
          headerBackButtonMenuEnabled: true,
        }}
      />

      <Stack.Screen
        name="CommentView"
        component={CommentView}
        options={{ title: "Comments", headerBackButtonMenuEnabled: true }}
      />

      <Stack.Screen
        name="UserRecents"
        component={Feed}
        options={{ title: "Feed", headerBackButtonMenuEnabled: true }}
      />

      <Stack.Screen
        name="GroupSummary"
        component={GroupSummary}
        options={{ title: "Summary", headerBackButtonMenuEnabled: true }}
      />

      <Stack.Group screenOptions={{ presentation: "transparentModal" }}>
        <Stack.Screen
          name="GroupMemberActions"
          component={GroupMemberActions}
          options={{ headerBackButtonMenuEnabled: true, headerShown: false }}
        />
      </Stack.Group>

      <Stack.Group screenOptions={{ presentation: "transparentModal" }}>
        <Stack.Screen
          name="Actions"
          component={Actions}
          options={{ headerBackButtonMenuEnabled: true, headerShown: false }}
        />
      </Stack.Group>

      <Stack.Group screenOptions={{ presentation: "modal" }}>
        <Stack.Screen
          name="GroupInvitation"
          component={GroupInvitation}
          options={{ headerBackButtonMenuEnabled: true }}
        />
      </Stack.Group>

      <Stack.Group screenOptions={{ presentation: "modal" }}>
        <Stack.Screen
          name="TemplateDetails"
          component={TemplateDetailsScreen}
          options={{
            title: "Template Details",
            headerBackButtonMenuEnabled: true,
            headerLeft: () => <BackButton />,
          }}
        />
      </Stack.Group>

      <Stack.Group screenOptions={{ presentation: "modal" }}>
        <Stack.Screen
          name="TemplatesBrowser"
          component={TemplatesBrowserScreen}
          options={{
            title: "Templates",
            headerBackButtonMenuEnabled: true,
            headerLeft: () => <BackButton />,
          }}
        />
      </Stack.Group>

      <Stack.Group screenOptions={{ presentation: "modal" }}>
        <Stack.Screen
          name="ReportUser"
          component={ReportUser}
          options={{
            title: "Report User",
            headerBackButtonMenuEnabled: true,
            headerLeft: () => <BackButton />,
          }}
        />
      </Stack.Group>

      <Stack.Group screenOptions={{ presentation: "modal" }}>
        <Stack.Screen
          name="ReportGroup"
          component={ReportGroup}
          options={{
            title: "Report Group",
            headerBackButtonMenuEnabled: true,
            headerLeft: () => <BackButton />,
          }}
        />
      </Stack.Group>

      <Stack.Group screenOptions={{ presentation: "modal" }}>
        <Stack.Screen
          name="ReportPost"
          component={ReportPost}
          options={{
            title: "Report Post",
            headerBackButtonMenuEnabled: true,
            headerLeft: () => <BackButton />,
          }}
        />
      </Stack.Group>

      <Stack.Group screenOptions={{ presentation: "modal" }}>
        <Stack.Screen
          name="Calendar"
          component={Calendar}
          options={{
            title: "Other Filter Options",
            headerBackButtonMenuEnabled: true,
            headerLeft: () => <BackButton />,
          }}
        />
        <Stack.Screen
          name="Filters"
          component={Filters}
          options={{
            title: "Filter Options",
            headerBackButtonMenuEnabled: true,
            headerLeft: () => <BackButton />,
          }}
        />
        <Stack.Screen
          name="FilterSelectorOptions"
          component={FilterSelectionOptions}
          options={{
            title: "Filter Options",
            headerBackButtonMenuEnabled: true,
            headerLeft: () => <BackButton />,
          }}
        />
      </Stack.Group>

      <Stack.Group screenOptions={{ presentation: "modal" }}>
        <Stack.Screen
          name="PickDate"
          component={PickDate}
          options={{
            title: "Edit Date",
            headerBackButtonMenuEnabled: true,
            headerLeft: () => <BackButton />,
          }}
        />

        <Stack.Screen
          name="PickTime"
          component={PickTime}
          options={{
            title: "Edit time",
            headerBackButtonMenuEnabled: true,
            headerLeft: () => <BackButton />,
          }}
        />

        <Stack.Screen
          name="CompleteTaskEditor"
          component={CompleteTaskEditor}
          options={{
            title: "Edit task",
            headerBackButtonMenuEnabled: true,
            headerLeft: () => <BackButton />,
          }}
        />

        <Stack.Screen
          name="FavoriteGroupSelector"
          component={FavoritesGroupSelector}
          options={{
            title: "Favorite Groups",
            headerBackButtonMenuEnabled: true,
            headerLeft: () => <BackButton />,
          }}
        />

        <Stack.Screen
          name="RecoverCompletedTask"
          component={RecoverCompletedTask}
          options={{
            title: "Recover",
            headerBackButtonMenuEnabled: true,
            headerLeft: () => <BackButton />,
          }}
        />

        <Stack.Screen
          name="DeleteCompletedTask"
          component={DeleteCompletedTask}
          options={{
            title: "Recover",
            headerBackButtonMenuEnabled: true,
            headerLeft: () => <BackButton />,
          }}
        />

        <Stack.Screen
          name="AssignCompletedTaskToTask"
          component={AssignCompletedTaskToTask}
          options={{
            title: "Assign Completed Task",
            headerBackButtonMenuEnabled: true,
            headerLeft: () => <BackButton />,
          }}
        />
      </Stack.Group>

      <Stack.Screen
        name="CompletedTasksByTaskId"
        component={CompletedTasksByTaskId}
        options={{
          title: "Completed Tasks",
          headerBackButtonMenuEnabled: true,
          headerLeft: () => <BackButton />,
        }}
      />
    </Stack.Navigator>
  );
});

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
const BottomTab = createBottomTabNavigator<RootTabParamList>();

const BottomTabNavigator = observer(() => {
  const store = useAppStore();
  const hasAccess = store.user.hasAccess;
  const pendingInvitations = store.pendingGroupInvitations.length;
  const globalAppNotifications = Object.keys(
    store.appNotifications.globalNotifiactions
  ).length;
  const currentUserEmail = store.user.email;
  const userProfiles = store.userProfiles;

  if (!hasAccess) {
    return (
      <Stack.Navigator>
        <Stack.Group screenOptions={{ presentation: "fullScreenModal" }}>
          <Stack.Screen
            name="NoAccessScreen"
            component={NoAccessScreen}
            options={{ headerShown: false }}
          />
        </Stack.Group>
      </Stack.Navigator>
    );
  }
  return (
    <BottomTab.Navigator
      screenOptions={{
        tabBarInactiveTintColor: setAlpha(colors.onBackground, 0.5),
        tabBarActiveTintColor: colors.secondary,
        tabBarHideOnKeyboard: Platform.OS !== "ios",
        headerShown: hasAccess,
      }}
    >
      <BottomTab.Screen
        name="Home"
        component={HomeScreen}
        options={({ navigation }: RootTabScreenProps<"Home">) => ({
          title: "Home",
          tabBarIcon: ({ focused, color }) => (
            <Feather
              name="home"
              size={24}
              color={
                focused ? colors.secondary : setAlpha(colors.onBackground, 0.5)
              }
            />
          ),
          headerLeft: () => {
            return AppSettingsButton(hasAccess);
          },
          headerRight: () => {
            return DefaultHeaderRightButton(
              hasAccess,
              pendingInvitations,
              userProfiles,
              globalAppNotifications,
              currentUserEmail
            );
          },
        })}
      />

      <BottomTab.Screen
        name="Groups"
        component={GroupsScreen}
        options={({ navigation }: RootTabScreenProps<"Groups">) => ({
          title: "Groups",
          tabBarIcon: ({ focused, color }) => (
            <MaterialIcons
              name="groups"
              size={24}
              color={
                focused ? colors.secondary : setAlpha(colors.onBackground, 0.5)
              }
            />
          ),
          headerLeft: () => {
            return AppSettingsButton(hasAccess);
          },
          headerRight: () => {
            return DefaultHeaderRightButton(
              hasAccess,
              pendingInvitations,
              userProfiles,
              globalAppNotifications,
              currentUserEmail
            );
          },
        })}
      />

      <BottomTab.Screen
        name="Recents"
        component={Recents}
        options={({ navigation }: RootTabScreenProps<"Recents">) => {
          return {
            title: "Recents",
            tabBarIcon: ({ focused, color }) => (
              <MaterialIcons
                name="dynamic-feed"
                size={24}
                color={
                  focused
                    ? colors.secondary
                    : setAlpha(colors.onBackground, 0.5)
                }
              />
            ),
            headerLeft: () => {
              return AppSettingsButton(hasAccess);
            },
            headerRight: () => {
              return DefaultHeaderRightButton(
                hasAccess,
                pendingInvitations,
                userProfiles,
                globalAppNotifications,
                currentUserEmail
              );
            },
          };
        }}
      />

      <BottomTab.Screen
        name="ComingUp"
        component={ComingUp}
        options={({ navigation }: RootTabScreenProps<"ComingUp">) => {
          return {
            title: "Coming Up",
            tabBarIcon: ({ focused, color }) => (
              <MaterialCommunityIcons
                name="clock-time-four-outline"
                size={24}
                color={
                  focused
                    ? colors.secondary
                    : setAlpha(colors.onBackground, 0.5)
                }
              />
            ),
            headerLeft: () => {
              return AppSettingsButton(hasAccess);
            },
            headerRight: () => {
              return DefaultHeaderRightButton(
                hasAccess,
                pendingInvitations,
                userProfiles,
                globalAppNotifications,
                currentUserEmail
              );
            },
          };
        }}
      />

      <BottomTab.Screen
        name="Tagged"
        component={Tagged}
        options={({ navigation }: RootTabScreenProps<"Tagged">) => {
          return {
            title: "Tagged",
            tabBarIcon: ({ focused, color }) => (
              <MaterialCommunityIcons
                name="tag"
                size={24}
                color={
                  focused
                    ? colors.secondary
                    : setAlpha(colors.onBackground, 0.5)
                }
              />
            ),
            headerLeft: () => {
              return AppSettingsButton(hasAccess);
            },
            headerRight: () => {
              return DefaultHeaderRightButton(
                hasAccess,
                pendingInvitations,
                userProfiles,
                globalAppNotifications,
                currentUserEmail
              );
            },
          };
        }}
      />
    </BottomTab.Navigator>
  );
});

export const DefaultHeaderRightButton = (
  hasAccess: boolean,
  pendingInvitations: number,
  userProfiles: UserProfilesType,
  globalAppNotifications: number,
  currentUserEmail?: string
) => {
  const store = useAppStore();
  const navigation = useNavigation();
  if (!hasAccess) {
    return null;
  }
  const hasPendingInvitations = pendingInvitations > 0;
  const hasGlobalAppNotifications = globalAppNotifications > 0;
  return (
    <View style={css({ flexDirection: "row" })}>
      {(hasPendingInvitations || hasGlobalAppNotifications) && (
        <View
          style={css({
            backgroundColor: "transparent",
            marginRight: spacings.regular,
            marginTop: 2,
          })}
        >
          <Pressable
            onPress={() => {
              store.initUpdateProfileForm();
              navigation.navigate("Notifications", { option: "groupActivity" });
            }}
            style={({ pressed }) => ({
              opacity: pressed ? 0.5 : 1,
            })}
          >
            {pendingInvitations > 0 && (
              <View
                style={css({
                  position: "absolute",
                  zIndex: 10,
                  backgroundColor: "red",
                  borderRadius: 10,
                  width: 20,
                  height: 20,
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: 30,
                })}
              >
                <Text
                  style={css({
                    ...appStyles.text.bodyBold,
                    color: colors.onError,
                  })}
                >
                  {pendingInvitations}
                </Text>
              </View>
            )}
            <SimpleLineIcons
              name="bell"
              size={30}
              color={colors.onBackground}
            />
          </Pressable>
        </View>
      )}
      <Pressable
        onPress={() => {
          store.initUpdateProfileForm();
          navigation.navigate("Profile");
        }}
        style={({ pressed }) => ({
          opacity: pressed ? 0.5 : 1,
          marginRight: spacings.regular,
        })}
      >
        <CachedProfileImage
          imageUrl={
            (currentUserEmail &&
              userProfiles[currentUserEmail]?.profileImageUrl) ||
            undefined
          }
          membershipType={
            (currentUserEmail && userProfiles[currentUserEmail]?.membership) ||
            "free"
          }
          size={30}
        />
      </Pressable>
    </View>
  );
};

export const AppSettingsButton = (
  hasAccess: boolean,
  currentUserEmail?: string
) => {
  const store = useAppStore();
  const navigation = useNavigation();
  if (!hasAccess) {
    return null;
  }

  return (
    <View style={css({ flexDirection: "row" })}>
      <View
        style={css({
          backgroundColor: "transparent",
          marginLeft: spacings.regular,
          marginTop: 2,
        })}
      >
        <Pressable
          onPress={() => {
            store.initUpdateProfileForm();
            navigation.navigate("Settings");
          }}
          style={({ pressed }) => ({
            opacity: pressed ? 0.5 : 1,
          })}
        >
          <ColmLogo id="settings-logo" size={30} />
        </Pressable>
      </View>
    </View>
  );
};
