import { StatusBar } from "expo-status-bar";
import { observer } from "mobx-react";
import { AppRegistry, Platform, View } from "react-native";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { appName } from "./constants/AppDefaults";
import useCachedResources from "./hooks/useCachedResources";
import useColorScheme from "./hooks/useColorScheme";
import Navigation from "./navigation";
import AppLoading from "expo-app-loading";
import {
  useFonts,
  Inter_900Black,
  Inter_700Bold,
  Inter_500Medium,
  Inter_600SemiBold,
} from "@expo-google-fonts/inter";
import { moderateScale } from "./defaultStyles";

function App() {
  let [fontsLoaded] = useFonts({
    Inter_900Black,
    Inter_700Bold,
    Inter_500Medium,
    Inter_600SemiBold,
  });
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();

  if (!fontsLoaded) {
    return <AppLoading />;
  }

  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <SafeAreaProvider>
        <Navigation colorScheme={colorScheme} />
        <StatusBar />
      </SafeAreaProvider>
    );
  }
}
export default observer(App);

AppRegistry.registerComponent(appName, () => App);
AppRegistry.registerComponent(appName.toLowerCase(), () => App);
