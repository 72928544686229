import * as Notifications from "expo-notifications";
import { Notification } from "expo-notifications";
import {
  collection,
  DocumentData,
  onSnapshot,
  orderBy,
  query,
  Query,
  where,
} from "firebase/firestore";
import { makeAutoObservable } from "mobx";
import { Platform } from "react-native";
import { Linking } from "react-native";
import { Firestore } from "../Firestore";
import SubscriptionManager from "./SubscriptionManager";

export interface FirebaseData {
  title?: string;
  message?: string;
  subtitle?: string;
  sound?: boolean | string;
  vibrate?: boolean | number[];
  // priority?: AndroidNotificationPriority;
  badge?: number;
}

export interface NotificationType {
  title: string;
  body: string;
  data: FirebaseData;
}

export interface GlobalNotificationType {
  id: string;
  title: string;
  details: string;
  type: string;
  link?: string;
  createdAt: number;
  forceUpdate: boolean;
}

export default class AppNotifications {
  notificationHandler: any;
  expoPushToken?: string;
  notification?: Notification;
  notificationListener: any;
  responseListener: any;
  lastNotificationResponse: any;
  globalNotifiactions: { [notificationId: string]: GlobalNotificationType };
  subscriptions: SubscriptionManager;

  constructor() {
    this.expoPushToken = undefined;
    this.notification = undefined;
    this.notificationHandler = undefined;
    this.globalNotifiactions = {};
    this.subscriptions = new SubscriptionManager();

    // this.lastNotificationResponse = Notifications.useLastNotificationResponse();

    // this.registerForPushNotificationsAsync().then((token) =>
    //   this.setExpoPushToken(token)
    // );

    // this.notificationListener = Notifications.addNotificationReceivedListener(
    //   (notification) => {
    //     this.setNotification(notification);
    //   }
    // );

    // this.responseListener =
    //   Notifications.addNotificationResponseReceivedListener((response) => {
    //     console.log(response);
    //   });

    this.getPushToken()
      .then((pushToken) => {
        this.setExpoPushToken(pushToken);
        if (pushToken) {
          // TODO
          // do what you gotta do
        }
      })
      .catch((err) => {
        console.log({ err });
      });
    makeAutoObservable(this);
  }

  get forceAppUpdate(): boolean {
    let forceUpdateCount = 0;
    Object.keys(this.globalNotifiactions).forEach((key) => {
      const notification = this.globalNotifiactions[key];
      if (notification.forceUpdate === true) {
        forceUpdateCount = forceUpdateCount + 1;
      }
    });
    console.log({ forceUpdateCount });
    return forceUpdateCount > 0;
  }

  loadGlobalNotificationSinceLastPublished = (
    api: Firestore,
    lastPublishedDate: number
  ) => {
    const c = collection(api.db, "notifications");
    const w = where("createdAt", ">=", lastPublishedDate);
    const orderByAsc = orderBy("createdAt", "asc"); // latest one first
    const q = query(c, w, orderByAsc);
    this.listenToGlobalNotificationSinceLastPublished(q);
  };

  loadGlobalNotifications = (api: Firestore) => {
    const query = api.fetchDocsQuery("notifications");
    if (query) {
      this.listenToGlobalAppNotifications(query);
    }
  };

  listenToGlobalNotificationSinceLastPublished = (
    query: Query<DocumentData>
  ) => {
    if (query) {
      const unsubscribe = onSnapshot(query, (querySnapshot) => {
        querySnapshot?.forEach((doc: any) => {
          // doc.data() is never undefined for query doc snapshots
          const d = doc.data();
          const notification: GlobalNotificationType = {
            id: d.id,
            title: d.title,
            details: d.details,
            type: d.type,
            createdAt: d.createdAt,
            link: d.link,
            forceUpdate: d.forceUpdate || false,
          };

          this.setGlobalNotification(d.id, notification);
        });

        this.subscriptions.setSubscriptions(
          "globalNotificationSinceLastPublished",
          unsubscribe
        );
      });
    }
  };

  listenToGlobalAppNotifications = (query: Query<DocumentData>) => {
    if (query) {
      const unsubscribe = onSnapshot(query, (querySnapshot) => {
        querySnapshot?.forEach((doc: any) => {
          // doc.data() is never undefined for query doc snapshots
          const d = doc.data();
          const notification: GlobalNotificationType = {
            id: d.id,
            title: d.title,
            details: d.details,
            type: d.type,
            createdAt: d.createdAt,
            link: d.link,
            forceUpdate: d.forceUpdate || false,
          };

          this.setGlobalNotification(d.id, notification);
        });

        this.subscriptions.setSubscriptions(
          "globalAppNotifications",
          unsubscribe
        );
      });
    }
  };

  setGlobalNotification = (
    id: string,
    notification: GlobalNotificationType
  ) => {
    this.globalNotifiactions[id] = notification;
  };

  setExpoPushToken = (token?: string) => {
    this.expoPushToken = token;
  };

  setNotification = (notification?: Notification) => {
    this.notification = notification;
  };

  schedulePushNotification = async (notification: NotificationType) => {
    await Notifications.scheduleNotificationAsync({
      content: {
        title: notification.title,
        body: notification.body,
        data: { data: notification.data },
      },
      trigger: { seconds: 2 },
    });
  };

  registerForPushNotificationsAsync = async () => {
    let token;
    if (Platform.OS === "ios") {
      const { status: existingStatus } =
        await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== "granted") {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== "granted") {
        alert("Failed to get push token for push notification!");
        return;
      }
      token = (await Notifications.getExpoPushTokenAsync()).data;
      console.log(token);
    } else {
      alert("Must use physical device for Push Notifications");
    }

    if (Platform.OS === "android") {
      Notifications.setNotificationChannelAsync("default", {
        name: "default",
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: "#FF231F7C",
      });
    }

    return token;
  };

  registerForExpoPushNotificationsAsync = async (userId: string) => {
    const expoPushToken = await Notifications.getExpoPushTokenAsync({
      experienceId: "@username/example",
    });

    await fetch("https://example.com/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId,
        expoPushToken,
      }),
    });
  };

  // Responding to a notification tap by opening a URL that could be put into the
  // notification's data (opening the URL is your responsibility and is not a part of
  // the expo-notifications API):

  respondToNotification = () => {
    const lastNotificationResponse = this.lastNotificationResponse;
    if (
      lastNotificationResponse &&
      lastNotificationResponse.notification.request.content.data.url &&
      lastNotificationResponse.actionIdentifier ===
        Notifications.DEFAULT_ACTION_IDENTIFIER
    ) {
      Linking.openURL(
        lastNotificationResponse.notification.request.content.data.url
      );
    }
  };

  // Presenting the notification to the user (recommended way)
  showNotification = (title: string, body: string) => {
    if (Platform.OS === "ios") {
      // First, set the handler that will cause the notification to show the alert
      // To set the behavior for when notifications are received while the app is foregrounded,
      Notifications.setNotificationHandler({
        handleNotification: async () => ({
          shouldShowAlert: true,
          shouldPlaySound: false,
          shouldSetBadge: true,
        }),
      });
      Notifications.scheduleNotificationAsync({
        content: {
          title,
          body,
        },
        trigger: null,
      });
    }
  };

  getPushToken = () => {
    if (Platform.OS !== "ios") {
      return Promise.reject("Must use physical device for Push Notifications");
    }

    try {
      return Notifications.getPermissionsAsync()
        .then((statusResult) => {
          return statusResult.status !== "granted"
            ? Notifications.requestPermissionsAsync()
            : statusResult;
        })
        .then((statusResult) => {
          if (statusResult.status !== "granted") {
            // throw "Failed to get push token for push notification!";
            console.warn("Failed to get push token for push notification!");
          }
          return Notifications.getExpoPushTokenAsync();
        })
        .then((tokenData) => tokenData.data);
    } catch (error) {
      return Promise.reject("Couldn't check notifications permissions");
    }
  };
}
