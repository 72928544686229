import { RootTabScreenProps } from "../types";
import { observer } from "mobx-react";
import { RedirectionOptionType, useAppStore } from "../store/AppStore";
import { NoAccessScreen } from "./NoAccessScreen";
import { CommonActions, useNavigation } from "@react-navigation/native";
import { RefreshControl, ScrollView, View } from "react-native";
import { css } from "../lib/glamor-native";
import EmptyGroupsScreen from "./EmptyGroupsScreen";
import { appStyles } from "../defaultStyles";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import ComingUp from "./ComingUp";

import { PendingInvitationCards } from "./PendingInvitationsView";
import { RecentGroupsLoggedByYou } from "./home/RecentGroupsLoggedByYou";
import { Favorites } from "./home/Favorites";

function HomeScreen({ navigation }: RootTabScreenProps<"Home">) {
  const store = useAppStore();

  if (!store.user?.hasAccess) {
    return <NoAccessScreen />;
  }
  const redirectData = store.linker.data?.redirectData;
  if (redirectData && redirectData.uri === "GroupsInvitation") {
    return <PendingRedirection />;
  }

  return <Home />;
}

export default observer(HomeScreen);

export const PendingRedirection: React.FC = observer(() => {
  const store = useAppStore();
  const navigation = useNavigation();
  // This is a clugy hack - change this once you figure out the proper way to do this
  // for this particular case, we are redirecting to group invitation, from not found
  // because I don't know how to redirect straight to the route I am looking for
  const redirectData = store.linker.data?.redirectData;
  if (redirectData) {
    const path = redirectData.path;
    const queryParams = redirectData.queryParams;
    if (path && path === RedirectionOptionType.GroupInvitation) {
      if (store.user.hasAccess) {
        const currentUserEmail = store.user.email;
        const groupId = queryParams.groupId;
        if (groupId && currentUserEmail) {
          // user is already part of this group
          if (store.groups.records[groupId]) {
            store.linker.clear();
            //store.tasks.loadTasks([groupId]);
            if (navigation) {
              // user already has joined this group, let's redirect them there
              navigation.dispatch(
                CommonActions.reset({
                  index: 1,
                  routes: [
                    { name: "Root" },
                    {
                      name: "Group",
                      params: { groupId },
                    },
                  ],
                })
              );
            }
          } else {
            store.linker.clear();
            store.addUserToPendingGroupFromLink(groupId, currentUserEmail);

            if (navigation) {
              navigation.dispatch(
                CommonActions.reset({
                  index: 1,
                  routes: [
                    { name: "Root" },
                    {
                      name: "GroupInvitation",
                      params: { groupId },
                    },
                  ],
                })
              );
              //  navigation.navigate(path, queryParams);
            }
          }
        }
      } else {
        //  user needs to login first
        navigation &&
          navigation.reset({
            index: 0,
            routes: [{ name: "Root" }],
          });
      }
    }
  }
  return null;
});

const Home: React.FC = observer(() => {
  const store = useAppStore();
  const hasGroups = store.groups.length > 0;
  const hasTasks = store.tasks.length > 0;
  const favs = store.groups.favorites;
  const favorites = Object.keys(favs);
  const hasFavorites = favorites.length > 0;
  return (
    <View
      style={css({
        ...appStyles.container,
        paddingTop: 0,
      })}
    >
      <KeyboardAwareScrollView
        style={css({ ...appStyles.container })}
        enableAutomaticScroll={true}
        extraScrollHeight={100}
      >
        <ScrollView
          contentContainerStyle={css(appStyles.scrollview)}
          refreshControl={
            <RefreshControl
              refreshing={store.groups.refreshing}
              onRefresh={() => {
                store.refresh();
              }}
            />
          }
        >
          <PendingInvitationCards />
          <View style={css({ ...appStyles.container })}>
            {hasFavorites && (
              <View style={css({ backgroundColor: "transparent" })}>
                {hasGroups && <Favorites />}
                {hasGroups && <RecentGroupsLoggedByYou />}
              </View>
            )}

            {!hasFavorites && (
              <View style={css({ backgroundColor: "transparent" })}>
                {hasGroups && <RecentGroupsLoggedByYou />}
                {hasGroups && <Favorites />}
              </View>
            )}

            <View style={css({ ...appStyles.container })}>
              {!hasGroups && <EmptyGroupsScreen />}
            </View>

            {hasTasks && <ComingUp showAds={false} />}
          </View>
        </ScrollView>
      </KeyboardAwareScrollView>
    </View>
  );
});
