import { makeAutoObservable } from "mobx";
import { TagglableInputEditor } from "./common/TagglableInputEditor";

export default class Ui {
  showSignUpForm: boolean;
  loadingGroups: boolean;
  loadingTasks: boolean;
  loadingCompletedTasks: boolean;
  loading: boolean;
  editTaskId?: string;
  showMenuForCompletedTaskId?: string;
  showEditTaskMenuId?: string;
  editCompletedTaskId?: string;
  selectedGroupId?: string;
  showCalendarType?: "from" | "to";
  taggableInputEditor: TagglableInputEditor;

  constructor() {
    this.showSignUpForm = false;
    this.loadingGroups = false;
    this.loadingTasks = false;
    this.loadingCompletedTasks = false;
    this.loading = false;
    this.editTaskId = undefined;
    this.showMenuForCompletedTaskId = undefined;
    this.showEditTaskMenuId = undefined;
    this.editCompletedTaskId = undefined;
    this.selectedGroupId = undefined;
    this.showCalendarType = undefined;
    this.taggableInputEditor = new TagglableInputEditor();
    makeAutoObservable(this);
  }

  setShowMenuForCompletedTaskId = (value?: string) => {
    this.showMenuForCompletedTaskId = value;
  };

  setShowEditTaskMenuId = (value?: string) => {
    this.showEditTaskMenuId = value;
  };

  setLoading = (value: boolean) => {
    this.loading = value;
  };

  setEditTaskId = (taskId: string | undefined) => {
    this.editTaskId = taskId;
  };

  setShowCalendarType = (value: "from" | "to") => {
    this.showCalendarType = value;
  };

  setEditCompletedTaskId = (id: string | undefined) => {
    this.editCompletedTaskId = id;
  };

  setShowSignUpForm = (value: boolean) => {
    this.showSignUpForm = value;
  };

  setLoadingGroups = (value: boolean) => {
    this.loadingGroups = value;
  };

  setLoadingCompletedTasks = (value: boolean) => {
    this.loadingCompletedTasks = value;
  };

  setLoadingTasks = (value: boolean) => {
    this.loadingTasks = value;
  };

  setSelectedGroupId = (value?: string) => {
    this.selectedGroupId = value;
  };
}
