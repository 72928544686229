import { observer } from "mobx-react-lite";
import { Pressable } from "react-native";
import { appStyles, colors, spacings } from "../defaultStyles";
import { Text, View } from "../components/Themed";
import { useAppStore } from "../store/AppStore";
import { css } from "../lib/glamor-native";
import { useNavigation } from "@react-navigation/native";
import Task, { getLabelForGroupType } from "../store/Task";
import { GroupOptionsType } from "../store/Group";
import { ConfirmDeleteTask } from "../components/alerts/Confirmation";
import Icon from "../components/common/Icon";
import { permissions } from "../store/auth/Permissions";
import Dev from "../components/Dev";
import FloatingMenu from "../components/common/FloatingMenu";
import SearchBox from "../components/common/SearchBox";
import EmptyTasksScreen from "./EmptyTasksScreen";
import { NoAccessScreen } from "./NoAccessScreen";
import { Tag } from "../components/common/Tag";
import { Heading } from "../components/common/Heading";
import { TertiaryButtonCreateTask } from "../components/Buttons";
import { MaterialIcons } from "@expo/vector-icons";
import Button from "../components/common/Button";
import { FormOptions } from "../store/common/form/FormStore";
import { Time } from "../store/common/Time";

const TasksScreen: React.FC = () => {
  const store = useAppStore();
  const groupId = store.ui.selectedGroupId;
  if (!groupId) {
    return null;
  }
  const group = store.groups.records[groupId];
  if (!group) {
    return null;
  }
  let tasks = store.tasks.values;

  let hasTasks = false;

  if (!store.user?.hasAccess) {
    return <NoAccessScreen />;
  }

  if (groupId) {
    // We want to filter tasks
    tasks = store.tasks.byGroup[groupId];
    hasTasks = tasks?.length > 0;
  } else {
    hasTasks = tasks?.length > 0;
  }

  if (!hasTasks && !store.tasks.search.hasSearchTerm) {
    return <EmptyTasksScreen groupId={groupId} />;
  }

  return (
    <View style={css({ ...appStyles.card })}>
      <SearchBox
        search={store.tasks.search}
        placeholder="Search available tasks"
        style={{ marginBottom: spacings.large }}
      />

      <Header tasks={tasks} groupId={store.ui.selectedGroupId} />
      <Tasks tasks={tasks} />
    </View>
  );
};

export default observer(TasksScreen);

export const Header: React.FC<{ tasks: Task[]; groupId?: string }> = ({
  tasks,
  groupId,
}) => {
  return (
    <View
      style={css({
        backgroundColor: "transparent",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: spacings.regular,
      })}
    >
      <Heading level={2} title={`Events (${tasks?.length || 0})`} />
      <TertiaryButtonCreateTask groupId={groupId} />
    </View>
  );
};
export const Points: React.FC<{
  points: number;
  groupType: GroupOptionsType;
  backgroundColor?: string;
  color?: string;
  unit: string;
}> = ({
  points,
  backgroundColor = colors.surface200,
  groupType,
  color = colors.onSurface200,
  unit,
}) => {
  return (
    <View
      style={css({
        backgroundColor: "transparent",
        flexDirection: "row",
      })}
    >
      <Tag
        backgroundColor={backgroundColor}
        color={color}
        label={getLabelForGroupType(points, groupType, true, unit) || ""}
      />
    </View>
  );
};

export const Tasks: React.FC<{ tasks: Task[] }> = observer(({ tasks }) => {
  return (
    <View
      style={css({
        backgroundColor: "transparent",
      })}
    >
      {tasks?.map((task) => {
        return <TaskCard key={`task-card-${task.taskId}`} task={task} />;
      })}
    </View>
  );
});

export const TaskCard: React.FC<{ task: Task }> = observer(({ task }) => {
  const store = useAppStore();
  const navigation = useNavigation();
  const group = store.groups.records[task.groupId];
  if (!group) {
    return null;
  }

  const groupType = group.type;
  const form = store.forms.getForm(FormOptions.completeTask);
  if (!form) {
    return null;
  }
  const fields = form.fields;
  const time: Time = form.options.time;
  const taskId = task.taskId;
  return (
    <Pressable
      onPress={() => {
        store.ui.setShowEditTaskMenuId(undefined);

        store.feed.loadRecentCompletedTasksForTaskIdinGroupId(
          taskId,
          task.groupId,
          30
        );
        navigation.navigate("CompletedTasksByTaskId", { taskId: taskId });
      }}
      style={{
        ...appStyles.card,
        backgroundColor: colors.surface200,
        marginBottom: 0,
        marginTop: spacings.slim,
        padding: 0,
      }}
    >
      <View
        key={taskId}
        style={css({
          backgroundColor: "transparent",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        })}
      >
        <TaskDetails task={task} />
        <View
          style={css({
            backgroundColor: "transparent",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginRight: spacings.large,
            marginTop: spacings.medium,
          })}
        >
          <Points
            groupType={groupType}
            points={task.score}
            unit={group.unit}
            backgroundColor={colors.surface300}
          />
          <Button
            variant="tertiary"
            label="Log"
            icon={
              <MaterialIcons
                name="add-task"
                size={32}
                color={colors.secondary}
              />
            }
            style={{ paddingTop: spacings.slim }}
            onPress={() => {
              // select task
              store.initCompletedTaskForm(task.groupId, task);
              const form = store.forms.getForm(FormOptions.completeTask);
              const fields = form.fields;
              fields.taskId.set(task.id);
              store.ui.setEditTaskId(task.taskId);
              store.ui.setShowMenuForCompletedTaskId(undefined);
              store.ui.setShowEditTaskMenuId(undefined);

              navigation.navigate("CompleteTaskEditor", {
                formId: FormOptions.completeTask,
                taskId: task.taskId,
                createOnSave: true,
              });
            }}
          />
        </View>

        <TaskActions task={task} />
      </View>
      <Footer task={task} />
    </Pressable>
  );
});

export const TaskDetails: React.FC<{ task: Task }> = observer(({ task }) => {
  return (
    <View
      style={css({
        backgroundColor: "transparent",
        marginBottom: spacings.slim,
        paddingBottom: spacings.slim,
        flexDirection: "column",
        padding: spacings.regular,
        borderRadius: spacings.roundBorderXl,
        flex: 1,
      })}
    >
      <Dev messages={[{ value: task.taskId, label: "taskId" }]} />

      <View
        style={css({
          flexDirection: "row",
          backgroundColor: "transparent",
          alignItems: "center",
        })}
      >
        <Text
          style={css({
            ...appStyles.heading.level3,
            flex: 0.7,
            paddingLeft: spacings.regular,
            paddingBottom: 0,
            marginBottom: spacings.small,
          })}
        >
          {task.title}
        </Text>
      </View>

      {task.description !== "" && (
        <View
          style={css({
            backgroundColor: "transparent",
            marginLeft: spacings.regular,
            opacity: 0.7,
          })}
        >
          <Text>{task.description}</Text>
        </View>
      )}
    </View>
  );
});
export const TaskActions: React.FC<{ task: Task }> = observer(({ task }) => {
  const store = useAppStore();
  const navigation = useNavigation();
  const taskId = task.taskId;
  if (!permissions.canEditTask(task, store.user.email)) {
    return null;
  }
  return (
    <View
      style={css({
        backgroundColor: "transparent",
        position: "absolute",
        top: 0,
        right: 0,
      })}
    >
      <FloatingMenu
        variant="vertical"
        show={store.ui.showEditTaskMenuId === taskId}
        onPress={() => store.ui.setShowEditTaskMenuId(taskId)}
        options={[
          {
            label: "Edit",
            onPress: () => {
              if (task) {
                store.ui.setEditTaskId(undefined);
                store.ui.setShowMenuForCompletedTaskId(undefined);
                store.ui.setShowEditTaskMenuId(undefined);
                store.initUpdateTasksForm(task.groupId, task);
                navigation.navigate("AddTask");
              }
            },
            iconProps: {
              name: "pencil",
              size: 16,
              position: "left",
              color: colors.secondary,
            },
          },
          {
            label: "Delete",
            onPress: () => {
              store.ui.setEditTaskId(undefined);
              store.ui.setShowMenuForCompletedTaskId(undefined);
              ConfirmDeleteTask(
                () => {
                  const group = store.groups.records[task.groupId];
                  if (group) {
                    store.tasks.deleteItem(taskId);
                    store.tasks._removeTask(taskId);
                  }
                },
                () => {}
              );
            },
            iconProps: {
              name: "trash",
              size: 16,
              position: "left",
              color: colors.secondary,
            },
          },
        ]}
      />
    </View>
  );
});

export const RestrictionForCreator: React.FC<{ task: Task }> = observer(
  ({ task }) => {
    const store = useAppStore();
    return (
      <View
        style={css({
          backgroundColor: "transparent",
          marginLeft: spacings.regular,
          flexDirection: "row",
          opacity: 0.7,
          alignItems: "center",
        })}
      >
        <Icon name="asterisk" size={6} color={colors.onSurface100} />
        <Text
          style={css({
            ...appStyles.text.smallFaded,
            marginLeft: spacings.regular,
          })}
        >
          Only{" "}
          {task.createdBy
            ? store.userProfiles[task.createdBy]?.displayName
            : task.createdBy}{" "}
          can change this task
        </Text>
      </View>
    );
  }
);

const Footer: React.FC<{ task: Task }> = observer(({ task }) => {
  const store = useAppStore();
  const group = store.groups.records[task.groupId];
  if (!group) {
    return null;
  }
  const groupType = group.type;
  return (
    <View
      style={css({
        backgroundColor: "transparent",
        flexDirection: "row",
        alignItems: "center",
      })}
    >
      <View
        style={css({
          flexDirection: "row",
          backgroundColor: "transparent",
          marginBottom: spacings.slim,
          alignItems: "stretch",
          paddingLeft: spacings.regular,
          marginTop: spacings.tiny,
        })}
      >
        {task.createdBy !== store.user.email && (
          <RestrictionForCreator task={task} />
        )}
      </View>
    </View>
  );
});
