import { observer } from "mobx-react-lite";
import { appStyles, colors, spacings, tableStyles } from "../defaultStyles";
import { Text, View } from "../components/Themed";
import { useAppStore } from "../store/AppStore";
import { css } from "../lib/glamor-native";
import { useNavigation, useRoute } from "@react-navigation/native";
import { NoAccessScreen } from "./NoAccessScreen";
import EmptyTasksScreen from "./EmptyTasksScreen";
import { useEffect } from "react";
import { Entypo } from "@expo/vector-icons";
import TasksScreen from "./TasksScreen";
import { Pressable, RefreshControl, ScrollView } from "react-native";
import { CachedProfileImage } from "../components/common/ImagePickerComponent";
import { Heading } from "../components/common/Heading";
import { Tag } from "../components/common/Tag";

const MAX_PROFILE_IMAGES = 3;
const GroupScreen: React.FC = observer(() => {
  const store = useAppStore();
  const route = useRoute();
  const navigation = useNavigation();
  //@ts-ignore
  const groupId = route.params?.groupId || store.ui.selectedGroupId;

  const tasks = store.tasks;
  const group = store.groups.records[groupId];
  useEffect(() => {
    group &&
      navigation.setOptions({
        title: `${group.title} Group`,
      });
  }, [group, navigation]);

  let hasTasks = false;

  if (!store.user?.hasAccess) {
    return <NoAccessScreen />;
  }

  let filteredTasks = [];

  if (groupId) {
    // We want to filter tasks
    filteredTasks = store.tasks.byGroup[groupId];
    hasTasks = filteredTasks?.length > 0;
  } else {
    hasTasks = tasks?.length > 0;
  }

  if (!hasTasks && !tasks.search.hasSearchTerm) {
    return <EmptyTasksScreen groupId={groupId} />;
  }

  return (
    <View style={css({ ...appStyles.viewWrapper, ...appStyles.container })}>
      <ScrollView
        contentContainerStyle={css(appStyles.scrollview)}
        refreshControl={
          <RefreshControl
            refreshing={store.tasks.refreshing}
            onRefresh={() => {
              if (groupId) {
                store.tasks.loadTasksForGroup(groupId);
              } else {
                store.tasks.loadTasksForGroups(store.groups.ids);
              }
            }}
          />
        }
      >
        <LoggedTasksMenu groupId={groupId} />
        <TasksScreen />
      </ScrollView>
    </View>
  );
});

export default GroupScreen;

const LoggedTasksMenu: React.FC<{ groupId: string }> = ({ groupId }) => {
  const store = useAppStore();
  const navigation = useNavigation();
  const userProfiles = store.userProfiles;
  const currentUser = store.user.email;
  const group = store.groups.records[groupId];
  const members = group.members;
  if (!currentUser) {
    return null;
  }
  const userProfile = userProfiles[currentUser];
  const items = [
    {
      id: "byYou",
      title: "By you",
      action: () => {
        store.feed.loadRecentsByYouByGroupId(currentUser, groupId, 50);
        navigation.navigate("GroupSummary", { groupId });
      },
    },
  ];

  if (members.length > 1) {
    items.push({
      id: "byAll",
      title: "By everyone",
      action: () => {
        store.feed.load(groupId, "recents");
        navigation.navigate("GroupSummary", { groupId });
      },
    });
  }
  return (
    <View
      style={css({
        ...appStyles.card,
        paddingLeft: 0,
        paddingRight: 0,
      })}
    >
      <View
        style={css({
          ...appStyles.cardListContainerHeader,
        })}
      >
        <Heading title="Logged Events" level={2} />
      </View>
      {items.map((item) => {
        return (
          <Pressable
            key={item.id}
            style={({ pressed }) =>
              css({
                ...tableStyles.row(pressed),
              })
            }
            onPress={item.action}
          >
            <View
              style={css({
                backgroundColor: "transparent",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              })}
            >
              <View
                style={css({
                  backgroundColor: "transparent",
                  flex: 1,
                  flexDirection: "row",
                  alignItems: "center",
                })}
              >
                {item.id === "byYou" && (
                  <CachedProfileImage
                    imageUrl={userProfile?.profileImageUrl}
                    size={30}
                  />
                )}
                {item.id === "byAll" && (
                  <MembersImages
                    urls={members.map(
                      (member) => store.userProfiles[member]?.profileImageUrl
                    )}
                  />
                )}
                <Text
                  style={css({
                    ...appStyles.text.body,
                    marginLeft: spacings.regular,
                  })}
                >
                  {item.title}
                </Text>
              </View>

              <Entypo
                name="chevron-thin-right"
                size={24}
                color={colors.onBackground}
              />
            </View>
          </Pressable>
        );
      })}
    </View>
  );
};

const MembersImages: React.FC<{ urls: (string | undefined)[] }> = ({
  urls,
}) => {
  const hasMoreMembersThanMax = urls.length > MAX_PROFILE_IMAGES;
  const max = hasMoreMembersThanMax ? MAX_PROFILE_IMAGES : urls.length;
  const top3 = urls.slice().splice(0, max);
  let plus = 0;

  if (hasMoreMembersThanMax) {
    plus = urls.length - MAX_PROFILE_IMAGES;
  }
  const widthShift = spacings.medium;
  return (
    <View
      style={css({
        backgroundColor: "transparent",
        flexDirection: "row",
        maxWidth: 100,
        marginLeft: widthShift,
      })}
    >
      {top3.map((url, i) => {
        if (!url) {
          return null;
        }
        return (
          <View
            key={`member-${i}`}
            style={css({
              backgroundColor: "transparent",
              marginLeft: -widthShift,
            })}
          >
            <CachedProfileImage imageUrl={url} size={30} />
          </View>
        );
      })}

      {hasMoreMembersThanMax && (
        <View
          style={css({
            backgroundColor: "transparent",
            marginLeft: -spacings.medium,
            marginTop: spacings.regular,
          })}
        >
          <Tag
            label={`+ ${plus}`}
            backgroundColor={colors.secondary}
            color={colors.onSecondary}
            style={{
              paddingLeft: spacings.slim,
              paddingRight: spacings.slim,
              borderRadius: 50,
            }}
          />
        </View>
      )}
    </View>
  );
};
