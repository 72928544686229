import { Svg, G, Line, Text, Circle, Rect } from "react-native-svg";
import * as d3 from "d3";
import { colors, setAlpha } from "../../../defaultStyles";
import moment from "moment";

const GRAPH_MARGIN = 20;
const GRAPH_BAR_WIDTH = 20;

const DateChart: React.FC<{
  id: string;
  data: { label: string; value: number; color?: string; action: () => void }[];
  round: number;
  unit: string;
}> = ({ id, data, round, unit }) => {
  const chartColors = {
    text: colors.onBackground,
    axis: colors.onBackground,
    bars: colors.onBackground,
  };
  // Dimensions
  const SVGHeight = 150;
  const SVGWidth = 400;
  const graphHeight = SVGHeight - 2 * GRAPH_MARGIN;
  const graphWidth = SVGWidth - 2 * GRAPH_MARGIN;

  const parseTime = d3.timeFormat("%B %d, %Y");
  // X scale point
  const dates = data.map((item) => new Date(item.value) || 0);

  const timestamps = data.map((item) => todayAtHour(new Date(item.value), 0));
  // The extent method takes an array as an argument and returns
  // a new array containing only the minimum and maximum elements.
  const xDomain = d3.extent(dates);
  const xRange = [0, graphWidth];

  //@ts-ignore
  const x = d3.scaleTime().domain(xDomain).range(xRange).nice();

  const xAxis = d3
    .scaleBand()
    .domain(dates.map((date) => parseTime(date)))
    .range(xRange);

  // // Y scale linear
  const topValue = 24; // max hours in the day
  const yDomain = [0, topValue];
  const yRange = [0, graphHeight];
  const y = d3.scaleLinear().domain(yDomain).range(yRange);

  // // top axis and middle axis
  const middleValue = 12;
  return (
    <Svg
      key={id}
      width={SVGWidth}
      height={SVGHeight}
      fill={colors.surface}
      style={{ backgroundColor: colors.surface }}
    >
      <G y={graphHeight + GRAPH_MARGIN}>
        {/* top axis */}
        <Line
          x1="0"
          y1={y(topValue) * -1}
          x2={graphWidth}
          y2={y(topValue) * -1}
          stroke={chartColors.axis}
          strokeDasharray={[3, 3]}
          strokeWidth="0.5"
        />

        {/* middle axis */}
        <Line
          x1="0"
          y1={y(middleValue) * -1}
          x2={graphWidth}
          y2={y(middleValue) * -1}
          stroke={chartColors.axis}
          strokeDasharray={[3, 3]}
          strokeWidth="0.5"
        />

        {/* bottom axis */}
        <Line
          x1="0"
          y1="2"
          x2={graphWidth}
          y2="2"
          stroke={chartColors.axis}
          strokeWidth="0.5"
        />

        {data.map((item) => {
          const value = new Date(item.value);

          if (value === null) {
            return null;
          }
          return (
            <Circle
              key={`circle-${item.value}`}
              cx={x(value) - GRAPH_BAR_WIDTH / 2}
              cy={y(value.getHours()) * -1}
              r={2}
              fill={item.color}
            />
          );
        })}

        {/* labels */}
        {timestamps.map((item, i) => (
          <Text
            key={"label" + item + i}
            fontSize="9"
            fontFamily="Arial"
            x={x(item)}
            fill={chartColors.text}
            y="12"
            textAnchor="start"
          >
            {moment(item).format("MMM D")}
          </Text>
        ))}

        {/** vertical lines */}
        {timestamps.map((item, i) => (
          <Line
            key={`vertical-line-${i}`}
            x1={x(new Date(item))}
            y1={1 * -1}
            x2={x(new Date(item))}
            y2={graphHeight * -1}
            stroke={setAlpha(chartColors.axis, 0.3)}
            strokeDasharray={[3, 3]}
            strokeWidth="0.5"
          />
        ))}

        {/* y labels */}
        {[0, 12, 23].map((item, i) => (
          <Text
            key={"label" + item + i}
            fontSize="9"
            x={8}
            fill={setAlpha(chartColors.text, 0.3)}
            fontFamily="Arial"
            y={y(item) * -1}
            textAnchor="start"
          >
            {getLabelTime(item)}
          </Text>
        ))}
      </G>
    </Svg>
  );
};

export default DateChart;
const getLabelTime = (time: number) => {
  switch (time) {
    case 0:
      return "12AM";
    case 12:
      return "Noon";
    case 23:
      return "11PM";
    default:
      return time;
  }
};
const formatMillisecond = d3.timeFormat(".%L"),
  formatSecond = d3.timeFormat(":%S"),
  formatMinute = d3.timeFormat("%I:%M"),
  formatHour = d3.timeFormat("%H %p"),
  formatDay = d3.timeFormat("%a %d"),
  formatWeek = d3.timeFormat("%b %d"),
  formatMonth = d3.timeFormat("%B"),
  formatYear = d3.timeFormat("%Y");

function multiFormat(date: Date) {
  return (
    d3.timeSecond(date) < date
      ? formatMillisecond
      : d3.timeMinute(date) < date
      ? formatSecond
      : d3.timeHour(date) < date
      ? formatMinute
      : d3.timeDay(date) < date
      ? formatHour
      : d3.timeMonth(date) < date
      ? d3.timeWeek(date) < date
        ? formatDay
        : formatWeek
      : d3.timeYear(date) < date
      ? formatMonth
      : formatYear
  )(date);
}

function todayAtHour(date: Date, hour: number) {
  const d = new Date(date);
  d.setHours(hour, 0, 0, 0);
  console.log({ d });
  return d;
}
