import { Text, TouchableHighlight, View } from "react-native";
import { appStyles, colors, sizes, spacings } from "../../defaultStyles";
import { css } from "../../lib/glamor-native";
import Icon from "./Icon";

const CheckboxButton: React.FC<{
  selected: boolean;
  label?: string;
  onPress: () => void;
  disabled?: boolean;
  accessibilityLabel?: string;
  children?: any;
  textStyle?: Object;
}> = ({
  label,
  selected,
  disabled = false,
  onPress,
  accessibilityLabel,
  children,
  textStyle = {},
}) => {
  let styles = {};
  if (disabled) {
    styles = {
      ...styles,
      ...{ opacity: 0.3 },
    };
  }
  return (
    <View
      style={css({
        ...(styles || {}),
        ...{ backgroundColor: "transparent", padding: spacings.slim },
      })}
    >
      <TouchableHighlight
        activeOpacity={0.6}
        underlayColor="transparent"
        onPress={() => !disabled && onPress()}
        accessibilityLabel={accessibilityLabel || ""}
        disabled={disabled}
      >
        <View
          style={css({
            flexDirection: "row",
            alignItems: "center",
            backgroundColor: "transparent",
          })}
        >
          {!selected && (
            <Icon name="square-o" style={{ marginRight: spacings.slim }} />
          )}
          {selected && (
            <Icon
              name="check-square-o"
              style={{ marginRight: spacings.slim }}
            />
          )}
          {label && (
            <Text
              style={css({
                ...appStyles.text.body,
                fontWeight: selected ? "bold" : "normal",
                fontSize: sizes.font.h3,
                ...textStyle,
              })}
            >
              {label}
            </Text>
          )}
          {children}
        </View>
      </TouchableHighlight>
    </View>
  );
};

export default CheckboxButton;
