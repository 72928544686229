import { observer } from "mobx-react";
import { View, Text } from "react-native";
import {
  appStyles,
  colors,
  fontFamilies,
  sizes,
  spacings,
} from "../../defaultStyles";
import { css } from "../../lib/glamor-native";
import { Time } from "../../store/common/Time";
import Input from "./Input";

const containerStyle = {
  alignItems: "center",
  backgroundColor: "transparent",
  borderRadius: spacings.roundBorderXl,
  flexDirection: "column",
  justifyContent: "center",
  // margin: spacings.slim,
  // padding: spacings.slim,
  flex: 1,
};

const separatorContainerStyle = {
  alignItems: "center",
  backgroundColor: colors.surface,
  borderRadius: spacings.roundBorderXl,
  flexDirection: "column",
  justifyContent: "center",
  margin: spacings.slim,
  padding: spacings.regular,
};

const textStyle = {
  color: colors.secondary,
  fontSize: 30,
  fontWeight: "bold",
  fontFamily: fontFamilies.bold,
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
};

const timeLabelStyle = {
  ...appStyles.text.smallBoldDecorated,
  color: colors.secondary,
};

const inputTextStyle = {
  backgroundColor: "transparent",
  fontSize: 60,
  color: colors.secondary,
  fontFamily: fontFamilies.bold,
  paddingTop: 0,
  paddingBottom: 0,
  alignItems: "center",
  textAlign: "center",
  marginTop: spacings.regular,
};

export const TimeInput: React.FC<{
  label?: string;
  required?: boolean;
  disabled?: boolean;
  time: Time;
  onChange: (time: number) => void;
}> = observer(({ time, label, onChange, required, disabled }) => {
  return (
    <View
      style={css({
        backgroundColor: "transparent",
        marginTop: spacings.regular,
        width: "100%",
      })}
    >
      <View style={{ flexDirection: "row", backgroundColor: "transparent" }}>
        {label && (
          <Text
            style={css({
              fontWeight: "bold",
              fontSize: sizes.font.body,
              color: colors.onBackground,
              marginBottom: spacings.slim,
            })}
          >
            {label}
          </Text>
        )}
        {required && (
          <Text
            style={{
              marginLeft: spacings.slim,
              color: colors.required,
              fontWeight: "bold",
              fontSize: sizes.font.body,
            }}
          >
            *
          </Text>
        )}
      </View>
      <TimePicker time={time} onChange={onChange} disabled={disabled} />
    </View>
  );
});

export const TimePicker: React.FC<{
  time: Time;
  disabled?: boolean;
  onChange: (time: number) => void;
}> = observer(({ time, onChange, disabled }) => {
  const hour = time.hourString === "00" ? "" : time.hourString;
  const min = time.minuteString === "00" ? "" : time.minuteString;
  const sec = time.secondsString === "00" ? "" : time.secondsString;

  return (
    <View
      style={css({
        backgroundColor: "transparent",
        flexDirection: "row",
        alignItems: "center",
        flex: 1,
        width: "100%",
      })}
    >
      <View style={css(containerStyle)}>
        <Text style={css(timeLabelStyle)}>Hours</Text>
        <Input
          placeholder="h"
          disabled={disabled}
          inputTextStyle={inputTextStyle}
          value={hour}
          onChangeText={(text) => {
            time.setHourString(text);
            onChange(time.seconds);
          }}
        />

        {/* <Controls value={time.hourString} minValue={0} onValueChange={onChange} /> */}
      </View>

      <TimeSeparator />

      <View style={css(containerStyle)}>
        <Text style={css(timeLabelStyle)}>Minutes</Text>
        <Input
          placeholder="m"
          disabled={disabled}
          inputTextStyle={inputTextStyle}
          value={min}
          onChangeText={(text) => {
            time.setMinuteString(text);
            onChange(time.seconds);
          }}
        />

        {/* <Controls value={time.hourString} minValue={0} onValueChange={onChange} /> */}
      </View>

      <TimeSeparator />

      <View style={css(containerStyle)}>
        <Text style={css(timeLabelStyle)}>Seconds</Text>
        <Input
          placeholder="s"
          disabled={disabled}
          inputTextStyle={inputTextStyle}
          value={sec}
          onChangeText={(text) => {
            time.setSecondsString(text);
            onChange(time.seconds);
          }}
        />

        {/* <Controls value={time.hourString} minValue={0} onValueChange={onChange} /> */}
      </View>
    </View>
  );
});

export const TimeSeparator: React.FC = observer(() => {
  return (
    <View
      style={css({
        ...separatorContainerStyle,
        margin: 0,
        padding: 0,
        backgroundColor: "transparent",
      })}
    >
      <Text style={css({ ...textStyle, marginRight: 0 })}>:</Text>
    </View>
  );
});
