import { useNavigation } from "@react-navigation/native";
import { observer } from "mobx-react";
import { Text, View } from "react-native";
import { appStyles, colors, spacings } from "../defaultStyles";
import { css } from "../lib/glamor-native";
import { useAppStore } from "../store/AppStore";
import { permissions } from "../store/auth/Permissions";
import { strings } from "../store/common/i18n/strings";
import { AlertupgradeToPremiumMaxGroupsLimitReached } from "./alerts/Confirmation";
import Button from "./common/Button";
import ColmLogo from "./common/graphics/ColmLogo";
import { Heading } from "./common/Heading";

export const CreateGroupFromTemplateCard: React.FC<{ compact?: boolean }> =
  observer(({ compact = false }) => {
    const logoSize = compact ? 100 : 150;
    return (
      <View
        style={css({
          ...appStyles.card,
          backgroundColor: compact ? colors.surface : colors.surface,
          padding: spacings.regular,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
        })}
      >
        <View
          style={{
            backgroundColor: "transparent",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            width: "100%",
            marginBottom: spacings.regular,
            marginTop: spacings.default,
          }}
        >
          <ColmLogo size={logoSize} id="groups-card-logo" />
        </View>

        <View
          style={css({
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          })}
        >
          <Heading level={2} title="Create groups" />
          <Text
            style={css({
              ...appStyles.text.body,
              marginTop: spacings.regular,
            })}
          >
            Categorize how you remember your past and take a peak into the
            future. Separate the memories, activities and events into different
            buckets.
          </Text>
        </View>
        <Buttons />
      </View>
    );
  });

const Buttons: React.FC = observer(() => {
  const store = useAppStore();
  const navigator = useNavigation();
  return (
    <View
      style={css({
        backgroundColor: "transparent",
        marginTop: spacings.default,
      })}
    >
      <Button
        label="Choose from Template"
        style={{ marginBottom: spacings.default }}
        iconProps={{
          name: "arrow-circle-o-right",
        }}
        onPress={() => {
          store.templatePicker.clearExceptions();
          navigator.navigate("TemplatesBrowser");
        }}
      />

      <Button
        variant="tertiary"
        label="Create group"
        onPress={() => {
          if (permissions.canCreateGroup()) {
            store.initEditGroupForm();
            navigator.navigate("EditGroup");
          } else {
            if (AlertupgradeToPremiumMaxGroupsLimitReached) {
              AlertupgradeToPremiumMaxGroupsLimitReached(
                () => {
                  // update to premium
                  navigator.navigate("UpdateMembership", {
                    email: store.user.email || "Guest",
                  });
                },
                () => {}
              );
            } else {
              alert(strings.upgradeToPremium.maxGroupsReached);
            }
          }
        }}
      />
    </View>
  );
});
