import { useNavigation } from "@react-navigation/native";
import { observer } from "mobx-react-lite";
import { ScrollView, RefreshControl } from "react-native";
import Button from "../components/common/Button";
import Input from "../components/common/Input";
import RadioButton from "../components/common/RadioButton";
import { Text, View } from "react-native";
import { appStyles, spacings } from "../defaultStyles";
import { css } from "../lib/glamor-native";
import { useAppStore } from "../store/AppStore";
import { FormOptions } from "../store/common/form/FormStore";
import { SeparatorSlim } from "../components/common/Separator";
import {
  labelForReasonId,
  ReportOptions,
  ReportReasonDetails,
} from "../store/data/reportReasons";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

function ReportUser() {
  const store = useAppStore();

  return (
    <KeyboardAwareScrollView
      style={css(appStyles.container)}
      enableAutomaticScroll={true}
      extraScrollHeight={100}
    >
      <ScrollView
        contentContainerStyle={css({
          ...appStyles.scrollview,
          paddingLeft: spacings.regular,
          paddingRight: spacings.regular,
        })}
      >
        <ReportUserView />
      </ScrollView>
    </KeyboardAwareScrollView>
  );
}

export default observer(ReportUser);

const ReportUserView: React.FC = observer(() => {
  const store = useAppStore();
  const navigation = useNavigation();
  const form = store.forms.getForm(FormOptions.report);
  const fields = form.fields;
  const hasReason: boolean = fields.reason.value !== undefined ? true : false;
  return (
    <View
      style={css({
        ...appStyles.card,
      })}
    >
      <Text style={css(appStyles.heading.level1)}>Report User</Text>
      <Text style={css(appStyles.text.bodyFaded)}>{fields.userId.value}</Text>
      <Text
        style={css({
          ...appStyles.text.bodyBold,
          marginTop: spacings.regular,
          marginBottom: spacings.regular,
        })}
      >
        If someone is in immediate danger, get help before reporting to Colem.
        Don't wait.
      </Text>
      <SeparatorSlim />
      <Text style={css(appStyles.heading.level2)}>
        What is the reason for reporting?
      </Text>

      {!hasReason &&
        ReportOptions.map((option) => {
          return (
            <RadioButton
              key={option.id}
              label={option.label}
              selected={fields.reason.value === option.id}
              onPress={() => {
                fields.reason.set(option.id);
              }}
            />
          );
        })}

      <ReasonDetails reasonId={fields.reason.value} />

      {fields.reasonDetails.value !== undefined && (
        <View style={css({ backgroundColor: "transparent" })}>
          <Input
            label="Describe the reason why you are submiting this report."
            value={fields.notes.value}
            onChangeText={(text) => {
              fields.notes.set(text);
            }}
          />

          {/* <Text style={css(appStyles.text.body)}>
            Does this go against our Community Standards? Our standards explain
            what we do and don't allow on Colem. See Community
            Standards
          </Text> */}

          <Button
            disabled={!form.isValid}
            label="Submit"
            onPress={() => {
              store.reportUser();
              navigation.canGoBack() && navigation.goBack();
              store.feedback.setFeedback({
                message: "You submitted this report successfully.",
                variant: "success",
              });
            }}
          />
        </View>
      )}
    </View>
  );
});

export const ReasonDetails: React.FC<{ reasonId: string }> = observer(
  ({ reasonId }) => {
    const store = useAppStore();
    const form = store.forms.getForm(FormOptions.report);
    const fields = form.fields;
    const hasReason: boolean = fields.reason.value !== undefined ? true : false;
    if (!hasReason) {
      return null;
    }
    const hasReasonDetails =
      fields.reasonDetails.value !== undefined ? true : false;
    return (
      <View
        style={css({
          backgroundColor: "transparent",
          marginBottom: spacings.default,
        })}
      >
        <RadioButton
          label={labelForReasonId(reasonId)}
          selected={true}
          disabled={true}
          onPress={() => {
            fields.reason.set(undefined);
          }}
        />
        <Text style={css(appStyles.heading.level2)}>
          What kind of {labelForReasonId(reasonId)}?
        </Text>

        {ReportReasonDetails[reasonId]?.map((details) => {
          return (
            <RadioButton
              label={details.label}
              selected={fields.reasonDetails.value === details.id}
              onPress={() => {
                fields.reasonDetails.set(details.id);
              }}
            />
          );
        })}
        {hasReason && !hasReasonDetails && (
          <Button
            label="This is not the reason"
            variant="tertiary"
            onPress={() => {
              fields.reason.set(undefined);
            }}
          />
        )}
      </View>
    );
  }
);
