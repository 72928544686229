import { UserProfilesType } from "../AppStore";

const MAX_DAYS = 30;
const TOTAL_DAYS_IN_YEAR = 360;

export const getYMDFromDays = (value: number) => {
  let years = Math.floor(value / TOTAL_DAYS_IN_YEAR);
  const remainingMonths = value % TOTAL_DAYS_IN_YEAR;
  let months = 0;
  let days = 0;
  if (years > 0) {
    if (remainingMonths > 0) {
      months = Math.floor(remainingMonths / MAX_DAYS);
      days = remainingMonths % MAX_DAYS;
    }
  } else if (remainingMonths / MAX_DAYS > 0) {
    months = Math.floor(remainingMonths / MAX_DAYS);
    days = Math.floor(remainingMonths % MAX_DAYS);
  } else {
    days = value;
  }

  return {
    days,
    months,
    years,
  };
};

export function getDisplayName(
  email?: string,
  userProfiles?: UserProfilesType
) {
  if (userProfiles && email) {
    return userProfiles[email]?.displayName || email || "Unknown";
  }
  return email || "Unknown";
}

export function hasSearchTerm(searchTerm: string, str: string): boolean {
  if (searchTerm && str) {
    return (
      str?.toLocaleLowerCase()?.indexOf(searchTerm?.toLocaleLowerCase()) >= 0
    );
  }
  return false;
}
