import { makeAutoObservable } from "mobx";

export default class Tags {
  tags: string[];

  constructor(tags?: string[]) {
    this.tags = tags || [];
    makeAutoObservable(this);
  }

  setTags = (value: string[]) => {
    this.tags = value;
  };

  removeTag = (tag: string) => {
    const updated = this.tags.filter((t) => t !== tag);
    this.setTags(updated);
  };

  addTag = (tag: string) => {
    this.tags = [...new Set([...this.tags, tag])];
  };
}
