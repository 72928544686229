import { makeAutoObservable } from "mobx";

export class FetcherScrollerAware {
  bottomPos: number;
  height: number;
  fetched: Record<string, boolean>;

  constructor() {
    this.bottomPos = 0;
    this.height = 0;
    this.fetched = {};
    makeAutoObservable(this);
  }

  get offset() {
    return this.height + this.bottomPos;
  }

  setBottomPos = (pos: number) => {
    this.bottomPos = pos;
  };

  setHeight = (height: number) => {
    this.height = height;
  };

  setFetched = (id: string) => {
    this.fetched[id] = true;
  };
}

export const fetcherScrollerAware = new FetcherScrollerAware();
