import { useNavigation } from "@react-navigation/native";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { ScrollView } from "react-native";
import {
  AlertupgradeToPremiumMaxGroupsLimitReached,
  ConfirmRejectGroupInvitation,
} from "../components/alerts/Confirmation";
import Button from "../components/common/Button";
import Icon from "../components/common/Icon";
import { Text, View } from "../components/Themed";
import { appStyles, colors, NEON_GREEN, spacings } from "../defaultStyles";
import { css } from "../lib/glamor-native";
import { PendingGroupInvitationType, useAppStore } from "../store/AppStore";
import { permissions } from "../store/auth/Permissions";
import { strings } from "../store/common/i18n/strings";

const PendingInvitationsView: React.FC = observer(() => {
  const store = useAppStore();
  const invitations = store.pendingGroupInvitations;
  const hasInvitations = invitations.length > 0;
  return (
    <View
      style={css({
        backgroundColor: "transparent",
      })}
    >
      {!hasInvitations && (
        <View
          style={css({
            backgroundColor: "transparent",
            alignItems: "center",
            justifyContent: "center",
          })}
        >
          <Icon name="check" color={NEON_GREEN} size={60} />
          <Text style={css(appStyles.heading.level0)}>
            You are all caught up!
          </Text>
        </View>
      )}
      {invitations.map((invitation: PendingGroupInvitationType) => {
        return (
          <View
            key={invitation.groupId}
            style={css({
              flexDirection: "row",
              backgroundColor: colors.surface,
              alignItems: "flex-end",
              marginBottom: spacings.slim,
              borderRadius: spacings.roundBorderXl,
              padding: spacings.default,
            })}
          >
            <View
              style={css({
                flexDirection: "column",
                flex: 1,
                backgroundColor: "transparent",
              })}
            >
              <Text style={css({ ...appStyles.heading.level1, flex: 1 })}>
                {invitation.groupName}
              </Text>
              <Text style={css(appStyles.text.body)}>
                {invitation.description}
              </Text>
              <View
                style={css({
                  flexDirection: "row",
                  backgroundColor: "transparent",
                  marginTop: spacings.regular,
                })}
              >
                <Text style={css(appStyles.text.bodyBold)}>Invited by </Text>
                <Text style={css(appStyles.text.bodyBold)}>
                  {invitation.invitedBy}
                </Text>
              </View>
              <Text style={css(appStyles.text.bodyFaded)}>
                {moment(invitation.invitedOn).fromNow()}
              </Text>
              <View
                style={css({
                  flexDirection: "row",
                  backgroundColor: "transparent",
                  marginBottom: spacings.regular,
                  justifyContent: "flex-end",
                })}
              >
                <Actions invitation={invitation} />
              </View>
            </View>
          </View>
        );
      })}
    </View>
  );
});

export default PendingInvitationsView;

export const Actions: React.FC<{ invitation: PendingGroupInvitationType }> =
  observer(({ invitation }) => {
    const store = useAppStore();
    const navigation = useNavigation();
    const currentUserEmail = store.user.email;
    if (!currentUserEmail) {
      return null;
    }
    return (
      <View
        style={css({
          flexDirection: "row",
          backgroundColor: "transparent",
        })}
      >
        <Button
          label="Reject"
          variant="secondary"
          style={{
            marginRight: spacings.relaxed,
          }}
          onPress={async () => {
            await store.groups.denyGroupInvitation(
              invitation.groupId,
              currentUserEmail
            );
            store.clearFromPendingInvitations(invitation.groupId);
            navigation.canGoBack() && navigation.goBack();
          }}
        />

        <Button
          label="Accept"
          onPress={async () => {
            if (permissions.canCreateGroup()) {
              await store.groups.acceptGroupInvitation(
                invitation.groupId,
                currentUserEmail
              );
              await store.refresh();
              store.clearFromPendingInvitations(invitation.groupId);
              alert(strings.messages.successfully.joinedGroup);
            } else {
              if (AlertupgradeToPremiumMaxGroupsLimitReached) {
                AlertupgradeToPremiumMaxGroupsLimitReached(
                  () => {
                    // update to premium
                    navigation.navigate("UpdateMembership", {
                      email: store.user.email || "Guest",
                    });
                  },
                  () => {}
                );
              } else {
                alert(strings.upgradeToPremium.maxGroupsReached);
              }
            }
          }}
        />
      </View>
    );
  });
export const PendingInvitationCards: React.FC = observer(() => {
  const store = useAppStore();
  const pendingInvitations = store.pendingGroupInvitations;
  const hasPendingInvitations = pendingInvitations.length > 0;
  if (!hasPendingInvitations) {
    return null;
  }
  return (
    <ScrollView horizontal={true} style={{ marginBottom: spacings.regular }}>
      <View
        style={css({
          backgroundColor: "transparent",
          flexDirection: "row",
          marginBottom: spacings.default,
        })}
      >
        {pendingInvitations.map((invitation) => {
          return (
            <PendingInvitationCard
              key={`invitation-${invitation.groupId}`}
              invitation={invitation}
            />
          );
        })}
      </View>
    </ScrollView>
  );
});

export const PendingInvitationCard: React.FC<{
  invitation: PendingGroupInvitationType;
}> = observer(({ invitation }) => {
  const store = useAppStore();
  const navigation = useNavigation();
  const currentUserEmail = store.user.email;
  if (!currentUserEmail) {
    return null;
  }
  return (
    <View
      style={css({
        backgroundColor: colors.surface,
        borderRadius: spacings.roundBorder,
        marginLeft: spacings.slim,
        marginRight: spacings.slim,
        padding: spacings.regular,
        width: 150,
        height: "100%",
        marginBottom: spacings.regular,
      })}
    >
      <View
        style={css({
          backgroundColor: "transparent",
          justifyContent: "flex-end",
          flexDirection: "row",
        })}
      >
        <Button
          style={{ marginTop: 0 }}
          onPress={() => {
            ConfirmRejectGroupInvitation(
              async () => {
                await store.groups.denyGroupInvitation(
                  invitation.groupId,
                  currentUserEmail
                );
                store.clearFromPendingInvitations(invitation.groupId);
              },
              () => {}
            );
          }}
          variant="tertiary"
          iconProps={{
            name: "close",
            color: colors.secondary,
            style: { marginTop: 0 },
          }}
        />
      </View>
      <Text style={css({ ...appStyles.heading.level1 })}>
        {invitation.groupName}
      </Text>
      <Text style={css(appStyles.text.label)}>Invited by</Text>
      <Text style={css(appStyles.text.label)}>{invitation.invitedBy}</Text>
      <View
        style={css({
          marginTop: spacings.regular,
          flexDirection: "row",
          backgroundColor: "transparent",
          alignItems: "center",
          justifyContent: "center",
        })}
      >
        <Button
          style={{
            marginTop: spacings.regular,
          }}
          label="Accept"
          onPress={async () => {
            if (permissions.canCreateGroup()) {
              await store.groups.acceptGroupInvitation(
                invitation.groupId,
                currentUserEmail
              );
              await store.refresh();
              store.clearFromPendingInvitations(invitation.groupId);
              alert(strings.messages.successfully.joinedGroup);
            } else {
              if (AlertupgradeToPremiumMaxGroupsLimitReached) {
                AlertupgradeToPremiumMaxGroupsLimitReached(
                  () => {
                    // update to premium
                    navigation.navigate("UpdateMembership", {
                      email: store.user.email || "Guest",
                    });
                  },
                  () => {}
                );
              } else {
                alert(strings.upgradeToPremium.maxGroupsReached);
              }
            }
          }}
        />
      </View>
    </View>
  );
});
