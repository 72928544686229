import { observer } from "mobx-react-lite";
import {
  ScrollView,
  RefreshControl,
  View,
  Text,
  Pressable,
} from "react-native";
import { appStyles, colors, spacings } from "../defaultStyles";
import { useAppStore } from "../store/AppStore";
import { css } from "../lib/glamor-native";
import { NoAccessScreen } from "./NoAccessScreen";
import { AntDesign } from "@expo/vector-icons";
import {
  NoRecentEntriesInFeed,
  PaginationComponent,
} from "../components/FeedList";
import { AdMobBanner } from "expo-ads-admob";
import { adUnitId } from "../constants/AppDefaults";
import { FeedCard } from "../store/common/FeedCard";
import { Heading } from "../components/common/Heading";
import { descending } from "d3";
import { CachedProfileImage } from "../components/common/ImagePickerComponent";
import ScrollProvider from "../components/common/scroll/ScrollProvider";

// ROOT - OPENS FROM BOTTOM TAB
const Recents: React.FC<{ showAds?: boolean }> = ({ showAds = true }) => {
  const store = useAppStore();

  if (!store.user?.hasAccess) {
    return <NoAccessScreen />;
  }

  let hasTasks = store.feed.allRecentlyLogged;

  if (!hasTasks) {
    return <NoRecentEntriesInFeed comingUp={true} />;
  }

  return (
    <View
      style={css({
        ...appStyles.viewWrapper,
        ...appStyles.container,
        paddingTop: 0,
      })}
    >
      {!store.user.isPremiumMember && showAds && (
        <View
          style={css({
            backgroundColor: "transparent",
            marginBottom: spacings.regular,
            justifyContent: "center",
            flexDirection: "row",
          })}
        >
          <AdMobBanner
            bannerSize="smartBannerPortrait"
            adUnitID={adUnitId}
            servePersonalizedAds // true or false
            onDidFailToReceiveAdWithError={(error) => {
              console.log("Error", error);
            }}
          />
        </View>
      )}

      {hasTasks && (
        <ScrollProvider
          contentContainerStyle={appStyles.scrollview}
          refreshControl={
            <RefreshControl
              refreshing={store.feed.items.refreshing}
              onRefresh={() => {
                store.ui.setEditTaskId(undefined);
                store.feed.loadCompletedTasksForUserIdInGroups(
                  store.feed.items.currentFilters["user"],
                  store.groups.ids,
                  0
                );
              }}
            />
          }
        >
          <RecentsList />
        </ScrollProvider>
      )}
    </View>
  );
};

export default observer(Recents);

const NUMBER_OF_FEEDCARDS_BEFORE_ANOTHER_AD_SHOWS = 10;
const RecentsList: React.FC = observer(() => {
  const store = useAppStore();
  const allRecentlyLogged = store.feed.allRecentlyLogged;
  const hasRecents = allRecentlyLogged.length > 0;
  const showEmpty = !hasRecents && !store.feed.items.refreshing;
  const filteredByUser = store.feed.items.currentFilters["user"];
  return (
    <View style={css({ backgroundColor: "transparent" })}>
      <MembersSelector />
      {filteredByUser && (
        <Text style={css(appStyles.text.body)}>
          {store.userProfiles[filteredByUser]?.displayName}
        </Text>
      )}
      <Header />

      {showEmpty && (
        <NoRecentEntriesInFeed
          comingUp={false}
          message={"Nothing logged reccently for selected user"}
        />
      )}
      {allRecentlyLogged
        .map((id) => store.feed.records[id])
        .sort((a, b) => descending(a.createdAt, b.createdAt))
        .map((completedTask, i) => {
          if (!completedTask) {
            return null;
          }
          if (
            i % NUMBER_OF_FEEDCARDS_BEFORE_ANOTHER_AD_SHOWS === 0 &&
            i !== 0
          ) {
            // show card with an ad
            return (
              <View
                key={`coming-up-feed-${completedTask.id}`}
                style={css({ backgroundColor: "transparent" })}
              >
                <FeedCard index={i} completedTask={completedTask} />
                {!store.user.isPremiumMember && (
                  <View
                    style={css({
                      backgroundColor: "transparent",
                      marginBottom: spacings.regular,
                      justifyContent: "center",
                      flexDirection: "row",
                    })}
                  >
                    <AdMobBanner
                      bannerSize="smartBannerPortrait"
                      adUnitID={adUnitId} // Test ID, Replace with your-admob-unit-id
                      servePersonalizedAds // true or false
                      onDidFailToReceiveAdWithError={(error) => {
                        console.log("Error", error);
                      }}
                    />
                  </View>
                )}
              </View>
            );
          } else {
            return (
              <FeedCard
                index={i}
                key={`coming-up-feed-${completedTask.id}`}
                completedTask={completedTask}
              />
            );
          }
        })}

      <PaginationComponent pagination={store.feed.items.pagination} />
    </View>
  );
});

const Header: React.FC = observer(() => {
  const store = useAppStore();
  const total = store.feed.allRecentlyLogged.length;

  if (total === 0) {
    return null;
  }

  return (
    <View
      style={css({
        backgroundColor: "transparent",
        marginBottom: spacings.regular,
        marginTop: spacings.regular,
      })}
    >
      <Heading level={1} title={`Recents `} />
    </View>
  );
});

const MembersSelector: React.FC = observer(() => {
  const store = useAppStore();
  const filteredByUser = store.feed.items.currentFilters["user"];
  const members = Object.keys(store.userProfiles).map(
    (email) => store.userProfiles[email]
  );
  return (
    <ScrollView horizontal>
      <View
        style={css({
          backgroundColor: "transparent",
          flexDirection: "row",
          marginBottom: spacings.regular,
        })}
      >
        {members.map((member) => {
          const userId = member?.email;
          if (!userId) {
            return null;
          }
          return (
            <Pressable
              key={userId}
              onPress={() => {
                userId &&
                  store.feed.loadCompletedTasksForUserIdInGroups(
                    userId,
                    store.groups.ids,
                    0
                  );
              }}
              style={({ pressed }) => ({
                opacity: pressed ? 0.5 : 1,
                marginRight: spacings.slim,
              })}
            >
              <View
                style={css({
                  backgroundColor: "transparent",
                  alignItems: "center",
                  justifyContent: "center",
                })}
              >
                <CachedProfileImage imageUrl={member?.profileImageUrl} />
                {filteredByUser === userId && (
                  <AntDesign name="up" size={24} color={colors.onBackground} />
                )}
              </View>
            </Pressable>
          );
        })}
      </View>
    </ScrollView>
  );
});
