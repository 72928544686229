import { TemplateTaskType } from "../templates";

export const mileageCount: TemplateTaskType[] = [
  {
    id: "mileage-drove-miles",
    score: 1,
    allowScoreOverwrite: true,
    title: "Drove miles",
    description: "You drove this many miles",
  },
];
