import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { css } from "../../../lib/glamor-native";
import { useAppStore } from "../../../store/AppStore";
import CompletedTask from "../../../store/CompletedTask";
import { fetcherScrollerAware } from "./FetcherScrollerAware";
const PRELOAD_CARDS_N = 3;
const ScrollReciever: React.FC<{
  name: string;
  index: number;
  completedTask: CompletedTask;
}> = observer(({ name, index, completedTask, children }, ...props) => {
  const store = useAppStore();
  const [position, setPosition] = useState<number | undefined>(undefined);
  const id: string = completedTask.id;

  const fetch = async () => {
    // always preload first 3 cards
    if (
      index < PRELOAD_CARDS_N ||
      (position &&
        position < fetcherScrollerAware.offset &&
        !fetcherScrollerAware.fetched[id])
    ) {
      completedTask.fetchComments(store.api);
      return await completedTask.fetchLikes(store.api);
    }
  };

  useEffect(() => {
    let cancel = false;

    fetch().then(() => {
      fetcherScrollerAware.setFetched(id);
      if (cancel) {
        return;
      }
    });

    return () => {
      cancel = true;
    };
  }, []);
  // trigger reload
  const offset = fetcherScrollerAware.offset;
  return (
    <View
      data-testID={offset}
      onLayout={(e) => {
        setPosition(e.nativeEvent.layout.y);
      }}
      style={css({ backgroundColor: "transparent" })}
    >
      {children}
    </View>
  );
});

export default ScrollReciever;
