import { TemplateTaskType } from "../templates";

export const autoMaintanance: TemplateTaskType[] = [
  {
    id: "auto-changed-oil",
    score: 1,
    allowScoreOverwrite: false,
    title: "Changed oil",
    description: "You changed the oil",
  },
  {
    id: "auto-rotated-tires",
    score: 1,
    allowScoreOverwrite: false,
    title: "Rotated tires",
    description: "You rotated the tires",
  },
  {
    id: "auto-replaced-tires",
    score: 1,
    allowScoreOverwrite: false,
    title: "Replaced tires",
    description: "You repleaced the tires",
  },
  {
    id: "auto-repaired-windshield",
    score: 1,
    allowScoreOverwrite: false,
    title: "Repaired windshield",
    description: "You repaired the windshield",
  },
  {
    id: "auto-replaced-windshield",
    score: 1,
    allowScoreOverwrite: false,
    title: "Replaced windshield",
    description: "You replaced the windshield",
  },
];
