import { makeAutoObservable } from "mobx";
import {
  getCurrentUTCTimeStamp,
  updateCountForPopularTask,
  updateCountForRecentlyUpdatedGroups,
} from "../utils";
import Apis from "./Api";
import { StartEndTimeRangeType, Time } from "./common/Time";
import CompletedTask, { CompletedTaskType } from "./CompletedTask";
import CompletedTaskList from "./CompletedTaskList";
import { Firestore } from "./Firestore";
import TaskList from "./TasksList";
import { descending } from "d3";
import { LoggedTaskUpdatesType } from "./AppStore";

export default class Feed {
  api: Firestore;
  from: number;
  range: StartEndTimeRangeType;
  selectedGroupId?: string;
  to: number;
  updated: number;
  records: { [completedTaskId: string]: CompletedTask }; // holds all loaded tasks
  tasks: TaskList;
  // References to records
  mostPopularTasks: { [taskId: string]: number };
  recentlyUpdatedGroups: { [groupId: string]: number };
  recentsByYou: { [completedTaskId: string]: number };
  // Feeds
  items: CompletedTaskList;
  currentUserEmail?: string;

  constructor(api: Firestore, tasks: TaskList) {
    this.api = api;
    this.tasks = tasks;
    this.from = getCurrentUTCTimeStamp();
    this.range = "today";
    this.currentUserEmail = undefined;
    this.selectedGroupId = undefined;
    this.to = getCurrentUTCTimeStamp();
    this.updated = new Date().getTime();

    this.mostPopularTasks = {};
    this.recentlyUpdatedGroups = {};
    this.recentsByYou = {};

    this.records = {};

    // Feed
    this.items = new CompletedTaskList(api, tasks);

    makeAutoObservable(this);
  }

  /**
   * List of taskIds for the recently most popular tasks logged by the user
   */
  get popularTasks(): string[] {
    const popular: string[] = [];
    return popular;
  }

  /**
   * List of groupIds for the recently most updated groups logged by the user
   */
  get popularGroups(): { groupId: string; count: number }[] {
    const popular = Object.keys(this.recentlyUpdatedGroups).map((key) => ({
      groupId: key,
      count: this.recentlyUpdatedGroups[key],
    }));
    const sortedByMostPopular = popular.sort((a, b) =>
      descending(a.count, b.count)
    );

    return sortedByMostPopular;
  }

  /**
   * Your recently completed tasks
   */
  get recents(): string[] {
    return Object.keys(this.recentsByYou);
  }

  /**
   * Coming up events for all groups
   */
  get allComingUp(): string[] {
    const today = new Date().getTime();
    const comingUp: string[] = [];
    Object.keys(this.records).forEach((id) => {
      const ct = this.records[id];
      if (ct.createdAt >= today) {
        comingUp.push(id);
      }
    });

    return comingUp;
  }

  /**
   * Tagged For Current User For All groups
   */
  get taggedToCurrentUser(): string[] {
    const tagged: string[] = [];
    const currentUserEmail = this.currentUserEmail;

    if (currentUserEmail) {
      Object.keys(this.records).forEach((id) => {
        const ct = this.records[id];
        const tags = ct.tags.tags;
        if (tags.includes(currentUserEmail)) {
          tagged.push(id);
        }
      });
    }

    return tagged;
  }

  /**
   * all recently logged events for all groups
   */
  get allRecentlyLogged(): string[] {
    const today = new Date().getTime();
    const recents: string[] = [];
    const byUser = this.items.currentFilters["user"];
    Object.keys(this.records).forEach((id) => {
      const ct = this.records[id];
      if (ct.createdAt <= today && ct.createdBy === byUser) {
        recents.push(id);
      }
    });

    return recents;
  }

  get list(): CompletedTask[] {
    const indexes = this.items.pagination.infiniteScrollIndex;

    return this.items.filteredValues?.slice(indexes.start, indexes.end);
  }

  get length(): number {
    return this.items.filteredValues.length;
  }

  setCurrentUser = (email: string) => {
    this.currentUserEmail = email;
  };
  loadCompletedTasksForUserIdInGroups = async (
    userId: string,
    groupIds: string[],
    index: number
  ) => {
    console.log("loadCompletedTasksForUserId", index);
    const groups = groupIds.slice().splice(index, 10);
    const completedTasks = await Apis.fetchCompletedTasksForUserIdInGroups(
      this.api.db,
      userId,
      groups,
      100
    );

    completedTasks?.forEach((doc) => {
      const ct = doc.data() as CompletedTaskType;
      if (!this.records[ct.id]) {
        const completedTask = new CompletedTask(this.api, ct);
        this.setRecord(completedTask);
      }
    });

    this.items.setCurrentFilters("user", userId);

    if (index < groupIds.length) {
      this.loadCompletedTasksForUserIdInGroups(userId, groupIds, index + 10);
    }
  };

  load = async (
    groupId?: string,
    range?: StartEndTimeRangeType,
    start?: number,
    end?: number
  ) => {
    this.items.resetFilters();
    this.items.clear();
    const selectedRange = range || "today";
    this.setRange(selectedRange);
    if (groupId) {
      this.items.pagination.setListSize(0);
      this.items.setRefreshing(true);

      let { startTime, endTime } = Time.getStartEndTimeFrom(selectedRange);
      const from = start || startTime;
      const to = end || endTime;
      this.setFrom(from);
      this.setTo(to);

      // load feed for group id
      const query = Apis.fetchFeed(this.api.db, groupId, this.range, from, to);

      if (query) {
        this.items.listenToListChanges("feed", query);
        this.items.setCurrentFilters("groupId", groupId);
      } else {
        console.log("NO QUERY FOUND WHEN TRYING TO FETCH FEED");
      }
    } else {
      // load feed for feed
      console.log("Is this my problemsszzz??");
    }
  };

  /**
   * load recents by userId for all groups
   * @param userId
   * @param limit
   */
  loadRecentsByYou = async (userId: string, limit: number) => {
    this.items.setCurrentFilters("user", userId);
    this.items.setRefreshing(true);
    const recents = await Apis.fetchCompletedTasksForUserId(
      this.api.db,
      userId,
      limit
    );

    // when we load recents by you, let's collect 3 types of information
    // in addition to your most recently updated tasks
    // 1. your last updated groups
    // 2. your most popular logged tasks
    // 3. your most recent logged completed tasks

    let mostPopularTasks: { [id: string]: number } = {};
    let recentlyUpdatedGroups: { [id: string]: number } = {};
    let recentsByYou: { [completedTaskId: string]: number } = {};
    const needNormalization: string[] = [];
    recents?.forEach((doc) => {
      const data = doc.data() as CompletedTaskType;
      const task = this.tasks.items[data.taskId];

      const title = task?.title || "";
      const description = task?.description || "";

      if (!task) {
        needNormalization.push(data.id);
      }
      const completedTask = new CompletedTask(
        this.api,
        data,
        title,
        description
      );

      this.setRecord(completedTask);
      recentsByYou[completedTask.id] = 1;
      mostPopularTasks = updateCountForPopularTask(
        mostPopularTasks,
        completedTask
      );
      recentlyUpdatedGroups = updateCountForRecentlyUpdatedGroups(
        recentlyUpdatedGroups,
        completedTask
      );
    });

    this.setRecentsByYou(recentsByYou);
    this.setMostRecentlyUpdatedGroups(recentlyUpdatedGroups);
    console.log({ mostPopularTasks, recentlyUpdatedGroups, recentsByYou });
    this.items.setRefreshing(false);
  };

  /**
   * load recently completed tasks for specific group id
   * @param groupId
   * @param limit
   */
  loadRecentsByGroupId = async (groupId: string, limit: number) => {
    const recents = await Apis.fetchCompletedTasksByGroupId(
      this.api.db,
      groupId,
      limit
    );

    recents?.forEach((doc) => {
      const ct = doc.data() as CompletedTaskType;
      const t = new CompletedTask(this.api, ct);
      this.items.setItem("id", t);
    });

    this.items.setCurrentFilters("groupId", groupId);
  };

  /**
   * load recently completed tasks for specific group id by you
   * @param userId
   * @param groupId
   * @param limit
   */
  loadRecentsByYouByGroupId = async (
    userId: string,
    groupId: string,
    limit: number
  ) => {
    this.setRange("all");
    const recents = await Apis.fetchCompletedTasksForUserIdForGroupId(
      this.api.db,
      userId,
      groupId,
      limit
    );

    recents?.forEach((doc) => {
      const ct = doc.data() as CompletedTaskType;
      const t = new CompletedTask(this.api, ct);
      this.items.setItem("id", t);
    });
    this.items.setCurrentFilters("user", userId);
    this.items.setCurrentFilters("groupId", groupId);
  };

  /**
   * load recently completed tasks for specific task id
   * @param taskId
   * @param limit
   */
  loadRecentCompletedTasksForTaskIdinGroupId = async (
    taskId: string,
    groupId: string,
    limit: number
  ) => {
    console.log({ filters: this.items.currentFilters });
    this.items.resetFilters();
    this.items.setRefreshing(true);
    const recents = await Apis.fetchCompletedTasksByTaskId(
      this.api.db,
      taskId,
      limit
    );

    this.items.clear();
    recents?.forEach((doc) => {
      const ct = doc.data() as CompletedTaskType;
      const t = new CompletedTask(this.api, ct);
      this.items.setItem("id", t);
    });

    this.items.setCurrentFilters("groupId", groupId);
    this.items.setRefreshing(false);
  };

  setRecentsByYou = (recents: { [completedTaskId: string]: number }) => {
    this.recentsByYou = recents;
  };

  setMostRecentlyUpdatedGroups = (value: { [groupId: string]: number }) => {
    this.recentlyUpdatedGroups = value;
  };

  setRecentGroup = (groupId: string) => {
    this.recentlyUpdatedGroups[groupId] = 1;
  };

  setMostPopularTasks = (value: { [groupId: string]: number }) => {
    this.mostPopularTasks = value;
  };

  setSelectedGroupId = (groupId?: string) => {
    this.selectedGroupId = groupId;
  };

  setRecord = (completedTask: CompletedTask) => {
    this.records[completedTask.id] = completedTask;
  };

  setUpdated = () => {
    this.updated = new Date().getTime();
  };

  setRange = (value: StartEndTimeRangeType) => {
    this.range = value;
  };

  setFrom = (value: number) => {
    this.from = value;
  };

  setTo = (value: number) => {
    this.to = value;
  };

  setTask = (completedTask: CompletedTaskType) => {
    const ct = this.items.items[completedTask.id];
    if (ct) {
      ct.update(completedTask);
    } else {
      this.items.setItem("id", new CompletedTask(this.api, completedTask));
    }

    this.setUpdated();
    this.items.pagination.setListSize(this.items.length);
  };

  updateCompletedTask = async (id: string, updates: LoggedTaskUpdatesType) => {
    let normalizedUpdates: LoggedTaskUpdatesType = {
      hashtags: updates.hashtags,
      notes: updates.notes,
      score: updates.score,
      tags: updates.tags,
    };
    const feedItems = this.items.items;
    const feedRecord = feedItems[id];

    feedRecord?.setNotes(normalizedUpdates.notes);
    feedRecord?.setScore(normalizedUpdates.score);
    feedRecord?.hashtags.setTags(normalizedUpdates.hashtags);
    feedRecord?.tags.setTags(normalizedUpdates.tags);
    const record = this.records[id];
    if (record) {
      record?.setNotes(normalizedUpdates.notes);
      record?.setScore(normalizedUpdates.score);
      record?.hashtags.setTags(normalizedUpdates.hashtags);
      record?.tags.setTags(normalizedUpdates.tags);
    }

    if (updates.createdAt) {
      normalizedUpdates.createdAt = updates.createdAt;
      feedRecord?.setCreatedAt(updates.createdAt);
      if (record) {
        record.setCreatedAt(updates.createdAt);
      }
    }

    if (updates.endedAt) {
      normalizedUpdates.endedAt = updates.endedAt;
      feedRecord?.setEndedAt(updates.endedAt);
      if (record) {
        record.setEndedAt(updates.endedAt);
      }
    }
  };
  removeCommentFromCompletedTask = (
    api: Firestore,
    ctId: string,
    commentId: string
  ) => {
    if (this.records[ctId]) {
      this.records[ctId].removeComment(commentId);
    }

    if (this.items.items[ctId]) {
      this.items.items[ctId].removeComment(commentId);
    }

    CompletedTask._removeComment(api, ctId, commentId);
  };

  removeCompletedTask = (completedTaskId: string) => {
    this.items.deleteItem(completedTaskId);
    if (this.records[completedTaskId]) {
      delete this.records[completedTaskId];
    }

    if (this.recentsByYou[completedTaskId]) {
      delete this.recentsByYou[completedTaskId];
    }
  };

  setRecords = (records: { [completedTaskId: string]: CompletedTask }) => {
    this.items.setItems(records);
  };
}
