import { useState } from "react";
import { Image, Text, useWindowDimensions, View } from "react-native";
import Carousel, { Pagination } from "react-native-snap-carousel";
import { appStyles, BLACK, spacings } from "../../defaultStyles";
import { css } from "../../lib/glamor-native";
import BG from "../../assets/images/bg1.png";
import BG1 from "../../assets/images/bg2.png";
import BG2 from "../../assets/images/bg3.png";
import BG3 from "../../assets/images/bg4.png";
import BG4 from "../../assets/images/bg5.png";
import BG6 from "../../assets/images/bg6.png";
import { useAppStore } from "../../store/AppStore";
import { useNavigation } from "@react-navigation/native";
import Button from "./Button";
import { AntDesign } from "@expo/vector-icons";
import { observer } from "mobx-react";
import ColmLogo from "./graphics/ColmLogo";

const reasonsWhy = [
  {
    title: "Collective memories",
    description:
      "For anyone who wants to keep track of the details of life in a collective way",
  },
  {
    title: "Track memories",
    description:
      "Remember your past activities. Share them with a group for deepen connections or keep it to yourself for enhanced memories",
  },
  {
    title: "Improve awareness",
    description:
      "Understand yourself or others around you simply by collectively tracking life events",
  },
  {
    title: "Gamify life",
    description:
      "Get visibility into who is doing what and when, be it about movies, books, house chores and keep a leaderboard by assigning specific points to events",
  },
  {
    title: "Increase visibility",
    description:
      "Keep track and share expenses values, pill taking, elderly care activity, baby sitting, cleaning, patient tracking or on anything else you can think of.",
  },
];

const MainCarousel: React.FC = () => {
  const store = useAppStore();
  const navigation = useNavigation();
  const dim = useWindowDimensions();
  const [activeSlide, setActiveSlide] = useState(0);
  const entries = reasonsWhy;
  const width = dim.width;
  const height = dim.height;
  const forceAppUpdate = store.appNotifications.forceAppUpdate;
  const logoSize = 100;
  return (
    <View style={css({ ...appStyles.container, padding: 0, margin: 0 })}>
      <View
        style={css({
          backgroundColor: "transparent",
          position: "absolute",
          alignItems: "center",
          justifyContent: "center",
          paddingBottom: spacings.regular,
          flex: 1,
          flexDirection: "column",
          width,
          bottom: 200,
          left: 0,
          zIndex: 1,
        })}
      >
        <Pagination
          dotsLength={reasonsWhy.length}
          activeDotIndex={activeSlide}
          containerStyle={{ backgroundColor: "transparent" }}
          dotStyle={{
            width: 10,
            height: 10,
            borderRadius: 5,
            marginHorizontal: 8,
            backgroundColor: "rgba(255, 255, 255, 0.92)",
          }}
          inactiveDotStyle={
            {
              // Define styles for inactive dots here
            }
          }
          inactiveDotOpacity={0.4}
          inactiveDotScale={0.6}
        />
        {!forceAppUpdate && (
          <Button
            style={{
              marginTop: spacings.large,
              backgroundColor: BLACK,
              borderColor: BLACK,
            }}
            variant="primary"
            label="Login"
            onPress={() => {
              store.initLoginForm();
              store.ui.setShowSignUpForm(false);
              navigation.navigate("Login");
            }}
          />
        )}
        {forceAppUpdate && <ForceAppUpdateMessage />}
        <AppUpdatesWarning />
      </View>
      <View
        style={css({
          backgroundColor: "transparent",
          position: "absolute",
          width,
          height,
          zIndex: 0,
        })}
      >
        <Image source={BG6} style={{ width, height }} />
      </View>

      <View
        style={css({
          backgroundColor: "transparent",
          position: "absolute",
          zIndex: 1,
          top: 100,
          left: width / 2 - logoSize / 2,
        })}
      >
        <ColmLogo id="splash-logo" size={logoSize} />
      </View>

      <Carousel
        itemWidth={width}
        vertical={false}
        sliderWidth={width}
        data={entries}
        renderItem={({ item, dataIndex, index }) =>
          RenderItem({ index, dataIndex, item }, width, height)
        }
        onSnapToItem={(index) => setActiveSlide(index)}
      />
    </View>
  );
};

export default observer(MainCarousel);

export const ForceAppUpdateMessage: React.FC = observer(() => {
  return (
    <View
      style={css({
        backgroundColor: "transparent",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      })}
    >
      <AntDesign name="warning" size={24} color="black" />
      <Text style={css(appStyles.text.bodyBold)}>
        You won't be able to login until you update the app
      </Text>
    </View>
  );
});
const RenderItem = (
  baseData: {
    index: number;
    dataIndex: number;
    item: any;
  },
  width: number,
  height: number
) => {
  const index = baseData.index;
  return (
    <View
      style={css({
        backgroundColor: "transparent",
        height,
      })}
    >
      {index === 0 && (
        <Card bg={BG} index={index} width={width} height={height} />
      )}
      {index === 1 && (
        <Card bg={BG1} index={index} width={width} height={height} />
      )}
      {index === 2 && (
        <Card bg={BG2} index={index} width={width} height={height} />
      )}
      {index === 3 && (
        <Card bg={BG3} index={index} width={width} height={height} />
      )}
      {index === 4 && (
        <Card bg={BG4} index={index} width={width} height={height} />
      )}
    </View>
  );
};

const Card: React.FC<{
  index: number;
  bg: any;
  width: number;
  height: number;
}> = ({ bg, width, height, index }) => {
  return (
    <View
      style={css({
        backgroundColor: "transparent",
        position: "relative",
        width,
        height,
      })}
    >
      <View
        style={css({
          backgroundColor: "transparent",
          position: "absolute",
          paddingTop: height / 2 - 200,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          zIndex: 2,
          padding: spacings.default,
        })}
      >
        <View
          style={css({
            backgroundColor: "transparent",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
          })}
        >
          {reasonsWhy[index].title.split(" ").map((word, i) => {
            return (
              <Text
                key={`word-${i}-${word}`}
                style={css({
                  fontSize: 50,
                  fontFamily: "Inter_900Black",
                  fontWeight: "bold",
                  color: "white",
                  flexDirection: "row",
                  textAlign: "center",
                  marginTop: -18,
                })}
              >
                {word}
              </Text>
            );
          })}
          <Text
            style={css({
              fontSize: 16,
              fontWeight: "bold",
              color: "white",
              flexDirection: "row",
              textAlign: "center",
              marginLeft: spacings.default,
            })}
          >
            {reasonsWhy[index].description}
          </Text>
        </View>
      </View>
    </View>
  );
};

const AppUpdatesWarning: React.FC = observer(() => {
  const store = useAppStore();
  const navigation = useNavigation();
  const notifications = store.appNotifications.globalNotifiactions;
  const latest = Object.keys(notifications).map((key) => notifications[key]);

  if (latest.length > 0) {
    // there are app updates since your last uploaded this app
    return (
      <View
        style={css({
          backgroundColor: "transparent",
          position: "absolute",
          bottom: -100,
        })}
      >
        <Button
          label="View app updates available"
          onPress={() => {
            navigation.navigate("Notifications", { option: "global" });
          }}
        />
      </View>
    );
  } else {
    return null;
  }
});
