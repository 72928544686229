import { useNavigation } from "@react-navigation/native";
import { StatusBar } from "expo-status-bar";
import { observer } from "mobx-react";
import { Platform, ScrollView, KeyboardAvoidingView } from "react-native";
import "react-native-get-random-values";
import { v4 as uuid } from "uuid";
import Button from "../components/common/Button";
import CheckboxButton from "../components/common/CheckboxButton";
import Input from "../components/common/Input";
import RadioButton from "../components/common/RadioButton";
import { Tag } from "../components/common/Tag";
import { Text, View } from "../components/Themed";
import { appStyles, colors, spacings } from "../defaultStyles";
import { css } from "../lib/glamor-native";
import { useAppStore } from "../store/AppStore";
import { permissions } from "../store/auth/Permissions";
import { FormOptions } from "../store/common/form/FormStore";
import { strings } from "../store/common/i18n/strings";
import { GroupType } from "../store/Group";
import { getCurrentUTCTimeStamp } from "../utils";

const EditGroupScreen: React.FC = () => {
  return (
    <View style={css({ ...appStyles.viewWrapper, ...appStyles.container })}>
      <ScrollView contentContainerStyle={css(appStyles.scrollview)}>
        <KeyboardAvoidingView
          behavior={Platform.OS === "ios" ? "padding" : "height"}
          style={css(appStyles.container)}
        >
          <EditGroupView />
        </KeyboardAvoidingView>
      </ScrollView>
    </View>
  );
};

export default observer(EditGroupScreen);

const EditGroupView: React.FC = observer(() => {
  const store = useAppStore();
  const navigation = useNavigation();
  const form = store.forms.getForm(FormOptions.editGroup);
  if (!form) {
    return null;
  }
  const isEditing = form.options.editMode;
  // const hasMembers =
  //   (form.fields.members.value && form.fields.members.value.length > 0) ||
  //   false;
  const fields = form.fields;
  if (!fields) {
    return null;
  }

  let allowUserToChangeGroupPermissions = !isEditing;
  const groupId = form.fields.groupId.value;
  let groupType = fields.type.value;
  if (groupId) {
    const group = store.groups.records[groupId];
    if (group && store.user.email) {
      allowUserToChangeGroupPermissions = group.isAdmin;
    }
  }

  const isMeasurementGroup = groupType === "measurement";
  const canCreateGroup = permissions.canCreateGroup();
  navigation.setOptions({
    title: isEditing ? "Edit Group" : "New Group",
  });
  return (
    <View style={css(appStyles.container)}>
      <View style={css({ ...appStyles.card })}>
        <MetadatadaFields />
        <TrakingFormFields />
        {isMeasurementGroup && (
          <Input
            value={fields.unit.value || ""}
            label="Unit of measure"
            onChangeText={(text) => fields.unit.set(text)}
          />
        )}
        {!isEditing && <AddGroupMembers />}
        {allowUserToChangeGroupPermissions && <PermissionFields />}

        <View
          style={{
            backgroundColor: "transparent",
            marginTop: 50,
          }}
        >
          <Button
            disabled={!form.isValid || !canCreateGroup}
            iconProps={{
              name: isEditing ? "check" : "plus-circle",
            }}
            label={
              isEditing
                ? strings.buttons.saveChanges
                : strings.buttons.createGroup
            }
            variant="primary"
            onPress={async () => {
              if (!canCreateGroup) {
                alert(strings.upgradeToPremium);
              } else {
                const formData = form.getData;
                const {
                  groupId,
                  title,
                  description,
                  type,
                  members,
                  createdBy,
                  isPublic,
                  allowJoinWithLink,
                  admins,
                  unit,
                } = formData;

                const group: GroupType = {
                  createdBy,
                  description,
                  groupId,
                  members,
                  title,
                  type,
                  createdAt: getCurrentUTCTimeStamp(),
                  public: isPublic,
                  allowJoinWithLink,
                  admins,
                  unit,
                };

                if (isEditing) {
                  await store.updateGroup(groupId, group);
                  navigation.canGoBack() && navigation.goBack();
                } else {
                  store.createGroup(group);
                  navigation.navigate("Root");
                }
              }
            }}
          />
        </View>
      </View>

      {/* Use a light status bar on iOS to account for the black space above the modal */}
      <StatusBar style={Platform.OS === "ios" ? "light" : "auto"} />
    </View>
  );
});

export const TrakingFormFields: React.FC = observer(() => {
  const store = useAppStore();
  const form = store.forms.getForm(FormOptions.editGroup);
  if (!form) {
    return null;
  }
  const fields = form.fields;
  if (!fields) {
    return null;
  }

  const groupType = form.fields.type.value;

  return (
    <View style={css({ backgroundColor: "transparent" })}>
      <View
        style={css({
          marginBottom: spacings.default,
          backgroundColor: "transparent",
        })}
      >
        <Text
          style={css({
            ...appStyles.heading.level2,
            fontWeight: "normal",
            marginBottom: spacings.slim,
            marginTop: spacings.slim,
            paddingLeft: spacings.slim,
            backgroundColor: "transparent",
          })}
        >
          {strings.messages.tracking}
        </Text>
        <View
          style={css({
            flexDirection: "column",
            justifyContent: "flex-start",
            backgroundColor: "transparent",
            flexWrap: "wrap",
          })}
        >
          <RadioButton
            key="date"
            label="Date"
            selected={groupType === "date"}
            onPress={() => {
              fields.type.set("date");
            }}
          />

          <RadioButton
            key="timestamp"
            label="Date and time"
            selected={groupType === "timestamp"}
            onPress={() => {
              fields.type.set("timestamp");
            }}
          />

          <RadioButton
            key="points"
            label="Points"
            selected={groupType === "points"}
            onPress={() => {
              fields.type.set("points");
            }}
          />
          <RadioButton
            key="time"
            label="Time"
            selected={groupType === "time"}
            onPress={() => {
              fields.type.set("time");
            }}
          />
          <RadioButton
            key="currency"
            label="Currency"
            selected={groupType === "currency"}
            onPress={() => {
              fields.type.set("currency");
            }}
          />

          <RadioButton
            key="numericValue"
            label="Numeric Value"
            selected={groupType === "numericValue"}
            onPress={() => {
              fields.type.set("numericValue");
            }}
          />
          <RadioButton
            key="measurement"
            label="Measurement (lbs, kg, km, miles, oz, liters, etc...)"
            selected={groupType === "measurement"}
            onPress={() => {
              fields.type.set("measurement");
            }}
          />
        </View>
      </View>
    </View>
  );
});

export const MetadatadaFields: React.FC = observer(() => {
  const store = useAppStore();
  const form = store.forms.getForm(FormOptions.editGroup);
  if (!form) {
    return null;
  }
  const fields = form.fields;
  if (!fields) {
    return null;
  }

  return (
    <View style={css({ backgroundColor: "transparent" })}>
      <View
        style={{
          flexDirection: "row",
          padding: spacings.slim,
          marginTop: spacings.relaxed,
          backgroundColor: "transparent",
        }}
      >
        <View
          style={{
            flex: 0.8,
            paddingRight: spacings.slim,
            backgroundColor: "transparent",
          }}
        >
          <Input
            label="Title"
            onChangeText={(value) => {
              fields.title.set(value);
            }}
            value={fields.title.value}
            placeholder="Enter your group's name"
            required
          />
        </View>
      </View>

      <View
        style={{
          flexDirection: "row",
          padding: spacings.slim,
          backgroundColor: "transparent",
        }}
      >
        <View style={{ flex: 1, backgroundColor: "transparent" }}>
          <Input
            multiline={true}
            label="Description"
            onChangeText={(value) => {
              fields.description.set(value);
            }}
            value={fields.description.value}
            placeholder="Description"
            numberOfLines={4}
          />
        </View>
      </View>
    </View>
  );
});

export const PermissionFields: React.FC = observer(() => {
  const store = useAppStore();
  const form = store.forms.getForm(FormOptions.editGroup);
  if (!form) {
    return null;
  }
  const fields = form.fields;
  if (!fields) {
    return null;
  }

  // const isPublic = fields.isPublic.value === true;
  return (
    <View style={css({ backgroundColor: "transparent" })}>
      {/* <View style={css({ backgroundColor: "transparent" })}>
        <CheckboxButton
          label="Public"
          selected={fields.isPublic.value === true}
          onPress={() => {
            fields.isPublic.set(!fields.isPublic.value);
          }}
        />
        {!isPublic && (
          <Text style={css(appStyles.text.bodyFaded)}>
            This group is private. Only admins will be able to make changes
          </Text>
        )}

        {isPublic && (
          <Text style={css(appStyles.text.bodyFaded)}>
            This group is public. Anyone in the group will be able to make
            changes
          </Text>
        )}
      </View> */}
      <View
        style={css({
          backgroundColor: "transparent",
          marginTop: spacings.default,
        })}
      >
        <CheckboxButton
          label="Allow invitations by link"
          selected={fields.allowJoinWithLink.value === true}
          onPress={() => {
            fields.allowJoinWithLink.set(!fields.allowJoinWithLink.value);
          }}
        />
        <Text style={css(appStyles.text.bodyFaded)}>
          {fields.allowJoinWithLink.value === true
            ? strings.messages.anyoneWithLinkCanJoin
            : strings.messages.onlyInvitedUsersCanJoin}
        </Text>
      </View>
    </View>
  );
});

export const AddGroupMembers: React.FC = observer(() => {
  const store = useAppStore();
  const navigation = useNavigation();
  const form = store.forms.getForm(FormOptions.editGroup);
  if (!form) {
    return null;
  }

  const hasMembers =
    (form.fields.members.value && form.fields.members.value.length > 0) ||
    false;
  const fields = form.fields;
  if (!fields) {
    return null;
  }

  return (
    <View style={css({ backgroundColor: "transparent" })}>
      <View
        style={css({
          backgroundColor: "transparent",
        })}
      >
        <View
          style={css({
            backgroundColor: "transparent",
            flexDirection: "row",
            alignItems: "center",
          })}
        >
          <Text
            style={css({
              ...appStyles.heading.level2,
              flex: 1,
            })}
          >
            Group Members
          </Text>
          <Button
            label="Add Member"
            variant="tertiary"
            iconProps={{ name: "plus-circle" }}
            onPress={() => {
              const groupId = form.fields.groupId.value || uuid();
              store.initAddMemberForm(groupId, true);
              navigation.navigate("AddMember");
            }}
          />
        </View>

        <Text style={css(appStyles.text.bodyFaded)}>
          You are already included by default
        </Text>
        <View
          style={css({
            marginTop: spacings.slim,
            flexDirection: "row",
            flexWrap: "wrap",
            marginBottom: spacings.default,
            backgroundColor: "transparent",
          })}
        >
          {hasMembers &&
            fields.members.value?.map((member: string) => {
              const canDeleteGroupUser = member !== fields.createdBy.value;
              return (
                <Tag
                  key={member}
                  label={member}
                  style={{ marginBottom: spacings.slim }}
                  backgroundColor={colors.surface}
                  color={colors.onSurface}
                  onDelete={
                    canDeleteGroupUser
                      ? () => {
                          //TODO: remove from form members list
                          const currentMembers = form.fields.members.value;
                          const updatedMemebers = currentMembers.filter(
                            (m: string) => member != m
                          );
                          form.fields.members.set(updatedMemebers);
                        }
                      : undefined
                  }
                >
                  {member}
                </Tag>
              );
            })}
        </View>
      </View>
    </View>
  );
});
