import { Unsubscribe } from "firebase/firestore";
import { makeAutoObservable } from "mobx";

export default class SubscriptionManager {
  subscriptions: { [subscriptionId: string]: Unsubscribe };

  constructor() {
    this.subscriptions = {};
    makeAutoObservable(this);
  }

  setSubscriptions = (id: string, subscription: Unsubscribe) => {
    this.subscriptions[id] = subscription;
  };

  unsubscribe = () => {
    // groups subscriptions
    Object.keys(this.subscriptions).forEach((key) => {
      const unsubscribe = this.subscriptions[key];
      console.log("unsubscribring from ", key);
      unsubscribe();
    });
  };
}
