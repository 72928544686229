import { useNavigation, useRoute } from "@react-navigation/native";
import { observer } from "mobx-react-lite";
import {
  View,
  ScrollView,
  KeyboardAvoidingView,
  Platform,
  Text,
} from "react-native";
import Button from "../components/common/Button";
import { Heading } from "../components/common/Heading";
import { appStyles, ORANGE, spacings } from "../defaultStyles";
import { css } from "../lib/glamor-native";
import { useAppStore } from "../store/AppStore";
import { CompletedTaskRawMetadata } from "../store/common/FeedCard";
import CompletedTask from "../store/CompletedTask";
import { MaterialCommunityIcons } from "@expo/vector-icons";

function DeleteCompletedTask() {
  const route = useRoute();
  //@ts-ignore
  const completedTask = route.params?.completedTask;

  if (!completedTask) {
    return null;
  }
  return (
    <View style={css({ ...appStyles.viewWrapper, ...appStyles.container })}>
      <ScrollView contentContainerStyle={css(appStyles.scrollview)}>
        <KeyboardAvoidingView
          behavior={Platform.OS === "ios" ? "padding" : "height"}
          style={css(appStyles.container)}
        >
          <DeleteCompletedTaskView completedTask={completedTask} />
        </KeyboardAvoidingView>
      </ScrollView>
    </View>
  );
}

export default observer(DeleteCompletedTask);

const DeleteCompletedTaskView: React.FC<{ completedTask: CompletedTask }> =
  observer(({ completedTask }) => {
    const store = useAppStore();
    const navigation = useNavigation();
    const completedTasks = store.feed.items.items;
    // find similar (with the same task id) completed tasks for the same user
    const similarCompletedTasks = Object.keys(completedTasks).filter(
      (key) =>
        completedTask.taskId === completedTasks[key].taskId &&
        completedTasks[key].createdBy === store.user.email &&
        completedTasks[key].id !== completedTask.id
    );
    const similarCount = similarCompletedTasks.length;
    const hasSimilar = similarCount > 0;
    return (
      <View style={css({ ...appStyles.container })}>
        <View style={css({ marginBottom: spacings.regular })}>
          <View style={css({ ...appStyles.card, flexDirection: "row" })}>
            <View style={css({ backgroundColor: "transparent", flex: 1 })}>
              <Text
                style={css({
                  ...appStyles.text.bodyBold,
                  marginBottom: spacings.regular,
                })}
              >
                Are you sure you want to delete the following entry?
              </Text>
              <CompletedTaskRawMetadata completedTask={completedTask} />
            </View>
            <DeleteCompletedTaskButton completedTask={completedTask} />
          </View>
        </View>

        {hasSimilar && <DeleteSimilar completedTask={completedTask} />}

        {hasSimilar && (
          <View style={css({ backgroundColor: "transparent" })}>
            <Heading title={`Similar (${similarCount})`} level={2} />
            {similarCompletedTasks.map((completedTaskId) => {
              const ct = store.feed.items.items[completedTaskId];
              if (!ct) {
                return null;
              }
              return (
                <CardMetadataWithDelete
                  key={`similar-${completedTaskId}`}
                  completedTask={ct}
                />
              );
            })}
          </View>
        )}
        <View
          style={css({
            backgroundColor: "transparent",
            flexDirection: "row",
            justifyContent: "center",
            marginBottom: spacings.large,
          })}
        >
          <Button
            variant="tertiary"
            label="Cancel"
            onPress={() => {
              navigation.goBack();
            }}
          />
        </View>
      </View>
    );
  });

const CardMetadataWithDelete: React.FC<{ completedTask: CompletedTask }> =
  observer(({ completedTask }) => {
    return (
      <View style={css({ ...appStyles.card, flexDirection: "row" })}>
        <View style={css({ backgroundColor: "transparent", flex: 1 })}>
          <Text
            style={css({
              ...appStyles.text.bodyBold,
              marginBottom: spacings.regular,
            })}
          >
            Delete the following entry?
          </Text>
          <CompletedTaskRawMetadata completedTask={completedTask} />
        </View>
        <DeleteCompletedTaskButton completedTask={completedTask} />
      </View>
    );
  });

const DeleteCompletedTaskButton: React.FC<{ completedTask: CompletedTask }> = ({
  completedTask,
}) => {
  const store = useAppStore();
  const navigation = useNavigation();
  return (
    <View style={css({ backgroundColor: "transparent" })}>
      <Button
        variant="secondary"
        label="Delete"
        onPress={async () => {
          await store.removeCompletedTask(
            completedTask.id,
            completedTask.groupId
          );
          navigation && navigation.goBack();
        }}
        style={{
          marginLeft: spacings.regular,
        }}
      />
    </View>
  );
};

const DeleteSimilar: React.FC<{ completedTask: CompletedTask }> = observer(
  ({ completedTask }) => {
    const store = useAppStore();
    const navigation = useNavigation();

    const completedTasks = store.feed.items.items;
    // find similar (with the same task id) completed tasks for the same user
    const similarCompletedTasks = Object.keys(completedTasks).filter(
      (key) =>
        completedTask.taskId === completedTasks[key].taskId &&
        completedTasks[key].createdBy === store.user.email &&
        completedTasks[key].id !== completedTask.id
    );
    const similarCount = similarCompletedTasks.length;
    return (
      <View
        style={css({
          ...appStyles.card,

          flexDirection: "row",
          alignItems: "center",
          width: "100%",
        })}
      >
        <MaterialCommunityIcons
          name="comment-question"
          size={24}
          color={ORANGE}
        />
        <Text
          style={css({
            ...appStyles.text.smallBoldFaded,
            flex: 1,
            paddingLeft: spacings.regular,
          })}
        >
          It looks like there are {similarCount} more similar tasks to the one
          you are trying to delete
        </Text>

        <Button
          label={`Delete all ${similarCount + 1} tasks`}
          onPress={() => {
            store.bulkDeleteTasks(similarCompletedTasks, completedTask.groupId);
            navigation.goBack();
          }}
          style={{
            marginLeft: spacings.regular,
          }}
        />
      </View>
    );
  }
);
