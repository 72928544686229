import { TemplateTaskType } from "../templates";

export const weightTracking: TemplateTaskType[] = [
  {
    id: "weight-tracking-event",
    score: 130,
    allowScoreOverwrite: true,
    title: "Weighted myself",
    description: "You weighted yourself",
  },
];
