import { useNavigation, useRoute } from "@react-navigation/native";
import { observer } from "mobx-react";
import { useWindowDimensions, View } from "react-native";
import Button, { ButtonType } from "../../components/common/Button";
import { appStyles, colors, setAlpha, spacings } from "../../defaultStyles";
import { css } from "../../lib/glamor-native";

export const Actions: React.FC = observer(() => {
  const route = useRoute();
  const navigation = useNavigation();

  const windowDimensions = useWindowDimensions();
  const params = route.params;
  if (!params) {
    return null;
  }

  //@ts-ignore
  const actions: ButtonType[] = params.actions;

  return (
    <View
      style={css({
        backgroundColor: setAlpha(colors.background, 0.1),
        position: "absolute",
        bottom: 0,
        zIndex: 2000,

        width: windowDimensions.width,
      })}
    >
      <View
        style={css({
          ...appStyles.actionContainer,
          padding: 0,
          height: windowDimensions.height / 3,
        })}
      >
        <View
          style={css({
            backgroundColor: "transparent",
            flexDirection: "row",
            justifyContent: "flex-end",
          })}
        >
          <Button
            iconProps={{ name: "remove", color: colors.onBackground }}
            variant="tertiary"
            onPress={() => {
              navigation.goBack();
            }}
          />
        </View>

        {actions.map((action: ButtonType, i: number) => {
          return (
            <View
              key={`${action.id}-${i}`}
              style={css({
                flexDirection: "row",
                backgroundColor: colors.background,
                borderBottomWidth: 1,
                borderBottomColor: setAlpha(colors.onBackground, 0.1),
                paddingTop: spacings.regular,
                paddingBottom: spacings.regular,
                paddingLeft: spacings.default,
                paddingRight: spacings.default,
                alignItems: "center",
              })}
            >
              <Button {...action} />
            </View>
          );
        })}
      </View>
    </View>
  );
});
