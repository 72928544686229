import { observer } from "mobx-react";
import { View, Text } from "react-native";
import { appStyles, spacings } from "../defaultStyles";
import { css } from "../lib/glamor-native";
import { useAppStore } from "../store/AppStore";
import Icon from "./common/Icon";

export interface DebbugeMessageType {
  label?: string;
  value?: string;
}
const Dev: React.FC<{
  messages: DebbugeMessageType[];
  forceShow?: boolean;
}> = observer(({ forceShow = false, messages }) => {
  const store = useAppStore();
  const devMode = store.settings.devMode;
  if (!devMode && forceShow === false) {
    return null;
  }
  return (
    <View style={css({ backgroundColor: "transparent" })}>
      {messages.map((message: DebbugeMessageType, i) => {
        return (
          <View
            key={`debubber-${i}`}
            style={css({
              backgroundColor: "transparent",
              marginTop: spacings.slim,
              flexDirection: "column",
            })}
          >
            <View
              style={css({
                backgroundColor: "transparent",
                marginTop: spacings.slim,
                flexDirection: "row",
              })}
            >
              <Icon name="warning" color="yellow" size={20} />
              <Text
                style={css({
                  ...appStyles.text.smallFaded,
                  marginLeft: spacings.slim,
                  marginRight: spacings.slim,
                })}
              >
                {message.label || "*"}
              </Text>
            </View>
            <Text style={css(appStyles.text.smallBold)}>
              {message.value || "undefined"}
            </Text>
          </View>
        );
      })}
    </View>
  );
});

export default Dev;
