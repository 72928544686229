import moment from "moment";
import Task, {
  getLabelForGroupType,
  getVerbForGroupType,
} from "../../store/Task";
import { showTimestampInCard } from "../../utils";
import CompletedTask from "../../store/CompletedTask";
import { observer } from "mobx-react";
import { useAppStore } from "../../store/AppStore";
import { Text, View } from "react-native";
import { css } from "../../lib/glamor-native";
import { appStyles, spacings } from "../../defaultStyles";
import { Dot } from "../common/Dot";

const FeedCardStamp: React.FC<{
  completedTask: CompletedTask;
}> = observer(({ completedTask }) => {
  const store = useAppStore();
  const userProfiles = store.userProfiles;

  const group = store.groups.records[completedTask.groupId];
  if (!group) {
    return null;
  }

  const displayName =
    userProfiles[completedTask.createdBy]?.displayName ||
    completedTask.createdBy;

  return (
    <View
      style={css({
        backgroundColor: "transparent",
        marginTop: spacings.regular,
        marginLeft: spacings.regular,
        flex: 1,
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "center",
      })}
    >
      <Text
        style={css({
          ...appStyles.text.smallFaded,
        })}
      >
        {moment(completedTask.createdAt).fromNow()}
      </Text>

      <Dot size={12} />

      <Text
        style={css({
          ...appStyles.text.smallFaded,
          marginRight: 2,
        })}
      >
        {displayName}
      </Text>
    </View>
  );
});

export default FeedCardStamp;
