import { Platform } from "react-native";
import { strings } from "../../store/common/i18n/strings";
import { AlertMessage, AlertType } from "./Alerts";

export const ConfirmDeleteGroup = (
  onDelete: () => void,
  onCancel: () => void
) => {
  if (Platform.OS === "web") {
    if (confirm(strings.confirmation.deleteGroup)) {
      onDelete();
      return null;
    } else {
      onCancel();
      return null;
    }
  }
  const alert: AlertType = {
    title: "Delete Group",
    message: strings.confirmation.deleteGroup,
    buttons: [
      {
        text: "Cancel",
        onPress: onCancel,
        style: "cancel",
      },
      {
        text: "Delete",
        onPress: onDelete,
        style: "destructive",
      },
    ],
  };
  return AlertMessage(alert);
};

export const ConfirmLeaveGroup = (
  onDelete: () => void,
  onCancel: () => void
) => {
  if (Platform.OS === "web") {
    if (confirm(strings.confirmation.leaveGroup)) {
      onDelete();
      return null;
    } else {
      onCancel();
      return null;
    }
  }

  const alert: AlertType = {
    title: "Leave Group",
    message: strings.confirmation.leaveGroup,
    buttons: [
      {
        text: "Cancel",
        onPress: onCancel,
        style: "cancel",
      },
      {
        text: "Delete",
        onPress: onDelete,
        style: "destructive",
      },
    ],
  };
  return AlertMessage(alert);
};

export const ConfirmDeleteLoggedTask = (
  onDelete: () => void,
  onCancel: () => void
) => {
  if (Platform.OS === "web") {
    if (confirm(strings.confirmation.deleteCompletedTask)) {
      onDelete();
      return null;
    } else {
      onCancel();
      return null;
    }
  }

  const alert: AlertType = {
    title: "Delete Logged Task",
    message: strings.confirmation.deleteCompletedTask,
    buttons: [
      {
        text: "Cancel",
        onPress: onCancel,
        style: "cancel",
      },
      {
        text: "Delete",
        onPress: onDelete,
        style: "destructive",
      },
    ],
  };
  return AlertMessage(alert);
};

export const ConfirmDeleteTask = (
  onDelete: () => void,
  onCancel: () => void
) => {
  if (Platform.OS === "web") {
    if (confirm(strings.confirmation.deleteTask)) {
      onDelete();
      return null;
    } else {
      onCancel();
      return null;
    }
  }
  const alert: AlertType = {
    title: "Delete Task",
    message: strings.confirmation.deleteTask,

    buttons: [
      {
        text: "Cancel",
        onPress: onCancel,
        style: "cancel",
      },
      {
        text: "Delete",
        onPress: onDelete,
        style: "destructive",
      },
    ],
  };
  return AlertMessage(alert);
};

export const ConfirmRejectGroupInvitation = (
  onDelete: () => void,
  onCancel: () => void
) => {
  if (Platform.OS === "web") {
    if (confirm(strings.confirmation.rejectInvitation)) {
      onDelete();
      return null;
    } else {
      onCancel();
      return null;
    }
  }
  const alert: AlertType = {
    title: "Reject Group Invitation",
    message: strings.confirmation.rejectInvitation,
    buttons: [
      {
        text: "Cancel",
        onPress: onCancel,
        style: "cancel",
      },
      {
        text: "Reject",
        onPress: onDelete,
        style: "destructive",
      },
    ],
  };
  return AlertMessage(alert);
};

export const AlertupgradeToPremiumMaxGroupsLimitReached = (
  onUpdate: () => void,
  onCancel: () => void
) => {
  if (Platform.OS === "web") {
    if (confirm(strings.upgradeToPremium.maxGroupsReached)) {
      onUpdate();
      return null;
    } else {
      onCancel();
      return null;
    }
  }
  const alert: AlertType = {
    title: strings.upgradeToPremium.title,
    message: strings.upgradeToPremium.maxGroupsReached,
    buttons: [
      {
        text: strings.buttons.cancel,
        onPress: onCancel,
        style: "cancel",
      },
      {
        text: strings.buttons.upgradeToPremium,
        onPress: onUpdate,
        style: "default",
      },
    ],
  };
  return AlertMessage(alert);
};

export const ConfirmRemovePendingInvitation = (
  onUpdate: () => void,
  onCancel: () => void
) => {
  if (Platform.OS === "web") {
    if (confirm(strings.messages.removePendingInvitation)) {
      onUpdate();
      return null;
    } else {
      onCancel();
      return null;
    }
  }
  const alert: AlertType = {
    title: strings.buttons.removeInvitation,
    message: strings.messages.removePendingInvitation,
    buttons: [
      {
        text: strings.buttons.cancel,
        onPress: onCancel,
        style: "cancel",
      },
      {
        text: strings.buttons.removeInvitation,
        onPress: onUpdate,
        style: "default",
      },
    ],
  };
  return AlertMessage(alert);
};
