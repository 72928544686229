import { Svg, G, Line, Text, Rect } from "react-native-svg";
import * as d3 from "d3";
import { colors } from "../../../defaultStyles";
import { getChartLabelForGroupType } from "../../../store/Task";
import { GroupOptionsType, GroupType } from "../../../store/Group";

const GRAPH_MARGIN = 20;
const GRAPH_BAR_WIDTH = 20;

const BarChart: React.FC<{
  id: string;
  data: { label: string; value: number; color?: string; action: () => void }[];
  round: number;
  groupType: GroupOptionsType;
}> = ({ id, data, round, groupType }) => {
  const chartColors = {
    text: colors.onBackground,
    axis: colors.onBackground,
    bars: colors.onBackground,
  };
  // Dimensions
  const SVGHeight = 150;
  const SVGWidth = 400;
  const graphHeight = SVGHeight - 2 * GRAPH_MARGIN;
  const graphWidth = SVGWidth - 2 * GRAPH_MARGIN;

  // X scale point
  const xDomain = data.map((item) => item.label);
  const xRange = [0, graphWidth];
  const x = d3.scalePoint().domain(xDomain).range(xRange).padding(1);

  // Y scale linear
  const maxValue = d3.max(data, (d) => d.value);
  const topValue = Math.ceil((maxValue || 20) / round) * round;
  const yDomain = [0, topValue];
  const yRange = [0, graphHeight];
  const y = d3.scaleLinear().domain(yDomain).range(yRange);

  // top axis and middle axis
  const middleValue = topValue / 2;
  return (
    <Svg
      key={id}
      width={SVGWidth}
      height={SVGHeight}
      fill={colors.surface}
      style={{ backgroundColor: colors.surface }}
    >
      <G y={graphHeight + GRAPH_MARGIN}>
        {/* Top value label */}
        <Text
          x={graphWidth}
          textAnchor="end"
          y={y(topValue) * -1 - 5}
          fontSize={12}
          fill={chartColors.text}
          fillOpacity={0.4}
        >
          {getChartLabelForGroupType(groupType, topValue)}
        </Text>

        {/* top axis */}
        <Line
          x1="0"
          y1={y(topValue) * -1}
          x2={graphWidth}
          y2={y(topValue) * -1}
          stroke={chartColors.axis}
          strokeDasharray={[3, 3]}
          strokeWidth="0.5"
        />

        {/* middle axis */}
        <Line
          x1="0"
          y1={y(middleValue) * -1}
          x2={graphWidth}
          y2={y(middleValue) * -1}
          stroke={chartColors.axis}
          strokeDasharray={[3, 3]}
          strokeWidth="0.5"
        />

        {/* bottom axis */}
        <Line
          x1="0"
          y1="2"
          x2={graphWidth}
          y2="2"
          stroke={chartColors.axis}
          strokeWidth="0.5"
        />

        {/* bars */}
        {data.map((item) => (
          <Rect
            // onPress={item.action}
            key={"bar" + item.label}
            x={(x(item.label) || 0) - GRAPH_BAR_WIDTH / 2}
            y={y(item.value) * -1}
            rx={2.5}
            width={GRAPH_BAR_WIDTH}
            height={y(item.value)}
            fill={item.color || chartColors.bars}
          />
        ))}

        {/* labels */}
        {data.map((item) => (
          <Text
            key={"label" + item.label}
            fontSize="8"
            x={x(item.label)}
            fill={chartColors.text}
            y="10"
            textAnchor="middle"
          >
            {item.label.split(" ")[0]}
          </Text>
        ))}
      </G>
    </Svg>
  );
};

export default BarChart;
