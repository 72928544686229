import { Pressable, Text, View } from "react-native";
import { css } from "../lib/glamor-native";
import Group from "../store/Group";
import { Ionicons } from "@expo/vector-icons";
import { appStyles, colors, spacings } from "../defaultStyles";
import { useAppStore } from "../store/AppStore";
import { observer } from "mobx-react";

const MarkFavoriteButton: React.FC<{ group: Group; compact?: boolean }> =
  observer(({ group, compact = true }) => {
    return (
      <View
        style={css({
          backgroundColor: "transparent",
        })}
      >
        {compact && <CompactFavoriteButton group={group} />}
        {!compact && <FullSizeFavoriteButton group={group} />}
      </View>
    );
  });

export default MarkFavoriteButton;

const iconSize = 18;
const CompactFavoriteButton: React.FC<{ group: Group }> = observer(
  ({ group }) => {
    const store = useAppStore();
    const isFavorite = group.favorite === true;
    return (
      <View
        style={css({
          backgroundColor: "transparent",
          marginTop: -5,
          marginLeft: spacings.slim,
        })}
      >
        <Pressable
          onPress={() => {
            store.markFavorite(group.groupId);
          }}
        >
          <View
            style={css({
              alignItems: "center",
              backgroundColor: isFavorite ? colors.warning : "transparent",
              borderRadius: spacings.roundBorder,
              flexDirection: "row",
              justifyContent: "center",
              width: iconSize,
              height: iconSize,
            })}
          >
            {!isFavorite && (
              <Ionicons
                name="star-outline"
                size={16}
                color={colors.onBackground}
              />
            )}
            {isFavorite && (
              <Ionicons name="star-sharp" size={16} color={colors.onWarning} />
            )}
          </View>
        </Pressable>
      </View>
    );
  }
);

const FullSizeFavoriteButton: React.FC<{ group: Group }> = observer(
  ({ group }) => {
    const store = useAppStore();
    const isFavorite = group.favorite === true;
    const textStyle = isFavorite
      ? css({
          ...appStyles.text.body,
          color: colors.onWarning,
          paddingLeft: spacings.regular,
        })
      : css({
          ...appStyles.text.body,
          color: colors.onSurface200,
          paddingLeft: spacings.regular,
        });
    return (
      <View style={css({ backgroundColor: "transparent" })}>
        <Pressable
          onPress={() => {
            store.markFavorite(group.groupId);
          }}
          style={({ pressed }) => {
            let bgColor = isFavorite ? colors.warning : colors.surface200;
            if (pressed) {
              bgColor = colors.surface200;
            }
            return {
              alignItems: "center",
              backgroundColor: bgColor,
              borderRadius: spacings.roundBorder,
              flex: 1,
              flexDirection: "row",
              justifyContent: "center",
              marginBottom: spacings.regular,
              marginTop: spacings.default,
              padding: spacings.regular,
            };
          }}
        >
          <View
            style={css({
              backgroundColor: "transparent",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            })}
          >
            {!group.favorite && (
              <Ionicons
                name="star-outline"
                size={16}
                color={colors.onSurface200}
              />
            )}
            {group.favorite && (
              <Ionicons name="star-sharp" size={16} color={colors.onWarning} />
            )}
            {isFavorite ? (
              <Text style={textStyle}>Favorite</Text>
            ) : (
              <Text style={textStyle}>Mark favorite</Text>
            )}
          </View>
        </Pressable>
      </View>
    );
  }
);
