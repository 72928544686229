import { TemplateTaskType } from "../templates";

export const lifeMemoryTasks: TemplateTaskType[] = [
  {
    id: "major-event",
    title: "Major event",
    description: "A major event happened",
  },
  {
    id: "funny-event",
    title: "Funny event",
    description: "Something funny happened",
  },
  {
    id: "notable-event",
    title: "Notable event",
    description: "Something notable happened",
  },
  {
    id: "moved-event",
    title: "Moved",
    description: "You moved to a new place",
  },
  {
    id: "new-job-event",
    title: "New Job",
    description: "You got a new job",
  },
  {
    id: "left-job-event",
    title: "Left Job",
    description: "You left a job",
  },
  {
    id: "life-changing-event",
    title: "Life Changing Event",
    description: "Something life changing happened",
  },
  {
    id: "life-health-event",
    title: "Urgent Care or ER Visit",
    description:
      "Someone had to visit Urgent Care or ER for something unexpected",
  },
];
