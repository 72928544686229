import { makeAutoObservable } from "mobx";

const ONE_MINUTE_IN_SECONDS = 60;
const ONE_HOUR_IN_SECONDS = 60 * ONE_MINUTE_IN_SECONDS;

export type StartEndTimeRangeType =
  | "today"
  | "recents"
  | "pastMonth"
  | "all"
  | "comingUp"
  | "inRange";

export interface StartEndTimeType {
  startTime: number;
  endTime: number;
}

export class Time {
  timeInSeconds: number;
  hourString: string;
  minuteString: string;
  secondsString: string;

  constructor(timeInSeconds?: number) {
    this.timeInSeconds = timeInSeconds || 0;
    this.hourString = Time.getHourStringValue(timeInSeconds);
    this.minuteString = Time.getMinutesStringValue(timeInSeconds);
    this.secondsString = Time.getSecondsStringValue(timeInSeconds);

    makeAutoObservable(this);
  }

  get seconds() {
    let hours = 0 || parseInt(this.hourString);
    let minutes = 0 || parseInt(this.minuteString);
    let seconds = 0 || parseInt(this.secondsString);

    if (this.timeInSeconds >= 0) {
      hours = (!isNaN(hours) ? hours : 0) * ONE_HOUR_IN_SECONDS;
      minutes = (!isNaN(minutes) ? minutes : 0) * ONE_MINUTE_IN_SECONDS;
      seconds = !isNaN(seconds) ? seconds : 0;
    }

    return hours + minutes + seconds;
  }

  setHourString = (value: string) => {
    if (value === "") {
      this.hourString = value;
    }
    if (parseInt(value) >= 0) {
      this.hourString = value;
    } else {
      if (parseInt(value) === NaN) {
        this.minuteString = "";
      }
    }
  };

  setMinuteString = (value: string) => {
    if (value === "") {
      this.minuteString = value;
    }
    if (parseInt(value) < 60 && parseInt(value) >= 0) {
      this.minuteString = value;
    } else {
      if (parseInt(value) === NaN) {
        this.minuteString = "";
      }
    }
  };

  setSecondsString = (value: string) => {
    if (value === "") {
      this.secondsString = value;
    }
    if (parseInt(value) < 60 && parseInt(value) >= 0) {
      this.secondsString = value;
    } else {
      if (parseInt(value) === NaN) {
        this.minuteString = "";
      }
    }
  };

  setTimeInSeconds = (value: number) => {
    this.timeInSeconds = value;
  };

  reset = () => {
    this.setTimeInSeconds(0);
  };

  static getSecondsValue = (timeString: string): number => {
    let arr = timeString.split(":");
    return +arr[0] * 60 * 60 + +arr[1] * 60 + +arr[2];
  };

  static getTimeStringValue = (timeInSeconds?: number): string => {
    if (!timeInSeconds) {
      return "";
    }
    const sec_num = timeInSeconds;

    let hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - hours * 3600) / 60);
    let seconds = sec_num - hours * 3600 - minutes * 60;

    let h = hours.toString();
    let m = minutes.toString();
    let s = seconds.toString();
    if (hours < 10) {
      h = `0${h}`;
    }
    if (minutes < 10) {
      m = `0${m}`;
    }
    if (seconds < 10) {
      s = `0${s}`;
    }
    return `${h}:${m}:${s}`;
  };

  static getHourStringValue = (timeInSeconds?: number) => {
    if (!timeInSeconds) {
      return "";
    }
    const sec_num = timeInSeconds;

    const hours = Math.floor(sec_num / 3600);

    if (hours < 10) {
      return `0${hours}`;
    }

    return `${hours}`;
  };

  static getMinutesStringValue = (timeInSeconds?: number) => {
    if (!timeInSeconds) {
      return "";
    }
    const sec_num = timeInSeconds;

    const hours = Math.floor(sec_num / 3600);
    const minutes = Math.floor((sec_num - hours * 3600) / 60);
    if (minutes < 10) {
      return `0${minutes}`;
    }

    return `${minutes}`;
  };

  static getSecondsStringValue = (timeInSeconds?: number) => {
    if (!timeInSeconds) {
      return "";
    }

    const sec_num = timeInSeconds;

    const hours = Math.floor(sec_num / 3600);
    const minutes = Math.floor((sec_num - hours * 3600) / 60);
    const seconds = sec_num - hours * 3600 - minutes * 60;

    if (seconds < 10) {
      return `0${seconds}`;
    }

    return `${seconds}`;
  };

  static getStringValue = (timeInSeconds?: number, short?: boolean): string => {
    if (!timeInSeconds) {
      return "Unknown";
    }
    const sec_num = timeInSeconds;

    let hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - hours * 3600) / 60);
    let seconds = sec_num - hours * 3600 - minutes * 60;

    let h = "";
    let m = "";
    let s = "";

    if (hours === 1) {
      h = `${hours}${short ? "h" : " hour"}`;
    }

    if (hours > 1) {
      h = `${hours}${short ? "h" : " hours"}`;
    }

    // if (hours >= 1 && minutes === 0) {
    //   m = `${minutes}${short ? "m" : "minutes"}`;
    // }
    if (minutes === 1) {
      m = `${minutes}${short ? "m" : " minute"}`;
    }

    if (minutes > 1) {
      m = `${minutes}${short ? "m" : " minutes"}`;
    }

    if (seconds === 1) {
      s = `${seconds}${short ? "s" : " second"}`;
    }

    if (seconds > 1) {
      s = `${seconds}${short ? "s" : " seconds"}`;
    }

    if (timeInSeconds === 0) {
      return "Timed";
    }

    return `${h} ${m} ${s}`.trim();
  };

  static getStartEndTimeFrom = (
    range: StartEndTimeRangeType
  ): StartEndTimeType => {
    switch (range) {
      case "comingUp": {
        const date = new Date();
        const today = new Date();
        date.setDate(date.getDate() + 365); // in the next year
        return {
          startTime: today.getTime(),
          endTime: date.getTime(),
        };
      }
      case "recents": {
        const date = new Date();
        const today = new Date();
        date.setDate(date.getDate() - 7);
        return {
          startTime: date.getTime(),
          endTime: today.getTime(), // RIGHT NOW
        };
      }

      case "pastMonth": {
        const date = new Date();
        const today = new Date();
        date.setDate(date.getDate() - 30); // in the past month
        return {
          startTime: date.getTime(), // 30 days ago
          endTime: today.getTime(), // RIGHT NOW
        };
      }
      case "today":
      default: {
        const date = new Date();
        const today = new Date();
        date.setDate(date.getDate() - 1);
        return {
          startTime: date.getTime(), // START OF TODAY
          endTime: today.getTime(), // RIGHT NOW
        };
      }
    }
  };
}

export function getStartTimeFromDay(year: number, month: number, day: number) {
  // set time to begin day UTC
  return Date.UTC(year, month, day, 0, 0, 0, 0);
}

export function getEndTimeFromDay(year: number, month: number, day: number) {
  // set time to begin day UTC
  return Date.UTC(year, month, day, 23, 59, 59, 0);
}

export const ONE_MILISECOND = 60;
export const ONE_SECOND = 60 * ONE_MILISECOND;
export const ONE_MINUTE = 60 * ONE_SECOND;
export const ONE_HOUR = 60 * ONE_MINUTE;
export const ONE_DAY = 24 * ONE_HOUR;
export const ONE_WEEK = 7 * ONE_DAY;
