import { TemplateTaskType } from "../templates";

export const babySitting: TemplateTaskType[] = [
  {
    id: "baby-sitting-diaper-change",
    score: 0,
    allowScoreOverwrite: false,
    title: "Changed diaper",
    description: "Change the child's diaper",
  },
  {
    id: "baby-sitting-bathe",
    score: 0,
    allowScoreOverwrite: false,
    title: "Bathed",
    description: "Bathed the child",
  },
  {
    id: "baby-sitting-fed",
    score: 0,
    allowScoreOverwrite: false,
    title: "Fed",
    description: "Fed the child",
  },
  {
    id: "baby-sitting-sleep",
    score: 0,
    allowScoreOverwrite: false,
    title: "Put to sleep",
    description: "Put the child to sleep",
  },
  {
    id: "baby-sitting-homework",
    score: 0,
    allowScoreOverwrite: false,
    title: "Helped with homework",
    description: "Helped with homework",
  },
  {
    id: "baby-sitting-transportation",
    score: 0,
    allowScoreOverwrite: false,
    title: "Drove",
    description: "Drove somewhere",
  },
  {
    id: "baby-sitting-housework",
    score: 0,
    allowScoreOverwrite: false,
    title: "Did house work",
    description: "Did house work",
  },
  {
    id: "baby-sitting-prepared-meals",
    score: 0,
    allowScoreOverwrite: false,
    title: "Prepared a meal",
    description: "Prepared a meal",
  },
  {
    id: "baby-sitting-play",
    score: 0,
    allowScoreOverwrite: false,
    title: "Played",
    description: "Played with the child",
  },
];
