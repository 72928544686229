import { observer } from "mobx-react";
import { View, Text } from "react-native";

import { appStyles, spacings, setAlpha, colors } from "../../defaultStyles";
import { css } from "../../lib/glamor-native";
import Icon from "./Icon";

export const ProTip: React.FC<{ message: string; hideDecoration?: boolean }> =
  observer(({ message, hideDecoration = false }) => {
    return (
      <View
        style={css({
          backgroundColor: "transparent",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: spacings.regular,
          marginTop: spacings.regular,
        })}
      >
        {!hideDecoration && (
          <View
            style={css({
              backgroundColor: "transparent",
              flexDirection: "row",
              alignItems: "center",
            })}
          >
            <Icon
              name="lightbulb-o"
              color={setAlpha(colors.onBackground, 0.3)}
            />
            <Text
              style={css({
                ...appStyles.text.bodyBold,
                marginLeft: spacings.regular,
                marginRight: spacings.default,
              })}
            >
              Pro tip!
            </Text>
          </View>
        )}
        <View
          style={css({
            backgroundColor: "transparent",
            flexDirection: "row",
            alignItems: "center",
            flex: 1,
          })}
        >
          <Text style={css({ ...appStyles.text.smallFaded, flexWrap: "wrap" })}>
            {message}
          </Text>
        </View>
      </View>
    );
  });
