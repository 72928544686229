import { useNavigation, useRoute } from "@react-navigation/native";
import { observer } from "mobx-react-lite";
import {
  View,
  ScrollView,
  RefreshControl,
  KeyboardAvoidingView,
  Platform,
  Text,
  Pressable,
} from "react-native";
import {
  appStyles,
  colors,
  NEON_GREEN,
  spacings,
  tableStyles,
} from "../defaultStyles";
import { css } from "../lib/glamor-native";
import { useAppStore } from "../store/AppStore";
import { getRangeLabel } from "../utils";
import { AntDesign } from "@expo/vector-icons";

function Filters() {
  const route = useRoute();
  //@ts-ignore
  const groupId = route.params?.groupId;
  if (!groupId) {
    return null;
  }
  return (
    <View style={css({ ...appStyles.viewWrapper, ...appStyles.container })}>
      <ScrollView contentContainerStyle={css(appStyles.scrollview)}>
        <KeyboardAvoidingView
          behavior={Platform.OS === "ios" ? "padding" : "height"}
          style={css(appStyles.container)}
        >
          <Options groupId={groupId} />
        </KeyboardAvoidingView>
      </ScrollView>
    </View>
  );
}

export default observer(Filters);

const Options: React.FC<{ groupId: string }> = observer(({ groupId }) => {
  const store = useAppStore();
  const navigation = useNavigation();

  const group = store.groups.records[groupId];
  if (!group) {
    return null;
  }
  let range = store.feed.range;
  if (group) {
    range = group.range;
  }

  const showMoreFilters = store.feed.range === "inRange";
  if (showMoreFilters) {
    range = "inRange";
  }
  const options = [
    {
      id: "today",
      label: getRangeLabel("today"),
      action: () => {
        group?.setRange("today");
        store.feed.setSelectedGroupId(group?.groupId);
        store.feed.load(group?.groupId, "today");
        navigation.goBack();
      },
    },
    {
      id: "recents",
      label: getRangeLabel("recents"),
      action: () => {
        group?.setRange("recents");
        store.feed.setSelectedGroupId(group?.groupId);
        store.feed.load(group?.groupId, "recents");
        navigation.goBack();
      },
    },
    {
      id: "pastMonth",
      label: getRangeLabel("pastMonth"),
      action: () => {
        group?.setRange("pastMonth");
        store.feed.setSelectedGroupId(group?.groupId);
        store.feed.load(group?.groupId, "pastMonth");
        navigation.goBack();
      },
    },
    {
      id: "comingUp",
      label: getRangeLabel("comingUp"),
      action: () => {
        const range = "comingUp";
        group?.setRange(range);
        store.feed.setSelectedGroupId(group?.groupId);
        store.feed.load(group?.groupId, "comingUp");
        navigation.goBack();
      },
    },
    {
      id: "All",
      label: getRangeLabel("all"),
      action: () => {
        const range = "all";
        group?.setRange(range);
        store.feed.setSelectedGroupId(group?.groupId);
        store.feed.load(group?.groupId, "all");
        navigation.goBack();
      },
    },
  ];
  return (
    <View
      style={css({
        ...appStyles.card,
        flexDirection: "column",
        paddingLeft: 0,
        paddingRight: 0,
      })}
    >
      <Text
        style={css({
          ...appStyles.heading.level2,
          marginLeft: spacings.default,
        })}
      >
        Filter
      </Text>
      {options.map((item) => {
        return <Row key={item.id} item={item} selected={range === item.id} />;
      })}
    </View>
  );
});

const Row: React.FC<{
  selected: boolean;
  item: { id: string; label: string; action?: () => void };
}> = observer(({ item, selected }) => {
  return (
    <Pressable
      style={({ pressed }) =>
        css({
          ...tableStyles.row(pressed),
        })
      }
      onPress={item.action}
    >
      <Text
        style={css({ ...appStyles.text.body, marginRight: spacings.regular })}
      >
        {item.label}
      </Text>
      {selected && (
        <AntDesign name="checkcircle" size={24} color={NEON_GREEN} />
      )}
    </Pressable>
  );
});
