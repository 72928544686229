import { useNavigation, useRoute } from "@react-navigation/native";
import { observer } from "mobx-react";
import { Pressable, ScrollView, Text } from "react-native";
import Input from "../components/common/Input";
import { View } from "../components/Themed";
import { appStyles, colors, spacings } from "../defaultStyles";
import { css } from "../lib/glamor-native";
import AppStore, { useAppStore } from "../store/AppStore";
import { FormOptions } from "../store/common/form/FormStore";
import Task, {
  getInputLabelForGroupType,
  getLabelForGroupType,
} from "../store/Task";
import Button from "../components/common/Button";
import CompletedTask, { CompletedTaskType } from "../store/CompletedTask";
import { v4 as uuid } from "uuid";
import { FontAwesomeIcon } from "../@types/fontAwesomeIcons";
import moment from "moment";
import {
  getCurrentUTCTimeStamp,
  getHashtagsFromString,
  isGroupTrackedByCreationDate,
  isGroupTrackedByTime,
  isInRange,
} from "../utils";
import { TimeInput } from "../components/common/TimePicker";
import { TaskSelector } from "../screens/LogTaskScreen";
import { Form } from "../store/common/form/Form";
import { Feather } from "@expo/vector-icons";
import { Entypo } from "@expo/vector-icons";
import { StartEndTimeRangeType } from "../store/common/Time";
import { Tag } from "./common/Tag";
import React from "react";
import { TaggableInput } from "./common/TaggableInput";
import Icon from "./common/Icon";
import Group from "../store/Group";
import RadioButton from "./common/RadioButton";

const CompleteTaskEditor: React.FC = observer(() => {
  const navigation = useNavigation();
  const store = useAppStore();
  const route = useRoute();
  //@ts-ignore
  const formId = route.params?.formId;
  if (!formId) {
    return null;
  }
  //@ts-ignore
  const taskId = route.params?.taskId;
  if (!taskId) {
    return null;
  }

  //@ts-ignore
  const createOnSave = route.params?.createOnSave;

  const form = store.forms.getForm(formId);
  if (!form) {
    return null;
  }

  const groupId = form.fields.groupId.value;
  if (!groupId) {
    return null;
  }
  const group = store.groups.records[groupId];

  const task = store.tasks.items[taskId];
  if (!task) {
    return null;
  }
  //@ts-ignore
  const goBackOnSave = route.params?.goBackOnSave;

  const fields = form.fields;
  const completedTaskId = store.ui.editCompletedTaskId;
  const isLoading = store.ui.loading;
  const editor = store.ui.taggableInputEditor;
  return (
    <View
      style={css({
        ...appStyles.viewWrapper,
        ...appStyles.container,
      })}
    >
      <ScrollView contentContainerStyle={css(appStyles.scrollview)}>
        <TaskSelector task={task} allowCheck={false} />
        <TaskEditor task={task} />
        <View
          style={css({
            backgroundColor: "transparent",
            flexDirection: "column",
            flex: 1,
            width: "100%",
          })}
        >
          <Tags tags={editor.profileMapping} group={group} />
          {!createOnSave && (
            <Button
              disabled={isLoading}
              style={{
                marginTop: spacings.default,
                width: "100%",
              }}
              label={isLoading ? "Saving..." : "Save"}
              onPress={async () => {
                if (completedTaskId) {
                  store.ui.setLoading(true);
                  const userProfiles = store.userProfiles;
                  const notesMapping = editor.getRaw(userProfiles);
                  const notes = notesMapping.updatedString;
                  // The tags are actually a profile mapping, they need to be in order
                  const tags = notesMapping.updatedMapping;
                  // TODO: detect members that are not in the group
                  let score = fields.score.value;
                  const groupType = store.groups.records[task.groupId]?.type;
                  if (groupType === "time" && task.allowScoreOverwrite) {
                    score = form.options.time.seconds;
                  }
                  if (!task.allowScoreOverwrite) {
                    score = task.score;
                  }
                  const createdAt = fields.createdAt.value;
                  // parse hashtags
                  const hashtags = getHashtagsFromString(notes);

                  const updates = {
                    createdAt: fields?.createdAt.value,
                    endedAt: fields?.endedAt.value,
                    hashtags,
                    notes,
                    score: parseFloat(score),
                    tags,
                  };
                  console.log({ updates });
                  let nonMembers: string[] = [];
                  if (fields.inviteTaggedNonMembersToJoinGroup.value === true) {
                    nonMembers = fields.nonMembers.value;
                  }

                  await store.updateCompletedTask(
                    completedTaskId,
                    updates,
                    nonMembers
                  );
                  const userId = store.user.email;
                  store.ui.setEditCompletedTaskId(undefined);
                  form.clearFields();
                  if (store.user.email) {
                    const range = getFeedRangeForRedirection(
                      store,
                      task,
                      createdAt
                    );
                    store.feed.setRange(range);
                    const group = store.groups.records[groupId];
                    group?.setRange(range);
                    if (goBackOnSave) {
                      navigation.goBack();
                    } else {
                      userId &&
                        (await store.feed.items.loadCompletedTasksForUserIdInGroup(
                          userId,
                          groupId,
                          30
                        ));
                      navigation &&
                        navigation.reset({
                          index: 1,
                          routes: [
                            { name: "Root" },
                            {
                              name: "UserRecents",
                              params: {
                                groupId: groupId,
                                email: store.user.email,
                              },
                            },
                          ],
                        });
                    }
                  }

                  store.ui.setLoading(false);
                } else {
                  alert("Something went wrong");
                }
              }}
            />
          )}
          {createOnSave && (
            <Button
              iconProps={{
                name: "check-circle-o",
              }}
              style={{
                marginTop: spacings.default,
                width: "100%",
              }}
              disabled={store.ui.loading === true}
              label={isLoading ? "Logging..." : "Log task"}
              onPress={async () => {
                store.ui.setLoading(true);
                const logTaskForm = store.forms.getForm(
                  FormOptions.completeTask
                );
                if (!logTaskForm) {
                  store.ui.setLoading(false);
                  return null;
                }
                const userProfiles = store.userProfiles;
                const fields = logTaskForm.fields;
                const notesMapping = editor.getRaw(userProfiles);
                const notes = notesMapping.updatedString;
                // The tags are actually a profile mapping, they need to be in order
                const tags = notesMapping.updatedMapping;
                const hashtags = getHashtagsFromString(notes);

                // this completed task does not exist yet!
                const id = uuid();
                let score = fields.score.value;
                const groupType = store.groups.records[task.groupId]?.type;

                if (groupType === "time" && task.allowScoreOverwrite) {
                  score = logTaskForm.options.time.seconds;
                }
                if (!task.allowScoreOverwrite) {
                  score = task.score;
                }
                const createdAt = getCurrentUTCTimeStamp(
                  fields.createdAt.value
                );

                const endedAt =
                  fields.endedAt.value !== undefined
                    ? getCurrentUTCTimeStamp(fields.endedAt.value)
                    : undefined;
                const completedTask: CompletedTaskType = {
                  allowScoreOverwrite: task.allowScoreOverwrite,
                  createdAt,
                  createdBy: store.user.email || "Unknown user",
                  endedAt,
                  groupId: task.groupId,
                  hashtags: [...new Set(hashtags)], // remove duplicates
                  id,
                  notes,
                  score,
                  tags,
                  taskId: task.taskId,
                  updatedAt: getCurrentUTCTimeStamp().toString(),
                };
                store.feed.setTask(completedTask);

                store.feedback.setFeedback({
                  message: "Logging ...",
                  variant: "info",
                });

                const title = task.title || "";
                const description = task.description || "";
                const recentlyCreatedCompletedTask =
                  store.feed.items.items[id] ||
                  new CompletedTask(
                    store.api,
                    completedTask,
                    title,
                    description
                  );
                const userId = store.user.email;

                const assignTo = fields.assignTo;
                // TODO:ASSIGN TASK TO USER & DO NOT LOG AS COMPLETED TASK
                let nonMembers: string[] = [];
                if (fields.inviteTaggedNonMembersToJoinGroup.value === true) {
                  nonMembers = fields.nonMembers.value;
                }
                await store.logCompletedTask(
                  id,
                  recentlyCreatedCompletedTask,
                  nonMembers
                );
                userId &&
                  (await store.feed.items.loadCompletedTasksForUserIdInGroup(
                    userId,
                    groupId,
                    30
                  ));
                store.feedback.setFeedback({
                  message: "Your event has been logged successfully!",
                  variant: "success",
                });
                store.ui.setLoading(false);

                if (store.user.email) {
                  // for appropriate redirection
                  // set feed range
                  const range = getFeedRangeForRedirection(
                    store,
                    task,
                    createdAt
                  );
                  store.feed.setRange(range);
                  store.feed.items.setCurrentFilters("dateRange", range);
                  const group = store.groups.records[groupId];
                  group?.setRange(range);
                  store.ui.setLoading(false);
                  form.clearFields();
                  if (goBackOnSave) {
                    navigation.goBack();
                  } else {
                    navigation &&
                      navigation.reset({
                        index: 1,
                        routes: [
                          { name: "Root" },
                          {
                            name: "UserRecents",
                            params: {
                              groupId: groupId,
                              email: store.user.email,
                            },
                          },
                        ],
                      });
                  }
                }
              }}
            />
          )}
        </View>
      </ScrollView>
    </View>
  );
});

export default CompleteTaskEditor;
export const Tags: React.FC<{ tags: string[]; group: Group }> = ({
  tags,
  group,
}) => {
  const store = useAppStore();
  const profiles = store.userProfiles;

  return (
    <View style={css({ backgroundColor: "transparent" })}>
      {[...new Set(tags)].map((tag) => {
        const displayName = profiles[tag]?.displayName;
        const showEmailDetails = displayName !== tag;
        return (
          <View
            key={tag}
            style={css({
              backgroundColor: "transparent",
              flexDirection: "row",
              alignItems: "center",
            })}
          >
            <Icon name="tag" size={13} color={colors.secondary} />
            <Text
              style={css({
                ...appStyles.text.smallBoldFaded,
                marginLeft: spacings.slim,
                color: colors.secondary,
              })}
            >
              {displayName || tag}
            </Text>
            {showEmailDetails && (
              <Text
                style={css({
                  ...appStyles.text.smallBoldFaded,
                  marginLeft: spacings.slim,
                  color: colors.secondary,
                })}
              >
                {`<${tag}>`}
              </Text>
            )}
          </View>
        );
      })}

      <InviteTaggedNonMembers group={group} tags={tags} />
    </View>
  );
};

export const InviteTaggedNonMembers: React.FC<{
  tags: string[];
  group: Group;
}> = observer(({ tags, group }) => {
  const store = useAppStore();
  const form = store.forms.getForm(FormOptions.completeTask);
  if (!form) {
    return null;
  }
  const members = group.members;
  const pendingMembers = group.pendingMembers;
  const nonMembers = tags.filter(
    (tag) => !members.includes(tag) && !pendingMembers.includes(tag)
  );
  const nonMembersLength = nonMembers.length;

  if (nonMembersLength === 0) {
    return null;
  }

  const hasMultiple = nonMembersLength > 1;

  const inviteNonMembers = form.fields.inviteTaggedNonMembersToJoinGroup;
  return (
    <View
      style={css({
        backgroundColor: "transparent",
        marginTop: spacings.regular,
      })}
    >
      <Text style={css(appStyles.text.body)}>
        {nonMembers.join(" ")} {hasMultiple ? "are" : "is"} not part of this
        group. Would you like to invite them?
      </Text>
      <RadioButton
        label="Yes"
        selected={inviteNonMembers.value === true}
        onPress={() => {
          const invite: boolean = !inviteNonMembers.value;
          inviteNonMembers.set(invite);
          if (invite === true) {
            console.log({ nonMembers });
            form.fields.nonMembers.set(nonMembers);
          }
        }}
      />
      <Text style={css(appStyles.text.smallBoldFaded)}>
        Users not in the group won't see this event
      </Text>
    </View>
  );
});
export const TaskEditor: React.FC<{ task: Task }> = observer(({ task }) => {
  const store = useAppStore();
  const form = store.forms.getForm(FormOptions.completeTask);
  const fields = form.fields;
  const createdAt = fields.createdAt.value;
  const endedAt = fields.endedAt.value;
  const group = store.groups.records[task.groupId];
  if (!group) {
    return null;
  }
  const showEndedDateTimePicker =
    endedAt !== undefined && createdAt !== endedAt;
  const groupType = group.type;
  const time = form.options.time;
  const score = fields.score.value;
  const scoreValue =
    fields.score.value === 0 ? undefined : fields.score.value?.toString();

  const editor = store.ui.taggableInputEditor;
  return (
    <View style={css({ backgroundColor: "transparent" })}>
      <View
        style={css({
          backgroundColor: "transparent",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: spacings.regular,
          marginTop: spacings.regular,
        })}
      >
        <View
          style={css({ backgroundColor: "transparent", flexDirection: "row" })}
        >
          <UpdateDateFromForm
            form={form}
            id="log-task-date"
            label={moment(new Date(createdAt).getTime()).format("MMMM DD YYYY")}
            initialDate={createdAt}
            onChange={(date: string) => {
              const hour = parseInt(moment(createdAt).format("hh"));
              const minute = parseInt(moment(createdAt).format("mm"));
              const amPm = moment(createdAt).format("A");
              const updatedHourString = `${moment(date).format(
                "dd DD-MMM-yyyy"
              )}, ${hour}:${minute} ${amPm}`;

              fields.createdAt.set(new Date(updatedHourString).getTime());
            }}
          />
          {showEndedDateTimePicker && (
            <UpdateDateFromForm
              form={form}
              style={{ marginLeft: spacings.slim }}
              id="log-task-end-date"
              label={moment(new Date(endedAt).getTime()).format("MMMM DD YYYY")}
              initialDate={createdAt}
              onChange={(date: string) => {
                const hour = parseInt(moment(createdAt).format("hh"));
                const minute = parseInt(moment(createdAt).format("mm"));
                const amPm = moment(createdAt).format("A");
                const updatedHourString = `${moment(date).format(
                  "dd DD-MMM-yyyy"
                )}, ${hour}:${minute} ${amPm}`;

                fields.createdAt.set(new Date(updatedHourString).getTime());
              }}
            />
          )}
        </View>

        <UpdateTimeFromForm
          form={form}
          id="log-task-time"
          label={moment(new Date(createdAt).getTime()).format("h:mm a")}
          initialDate={createdAt}
          onChange={(date: number) => {
            fields.createdAt.set(new Date(date).getTime());
          }}
          icon="clock-o"
        />
      </View>

      <View
        style={{
          backgroundColor: "transparent",
          flexDirection: "column",
          flex: 1,
          justifyContent: "flex-start",
        }}
      >
        <TaggableInput label="Notes" editor={editor} />

        {task.score > 0 && (
          <View
            style={css({
              backgroundColor: "transparent",
              flexDirection: "row",
              marginTop: spacings.regular,
              marginBottom: spacings.regular,
            })}
          >
            {task.allowScoreOverwrite && (
              <Text style={css(appStyles.text.bodyBold)}>Suggested value </Text>
            )}
            {!isGroupTrackedByCreationDate(groupType) && (
              <Tag
                label={getLabelForGroupType(
                  task.score,
                  group?.type,
                  undefined,
                  group?.unit
                )}
              />
            )}
          </View>
        )}

        {task.allowScoreOverwrite &&
          !isGroupTrackedByTime(groupType) &&
          !isGroupTrackedByCreationDate(groupType) && (
            <View
              style={css({
                backgroundColor: "transparent",
                flexDirection: "row",
                alignItems: "center",
                flex: 1,
                width: "100%",

                justifyContent: "flex-start",
              })}
            >
              <Input
                required
                keyboardType={"decimal-pad"}
                label={getInputLabelForGroupType(groupType, group.unit)}
                onChangeText={(value) => {
                  fields.score.set(value);
                }}
                value={scoreValue}
                placeholder={getInputLabelForGroupType(groupType, group.unit)}
                style={{
                  width: "100%",
                }}
                inputTextStyle={{
                  width: "100%",
                  backgroundColor: "transparent",
                  fontSize: 60,
                  color: colors.secondary,
                  fontWeight: "bold",
                  textAlign: "left",
                }}
              />
            </View>
          )}

        {task.allowScoreOverwrite && isGroupTrackedByTime(groupType) && (
          <View
            style={css({
              backgroundColor: "transparent",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            })}
          >
            <TimeInput label="Default time" time={time} onChange={() => {}} />
          </View>
        )}
      </View>
    </View>
  );
});

export const UpdateDateFromForm: React.FC<{
  id: string;
  label: string;
  icon?: FontAwesomeIcon;
  onChange: (number: string) => void;
  initialDate: string;
  form: Form;
  style?: React.CSSProperties;
}> = observer(
  ({ id, label, icon, onChange, initialDate, form, style = {} }) => {
    const navigation = useNavigation();

    const fields = form?.fields;
    if (!fields) {
      return null;
    }

    return (
      <Pressable
        onPress={() => {
          navigation.navigate("PickDate", {
            formId: form.id,
          });
        }}
        style={({ pressed }) =>
          css({
            opacity: pressed ? 0.5 : 1,
            ...style,
          })
        }
      >
        <View
          style={css({
            backgroundColor: colors.surface200,
            borderRadius: spacings.roundBorderXl,
            alignItems: "center",
            flexDirection: "row",
            padding: spacings.slim,
          })}
        >
          <Feather name="calendar" size={18} color={colors.onSurface200} />
          <Text
            style={css({
              ...appStyles.text.body,
              color: colors.onSurface200,
              marginLeft: spacings.regular,
            })}
          >
            {label}
          </Text>
          <Entypo name="chevron-down" size={20} color={colors.onSurface200} />
        </View>
      </Pressable>
    );
  }
);

export const UpdateTimeFromForm: React.FC<{
  id: string;
  label: string;
  icon?: FontAwesomeIcon;
  onChange: (updatedDateTime: number) => void;
  initialDate: string;
  form: Form;
}> = observer(({ id, label, icon, onChange, initialDate, form }) => {
  const navigation = useNavigation();
  const fields = form?.fields;
  if (!fields) {
    return null;
  }

  return (
    <Pressable
      onPress={() => {
        navigation.navigate("PickTime", {
          formId: form.id,
        });
      }}
      style={({ pressed }) =>
        css({
          opacity: pressed ? 0.5 : 1,
        })
      }
    >
      <View
        style={css({
          backgroundColor: colors.surface200,
          borderRadius: spacings.roundBorderXl,
          alignItems: "center",
          flexDirection: "row",
          padding: spacings.slim,
        })}
      >
        <Feather name="clock" size={18} color={colors.onSurface200} />
        <Text
          style={css({
            ...appStyles.text.body,
            color: colors.onSurface200,
            marginLeft: spacings.regular,
          })}
        >
          {label}
        </Text>
        <Entypo name="chevron-down" size={20} color={colors.onSurface200} />
      </View>
    </Pressable>
  );
});

const getFeedRangeForRedirection = (
  store: AppStore,
  task: Task,
  createdAt: number
): StartEndTimeRangeType => {
  // for appropriate redirection
  // set feed range
  const group = store.groups.records[task.groupId];
  if (isInRange("today", createdAt)) {
    return "today";
  } else if (isInRange("recents", createdAt)) {
    return "recents";
  } else if (isInRange("comingUp", createdAt)) {
    return "comingUp";
  } else {
    return "pastMonth";
  }
};
