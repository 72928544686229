import { useRoute } from "@react-navigation/native";
import { observer } from "mobx-react";
import {
  KeyboardAvoidingView,
  RefreshControl,
  ScrollView,
  Text,
  useWindowDimensions,
  View,
} from "react-native";
import Button from "../components/common/Button";
import { CachedProfileImage } from "../components/common/ImagePickerComponent";
import Input from "../components/common/Input";
import { appStyles, colors, setAlpha, spacings } from "../defaultStyles";
import { css } from "../lib/glamor-native";
import { useAppStore } from "../store/AppStore";
import Comment from "../store/common/Comment";
import CompletedTask from "../store/CompletedTask";
import { Feather } from "@expo/vector-icons";
import React from "react";
import { v4 as uuid } from "uuid";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { NoRecentEntriesInFeed } from "../components/FeedList";
import { useKeyboard } from "@react-native-community/hooks";
import moment from "moment";

export const CommentView: React.FC = observer(() => {
  const store = useAppStore();
  const route = useRoute();
  const windowDimensions = useWindowDimensions();
  const keyboard = useKeyboard();
  const isKeyboardShown = keyboard.keyboardShown;
  const viewHeight = windowDimensions.height;
  const heightMinusKeyboard = viewHeight - keyboard.keyboardHeight - 50;
  const commentsContainerHeight = isKeyboardShown
    ? heightMinusKeyboard
    : viewHeight;
  //@ts-ignore
  const completedTaskId: string = route.params?.completedTaskId;
  let completedTask = store.feed.records[completedTaskId];
  if (!completedTask) {
    completedTask = store.feed.items.items[completedTaskId];
    if (!completedTask) {
      return <NoRecentEntriesInFeed message={`task id: ${completedTaskId}`} />;
    }
  }

  return (
    <KeyboardAvoidingView style={css({ ...appStyles.container })}>
      <View
        style={css({
          backgroundColor: "transparent",
          flexDirection: "column",
          height: commentsContainerHeight,
        })}
      >
        <View style={css({ backgroundColor: "transparent", flex: 3 })}>
          <ScrollView
            contentContainerStyle={css({
              ...appStyles.scrollview,
            })}
            refreshControl={
              <RefreshControl
                refreshing={completedTask.refreshing}
                onRefresh={async () => {
                  await completedTask.fetchComments(store.api, true);
                }}
              />
            }
          >
            <Comments
              completedTask={completedTask}
              comments={completedTask.commentsList}
            />
          </ScrollView>
        </View>
        <View
          style={css({
            backgroundColor: "transparent",
            flex: 1,
            borderTopWidth: 1,
            borderTopColor: setAlpha(colors.onSurface200, 0.1),
          })}
        >
          <CommentEditor completedTask={completedTask} />
        </View>
      </View>
    </KeyboardAvoidingView>
  );
});

export const CommentEditor: React.FC<{ completedTask: CompletedTask }> =
  observer(({ completedTask }) => {
    const store = useAppStore();
    const windowDimensions = useWindowDimensions();
    const currentUser = store.user.email;

    if (!currentUser) {
      return null;
    }
    return (
      <View
        style={css({
          ...appStyles.viewWrapper,
          backgroundColor: "transparent",
          flexDirection: "row",
          width: windowDimensions.width - 140,
        })}
      >
        <CachedProfileImage
          imageUrl={store.userProfiles[currentUser]?.profileImageUrl}
        />
        <Input
          multiline={true}
          value={completedTask.commentText || ""}
          onChangeText={(text: string) => {
            completedTask.setCommentText(text);
          }}
          style={{
            marginLeft: spacings.slim,
          }}
        />
        <Button
          variant="primary"
          label="Post"
          style={{
            height: 40,
            paddingTop: spacings.slim,
            paddingBottom: spacings.slim,
            marginLeft: spacings.slim,
            marginTop: 0,
            marginBottom: 0,
          }}
          disabled={completedTask.commentText === ""}
          icon={<Feather name="send" size={24} color={colors.onSecondary} />}
          onPress={() => {
            const id = uuid();
            completedTask.addComment(store.api, {
              userId: currentUser,
              text: completedTask.commentText || "",
              createdAt: new Date().getTime(),
              id,
            });
            completedTask.setCommentText("");
          }}
        />
      </View>
    );
  });

export const Comments: React.FC<{
  completedTask: CompletedTask;
  comments: Comment[];
}> = observer(({ comments, completedTask }) => {
  if (comments.length === 0) {
    return (
      <NoRecentEntriesInFeed
        icon={
          <MaterialCommunityIcons
            name="comment-outline"
            size={60}
            color={setAlpha(colors.onBackground, 0.3)}
          />
        }
        message="No comments"
      />
    );
  }
  return (
    <View
      style={css({
        ...appStyles.viewWrapper,
        backgroundColor: "transparent",
      })}
    >
      {comments.map((comment) => {
        return (
          <CommentCard
            completedTask={completedTask}
            key={comment.id}
            comment={comment}
          />
        );
      })}
    </View>
  );
});

export const CommentCard: React.FC<{
  completedTask: CompletedTask;
  comment: Comment;
}> = observer(({ comment, completedTask }) => {
  const store = useAppStore();
  const userId = comment.userId;
  if (!userId) {
    return null;
  }
  const user = store.userProfiles[comment.userId];
  return (
    <View
      style={css({
        ...appStyles.viewWrapper,
        paddingTop: spacings.slim,
        paddingBottom: spacings.slim,
        backgroundColor: "transparent",
        flexDirection: "column",
      })}
    >
      <View
        style={css({
          backgroundColor: "transparent",
          flexDirection: "row",
          alignItems: "center",
        })}
      >
        <CachedProfileImage imageUrl={user?.profileImageUrl} size={24} />
        <Text
          style={css({
            ...appStyles.text.bodyBold,
            marginLeft: spacings.regular,
          })}
        >
          {user?.displayName}
        </Text>
      </View>
      <Text style={css({ ...appStyles.text.body, marginLeft: spacings.large })}>
        {comment.text}
      </Text>
      <Text
        style={css({
          ...appStyles.text.smallFaded,
          marginLeft: spacings.large,
        })}
      >
        {moment(comment.createdAt).fromNow()}
      </Text>
      <View
        style={css({
          backgroundColor: "transparent",
          position: "absolute",
          right: 0,
          top: 0,
        })}
      >
        <CommentCardActions completedTask={completedTask} comment={comment} />
      </View>
    </View>
  );
});

export const CommentCardActions: React.FC<{
  completedTask: CompletedTask;
  comment: Comment;
}> = observer(({ comment, completedTask }) => {
  const store = useAppStore();
  const userId = comment.userId;
  if (!userId) {
    return null;
  }

  if (userId !== store.user.email) {
    return null;
  }
  return (
    <View
      style={css({
        backgroundColor: "transparent",
        flexDirection: "column",
      })}
    >
      <Button
        variant="tertiary"
        iconProps={{
          name: "trash",
        }}
        onPress={() => {
          store.feed.removeCommentFromCompletedTask(
            store.api,
            completedTask.id,
            comment.id
          );
        }}
      />
    </View>
  );
});
