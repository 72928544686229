import { View } from "react-native";
import Svg, { Path, Text } from "react-native-svg";
import { colors } from "../../../defaultStyles";
import { css } from "../../../lib/glamor-native";

export const Bubble: React.FC<{
  id: string;
  message: string;
  bubbleColor?: string;
  textColor?: string;
  width?: number;
  height?: number;
  fontSize?: number;
}> = ({
  id,
  message,
  bubbleColor = colors.secondary,
  textColor = colors.onSecondary,
  width = "100%",
  height = "100%",
  fontSize = 53,
}) => {
  return (
    <View style={css({ width, height })}>
      <Svg id={id} data-name={id} viewBox="0 0 212.7 210.87">
        <Path
          fill={bubbleColor}
          d="M90.71,101.65c32.81-37.91,89.67-47,132.5-21.11,39.15,23.63,58.44,67.29,49.33,112.09a105.11,105.11,0,0,1-22.42,46.46c-3.64,4.42-3.29,8.47.88,12.25,2.95,2.68,5.92,5.34,8.86,8l16.79,15.31a1.15,1.15,0,0,1-.76,2h0c-36.34-.07-72.68.34-109-.38-31.86-.64-58.39-13.85-78.53-38.47C55.33,197.47,56.58,141.07,90.71,101.65Z"
          transform="translate(-64.33 -65.85)"
        />

        <Text
          fill={textColor}
          fontSize={fontSize}
          fontWeight="bold"
          fontFamily="Arial"
          transform="translate(40.91 119.91)"
        >
          {message}
        </Text>
      </Svg>
    </View>
  );
};
