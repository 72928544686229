import { makeAutoObservable } from "mobx";
import moment from "moment";
import History from "../docs/history.json";

export default class Settings {
  devMode: boolean;
  showQueryPerformance: boolean;
  appVersion: string;
  appVersionDescription: string;
  versionPublishedOn: number;

  constructor() {
    this.devMode = false;
    this.showQueryPerformance = false;
    const { version, lastUpdated, description } = this.getAppVersion();
    this.appVersion = version;
    this.appVersionDescription = description;
    this.versionPublishedOn = lastUpdated;

    makeAutoObservable(this);
  }

  setDevMode = (value: boolean) => {
    this.devMode = value;
  };
  setShowQueryPerformance = (value: boolean) => {
    this.showQueryPerformance = value;
  };

  setAppVersion(version: string) {
    this.appVersion = version;
  }

  setVersionPublishedOn(timestamp: number) {
    this.versionPublishedOn = timestamp;
  }

  setAppVersionDescription(description: string) {
    this.appVersionDescription = description;
  }

  getAppVersion = (): {
    version: string;
    lastUpdated: number;
    description: string;
  } => {
    const history = History.history;
    //@ts-ignore
    if (history.length > 0) {
      const version = history[0];
      if (version) {
        const content = version.split("|");
        if (content.length > 1) {
          const versionNo = content[0];
          const versionTimestamp = content[1];
          const versionDescription = content[2];

          return {
            version: versionNo,
            lastUpdated: new Date(
              moment(versionTimestamp).toLocaleString()
            ).getTime(),
            description: versionDescription,
          };
        }
      }
    }

    return {
      version: "unknown",
      lastUpdated: 0,
      description: "unknown",
    };
  };
}
