import { observer } from "mobx-react";
import { View } from "react-native";
import Button, { ButtonType } from "../../components/common/Button";
import { colors, spacings } from "../../defaultStyles";
import { css } from "../../lib/glamor-native";
import { IconPropsType } from "./Icon";
import { Entypo } from "@expo/vector-icons";

type FloatingMenuVariantType = "vertical" | "horizontal";

export interface FloatingMenuType {
  options: ButtonType[];
  variant?: FloatingMenuVariantType;
  iconProps?: IconPropsType;
  onPress?: (showMenu: boolean) => void;
  show?: boolean;
}
const FloatingMenu: React.FC<FloatingMenuType> = observer(
  ({ options, variant = "horizontal", onPress, iconProps, show = false }) => {
    return (
      <View
        style={css({
          backgroundColor: "transparent",
          flexDirection: "row",
          alignItems: "center",
          position: "absolute",
          justifyContent: "flex-end",
          right: 0,
          top: 0,
          zIndex: 2,
        })}
      >
        <View
          style={css({
            backgroundColor: colors.floatingMenuBackground,
            borderRadius: spacings.roundBorderXl,
            flexDirection: "row",
            alignItems: "center",
            position: "absolute",
            top: 0,
            right: 5,
          })}
        >
          {show && (
            <MenuActionButtons
              options={options}
              variant={variant}
              onMenuOptionSelected={(selected: ButtonType) => {
                onPress && onPress(true);
              }}
            />
          )}
        </View>

        {!show && (
          <Button
            variant="tertiary"
            icon={
              <Entypo
                name={
                  variant === "horizontal"
                    ? "dots-three-horizontal"
                    : "dots-three-vertical"
                }
                size={16}
                color={colors.onSurface300}
              />
            }
            onPress={() => {
              onPress && onPress(true);
            }}
            style={{ marginTop: 0, marginBottom: 0 }}
          />
        )}
      </View>
    );
  }
);
export default FloatingMenu;

const MenuActionButtons: React.FC<{
  options: ButtonType[];
  variant: FloatingMenuVariantType;
  onMenuOptionSelected: (selected: ButtonType) => void;
}> = observer(({ options, variant, onMenuOptionSelected }) => {
  return (
    <View
      style={css({
        flexDirection: variant === "horizontal" ? "row" : "column",
        alignItems: variant === "horizontal" ? "center" : "flex-end",
        padding: 0,
        backgroundColor: "transparent",
        borderWidth: 0,
        borderRadius: spacings.roundBorderXl,
      })}
    >
      {options.map((option, i) => {
        const showSeparator: boolean =
          variant === "horizontal" && i < options.length - 1;
        return (
          <View
            key={`${
              option.label || option.iconProps?.name || "button-option"
            }-${i}`}
            style={css({
              backgroundColor: "transparent",
              flexDirection: variant === "horizontal" ? "row" : "column",
              alignItems: "center",
            })}
          >
            <Button
              label={option.label}
              variant={option.variant || "tertiary"}
              iconProps={option.iconProps}
              onPress={() => {
                onMenuOptionSelected(option);
                option.onPress();
              }}
              onLongPress={() => {
                onMenuOptionSelected(option);
                if (option.onLongPress) {
                  option.onLongPress();
                } else {
                  option.onPress();
                }
              }}
              accessibilityLabel={option.accessibilityLabel}
              style={option.style}
              disabled={option.disabled}
            />
            {showSeparator && (
              <View
                style={css({
                  backgroundColor: colors.onSurface200,
                  width: 1,
                  height: 20,
                  marginLeft: spacings.slim,
                  marginRight: spacings.slim,
                })}
              ></View>
            )}
          </View>
        );
      })}
    </View>
  );
});
