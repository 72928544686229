import { useRoute } from "@react-navigation/native";
import { observer } from "mobx-react";
import moment from "moment";
import { ScrollView, Text, View } from "react-native";
import { appStyles, colors, spacings } from "../../defaultStyles";
import { css } from "../../lib/glamor-native";
import { PendingGroupInvitationType, useAppStore } from "../../store/AppStore";
import { Actions } from "../PendingInvitationsView";

const GroupInvitation: React.FC = () => {
  const store = useAppStore();
  const route = useRoute();
  //@ts-ignore
  const groupId = route.params?.groupId;
  const pendingInvitations = store.pendingGroupInvitations.filter(
    (invitation) => invitation.groupId === groupId
  );
  let invitation: undefined | PendingGroupInvitationType;
  if (pendingInvitations.length > 0) {
    invitation = pendingInvitations[0];
  }
  if (!invitation) {
    return null;
  }
  return (
    <View style={css({ ...appStyles.viewWrapper, ...appStyles.container })}>
      <ScrollView contentContainerStyle={css(appStyles.scrollview)}>
        <Text style={css(appStyles.heading.level0)}>You are invited!</Text>

        <View
          key={invitation.groupId}
          style={css({
            flexDirection: "row",
            backgroundColor: colors.surface,
            alignItems: "flex-end",
            marginBottom: spacings.regular,
            borderRadius: spacings.roundBorderXl,
            padding: spacings.default,
          })}
        >
          <View
            style={css({
              flexDirection: "column",
              flex: 1,
              backgroundColor: "transparent",
            })}
          >
            <Text style={css({ ...appStyles.heading.level1, flex: 1 })}>
              {invitation.groupName}
            </Text>
            <Text style={css(appStyles.text.body)}>
              {invitation.description}
            </Text>
            <View
              style={css({
                flexDirection: "row",
                backgroundColor: "transparent",
                marginTop: spacings.regular,
              })}
            >
              <Text style={css(appStyles.text.bodyBold)}>Invited by </Text>
              <Text style={css(appStyles.text.bodyBold)}>
                {invitation.invitedBy}
              </Text>
            </View>
            <Text style={css(appStyles.text.bodyFaded)}>
              {moment(invitation.invitedOn).fromNow()}
            </Text>
            <View
              style={css({
                flexDirection: "row",
                backgroundColor: "transparent",
                marginBottom: spacings.regular,
                justifyContent: "flex-end",
              })}
            >
              <Actions invitation={invitation} />
            </View>
          </View>
        </View>
      </ScrollView>
    </View>
  );
};

export default observer(GroupInvitation);
