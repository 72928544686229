import { useNavigation, useRoute } from "@react-navigation/native";
import { observer } from "mobx-react";
import moment from "moment";
import { RefreshControl, ScrollView, ShareContent } from "react-native";

import {
  IconButtonAddMembersToGroup,
  ButtonLeaveGroup,
  ButtonEditGroup,
  ButtonDeleteGroup,
} from "../components/Buttons";
import Button from "../components/common/Button";
import { Feedback } from "../components/common/Feedback";
import { LabeledText } from "../components/common/LabeledText";
import { Separator } from "../components/common/Separator";
import { GroupMembersList } from "../components/GroupMembersList";
import MarkFavoriteButton from "../components/MarkFavoriteButton";
import { Text, View } from "../components/Themed";
import { appName } from "../constants/AppDefaults";
import { appStyles, colors, spacings } from "../defaultStyles";
import { css } from "../lib/glamor-native";
import { useAppStore } from "../store/AppStore";
import { FormOptions } from "../store/common/form/FormStore";
import { strings } from "../store/common/i18n/strings";
import Linker from "../store/common/Linker";
import Group from "../store/Group";
import { getInputLabelForGroupType } from "../store/Task";

const GroupDetailsScreen: React.FC = () => {
  const store = useAppStore();
  const route = useRoute();
  //@ts-ignore
  const groupId = route.params?.groupId;
  if (!groupId) {
    return null;
  }
  return (
    <View style={css({ ...appStyles.viewWrapper, ...appStyles.container })}>
      <Feedback />
      <ScrollView
        contentContainerStyle={css(appStyles.scrollview)}
        refreshControl={
          <RefreshControl
            refreshing={store.groups.refreshing}
            onRefresh={() => store.groups.records[groupId].load(store.api)}
          />
        }
      >
        <GroupDetailsView />
      </ScrollView>
    </View>
  );
};

export default observer(GroupDetailsScreen);

const GroupDetailsView: React.FC = observer(() => {
  const store = useAppStore();
  const form = store.forms.getForm(FormOptions.editGroup);
  const currentUserEmail = store.user.email;
  if (!currentUserEmail) {
    return null;
  }
  const fields = form.fields;
  const groupId = fields.groupId.value;
  if (!groupId) {
    return null;
  }
  const group = store.groups.records[groupId];

  if (!group) {
    return null;
  }

  return (
    <View
      style={css({
        backgroundColor: "transparent",
      })}
    >
      <GroupInfo group={group} />
      <GroupParticipants group={group} />
      <GroupActions group={group} />

      <View
        style={css({
          backgroundColor: "transparent",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        })}
      >
        <Separator />
      </View>
    </View>
  );
});

export const GroupActions: React.FC<{ group: Group }> = observer(
  ({ group }) => {
    const store = useAppStore();
    const navigation = useNavigation();
    const groupId = group.groupId;
    const displayName =
      (group.createdBy && store.userProfiles[group.createdBy]?.displayName) ||
      group.createdBy;
    const isOnlyMember = group.members.length === 1;
    return (
      <View
        style={css({
          backgroundColor: "transparent",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: spacings.regular,
        })}
      >
        {!isOnlyMember && <ButtonLeaveGroup groupId={groupId} />}
        {isOnlyMember && <ButtonDeleteGroup groupId={groupId} />}

        <Text
          style={css({
            ...appStyles.text.smallFaded,
            marginTop: spacings.regular,
          })}
        >
          Created by {displayName}
        </Text>
        <Text style={css(appStyles.text.smallFaded)}>
          Created {moment(group.createdAt).format("MMM D, YYYY")}
        </Text>

        <Button
          label="Report Group"
          variant="tertiary"
          onPress={() => {
            store.initReportForm("group", undefined, group.groupId, undefined);
            navigation.navigate("ReportGroup", { groupId: group.groupId });
          }}
          style={{ marginTop: spacings.default }}
        />
      </View>
    );
  }
);

export const GroupParticipants: React.FC<{ group: Group }> = observer(
  ({ group }) => {
    const store = useAppStore();
    const groupId = group.groupId;
    return (
      <View style={css(appStyles.cardList)}>
        <View style={css(appStyles.cardListContainerHeader)}>
          <View
            style={css({
              flexDirection: "row",
              backgroundColor: "transparent",
            })}
          >
            <Text style={css(appStyles.heading.level3)}>
              Members ({group.members?.length || "0"}){" "}
            </Text>
            {group.permissions.canAddMembers && (
              <IconButtonAddMembersToGroup groupId={groupId} />
            )}
          </View>
        </View>

        <GroupMembersList groupId={groupId} />

        {!group.public &&
          group.hasNonAdminMembers &&
          group.permissions.canAddAdmin && (
            <Button
              variant="tertiary"
              label="Make all members admins"
              onPress={() => {
                store.groups.giveAdminAccess(group.groupId, group.members);
              }}
              style={{
                marginTop: spacings.regular,
              }}
            />
          )}

        {group.isCreator && group.hasAdminsOtherThanCreator && (
          <Button
            variant="tertiary"
            label="Revoke all admin priviledges"
            onPress={() => {
              store.groups.revokeAdminPreviledgesToAllMembersExceptOwner(
                group.groupId
              );
            }}
            style={{
              marginTop: spacings.regular,
            }}
          />
        )}
      </View>
    );
  }
);

export const GroupInfo: React.FC<{ group: Group }> = observer(({ group }) => {
  const groupId = group.groupId;
  return (
    <View style={css(appStyles.card)}>
      <View
        style={css({ flexDirection: "row", backgroundColor: "transparent" })}
      >
        <View
          style={css({
            flexDirection: "column",
            flex: 1,
            backgroundColor: "transparent",
          })}
        >
          <Text
            style={css({
              color: colors.onBackground,
            })}
          >
            Group Name
          </Text>

          <Text style={css({ ...appStyles.heading.level2, marginTop: 0 })}>
            {group.title}
          </Text>
        </View>
        <View
          style={css({
            backgroundColor: "transparent",
          })}
        >
          {group.permissions.canEditMetadata && (
            <ButtonEditGroup groupId={groupId} />
          )}
        </View>
      </View>
      <LabeledText label="Description" text={group.description} />
      <View
        style={css({
          flexDirection: "row",
          backgroundColor: "transparent",
          marginTop: spacings.regular,
        })}
      >
        <LabeledText
          label="Tracked by"
          text={getInputLabelForGroupType(group.type, group.unit)}
          style={{ flex: 1 }}
        />

        <LabeledText
          label="Group Permissions"
          text={
            group.allowJoinWithLink
              ? strings.messages.anyoneWithLinkCanJoin
              : "Private"
          }
        />
      </View>

      {group.allowJoinWithLink && (
        <Button
          label="Share Invitation Link"
          variant="tertiary"
          iconProps={{
            name: "share-alt-square",
          }}
          onPress={() => {
            const shareUrl = group.getInvitationLink();
            const content: ShareContent = {
              message: `You are invited to join a group on ${appName}! Click on the link below to join the group ${group.title}: ${group.description}`,
              url: shareUrl,
            };

            Linker.share(content);
          }}
          style={{ marginTop: spacings.default }}
        />
      )}
      <MarkFavoriteButton group={group} compact={false} />
    </View>
  );
});
