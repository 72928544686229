import { RefreshControl, ScrollView } from "react-native";
import { View } from "../components/Themed";
import { observer } from "mobx-react";
import { useAppStore } from "../store/AppStore";
import { css } from "../lib/glamor-native";
import { appStyles, spacings } from "../defaultStyles";
import Button from "../components/common/Button";
import { useNavigation } from "@react-navigation/native";
import { PendingInvitationCards } from "./PendingInvitationsView";
import { hasSearchTerm } from "../store/utils/formatters";
import Dev from "../components/Dev";
import { queryCounter } from "../store/utils/QueryCounter";
import { permissions } from "../store/auth/Permissions";
import { strings } from "../store/common/i18n/strings";
import { AlertupgradeToPremiumMaxGroupsLimitReached } from "../components/alerts/Confirmation";
import EmptyGroupsScreen from "./EmptyGroupsScreen";
import { CreateGroupFromTemplateCard } from "../components/CreateGroupFromTemplateCard";
import { Heading } from "../components/common/Heading";
import SearchBox from "../components/common/SearchBox";
import GroupCard from "../components/GroupCard";

function GroupsScreen() {
  const store = useAppStore();
  const hasGroups = store.groups.length > 0;
  return (
    <View style={css({ ...appStyles.viewWrapper, ...appStyles.container })}>
      {hasGroups && (
        <SearchBox search={store.search} placeholder="Search groups" />
      )}
      <ScrollView
        contentContainerStyle={css(appStyles.scrollview)}
        refreshControl={
          <RefreshControl
            refreshing={store.groups.refreshing}
            onRefresh={() => {
              store.refresh();
            }}
          />
        }
      >
        <PendingInvitationCards />

        {!hasGroups && !store.groups.refreshing && <EmptyGroupsScreen />}
        <Dev
          key="query-count"
          messages={[
            { value: queryCounter.total.toString(), label: "Total queries" },
          ]}
          forceShow={store.settings.showQueryPerformance}
        />

        {hasGroups && <GroupsView />}
      </ScrollView>
    </View>
  );
}

export default observer(GroupsScreen);

const Header: React.FC = observer(() => {
  const store = useAppStore();
  const navigation = useNavigation();
  const groupCount = store.search.hasSearchTerm ? 0 : store.groups.count;
  const groupCountString = groupCount === 0 ? "" : `(${store.groups.count})`;
  return (
    <View
      style={css({
        backgroundColor: "transparent",
        flexDirection: "row",
        alignItems: "center",
        marginTop: spacings.regular,
        marginBottom: spacings.regular,
      })}
    >
      <Heading
        level={2}
        title={`Groups ${groupCountString}`}
        style={{ flex: 1 }}
      />
      <Button
        variant="tertiary"
        label={strings.buttons.createGroup}
        onPress={() => {
          if (permissions.canCreateGroup()) {
            store.initEditGroupForm();
            navigation.navigate("EditGroup");
          } else {
            if (AlertupgradeToPremiumMaxGroupsLimitReached) {
              AlertupgradeToPremiumMaxGroupsLimitReached(
                () => {
                  // update to premium
                  navigation.navigate("UpdateMembership", {
                    email: store.user.email || "Guest",
                  });
                },
                () => {}
              );
            } else {
              alert(strings.upgradeToPremium.maxGroupsReached);
            }
          }
        }}
        style={{}}
      />
    </View>
  );
});

const GroupsView: React.FC = observer(() => {
  const store = useAppStore();
  const groups = store.groups.records;
  return (
    <View
      style={{
        backgroundColor: "transparent",
        borderRadius: spacings.roundBorder,
      }}
    >
      <Header />
      {store.groups.ids.map((key) => {
        const group = groups[key];
        if (
          store.search.hasSearchTerm &&
          !hasSearchTerm(store.search.term, group.title)
        ) {
          return null;
        }
        return <GroupCard key={group.groupId} group={group} />;
      })}
      <Heading
        title="Get More From Colem"
        level={2}
        style={{ marginTop: spacings.medium }}
      />
      <CreateGroupFromTemplateCard compact />
    </View>
  );
});

const Ranking: React.FC<{ groupId: string }> = observer(({ groupId }) => {
  const store = useAppStore();
  // const navigation = useNavigation();
  const usersRanking = store.userRankingByCompletedTasksByGroup[groupId];
  if (!usersRanking) {
    return null;
  }
  if (usersRanking.length === 0) {
    return null;
  }
  const topContributor = usersRanking[0];
  if (topContributor.points === 0) {
    return null;
  }
  // const userProfiles = store.userProfiles;

  return (
    <View
      style={css({
        alignItems: "flex-start",
        flexDirection: "row",
        flexWrap: "wrap",
        backgroundColor: "transparent",
        marginBottom: spacings.relaxed,
      })}
    >
      {/* {usersRanking?.map((rank, i) => {
        const color = getColorForUser(usersRanking.length, i);
        const id = `rank-${rank.user}-${groupId}-${i}`;
        const profile = userProfiles[rank.user];
        if (!profile) {
          return null;
        }
        const displayName = profile.displayName || rank.user.split("@")[0];
        const params = {
          email: rank.user,
          groupId,
        };

        return (
          <View
            key={id}
            style={css({
              width: "50%",
              height: 60,
              marginBottom: spacings.slim,
              marginTop: spacings.relaxed,
              backgroundColor: "transparent",
              paddingLeft: spacings.relaxed,
              paddingRight: spacings.relaxed,
            })}
          >
            <TouchableHighlight
              activeOpacity={0.6}
              underlayColor="transparent"
              onPress={() => {
                navigation.navigate("UserRecents", params);
              }}
            >
              <LeaderBoardSvg
                backgroundColor="transparent"
                id={id}
                color={color}
                place={`0${i + 1}`}
                title={displayName}
                details={`${rank.points} Points`}
              />
            </TouchableHighlight>
          </View>
        );
      })} */}
    </View>
  );
});
