import { observer } from "mobx-react-lite";
import { View } from "react-native";
import { appStyles } from "../defaultStyles";
import { css } from "../lib/glamor-native";
import MainCarousel from "../components/common/Carousel";

export const NoAccessScreen: React.FC<{ message?: string }> = observer(
  ({ message }) => {
    return (
      <View style={css({ ...appStyles.container })}>
        <MainCarousel />
      </View>
    );
  }
);
