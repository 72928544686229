import { action, computed, makeObservable, observable } from "mobx";
import { hasSearchTerm } from "../utils/formatters";
import Search from "../utils/Search";
import { sortByField, SortByOrderOptions } from "../utils/sort";
import { Pagination } from "./Pagination";

export type OnLoadItemsType = (api: any) => Promise<any[] | undefined>;
export type FilterByValueType = { fieldId: string; value: string };
export type SortByFieldType = {
  fieldId: string;
  value: "date" | "number" | "string";
};
export default class List {
  editing: boolean;
  filterBy?: FilterByValueType | undefined;
  groupBy?: string;
  items: { [id: string]: any };
  pagination: Pagination;
  refreshing: boolean;
  registeredSearchFieldKeys: string[];
  search: Search;
  sortByField: SortByFieldType;
  sortOrder: SortByOrderOptions;
  updated: number;

  constructor(searchFields: string[], sortByField: SortByFieldType) {
    this.editing = false;
    this.filterBy = undefined;
    this.groupBy = undefined;
    this.items = {};
    this.pagination = new Pagination();
    this.refreshing = false;
    this.registeredSearchFieldKeys = searchFields;
    this.search = new Search();
    this.sortByField = sortByField;
    this.sortOrder = SortByOrderOptions.ascending;
    this.updated = new Date().getTime();

    makeObservable(this, {
      // observables
      editing: observable,
      filterBy: observable,
      groupBy: observable,
      items: observable,
      pagination: observable,
      refreshing: observable,
      registeredSearchFieldKeys: observable,
      search: observable,
      sortByField: observable,
      sortOrder: observable,
      updated: observable,

      // actions
      clear: action,
      clearFilters: action,
      deleteItem: action,
      setEditing: action,
      setFilterBy: action,
      setGroupBy: action,
      setItem: action,
      setItems: action,
      setRefreshing: action,
      setRegisteredSearchFieldKeys: action,
      setSortByField: action,
      setSortOrder: action,
      setUpdated: action,
      sort: action,
      toggleSortOrder: action,

      // computed
      length: computed,
      values: computed,
    });
  }

  get length(): number {
    return this.values.length;
  }

  get values(): any[] {
    const searchTerm = this.search.term;
    const hasTerm = this.search.hasSearchTerm;
    const hasFilterBy = this.filterBy !== undefined;
    const sortByFieldId = this.sortByField?.fieldId;
    const sortByFieldValue = this.sortByField?.value;
    const searchFieldKeys = this.registeredSearchFieldKeys;
    const sortOrder = this.sortOrder;

    const items = Object.keys(this.items).map((key) => this.items[key]);
    if (hasTerm || hasFilterBy) {
      return items
        .slice()
        .filter((item) => {
          if (hasTerm) {
            return matchSearchTerm(item, searchTerm, searchFieldKeys);
          }
          if (hasFilterBy) {
            return matchFilterBy(item, this.filterBy);
          }

          return true;
        })
        .sort((a, b) =>
          sortMe(a, b, sortByFieldId, sortByFieldValue, sortOrder)
        );
    }

    return items
      .slice()
      .sort((a, b) => sortMe(a, b, sortByFieldId, sortByFieldValue, sortOrder));
  }

  setRegisteredSearchFieldKeys = (fieldKeys: string[]) => {
    this.registeredSearchFieldKeys = fieldKeys;
  };

  setItems = (items?: any) => {
    this.items = items;
  };

  setItem = (id: string, item: any): any => {
    const key = item[id];
    if (key) {
      this.items[key] = item;
      this.setUpdated();
      return this.items[key];
    }
    return undefined;
  };

  setUpdated = () => {
    this.updated = new Date().getTime();
  };

  setGroupBy = (groupBy: string) => {
    this.groupBy = groupBy;
  };

  deleteItem = (id: string) => {
    if (this.items[id]) {
      delete this.items[id];
    }
  };

  clearFilters = () => {
    this.filterBy = undefined;
  };

  setFilterBy = (filter: FilterByValueType) => {
    this.filterBy = filter;
  };

  setSortByField = (field: SortByFieldType) => {
    this.sortByField = field;
  };

  setSortOrder = (order: SortByOrderOptions) => {
    this.sortOrder = order;
  };

  toggleSortOrder = () => {
    if (this.sortOrder === SortByOrderOptions.ascending) {
      this.setSortOrder(SortByOrderOptions.descending);
    } else {
      this.setSortOrder(SortByOrderOptions.ascending);
    }
  };

  sort = (field: SortByFieldType, order: SortByOrderOptions) => {
    console.log("sort", field, order);
    this.setSortByField(field);
    this.setSortOrder(order);
  };

  setRefreshing = (value: boolean) => {
    this.refreshing = value;
  };

  setEditing = (value: boolean) => {
    this.editing = value;
  };

  clear = () => {
    console.log("--------- clearing list ----------");
    this.setItems({});
    this.clearFilters();
  };
}

export const matchFilterBy = (
  item: any,
  filterBy?: FilterByValueType
): boolean => {
  if (filterBy) {
    const fieldId = filterBy?.fieldId;
    if (fieldId) {
      return item[fieldId] === filterBy.value;
    }
  }
  return false;
};

export const matchSearchTerm = (
  item: any,
  searchTerm: string,
  registeredSearchFieldKeys: string[]
) => {
  let keep = false;
  registeredSearchFieldKeys.forEach((fieldKey) => {
    const fieldValue = item[fieldKey];
    if (fieldValue) {
      if (hasSearchTerm(searchTerm, fieldValue)) {
        keep = true;
      }
    } else {
      // console.warn("Attempt to search by field key that does not exist", {
      //   fieldValue,
      //   fieldKey,
      // });
    }
  });

  return keep;
};

export const sortMe = (
  a: any,
  b: any,
  sortByFieldId: string,
  sortByFieldType: "date" | "string" | "number",
  sortOrder: SortByOrderOptions
) => {
  if (sortByFieldType === "date") {
    return sortByField(a, b, sortByFieldId, sortOrder, true);
  }

  return sortByField(a, b, sortByFieldId, sortOrder);
};
