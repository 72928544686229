import { FontAwesome } from "@expo/vector-icons";
import { observer } from "mobx-react";
import SelectDropdown from "react-native-select-dropdown";
import { appStyles, colors, setAlpha, spacings } from "../../defaultStyles";
import { OptionSelector } from "../../store/AppStore";

const Dropdown: React.FC<{
  defaultButtonText?: string;
  defaultValue?: string;
  groupOptions: any[];
  onSelect: (selectedItem: any, index: any) => void;
}> = ({
  groupOptions,
  defaultButtonText = "Select option",
  onSelect,
  defaultValue,
}) => {
  return (
    <SelectDropdown
      defaultButtonText={defaultButtonText}
      defaultValue={defaultValue}
      renderDropdownIcon={(isOpened) => {
        return (
          <FontAwesome
            name={isOpened ? "chevron-up" : "chevron-down"}
            size={20}
            color={colors.onBackground}
            style={{ marginRight: 15 }}
          />
        );
      }}
      buttonStyle={{
        ...appStyles.input,
        // backgroundColor: colors.background,
        // borderWidth: 1,
        // borderColor: setAlpha(colors.onBackground, 0.6),
        // borderRadius: spacings.roundBorderXl,
        // padding: 0,
      }}
      buttonTextStyle={{
        color: colors.onBackground,
      }}
      dropdownStyle={{
        backgroundColor: colors.surface,
        borderColor: setAlpha(colors.onSurface, 0.6),
        borderRadius: spacings.roundBorderXl,
      }}
      rowTextStyle={{
        color: colors.onBackground,
        borderBottomWidth: 0,
      }}
      rowStyle={{
        borderBottomWidth: 1,
        borderBottomColor: setAlpha(colors.onSurface, 0.1),
      }}
      data={groupOptions.map((option: OptionSelector) => option.label)}
      onSelect={(selectedItem, index) => {
        onSelect(selectedItem, index);
      }}
      buttonTextAfterSelection={(selectedItem, index) => {
        // text represented after item is selected
        // if data array is an array of objects then return selectedItem.property to render after item is selected
        return selectedItem;
      }}
      rowTextForSelection={(item, index) => {
        // text represented for each item in dropdown
        // if data array is an array of objects then return item.property to represent item in dropdown
        return item;
      }}
    />
  );
};

export default observer(Dropdown);
