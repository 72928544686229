import { makeAutoObservable } from "mobx";
import { revertProfileDisplayNameMappingToEmail } from "../../utils";
import { UserProfilesType } from "../AppStore";

export class TagglableInputEditor {
  profileMapping: string[];
  text: string;

  constructor(text?: string) {
    this.text = text || "";
    this.profileMapping = [];
    makeAutoObservable(this);
  }

  get lastWord(): string {
    let lastword: string = "";
    const text = this.text;
    if (text !== undefined && typeof text === "string") {
      const lines = text.split("\n");
      lines.forEach((line) => {
        const words = line.split(" ") || [];
        const wordsLength = words.length;
        if (wordsLength > 0) {
          lastword = words[wordsLength - 1];
        }
      });
    }
    return lastword;
  }

  getRaw = (
    userProfiles: UserProfilesType
  ): { updatedString: string; updatedMapping: string[] } => {
    // assuming text is always pretty print
    const text = this.text;
    if (text !== "") {
      const mapping = revertProfileDisplayNameMappingToEmail(
        text,
        this.profileMapping,
        userProfiles
      );
      return mapping;
    }
    return { updatedString: "", updatedMapping: [] };
  };

  setText = (text: string) => {
    this.text = text;
  };

  setProfileMapping = (mapping: string[]) => {
    this.profileMapping = mapping;
  };
}
