import { Theme } from "@react-navigation/native";
import { Appearance, Dimensions, Platform } from "react-native";
const { width, height } = Dimensions.get("window");

//Guideline sizes are based on standard ~5" screen mobile device
const guidelineBaseWidth = 350;
const guidelineBaseHeight = 680;

const scale = (size: number) => (width / guidelineBaseWidth) * size;
const verticalScale = (size: number) => (height / guidelineBaseHeight) * size;
export const moderateScale = (size: number, factor = 0.5) =>
  size + (scale(size) - size) * factor;

export const toRem = (value: number): string => `${value * 0.0625}rem`;

// Helper function to transform a hex color into RGB numbers into a comma
// separated string. Example: #FFFFFF will return '255,255,255'
export const hexToRGB = (hex: string): string => {
  if (typeof hex !== "string") {
    throw new TypeError("Expected a string");
  }
  let hexColor = hex.replace(/^#/, "");
  if (hexColor.length === 3) {
    hexColor = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  const num = parseInt(hexColor, 16);
  return `${num >> 16}, ${(num >> 8) & 255}, ${num & 255}`;
};

export const setAlpha = (hex: string, alpha: number): string =>
  `rgba(${hexToRGB(hex)}, ${alpha})`;

// RAINBOW
export const YELLOW = "#F4F406";
const GREEN = "#9EF84D";
const CYAN = "#64D9EB";
const BLUE = "#356EC4";
const PURPLE = "#AC26D8";
const PINK = "#D92F98";

const SECONDARY_COLOR = "#ea00d4"; // pink
//const SECONDARY_COLOR = "#053eff"; blue
export const WHITE = "#EEEEEE";
const DARK = "#000000";
const BLUE_DE_FRANCE = "#4630EB";
const DOVEGRAY = "#747474";
const HUNTER_GREEN = "#1f1f1f";
const NERO = "#272525";
const OCEAN_GREEN = "#009966";
const RAGOON_GREEN = "#ffffff0d";
const DUBBING_PURPLE = "#7157E6";
const ONIX = "#12141d";
export const BLACK = "#0A0A0A";
export const ORANGE = "#f57503";
export const NEON_GREEN = "#97e81e";
const NEON_BLUE = "#053EFE";
const RED = "#B7263D";
const BURGANDY = "#841E3F";

const WHITE_PURE = "#FFFFFF";
const WHITE_P1 = "#F6F6F6";
const WHITE_P2 = "#ECECEC";
const WHITE_P3 = "#E1E1E1";
const WHITE_P4 = "#D0D0D0";
export const Opacity = {
  light: 0.1,
};

export const spacings = {
  default: 24,
  relaxed: 12,
  medium: 16,
  regular: 8,
  slim: 6,
  tiny: 4,
  small: 2,
  large: 36,
  roundBorder: 4,
  roundBorderXl: 6,
};

export const appVariationColors = {
  info: BLUE_DE_FRANCE,
  onInfo: WHITE,
  completed: OCEAN_GREEN,
  decoration: "orange",
  done: "#189309",
  inProgress: OCEAN_GREEN,
  onCompleted: "#FFFFFF",
  onDone: "#225d1b",
  onInProgress: "#FFFFFF",
  onOverdue: "#FFFFFF",
  onSuccess: "#FFFFFF",
  onPending: "#FFFFFF",
  overdue: "red",
  pending: DOVEGRAY,
  rejection: "#cc3333",
  success: OCEAN_GREEN,
  shadow: DARK,
  warning: "#FFFF00",
  onWarning: DARK,
  brand: DUBBING_PURPLE,
  onBrand: WHITE,
  highlight: "#242424",
  recording: "#7F050B",
  required: RED,
  floatingMenuBackground: WHITE_P3,
};

export const DarkThemeColors = {
  background: ONIX,
  RAGOON_GREEN,
  dark: DARK,
  error: "#e81919",
  fadedBackground: setAlpha(RAGOON_GREEN, 0.5),
  foreground: WHITE,
  bodyFaded: "#ffffff80",
  light: WHITE,
  onBackground: WHITE,
  onBackgroundVariant: WHITE,
  onError: "#FFFFFF",
  onPrimary: WHITE,
  onPrimary10: "#505050",
  onPrimary100: WHITE,
  onPrimary200: WHITE,
  onPrimary300: WHITE,
  onPrimary400: WHITE,
  onSecondary: WHITE,
  onSurface: WHITE,
  onSurface100: "gray",
  onSurface200: setAlpha(WHITE, 0.6),
  onSurface300: "#d1d1d1",
  onTertiary: WHITE,
  primary: RAGOON_GREEN,
  primary10: "#AAAAAA",
  primary100: "#505050",
  primary200: "#242424",
  primary300: "#323232",
  primary400: "#181818",
  secondary: SECONDARY_COLOR,
  surface: RAGOON_GREEN,
  surface10: HUNTER_GREEN,
  surface100: "#323232",
  surface200: "#ffffff1a",
  surface300: "#525252",
  tertiary: YELLOW,

  // app variations (decoration)
  ...appVariationColors,
  toggleBackground: DARK,
  toggleThumb: NERO,
};

export const LightThemeColors = {
  background: "#f6f6f6",
  dark: DARK,
  error: "#e81919",
  bodyFaded: setAlpha(BLACK, 0.5),
  fadedBackground: setAlpha(RAGOON_GREEN, 0.1),
  foreground: BLACK,
  light: BLACK,
  onBackground: BLACK,
  onBackgroundVariant: BLACK,
  onError: "#FFFFFF",
  onPrimary: BLACK,
  onPrimary10: "#505050",
  onPrimary100: BLACK,
  onPrimary200: BLACK,
  onPrimary300: BLACK,
  onPrimary400: BLACK,
  onSecondary: WHITE,
  onSurface: BLACK,
  onSurface100: "#928c8c",
  onSurface200: setAlpha(BLACK, 0.6),
  onSurface300: "#3e3e3e",
  onTertiary: BLACK,
  primary: RAGOON_GREEN,
  primary10: "#AAAAAA",
  primary100: "#505050",
  primary200: "#242424",
  primary300: "#323232",
  primary400: "#181818",
  secondary: SECONDARY_COLOR,
  surface: WHITE,
  surface10: WHITE_P1,
  surface100: WHITE_P2,
  surface200: WHITE_P3,
  surface300: WHITE_P4,
  tertiary: YELLOW,
  // app variations (decoration)
  ...appVariationColors,
  toggleBackground: DARK,
  toggleThumb: NERO,
};

let selectedTheme: any = DarkThemeColors;

const colorScheme = Appearance.getColorScheme();
if (colorScheme === "light") {
  selectedTheme = LightThemeColors;
}
export const colors = selectedTheme;

export const AppDarkTheme: Theme = {
  dark: true,
  colors: {
    primary: DarkThemeColors.onBackground,
    background: DarkThemeColors.background,
    card: DarkThemeColors.background,
    text: DarkThemeColors.onBackground,
    border: DarkThemeColors.surface100,
    notification: DarkThemeColors.surface200,
  },
};

export const AppLightTheme: Theme = {
  dark: false,
  colors: {
    primary: LightThemeColors.secondary,
    background: LightThemeColors.background,
    card: LightThemeColors.surface,
    text: LightThemeColors.onBackground,
    border: LightThemeColors.surface100,
    notification: LightThemeColors.surface200,
  },
};

export const sizes = {
  font: {
    body: 15,
    label: 13,
    small: 11,
    inputLarge: 15,
    input: 12,
    h0: 32,
    h1: 26,
    h2: 20,
    h3: 16,
  },
};

export const fontFamilies = {
  regular: "Inter_500Medium",
  bold: "Inter_700Bold",
};
export const fontFamily = '"Inter_500Medium", Helvetica, Arial, sans-serif';

export const borderRadius = {
  size1: 4,
  size2: 8,
  size3: 12,
  size4: 14,
};
export const appStyles = {
  viewWrapper: {
    paddingTop: spacings.default,
    paddingBottom: spacings.default,
    paddingLeft: spacings.relaxed,
    paddingRight: spacings.relaxed,
  },
  container: {
    flex: 1,
    backgroundColor: "transparent",
  },
  scrollview: {
    backgroundColor: "transparent",
  },
  actionContainer: {
    backgroundColor: colors.background,
    borderTopWidth: 4,
    borderTopColor: setAlpha(colors.secondary, 0.7),
    padding: spacings.default,
    borderRadius: spacings.roundBorderXl,
    marginTop: spacings.regular,
    marginBottom: spacings.regular,
  },
  feedCard: {
    backgroundColor: colors.surface,
    paddingLeft: spacings.regular,
    paddingRight: spacings.regular,
    paddingTop: spacings.tiny,
    paddingBottom: spacings.regular,
    borderRadius: spacings.roundBorderXl,
    marginTop: spacings.tiny,
    marginBottom: 0,
  },
  card: {
    backgroundColor: colors.surface,
    padding: spacings.default,
    borderRadius: spacings.roundBorderXl,
    marginTop: spacings.regular,
    marginBottom: spacings.regular,
  },
  cardListContainerHeader: {
    backgroundColor: "transparent",
    marginBottom: spacings.regular,
    paddingLeft: spacings.default,
    paddingRight: spacings.default,
  },
  cardList: {
    backgroundColor: colors.surface,
    borderRadius: spacings.roundBorderXl,
    paddingTop: spacings.default,
    paddingBottom: spacings.default,
    marginTop: spacings.regular,
    marginBottom: spacings.regular,
  },
  text: {
    body: {
      fontFamily: fontFamilies.regular,
      fontSize: sizes.font.body,
      color: colors.onBackground,
    },
    bodyFaded: {
      fontFamily: fontFamilies.regular,
      fontSize: sizes.font.body,
      color: colors.bodyFaded,
    },
    bodyBold: {
      fontFamily: fontFamilies.bold,
      fontWeight: "bold",
      fontSize: sizes.font.body,
      color: colors.onBackground,
    },
    label: {
      fontFamily: fontFamilies.regular,
      fontWeight: "bold",
      fontSize: sizes.font.label,
      color: colors.onBackground,
    },
    smallFaded: {
      fontFamily: fontFamilies.regular,
      fontSize: sizes.font.small,
      color: setAlpha(colors.onBackground, 0.6),
    },
    smallBold: {
      fontFamily: fontFamilies.bold,
      fontWeight: "bold",
      fontSize: sizes.font.small,
      color: colors.onBackground,
    },
    smallBoldFaded: {
      fontFamily: fontFamilies.bold,
      fontWeight: "bold",
      fontSize: sizes.font.small,
      color: setAlpha(colors.onBackground, 0.5),
    },
    smallBoldDecorated: {
      fontFamily: fontFamilies.bold,
      fontWeight: "bold",
      fontSize: sizes.font.small,
      color: colors.onBackground,
    },
    premium: {
      fontFamily: fontFamilies.regular,
      fontWeight: "bold",
      fontSize: 11,
      color: NEON_GREEN,
      letterSpacing: 1,
    },
  },
  heading: {
    primary: {
      fontFamily: "Inter_700Bold",
      fontSize: sizes.font.h0,
      fontWeight: "bold",
      color: colors.onBackground,
      marginTop: spacings.regular,
      marginBottom: spacings.regular,
    },
    level0: {
      fontFamily: "Inter_600SemiBold",
      fontSize: sizes.font.h0,
      fontWeight: "bold",
      color: colors.onBackground,
      marginTop: spacings.regular,
      marginBottom: spacings.regular,
    },
    level1: {
      fontFamily: "Inter_700Bold",
      fontSize: sizes.font.h1,
      fontWeight: "bold",
      color: colors.onBackground,
      marginBottom: spacings.regular,
    },
    level2: {
      fontFamily: "Inter_700Bold",
      fontSize: sizes.font.h2,
      fontWeight: "bold",
      color: colors.onBackground,
      marginBottom: spacings.slim,
    },
    level3: {
      fontFamily: "Inter_700Bold",
      fontSize: sizes.font.h3,
      fontWeight: "bold",
      color: colors.onBackground,
      marginBottom: spacings.regular,
    },
  },
  input: {
    borderWidth: 0,
    padding: spacings.slim,
    borderRadius: spacings.roundBorderXl,
    backgroundColor: colors.surface200,
    color: colors.onPrimary200,
    paddingTop: spacings.relaxed,
    paddingBottom: spacings.relaxed,
  },
  button: {
    paddingLeft: spacings.relaxed,
    paddingRight: spacings.relaxed,
    paddingTop: spacings.relaxed,
    paddingBottom: spacings.relaxed,
    borderRadius: spacings.roundBorderXl,
  },
  buttonVariant: {
    primary: {
      backgroundColor: colors.secondary,
      borderWidth: 1,
      borderColor: colors.secondary,
    },
    secondary: {
      backgroundColor: "transparent",
      borderWidth: 1,
      borderColor: colors.secondary,
    },
  },
};

export const tableStyles = {
  row: (pressed: boolean) => ({
    flexDirection: "row",
    backgroundColor: colors.background,
    borderBottomWidth: 1,
    borderBottomColor: setAlpha(colors.onBackground, 0.1),
    paddingTop: spacings.medium,
    paddingBottom: spacings.medium,
    paddingLeft: spacings.default,
    paddingRight: spacings.default,
    alignItems: "center",
    width: "100%",
    opacity: pressed ? 0.5 : 1,
  }),
};

export const PillStyle = {
  alignItems: "center",
  backgroundColor: colors.surface200,
  borderWidth: 0.8,
  borderColor: colors.surface200,
  borderRadius: 20,
  flexDirection: "row",
  justifyContent: "center",
  paddingLeft: spacings.regular,
  paddingRight: spacings.slim,
  paddingTop: spacings.small,
  paddingBottom: spacings.small,
};
