import { ReactNode } from "react";
import { Text, View } from "react-native";
import { appStyles, spacings } from "../../defaultStyles";
import { css } from "../../lib/glamor-native";

const headingStyles = [
  appStyles.heading.level0,
  appStyles.heading.level1,
  appStyles.heading.level2,
  appStyles.heading.level3,
];
export const Heading: React.FC<{
  title: string;
  level: number;
  style?: object;
  description?: string;
  children?: ReactNode;
}> = ({ title, level, style = {}, description, children }) => {
  let styles = {};
  if (level < 3) {
    styles = headingStyles[level];
  }
  return (
    <View
      style={css({
        backgroundColor: "transparent",
        flexDirection: "column",
        ...style,
      })}
    >
      <Text style={css({ ...styles, marginBottom: 0 })}>{title}</Text>
      {description && (
        <Text
          style={css({
            ...appStyles.text.smallBoldFaded,
            marginBottom: spacings.medium,
          })}
        >
          {description}
        </Text>
      )}

      {children && children}
    </View>
  );
};
