import { useNavigation } from "@react-navigation/native";
import { observer } from "mobx-react";
import moment from "moment";
import { ScrollView, Text, View } from "react-native";
import CalendarPicker from "react-native-calendar-picker";
import Button from "../components/common/Button";
import RadioButton from "../components/common/RadioButton";
import { appStyles, colors, setAlpha, spacings } from "../defaultStyles";
import { css } from "../lib/glamor-native";
import { useAppStore } from "../store/AppStore";

const Calendar: React.FC = observer(() => {
  const store = useAppStore();
  const navigation = useNavigation();
  const range = store.feed.range;
  const isRangeAll = range === "all";
  return (
    <View
      style={css({
        ...appStyles.viewWrapper,
        ...appStyles.container,
      })}
    >
      <ScrollView contentContainerStyle={css(appStyles.scrollview)}>
        <View style={css({ backgroundColor: "transparent" })}>
          <RadioButton
            label="Show all events in date range"
            selected={range === "inRange"}
            onPress={() => {
              if (range !== "inRange") {
                store.feed.setRange("inRange");
              }
            }}
          />

          {!isRangeAll && <CalendarFrom />}
          {!isRangeAll && (
            <View
              style={css({
                backgroundColor: "transparent",
                marginBottom: spacings.default,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginTop: spacings.default,
              })}
            >
              <Button
                label="Apply dates and filter"
                onPress={() => {
                  store.feed.setRange("inRange");
                  const groupId = store.feed.selectedGroupId;
                  if (groupId) {
                    store.feed.load(
                      groupId,
                      "inRange",
                      store.feed.from,
                      store.feed.to
                    );
                  }

                  navigation.goBack();
                }}
              />
            </View>
          )}

          {isRangeAll && (
            <View
              style={css({
                backgroundColor: "transparent",
                flexDirection: "row",
                marginTop: spacings.regular,
              })}
            >
              <Button
                label="Apply filter"
                onPress={() => {
                  const groupId = store.feed.selectedGroupId;
                  if (groupId) {
                    const group = store.groups.records[groupId];
                    if (group) {
                      group.setRange("all");
                      store.feed.setRange("all");
                      store.feed.load(groupId, "inRange");
                    }
                  }

                  navigation.goBack();
                }}
              />
            </View>
          )}
        </View>
      </ScrollView>
    </View>
  );
});

const CalendarFrom: React.FC = observer(() => {
  const store = useAppStore();
  const from = store.feed.from;
  const to = store.feed.to;
  return (
    <View
      style={css({
        ...appStyles.viewWrapper,
        ...appStyles.container,
        paddingBottom: 0,
      })}
    >
      <View
        style={css({
          backgroundColor: "transparent",
          flexDirection: "row",
          justifyContent: "space-between",
        })}
      >
        <DateWithLabel label="From" date={from} />
        <DateWithLabel label="To" date={to} />
      </View>
      <View
        style={css({
          backgroundColor: colors.surface200,
          borderRadius: spacings.roundBorderXl,
        })}
      >
        <Calendario />
      </View>
    </View>
  );
});

export const DateWithLabel: React.FC<{ label: string; date: number }> =
  observer(({ label, date }) => {
    return (
      <View
        style={css({
          backgroundColor: "transparent",
          flexDirection: "column",
          marginBottom: spacings.regular,
        })}
      >
        <Text
          style={css({
            ...appStyles.heading.level3,
            color: setAlpha(colors.onBackground, 0.5),
            fontWeight: "normal",
            marginBottom: 0,
          })}
        >
          {label}
        </Text>
        <Text
          style={css({
            ...appStyles.heading.level2,
            marginTop: 0,
            paddingTop: 0,
          })}
        >
          {moment(date).format("MMMM DD, YYYY")}
        </Text>
      </View>
    );
  });
const Calendario: React.FC = observer(() => {
  const store = useAppStore();
  const from = store.feed.from;

  return (
    <View style={css({ backgroundColor: "transparent" })}>
      <CalendarPicker
        scaleFactor={400}
        textStyle={{
          color: colors.onBackground,
        }}
        todayTextStyle={{ fontWeight: "bold", color: colors.BLACK }}
        allowRangeSelection={true}
        previousTitleStyle={{
          fontSize: 18,
          fontWeight: "bold",
          marginLeft: spacings.large,
        }}
        nextTitleStyle={{
          fontSize: 18,
          fontWeight: "bold",
          marginRight: spacings.large,
        }}
        initialDate={new Date(from)}
        onDateChange={(date, type) => {
          if (!date) {
            return;
          }
          const timestamp = new Date(date.toString()).getTime();
          if (type === "END_DATE") {
            // EDITING END DATE
            if (timestamp > store.feed.from) {
              // only update if end date is ahead of start date
              store.feed.setTo(timestamp);
            } else {
              // if end date is before start date, reset both to end date
              store.feed.setTo(timestamp);
              store.feed.setFrom(timestamp);
            }
          } else {
            // EDITING START DATE
            if (timestamp > store.feed.to) {
              // start date is ahead of end date, let's reset both
              store.feed.setFrom(timestamp);
              store.feed.setTo(timestamp);
            } else {
              // start date is before of end date, let's update start date only
              store.feed.setFrom(timestamp);
            }
          }
        }}
      />
    </View>
  );
});

export default Calendar;
