import { useNavigation } from "@react-navigation/native";
import { observer } from "mobx-react-lite";
import {
  View,
  StatusBar,
  ActivityIndicator,
  Image,
  Platform,
} from "react-native";
import { colors, spacings } from "../../defaultStyles";
import { css } from "../../lib/glamor-native";
import ImagePickerHandler from "../../store/common/ImagePicker";
import { MembershipOptionsType } from "../../store/UserProfile";
import Button from "./Button";
import Icon from "./Icon";
import { Image as CachedImage } from "react-native-expo-image-cache";

const PIXEL_1X1 =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNk+A8AAQUBAScY42YAAAAASUVORK5CYII=";
/**
 * @param autoUploadOnDone uploads straight to storage bucket after the file chosen from either camera or camera roll
 */
export const ImagePickerComponent: React.FC<{
  imageUrl?: string;
  imagePickerHandler: ImagePickerHandler;
  uploadOnDone?: boolean;
}> = observer(({ imagePickerHandler, uploadOnDone = false, imageUrl }) => {
  const navigation = useNavigation();

  function onDone() {
    navigation.canGoBack() && navigation.goBack();
  }
  return (
    <View
      style={css({
        backgroundColor: "transparent",
        marginBottom: spacings.default,
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      })}
    >
      <View
        style={{
          backgroundColor: "transparent",
        }}
      >
        <ProfileImage imageUrl={imageUrl} size={180} />
        <ImageUploadingOverlay imagePickerHandler={imagePickerHandler} />
        <View
          style={{
            backgroundColor: "transparent",
            flexDirection: "row",
            marginTop: spacings.slim,
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            bottom: 0,
            right: 0,
            opacity: 0.6,
          }}
        >
          <Button
            iconProps={{
              name: "camera",
            }}
            variant="primary"
            onPress={() => {
              navigation.navigate("Actions", {
                actions: [
                  {
                    variant: "tertiary",
                    label: "Upload from camera roll",
                    onPress: () => {
                      imagePickerHandler.pickImage(uploadOnDone, onDone);
                    },
                  },
                  {
                    variant: "tertiary",
                    label: "Take a photo",
                    onPress: () => {
                      imagePickerHandler.takePhoto(uploadOnDone, onDone);
                    },
                  },
                  {
                    variant: "tertiary",
                    label: "Remove photo",
                    onPress: () => {
                      imagePickerHandler.deletePhoto(uploadOnDone, onDone);
                    },
                  },
                ],
              });
            }}
            style={{ marginRight: spacings.slim }}
          />
        </View>

        <StatusBar barStyle="default" />
      </View>
    </View>
  );
});

export const ImageUploadingOverlay: React.FC<{
  imagePickerHandler: ImagePickerHandler;
}> = observer(({ imagePickerHandler }) => {
  if (imagePickerHandler.uploading) {
    return (
      <View
        style={css(
          // StyleSheet.absoluteFill,
          {
            backgroundColor: "rgba(0,0,0,0.4)",
            alignItems: "center",
            justifyContent: "center",
          }
        )}
      >
        <ActivityIndicator color={colors.onBackground} animating size="large" />
      </View>
    );
  }

  return null;
});

export const ProfileImage: React.FC<{
  imageUrl?: string;
  size?: number;
  color?: string;
  membershipType?: MembershipOptionsType;
}> = observer(
  ({
    imageUrl,
    size = 40,
    color = colors.onBackground,
    membershipType = "free",
  }) => {
    if (!imageUrl || imageUrl === "Guest") {
      return (
        <View style={css({ backgroundColor: "transparent" })}>
          <Icon name="user-circle" size={size} color={color} />
        </View>
      );
    }
    return (
      <View
        style={{
          backgroundColor: colors.onBackground,
          width: size + 3,
          height: size + 3,
          borderRadius: size + 3,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          overflow: "hidden",
        }}
      >
        <View
          style={{
            shadowColor: "rgba(0,0,0,1)",
            shadowOpacity: 0.2,
            shadowOffset: { width: 4, height: 4 },
            shadowRadius: 5,
            borderRadius: 40,
            overflow: "hidden",
          }}
        >
          <Image
            source={{ uri: imageUrl }}
            style={{ width: size, height: size, borderRadius: size }}
          />
        </View>
      </View>
    );
  }
);

export const CachedProfileImage: React.FC<{
  imageUrl?: string;
  size?: number;
  color?: string;
  membershipType?: MembershipOptionsType;
}> = observer(
  ({
    imageUrl,
    size = 40,
    color = colors.onBackground,
    membershipType = "free",
  }) => {
    const preview = {
      uri: PIXEL_1X1,
    };
    const isWeb = Platform.OS === "web";

    if (!imageUrl || imageUrl === "Guest") {
      return (
        <View style={css({ backgroundColor: "transparent" })}>
          <Icon name="user-circle" size={size} color={color} />
        </View>
      );
    }
    try {
      return (
        <View
          style={{
            backgroundColor: colors.onBackground,
            width: size + 3,
            height: size + 3,
            borderRadius: size + 3,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
          }}
        >
          <View
            style={{
              shadowColor: "rgba(0,0,0,1)",
              shadowOpacity: 0.2,
              shadowOffset: { width: 4, height: 4 },
              shadowRadius: 5,
              borderRadius: 40,
              overflow: "hidden",
            }}
          >
            {!isWeb && (
              <CachedImage
                preview={{
                  uri: preview.uri,
                }}
                uri={imageUrl}
                style={{ width: size, height: size, borderRadius: size }}
              />
            )}
            {isWeb && (
              <Image
                source={{ uri: imageUrl }}
                style={{ width: size, height: size, borderRadius: size }}
              />
            )}
          </View>
        </View>
      );
    } catch (error) {
      console.log({ error });
      return null;
    }
  }
);
