import { observer } from "mobx-react";
import React from "react";
import { ScrollView, ScrollViewProps } from "react-native";
import { fetcherScrollerAware } from "./FetcherScrollerAware";

export const ScrollProvider: React.FC<ScrollViewProps> = observer((props) => {
  return (
    <ScrollView
      {...props}
      scrollEventThrottle={100}
      onScroll={(e) => {
        const { contentOffset, layoutMeasurement } = e.nativeEvent;
        fetcherScrollerAware.setBottomPos(contentOffset.y);
        fetcherScrollerAware.setHeight(layoutMeasurement.height);
      }}
    >
      {props.children}
    </ScrollView>
  );
});

export default ScrollProvider;
