import { observer } from "mobx-react";
import moment from "moment";
import { View, Text } from "react-native";
import { colors, spacings } from "../../defaultStyles";
import { css } from "../../lib/glamor-native";
import Button from "./Button";

const containerStyle = {
  alignItems: "center",
  backgroundColor: colors.surface,
  borderRadius: spacings.roundBorderXl,
  flex: 0.3,
  flexDirection: "row",
  justifyContent: "center",
  margin: spacings.slim,
  padding: spacings.regular,
};

const arrowButtonStyle = {
  alignItems: "center",
  backgroundColor: "transparent",
  flexDirection: "column",
  justifyContent: "center",
};

const textStyle = {
  color: colors.onBackground,
  fontSize: 30,
  fontWeight: "bold",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
};

const buttonStyle = {
  paddingBottom: 0,
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 0,
};
const buttonVariant = "tertiary";

const iconSize = 30;
export const DayTimePicker: React.FC<{
  initialDate: string;
  onChange: (updatedDateTime: number) => void;
}> = observer(({ onChange, initialDate }) => {
  return (
    <View
      style={css({
        backgroundColor: "transparent",
        flexDirection: "row",
        alignItems: "center",
      })}
    >
      <View style={css(containerStyle)}>
        <Text style={css({ ...textStyle, flex: 1 })}>
          {moment(initialDate).format("hh")}
        </Text>
        <HourControls value={initialDate} onValueChange={onChange} />
      </View>
      <View
        style={css({
          ...containerStyle,
          flex: 0.1,
          margin: 0,
          padding: 0,
          backgroundColor: "transparent",
        })}
      >
        <Text style={css({ ...textStyle, marginRight: 0 })}>:</Text>
      </View>
      <View style={css(containerStyle)}>
        <Text style={css({ ...textStyle, flex: 1 })}>
          {moment(initialDate).format("mm")}
        </Text>
        <MinuteControls value={initialDate} onValueChange={onChange} />
      </View>

      <View style={css(containerStyle)}>
        <Text style={css({ ...textStyle, flex: 1 })}>
          {moment(initialDate).format("A")}
        </Text>
        <AmPmControls value={initialDate} onValueChange={onChange} />
      </View>
    </View>
  );
});

const HourControls: React.FC<{
  value: string;
  onValueChange: (newValue: number) => void;
}> = observer(({ value, onValueChange }) => {
  const hour = parseInt(moment(value).format("hh"));
  const minute = parseInt(moment(value).format("mm"));
  const amPm = moment(value).format("A");
  return (
    <View style={css({ backgroundColor: "transparent" })}>
      <View style={css(arrowButtonStyle)}>
        <Button
          style={buttonStyle}
          variant={buttonVariant}
          iconProps={{
            name: "chevron-circle-up",
            size: iconSize,
            color: colors.secondary,
          }}
          onPress={() => {
            if (hour <= 12 && hour >= 0) {
              let h = hour + 1;
              if (h === 13) {
                h = 1;
              }
              const date = new Date(value);
              const updatedHourString = `${moment(date).format(
                "dd DD-MMM-yyyy"
              )}, ${h}:${minute} ${amPm}`;
              onValueChange(new Date(updatedHourString).getTime());
            } else {
              alert(`Invalid Hour: ${hour}`);
            }
          }}
        />
        <Button
          style={buttonStyle}
          variant={buttonVariant}
          iconProps={{
            name: "chevron-circle-down",
            size: iconSize,
            color: colors.secondary,
          }}
          onPress={() => {
            if (hour <= 12 && hour >= 0) {
              let h = hour - 1;
              if (h === -1) {
                h = 12;
              }
              console.log({ h, hour });
              const date = new Date(value);
              const updatedHourString = `${moment(date).format(
                "dd DD-MMM-yyyy"
              )}, ${h}:${minute} ${amPm}`;
              onValueChange(new Date(updatedHourString).getTime());
            }
          }}
        />
      </View>
    </View>
  );
});

const MinuteControls: React.FC<{
  value: string;
  onValueChange: (newValue: number) => void;
}> = observer(({ value, onValueChange }) => {
  const hour = parseInt(moment(value).format("hh"));
  const minute = parseInt(moment(value).format("mm"));
  const amPm = moment(value).format("A");
  return (
    <View style={css({ backgroundColor: "transparent" })}>
      <View style={css(arrowButtonStyle)}>
        <Button
          style={buttonStyle}
          variant={buttonVariant}
          iconProps={{
            name: "chevron-circle-up",
            size: iconSize,
            color: colors.secondary,
          }}
          onLongPress={() => {
            if (minute <= 60 || minute > 0) {
              let m = minute + 10;
              if (m >= 60) {
                m = 59;
              }
              const date = new Date(value);
              const updatedHourString = `${moment(date).format(
                "dd DD-MMM-yyyy"
              )}, ${hour}:${m} ${amPm}`;
              onValueChange(new Date(updatedHourString).getTime());
            }
          }}
          onPress={() => {
            if (minute <= 60 || minute >= 0) {
              let m = minute + 1;
              if (m >= 60) {
                m = 59;
              }
              const date = new Date(value);
              const updatedHourString = `${moment(date).format(
                "dd DD-MMM-yyyy"
              )}, ${hour}:${m} ${amPm}`;
              onValueChange(new Date(updatedHourString).getTime());
            }
          }}
        />
        <Button
          style={buttonStyle}
          variant={buttonVariant}
          iconProps={{
            name: "chevron-circle-down",
            size: iconSize,
            color: colors.secondary,
          }}
          onLongPress={() => {
            if (minute < 60 && minute > 0) {
              let m = minute - 10;
              if (m <= 0) {
                m = 0;
              }
              const date = new Date(value);
              const updatedHourString = `${moment(date).format(
                "dd DD-MMM-yyyy"
              )}, ${hour}:${m} ${amPm}`;
              onValueChange(new Date(updatedHourString).getTime());
            }
          }}
          onPress={() => {
            if (minute < 60 && minute > 0) {
              let m = minute - 1;
              if (m <= 0) {
                m = 0;
              }
              const date = new Date(value);
              const updatedHourString = `${moment(date).format(
                "dd DD-MMM-yyyy"
              )}, ${hour}:${m} ${amPm}`;
              onValueChange(new Date(updatedHourString).getTime());
            }
          }}
        />
      </View>
    </View>
  );
});

const AmPmControls: React.FC<{
  value: string;
  onValueChange: (newValue: number) => void;
}> = observer(({ value, onValueChange }) => {
  const hour = parseInt(moment(value).format("hh"));
  const minute = parseInt(moment(value).format("mm"));
  const amPm = moment(value).format("A");
  return (
    <View style={css(arrowButtonStyle)}>
      <Button
        style={buttonStyle}
        variant={buttonVariant}
        iconProps={{
          name: "chevron-circle-up",
          size: iconSize,
          color: colors.secondary,
        }}
        onPress={() => {
          const AMPM = amPm === "AM" ? "PM" : "AM";
          const date = new Date(value);
          const updatedHourString = `${moment(date).format(
            "dd DD-MMM-yyyy"
          )}, ${hour}:${minute} ${AMPM}`;
          onValueChange(new Date(updatedHourString).getTime());
        }}
      />
      <Button
        style={buttonStyle}
        variant={buttonVariant}
        iconProps={{
          name: "chevron-circle-down",
          size: iconSize,
          color: colors.secondary,
        }}
        onPress={() => {
          const AMPM = amPm === "AM" ? "PM" : "AM";
          const date = new Date(value);
          const updatedHourString = `${moment(date).format(
            "dd DD-MMM-yyyy"
          )}, ${hour}:${minute} ${AMPM}`;
          onValueChange(new Date(updatedHourString).getTime());
        }}
      />
    </View>
  );
});
