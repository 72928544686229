import { TemplateTaskType } from "../templates";

export const expenses: TemplateTaskType[] = [
  {
    id: "paid-credit-card",
    score: 0,
    allowScoreOverwrite: true,
    title: "Credit card",
    description: "You paid a credit card bill",
  },
  {
    id: "paid-a-bill",
    score: 0,
    allowScoreOverwrite: true,
    title: "Bill ",
    description: "You paid a bill",
  },
  {
    id: "paid-taxes",
    score: 0,
    allowScoreOverwrite: true,
    title: "Taxes",
    description: "You paid taxes",
  },
  {
    id: "paid-mortgage",
    score: 0,
    allowScoreOverwrite: true,
    title: "Mortgage",
    description: "You paid the mortgage",
  },
  {
    id: "paid-loan",
    score: 0,
    allowScoreOverwrite: true,
    title: "Loan",
    description: "You paid a loan",
  },
  {
    id: "grocery-shopping",
    score: 0,
    allowScoreOverwrite: true,
    title: "Groceries",
    description: "You paid for groceries",
  },
  {
    id: "utilities",
    score: 0,
    allowScoreOverwrite: true,
    title: "Utilities",
    description: "You paid for utilities",
  },
  {
    id: "insurance",
    score: 0,
    allowScoreOverwrite: true,
    title: "Insurance",
    description: "You paid for isurance",
  },
  {
    id: "gas",
    score: 0,
    allowScoreOverwrite: true,
    title: "Gas",
    description: "You paid for gas",
  },
  {
    id: "electricity",
    score: 0,
    allowScoreOverwrite: true,
    title: "Electricity",
    description: "You paid for electricity",
  },
];
