import { makeAutoObservable } from "mobx";
import { TemplateTaskType } from "./data/templates";

export default class TemplatePicker {
  selectedTasks: { [taskId: string]: boolean };

  constructor(templateTasks: TemplateTaskType[]) {
    this.selectedTasks = {};
    this.setSelectedTasksFromTemplate(templateTasks);

    makeAutoObservable(this);
  }

  setSelectedTasksFromTemplate = (tasks: TemplateTaskType[]) => {
    tasks.forEach((task) => {
      this.selectedTasks[task.id] = true;
    });
  };

  onTaskPress = (task: TemplateTaskType) => {
    const currentValue = this.selectedTasks[task.id];
    this.selectedTasks[task.id] = !currentValue;
  };

  clearExceptions = () => {};

  reset = () => {};
}
