import { makeAutoObservable } from "mobx";

class Query {
  count: number;
  query: string;
  constructor(query: string) {
    this.query = query;
    this.count = 0;
    makeAutoObservable(this);
  }
  add = () => {
    this.count = this.count + 1;
  };
}
export class QueryCounter {
  total: number;
  queries: { [query: string]: Query };
  updates: { [collection: string]: Query };
  sets: { [collection: string]: Query };
  deletes: { [collection: string]: Query };
  fetches: { [collection: string]: Query };
  constructor() {
    this.total = 0;
    this.queries = {};
    this.updates = {};
    this.sets = {};
    this.deletes = {};
    this.fetches = {};
    makeAutoObservable(this);
  }

  get tooManyCalls() {
    if (this.total === 200) {
      console.log(
        "High query count, but it will query anyway",
        queryCounter.total
      );
      return true;
    }
    return false;
  }
  addUpdate = (collection: string) => {
    console.log(`[FIREBASE]: [UPDATE] : ${collection}`);
    if (this.updates[collection]) {
      this.updates[collection].add();
    } else {
      const q = new Query(collection);
      q.add();
      this.updates[collection] = q;
    }
    this.add();
  };

  addFetch = (collection: string) => {
    console.log(`[FIREBASE]: [FETCH] : ${collection}`);
    if (this.fetches[collection]) {
      this.fetches[collection].add();
    } else {
      const q = new Query(collection);
      q.add();
      this.fetches[collection] = q;
    }
    this.add();
  };

  addSet = (collection: string) => {
    console.log(`[FIREBASE]: [SET] : ${collection}`);
    if (this.sets[collection]) {
      this.sets[collection].add();
    } else {
      const q = new Query(collection);
      q.add();
      this.sets[collection] = q;
    }
    this.add();
  };

  addDelete = (collection: string) => {
    console.log(`[FIREBASE]: [SET] : ${collection}`);
    if (this.deletes[collection]) {
      this.deletes[collection].add();
    } else {
      const q = new Query(collection);
      q.add();
      this.deletes[collection] = q;
    }
    this.add();
  };

  addCall = (query: string) => {
    console.log(`[FIREBASE]: [CALL] : ${query}`);
    if (this.queries[query]) {
      this.queries[query].add();
    } else {
      const q = new Query(query);
      q.add();
      this.queries[query] = q;
    }
    this.add();
  };

  add = () => {
    this.total = this.total + 1;
  };
}

export const queryCounter = new QueryCounter();
