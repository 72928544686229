import { useNavigation, useRoute } from "@react-navigation/native";
import { observer } from "mobx-react";
import { Pressable, ScrollView, Text } from "react-native";
import { View } from "../components/Themed";
import { appStyles, colors, spacings, tableStyles } from "../defaultStyles";
import { css } from "../lib/glamor-native";
import { useAppStore } from "../store/AppStore";
import { FormOptions } from "../store/common/form/FormStore";
import Task from "../store/Task";
import CompletedTask from "../store/CompletedTask";
import { Form } from "../store/common/form/Form";
import { Heading } from "../components/common/Heading";
import { hasSearchTerm } from "../store/utils/formatters";
import { AntDesign } from "@expo/vector-icons";
import { Entypo } from "@expo/vector-icons";
import SearchBox from "../components/common/SearchBox";
import { ButtonCreateTask } from "../components/Buttons";

const AssignCompletedTaskToTask: React.FC = observer(() => {
  const store = useAppStore();
  const route = useRoute();

  //@ts-ignore
  const completedTask: CompletedTask = route.params?.completedTask;
  if (!completedTask) {
    return null;
  }

  const form = store.forms.getForm(FormOptions.reassignCompletedTask);
  if (!form) {
    return null;
  }

  const groupId = form.options.groupId;
  if (!groupId) {
    return null;
  }
  return (
    <View
      style={css({
        ...appStyles.viewWrapper,
        ...appStyles.container,
      })}
    >
      <SearchBox search={store.search} placeholder="Search tasks" />
      <ButtonCreateTask groupId={groupId} />
      <ScrollView contentContainerStyle={css(appStyles.scrollview)}>
        <TaskSelectorView form={form} />
      </ScrollView>
    </View>
  );
});

export default AssignCompletedTaskToTask;

const TaskSelectorView: React.FC<{ form: Form }> = observer(({ form }) => {
  const store = useAppStore();
  const groupId = form.options.groupId;

  if (!groupId) {
    return null;
  }

  const tasks = store.tasks.values;

  return (
    <View style={css({ ...appStyles.container })}>
      <Heading level={2} title="Tasks" />
      <Tasks tasks={tasks} form={form} />
    </View>
  );
});

const Tasks: React.FC<{ tasks: Task[]; form: Form }> = observer(
  ({ tasks, form }) => {
    return (
      <View
        style={css({
          ...appStyles.card,
          flexDirection: "column",
          paddingLeft: 0,
          paddingRight: 0,
        })}
      >
        {tasks?.map((task) => {
          return <TaskRow key={task.taskId} task={task} form={form} />;
        })}
      </View>
    );
  }
);

const TaskRow: React.FC<{
  task: Task;
  form: Form;
}> = observer(({ task, form }) => {
  const store = useAppStore();
  const navigation = useNavigation();
  const route = useRoute();
  //@ts-ignore
  const others: string[] = route.params?.others;
  const isSearching = store.search.hasSearchTerm;
  const isSelected = form.fields.taskId.value === task.taskId;
  if (isSearching) {
    const searchTerm = store.search.term;
    if (
      !hasSearchTerm(searchTerm, task.title) &&
      !hasSearchTerm(searchTerm, task.description)
    ) {
      return null;
    }
  }

  return (
    <Pressable
      style={({ pressed }) =>
        css({
          ...tableStyles.row(pressed),
        })
      }
      onPress={async () => {
        form.fields.taskId.set(task.taskId);
        form.fields.description.set(task.description);
        form.fields.groupId.set(task.groupId);
        form.fields.title.set(task.title);

        const data: any = form.getData;

        await store.reassignCompletedTask(data);
        if (others && others.length > 0) {
          store.bulkReassignTasks(data, others);
        }
        navigation &&
          navigation.reset({
            index: 1,
            routes: [
              { name: "Root" },
              { name: "GroupSummary", params: { groupId: task.groupId } },
            ],
          });
      }}
    >
      <View
        style={css({
          backgroundColor: "transparent",
          alignItems: "flex-start",
          flexDirection: "row",
        })}
      >
        {!isSelected && (
          <Entypo name="circle" size={24} color={colors.onBackground} />
        )}

        {isSelected && (
          <AntDesign name="checkcircle" size={24} color={colors.onBackground} />
        )}
        <TaskName task={task} />
      </View>
    </Pressable>
  );
});

const TaskName: React.FC<{ task: Task }> = ({ task }) => {
  return (
    <View
      style={css({
        backgroundColor: "transparent",
        flexDirection: "column",
        marginLeft: spacings.regular,
        flex: 1,
      })}
    >
      <Text style={css(appStyles.text.bodyBold)}>{task.title}</Text>
      <Text style={css(appStyles.text.smallFaded)}>{task.description}</Text>
    </View>
  );
};
