import { initializeApp } from "firebase/app";
import { autorun, makeAutoObservable } from "mobx";
import React from "react";
import { FormOptions, FormStore } from "./common/form/FormStore";
import { firebaseConfig } from "./firebaseConfig";
import Group, { GroupType } from "./Group";
import Task, { TaskType } from "./Task";
import User from "./User";
import Groups from "./Groups";
import { v4 as uuid } from "uuid";
import Ui from "./Ui";
import { Firestore } from "./Firestore";
import Apis from "./Api";
import { permissions } from "./auth/Permissions";
import Feedback from "./Feedback";
import Search from "./utils/Search";
import Feed from "./Feed";
import CompletedTask, { CompletedTaskType } from "./CompletedTask";
import UserProfile, { UserProfileType } from "./UserProfile";
import { DocumentData, onSnapshot, Query, updateDoc } from "firebase/firestore";
import {
  getCurrentUTCTimeStamp,
  isGroupTrackedByCreationDate,
  isUserInGroup,
  revertProfileEmailMappingToDisplayName,
} from "../utils";
import { StartEndTimeRangeType, Time } from "./common/Time";
import Settings from "./Settings";
import moment from "moment";
import Linker, { CREATED_BY_SHARED_LINKED } from "./common/Linker";
import { strings } from "./common/i18n/strings";
import { templatesById, templateTasks } from "./data/templates";
import TemplatePicker from "./TemplatePicker";
import AppNotifications from "./common/AppNotifications";
import { labelForReasonId } from "./data/reportReasons";
import { Unsubscribe } from "firebase/auth";
import TaskList from "./TasksList";

export enum RedirectionOptionType {
  GroupInvitation = "GroupInvitation",
}
export const MIN_PASSWORD_LENGTH = 6;
export interface UserRankingType {
  user: string;
  count: number;
  points: number;
}
export interface OptionSelector {
  value: string;
  label: string;
}

export type RanksByGroupType = {
  [id: string]: UserRankingType[];
};

export interface LoggedTaskUpdatesType {
  createdAt?: number;
  endedAt?: number;
  hashtags: string[];
  notes: string;
  score: number;
  tags: string[];
}

export interface PendingGroupInvitationType {
  description: string;
  groupCreatedBy: string;
  groupCreatedOn: number;
  groupId: string;
  groupName: string;
  invitedBy: string;
  invitedOn: number;
}

export type UserProfilesType = { [email: string]: UserProfile | undefined };
export type AuthType = "Facebook" | "Google" | "Email";

export default class AppStore {
  api: Firestore;
  appNotifications: AppNotifications;
  feed: Feed;
  feedback: Feedback;
  forms: FormStore;
  groups: Groups;
  linker: Linker;
  pendingGroupInvitations: PendingGroupInvitationType[];
  profilesUpdatedAt: number;
  search: Search;
  settings: Settings;
  subscriptions: { [subscriptionId: string]: Unsubscribe };
  tasks: TaskList;
  templatePicker: TemplatePicker;
  ui: Ui;
  user: User;
  userProfiles: UserProfilesType;

  constructor() {
    // Initialize User / Firebase and App
    initializeApp(firebaseConfig);
    this.user = new User(this.onLogout);
    this.api = new Firestore(this.user);

    // Initialize UI helpers
    this.appNotifications = new AppNotifications();
    this.feedback = new Feedback();
    this.forms = new FormStore();
    this.linker = new Linker();
    this.pendingGroupInvitations = [];
    this.profilesUpdatedAt = 0;
    this.search = new Search();
    this.settings = new Settings();
    this.subscriptions = {};
    this.templatePicker = new TemplatePicker(templateTasks);
    this.userProfiles = {};
    this.ui = new Ui();

    // Initialize Main Data Models
    this.groups = new Groups(
      this.api,
      this.userProfiles,
      this.onAddedMembers.bind(this)
    );
    this.tasks = new TaskList(this.api);
    this.feed = new Feed(this.api, this.tasks);

    // load global notifications
    this.appNotifications.loadGlobalNotificationSinceLastPublished(
      this.api,
      this.settings.versionPublishedOn
    );

    // Initialize completed tasks form
    this.initCompletedTaskForm();

    // Give the singleton Permissions, access to the store
    permissions.setStore(this);
    // listen for when user has access and it is logged in
    autorun(async () => {
      if (this.user.hasAccess) {
        // user is logged in, initialize app and start loading data
        this.init();
      }
    });
    makeAutoObservable(this);
  }

  get recentGroups(): Group[] {
    const groups: Group[] = [];
    const feedItems = this.feed.items.values;

    feedItems?.forEach((completedTask: CompletedTask) => {
      const groupId = completedTask.groupId;
      if (this.groups.records[groupId]) {
        if (!this.groups.favorites[groupId]) {
          groups.push(this.groups.records[groupId]);
        }
      }
    });
    return [...new Set(groups)];
  }

  get userRankingByCompletedTasksByGroup(): RanksByGroupType {
    let users: { [id: string]: UserRankingType } = {};
    const groups = this.groups.ids;
    const ranksByGroup: RanksByGroupType = {};
    // let's initialize the rankings by group
    groups?.forEach((groupId) => {
      ranksByGroup[groupId] = [];
    });

    groups?.forEach((groupId) => {
      users = {};
      const group = this.groups.records[groupId];
      if (group && group.members) {
        group.members.forEach((member) => {
          users[member] = {
            user: member,
            count: 0,
            points: 0,
          };
        });
      }

      this.feed.items.filteredValues.forEach((completedTask) => {
        if (completedTask.groupId === groupId) {
          const email = completedTask.createdBy || "unknown";

          const task = this.tasks.items[completedTask.taskId];
          if (task) {
            let score = task?.score;
            if (task?.allowScoreOverwrite) {
              score = completedTask?.score || task.score;
            }
            if (!score) {
              score = completedTask?.score || 0;
            }

            if (users[email]) {
              const user = users[email];
              const count = user.count;
              const points = user.points;
              users[email] = {
                user: email,
                count: count + 1,
                points: points + score,
              };
            } else {
              users[email] = {
                user: email,
                count: 1,
                points: completedTask.score,
              };
            }
          }
        }
      });

      const ranked = Object.keys(users)
        .map((key) => ({
          user: users[key].user,
          count: users[key].count,
          points: users[key].points,
        }))
        .sort((a, b) => {
          if (a.points < b.points) {
            return 1;
          }
          if (a.points > b.points) {
            return -1;
          }
          return 0;
        });

      ranksByGroup[groupId] = ranked;
    });

    return ranksByGroup;
  }

  setUserProfile = (profile: UserProfileType) => {
    if (profile.email) {
      this.userProfiles[profile.email] = new UserProfile(profile);
    }
  };

  reset = () => {
    // Initialize User / Firebase and App
    initializeApp(firebaseConfig);
    this.user = new User(this.onLogout);
    this.api = new Firestore(this.user);

    // Initialize UI helpers
    this.feedback = new Feedback();
    this.forms = new FormStore();
    this.linker = new Linker();
    this.pendingGroupInvitations = [];
    this.settings = new Settings();
    this.ui = new Ui();
    this.userProfiles = {};
    this.search = new Search();

    // Initialize Main Data Models
    this.groups = new Groups(
      this.api,
      this.userProfiles,
      this.onAddedMembers.bind(this)
    );
    this.tasks = new TaskList(this.api);
    this.feed = new Feed(this.api, this.tasks);

    // Give the singleton Permissions, access to the store
    permissions.setStore(this);
  };

  init = async () => {
    const currentUserEmail = this.user.email;
    if (currentUserEmail) {
      try {
        this.feed.setCurrentUser(currentUserEmail);
        await this.groups.loadUserGroupIds(currentUserEmail);
        await this.feed.loadRecentsByYou(currentUserEmail, 30);
        await this.groups.loadFavorites(currentUserEmail);
        this.loadFeedForDate("comingUp");
        this.loadPendingGroupInvitations(currentUserEmail);
      } catch (err) {
        console.log("Fail on init", err);
      }
    }
  };

  refresh = () => {
    // this.init(true);
  };

  onLogout = () => {
    // unsbscribe from all queries
    this.unsubscribe();

    // group id subscriptions
    this.groups.unsubscribe();
    this.feed.items.subscriptions.unsubscribe();

    // groups subscriptions
    Object.keys(this.groups.records).forEach((groupId) => {
      const group = this.groups.records[groupId];
      group.subscriptions.unsubscribe();
    });
  };

  unsubscribe = () => {
    // groups subscriptions
    Object.keys(this.subscriptions).forEach((key) => {
      const unsubscribe = this.subscriptions[key];
      unsubscribe();
    });
  };

  loadTasksForRecents = () => {
    const recentsByYou = this.feed.items.values;
    recentsByYou.forEach((completedTask: CompletedTask) => {
      this.tasks.loadTasksForGroup(completedTask.groupId);
    });
  };

  loadAllTasksForAllGroups = () => {
    const groupIds = this.groups.ids;
    this.tasks.loadTasksForGroups(groupIds);
  };

  loadTasksForLoadedCompletedTasks = () => {
    const groupIds: string[] = [];
    this.feed.items.filteredValues.forEach((completedTask) => {
      groupIds.push(completedTask.groupId);
    });
    const ids = [...new Set(groupIds)];
    ids.forEach((gId) => {
      this.tasks.loadTasksForGroup(gId);
    });
  };

  loadPendingGroupInvitations = (currentUserEmail: string) => {
    const pendingInvitationsQuery = this.api.fetchDocsQuery(
      `users/${currentUserEmail}/pendingGroups`
    );
    if (pendingInvitationsQuery) {
      this.listenToPendingGroupsInvitations(pendingInvitationsQuery);
    }
  };

  listenToPendingGroupsInvitations = (
    pendingInvitationsQuery: Query<DocumentData>
  ) => {
    if (pendingInvitationsQuery) {
      const unsubscribe = onSnapshot(
        pendingInvitationsQuery,
        (querySnapshot) => {
          const invitations: PendingGroupInvitationType[] = [];
          querySnapshot?.forEach((doc: any) => {
            const d = doc.data();
            const invite = {
              groupId: d.groupId,
              invitedBy: d.createdBy,
              invitedOn: d.updatedAt,
              groupName: d.groupName,
              description: d.description || "",
              groupCreatedOn: d.groupCreatedOn,
              groupCreatedBy: d.groupCreatedBy,
            };

            invitations.push(invite);
          });

          // this.appNotifications.showNotification(
          //   strings.notifications.pendingGroupInvitation,
          //   "You have a new group invitation"
          // );
          this.setPendingGroupInvitations(invitations);
        }
      );

      this.setSubscription("pendingGroupInvigations", unsubscribe);
    }
  };

  setSubscription = (id: string, subpscription: Unsubscribe) => {
    this.subscriptions[id] = subpscription;
  };

  clearPendingGroupInvitations = () => {
    this.setPendingGroupInvitations([]);
  };

  setPendingGroupInvitations = (
    pendingGroups: PendingGroupInvitationType[]
  ) => {
    this.pendingGroupInvitations = pendingGroups;
  };

  initReportForm = (
    type: "group" | "user" | "post",
    userId?: string,
    groupId?: string,
    completedTaskId?: string
  ) => {
    let required = ["notes", "reason", "type"];

    if (type === "group") {
      required.push("groupId");
    } else if (type === "user") {
      required.push("userId");
    } else if (type === "post") {
      required.push("completedTaksId");
    }

    this.forms.init({
      id: FormOptions.report,
      fields: {
        userId,
        groupId,
        completedTaskId,
        notes: "",
        reason: undefined,
        reasonDetails: undefined,
        type,
      },
      options: {},
      title: `Report ${type}`,
      validations: {},
      required,
    });
  };

  initUpdateTasksForm = (
    groupId?: string,
    task?: Task,
    onUpdated?: () => void
  ) => {
    let groupName = undefined;
    let groupType = undefined;
    let autoGroupId = groupId;
    if (!groupId) {
      if (this.groups.length === 1) {
        const firstGroupIdKey = Object.keys(this.groups.records)[0];
        autoGroupId = this.groups.records[firstGroupIdKey].groupId;
        groupName = this.groups.records[firstGroupIdKey].title;
        groupType = this.groups.records[firstGroupIdKey].type;
      }
    } else {
      groupName = this.groups.records[groupId].title;
      groupType = this.groups.records[groupId].type;
    }

    let score = task?.score || 0;
    if (groupType === "timestamp") {
      score = 1;
    }

    // if there's only one group and no task has been
    // selected, let's prefill the groupId by default

    const time = new Time(score);
    console.log("Initialized form", {
      groupId,
      task,
      onUpdated,
      groupName,
      groupType,
      score,
      timeScore: time.seconds,
    });
    this.forms.init({
      id: FormOptions.tasks,
      fields: {
        taskId: task?.taskId || "",
        title: task?.title || "",
        description: task?.description || "",
        score: task?.score || time.seconds,
        groupId: autoGroupId,
        groupName,
        notes: task?.notes || "",
        allowScoreOverwrite: task?.allowScoreOverwrite || false,
      },
      options: {
        editMode: task ? true : false,
        onUpdated,
        hasGroupSelected: groupId !== undefined ? true : false,
        time,
      },
      title: "Add new Item",
      validations: {},
      required:
        groupType && isGroupTrackedByCreationDate(groupType)
          ? ["title", "groupId"]
          : ["title", "score", "groupId"],
    });
  };

  initUpdateProfileForm = () => {
    const currentUserId = this.user.email;
    if (!currentUserId) {
      return null;
    }
    const displayName =
      this.user.displayName ||
      this.userProfiles[currentUserId]?.displayName ||
      currentUserId;
    this.forms.init({
      id: FormOptions.profile,
      fields: {
        displayName,
      },
      options: {},
      title: "Update User Profile",
      validations: {},
      required: ["displayName"],
    });
  };

  initReasignCompletedTaskForm = (completedTask: CompletedTask) => {
    this.forms.init({
      id: FormOptions.reassignCompletedTask,
      fields: {
        id: completedTask.id,
        taskId: undefined,
        title: undefined,
        description: undefined,
        score: completedTask.score,
        groupId: undefined,
        notes: completedTask?.notes || "",
        createdAt:
          completedTask?.createdAt ||
          completedTask?.updatedAt ||
          getCurrentUTCTimeStamp(),
      },
      options: {
        groupId: completedTask.groupId,
      },
      title: "Assign Completed Task",
      validations: {},
      required: ["title", "score", "group", "groupId", "taskId", "id"],
    });
  };

  initCompletedTaskForm = (
    groupId?: string,
    task?: Task,
    completedTask?: CompletedTask
  ) => {
    // reset loading in case it was stuck loading before
    this.ui.setLoading(false);

    let normalizedTask = task;
    if (completedTask && !task) {
      normalizedTask = this.tasks.items[completedTask.taskId];
    }

    let score = completedTask?.score || normalizedTask?.score || 0;
    const time = new Time(score);
    const group = groupId && this.groups.records[groupId];
    if (group) {
      if (group.type === "timestamp") {
        score = time.seconds;
      }
    }
    console.log("Initialized completed task form", {
      groupId,
      task,
      score,
      timeScore: isNaN(time.seconds) ? time.seconds : 0,
    });
    const currentDateStamp = getCurrentUTCTimeStamp();
    const editor = this.ui.taggableInputEditor;
    let notes = completedTask?.notes || "";
    if (notes) {
      notes = revertProfileEmailMappingToDisplayName(notes, this.userProfiles);
    }
    // initialize editor with notes
    editor.setText(notes);
    const tags = completedTask?.tags.tags;
    if (tags) {
      editor.setProfileMapping(completedTask?.tags.tags);
    } else {
      // clear profile mapping
      editor.setProfileMapping([]);
    }

    this.forms.init({
      id: FormOptions.completeTask,
      fields: {
        taskId: completedTask?.taskId || "",
        title: normalizedTask?.title || "",
        description: normalizedTask?.description || "",
        score: score,
        groupId: completedTask?.groupId || groupId,
        notes,
        createdAt:
          completedTask?.createdAt ||
          completedTask?.updatedAt ||
          currentDateStamp,
        endedAt: completedTask?.endedAt,
        assignTo: undefined,
        inviteTaggedNonMembersToJoinGroup: false,
        nonMembers: undefined,
      },
      options: {
        time,
      },
      title: "Log Item",
      validations: {},
      required: ["title", "score", "group", "groupId"],
    });
  };

  initSignUpForm = () => {
    this.forms.init({
      id: FormOptions.signUp,
      fields: {
        email: "",
        password: "",
        passwordConfirmation: "",
        agreeWithTerms: false,
      },
      options: {},
      title: "Create an account",
      validations: {
        email: (email: string) => {
          if (email !== undefined) {
            return isValidEmail(email);
          }
          return true;
        },
        //@ts-ignore
        agreeWithTerms: (agreeWithTerms: boolean) => {
          return !agreeWithTerms;
        },
        passwordConfirmation: (passwordConfirmation: string) => {
          const password = this.forms.getForm(FormOptions.signUp).getData
            .password;
          if (
            password === passwordConfirmation &&
            password.split("").length > MIN_PASSWORD_LENGTH
          ) {
            return false;
          }
          return true;
        },
      },
      required: ["email", "password", "passwordConfirmation", "agreeWithTerms"],
    });
  };

  initLoginForm = () => {
    this.forms.init({
      id: FormOptions.login,
      fields: {
        email: "",
        password: "",
      },
      options: {},
      title: "Log In",
      validations: {
        email: (email: string) => {
          if (email !== undefined) {
            return isValidEmail(email);
          }
          return true;
        },
        password: (password: string) => {
          if (password.length > 3) {
            return false;
          }
          return true;
        },
      },
      required: ["email", "password"],
    });
  };

  initEditGroupForm = (group?: Group) => {
    const members = group?.members;
    let uniqueMembers = members || [];
    if (members) {
      uniqueMembers = [...new Set(members)];
    }
    // if initializing form, we add the creator of the form
    // as the default member
    const currentUserEmail = this.user.email;
    if (!currentUserEmail) {
      console.log("Error when initializing edit group form, no user found");
      return null;
    }
    if (!(uniqueMembers.indexOf(currentUserEmail) >= 0)) {
      uniqueMembers = [...uniqueMembers, currentUserEmail];
    }
    console.log("initialized group with ", group);
    this.forms.init({
      id: FormOptions.editGroup,
      fields: {
        groupId: group?.groupId || uuid(),
        title: group?.title || "",
        description: group?.description || "",
        members: uniqueMembers,
        type: group?.type,
        createdBy: group?.createdBy || currentUserEmail,
        isPublic: group?.public || false,
        allowJoinWithLink: group?.allowJoinWithLink || true,
        admins: group?.admins || [currentUserEmail],
        unit: group?.unit || "",
      },
      options: {
        editMode: group ? true : false,
      },
      title: "Add new group",
      validations: {},
      required: ["title", "type"],
    });
  };

  initAddMemberForm = (
    groupId: string,
    showUpdateGroupFormOnSubmit: boolean
  ) => {
    this.forms.init({
      id: FormOptions.addMember,
      fields: {
        groupId,
        email: "",
      },
      options: {
        showUpdateGroupFormOnSubmit,
      },
      title: "Add new member",
      validations: {
        email: (email: string) => {
          if (email !== undefined) {
            return isValidEmail(email);
          }
          return true;
        },
      },
      required: ["email", "groupId"],
    });
  };

  logCompletedTask = async (
    id: string,
    task: CompletedTask,
    inviteNonMembers?: string[]
  ) => {
    try {
      const groupId = task.groupId;
      const updates: any = {
        createdAt: task.createdAt,
        groupId,
        hashtags: task.hashtags.tags,
        id,
        notes: task.notes,
        score: task.score,
        tags: task.tags.tags,
        taskId: task.taskId,
      };

      if (task.endedAt !== undefined) {
        updates.endedAt = task.endedAt;
      }
      console.log("updates", updates, { inviteNonMembers });
      await this.api.set("completedTasks", id, updates);

      if (inviteNonMembers && inviteNonMembers.length > 0) {
        inviteNonMembers.forEach((nonMember) => {
          this.addPendingGroupMember(groupId, nonMember);
        });
      }
    } catch (err) {
      console.log({ err });
    }
  };

  reportUser = () => {
    const form = this.forms.getForm(FormOptions.report);
    const data = form.getData;
    this._reportUser(data);
  };

  _reportUser = async (
    data:
      | {
          userId: string;
          notes: string;
          reason: string;
          reasonDetails: string;
          type: string;
        }
      | any
  ) => {
    const reportId = uuid();
    await this.api.set("reports", reportId, {
      userId: data.userId,
      notes: data.notes || "",
      reason: labelForReasonId(data.reason) || "Unknown",
      reasonDetails: data.reasonDetails || "Unknown",
      type: data.type,
    });
  };

  reportGroup = () => {
    const form = this.forms.getForm(FormOptions.report);
    const data = form.getData;
    this._reportGroup(data);
  };

  _reportGroup = async (
    data:
      | {
          groupId: string;
          notes: string;
          reason: string;
          reasonDetails: string;
          type: string;
        }
      | any
  ) => {
    const reportId = uuid();
    await this.api.set("reports", reportId, {
      groupId: data.groupId,
      notes: data.notes || "",
      reason: labelForReasonId(data.reason) || "Unknown",
      reasonDetails: data.reasonDetails || "Unknown",
      type: data.type,
    });
  };

  reportPost = () => {
    const form = this.forms.getForm(FormOptions.report);
    const data = form.getData;
    this._reportPost(data);
  };

  _reportPost = async (
    data:
      | {
          completedTaskId: string;
          notes: string;
          reason: string;
          reasonDetails: string;
          type: string;
        }
      | any
  ) => {
    const reportId = uuid();
    await this.api.set("reports", reportId, {
      completedTaksId: data.completedTaskId,
      notes: data.notes || "",
      reason: labelForReasonId(data.reason) || "Unknown",
      reasonDetails: data.reasonDetails || "Unknown",
      type: data.type,
    });
  };

  processTemplate = (templateId: string) => {
    const currentUserEmail = this.user.email;
    if (!currentUserEmail) {
      return;
    }
    const templatePicker = this.templatePicker;
    alert("Template chosen! This template is now available on your dashboard.");
    const template = templatesById[templateId];
    if (!template) {
      return;
    }

    const groupId = uuid();
    const group: GroupType = {
      groupId,
      title: template.title,
      description: template.description,
      type: template.type,
      members: [currentUserEmail],
      admins: [currentUserEmail],
      allowJoinWithLink: true,
      createdBy: currentUserEmail,
      createdAt: getCurrentUTCTimeStamp(),
      public: false,
      unit: template.unit || "",
    };

    this.createGroup(group);

    template.tasks.forEach((t) => {
      const isSelected = templatePicker.selectedTasks[t.id] === true;
      if (isSelected) {
        const task: TaskType = {
          title: t.title,
          description: t.description,
          groupId,
          taskId: uuid(),
          createdBy: currentUserEmail,
          score: (t?.score && t.score > 0 && t?.score) || 0,
          allowScoreOverwrite: t.allowScoreOverwrite || false,
        };
        this.createTask(task);
      }
    });
  };

  prefillCompletedTaskForEdit = (task: Task, excludeNotes: boolean) => {
    const form = this.forms.getForm(FormOptions.completeTask);
    if (form) {
      form.fields.title.set(task.title);
      form.fields.description.set(task.description);
      form.fields.score.set(task.score);
      form.fields.taskId.set(task.taskId);
      form.fields.groupId.set(task.groupId);
      if (!excludeNotes) {
        form.fields.notes.set(task.notes);
      }
    } else {
      console.warn(
        "Attempte to prefill tasks for edit form but form was not initialized, initialized form on the fly"
      );
      this.initCompletedTaskForm(task.groupId, task);
    }
  };

  prefillTaskForEdit = (task: Task, excludeNotes: boolean) => {
    const form = this.forms.getForm(FormOptions.tasks);
    if (form) {
      form.fields.title.set(task.title);
      form.fields.description.set(task.description);
      form.fields.score.set(task.score);
      form.fields.taskId.set(task.taskId);
      form.fields.groupId.set(task.groupId);
      if (!excludeNotes) {
        form.fields.notes.set(task.notes);
      }
    } else {
      console.warn(
        "Attempte to prefill tasks for edit form but form was not initialized, initialized form on the fly"
      );
      this.initCompletedTaskForm(task.groupId, task);
    }
  };

  openTaskEditor = (taskId: string) => {};

  loadCurrentUserProfile = async (currentUserEmail: string) => {
    if (!currentUserEmail) {
      return false;
    }
    if (this.user && this.api) {
      this.ui.setLoading(true);
      const profile = await Apis.fetchUserProfile(this.api, currentUserEmail);

      if (!profile) {
        this._createUserProfile(currentUserEmail, []).then((response) => {
          this.setUserProfile({
            email: currentUserEmail,
            displayName: currentUserEmail,
            membership: "free",
          });
        });
      } else {
        const displayName = profile.displayName || currentUserEmail;

        this.user.setDisplayName();
        this.user.setLastUpdated(profile.updatedAt);
        this.user.profile.setMembership(profile.membership);
        this.user.profile.setSupportModeEnabled(profile.supportModeEnabled);
        this.user.profile.setDisplayName(profile.displayName);
        this.user.profile.setCreatedAt(profile.createdAt);
        this.setUserProfile({
          email: currentUserEmail,
          displayName,
          membership: profile.membership || "free",
          createdAt: profile.createdAt,
        });
      }
      this.ui.setLoading(false);
    }
  };

  _createUserProfile = async (email: string, groups: string[]) => {
    await this.api.set("users", email, {
      groups,
      displayName: email,
      membership: "free",
      supportModeEnabled: false,
    });
  };

  _createUserProfileWithPendingGroup = async (
    email: string,
    pendingGroups: string[]
  ) => {
    await this.api.set("users", email, {
      groups: [],
      pendingGroups,
      displayName: email,
    });
  };

  loadFeedForDate = async (
    range: StartEndTimeRangeType,
    groupId?: string,
    userId?: string
  ) => {
    console.log("load feed for date", range, groupId, userId);
    if (groupId) {
      // load feed for specific group
      const group = this.groups.records[groupId];
      if (group) {
        this.feed.load(groupId, range);
      }
    } else {
      if (userId) {
        this.feed.loadCompletedTasksForUserIdInGroups(
          userId,
          this.groups.ids,
          0
        );
      } else {
        // load feed for all groups
        this.loadAllTasksForAllGroups();
        await this.loadFeed(this.groups.ids, range);
      }
    }
  };

  loadRecentlyTagged = async (range: StartEndTimeRangeType) => {
    // load feed for all groups
    this.loadAllTasksForAllGroups();
    const currentUser = this.user.email;
    if (!currentUser) {
      return undefined;
    }

    const groups = this.groups.ids;
    this.feed.items.setRefreshing(true);

    const api = this.api.db;
    const store: AppStore = this;

    async function loadFeedForGroups(startIndex: number) {
      // Firebase only supports a max of 10 in array
      // lets query the first 10 and continue later with the remaining

      const MAX_QUERY_IN_ARRAY = 10;
      const endIndex =
        startIndex + MAX_QUERY_IN_ARRAY > groups.length
          ? groups.length
          : startIndex + MAX_QUERY_IN_ARRAY;

      const queryGroups = groups.slice(startIndex, endIndex);
      const nextIndex = endIndex;

      let completedTasks;
      if (currentUser) {
        // fetch completed tasks in some range
        completedTasks =
          await Apis.fetchCompletedTasksTaggedForCurrentUserInGroups(
            api,
            currentUser,
            queryGroups,
            30
          );

        if (completedTasks && completedTasks?.size > 0) {
          console.log("found completed tasks", {
            RANGE: range,
            total: completedTasks?.size,
          });

          completedTasks.forEach((doc) => {
            const data = doc.data() as CompletedTaskType;
            const updateCurrent = store.feed.items.items[data.id];
            if (updateCurrent !== undefined) {
              store.feed.items.items[data.id].update(data);
            } else {
              const record = store.feed.records[data.id];
              if (!record) {
                store.feed.setRecord(new CompletedTask(api, data));
              } else {
                record.update(data);
              }
            }
          });
        }
      }

      const loadMore = groups.length - 1 >= nextIndex;

      if (loadMore) {
        loadFeedForGroups(nextIndex);
      } else {
        store.feed.setRange(range);
        store.feed.items.setRefreshing(false);
      }
    }

    loadFeedForGroups(0);
  };

  private loadFeed = async (groups: string[], range: StartEndTimeRangeType) => {
    this.feed.items.setRefreshing(true);

    const api = this.api.db;
    const store: AppStore = this;
    const { startTime, endTime } = Time.getStartEndTimeFrom(range);

    async function loadFeedForGroups(startIndex: number) {
      // Firebase only supports a max of 10 in array
      // lets query the first 10 and continue later with the remaining

      const MAX_QUERY_IN_ARRAY = 10;
      const endIndex =
        startIndex + MAX_QUERY_IN_ARRAY > groups.length
          ? groups.length
          : startIndex + MAX_QUERY_IN_ARRAY;

      const queryGroups = groups.slice(startIndex, endIndex);
      const nextIndex = endIndex;
      console.log(
        "Feching feed in range",
        queryGroups,
        moment(startTime).toDate(),
        moment(endTime).toDate()
      );

      let completedTasks;
      if (range !== "all") {
        // fetch completed tasks in some range
        completedTasks =
          await Apis.fetchCompletedTasksForGroupIdInCreatedByRange(
            api,
            queryGroups,
            startTime,
            endTime,
            100
          );

        if (completedTasks && completedTasks?.size > 0) {
          console.log("found completed tasks", {
            RANGE: range,
            total: completedTasks?.size,
          });

          completedTasks.forEach((doc) => {
            const data = doc.data() as CompletedTaskType;
            const updateCurrent = store.feed.items.items[data.id];
            if (updateCurrent !== undefined) {
              store.feed.items.items[data.id].update(data);
            } else {
              const record = store.feed.records[data.id];
              if (!record) {
                store.feed.setRecord(new CompletedTask(api, data));
              } else {
                record.update(data);
              }
            }
          });
        }
      } else {
        // fetch all completed tasks ever
        console.log("$$$$ Super expensive query");
        completedTasks = await Apis.fetchCompletedTasksForGroupIds(
          api,
          queryGroups
        );
        if (completedTasks) {
          completedTasks.forEach((doc) => {
            const data = doc.data() as CompletedTaskType;
            const updateCurrent = store.feed.items.items[data.id];
            if (updateCurrent !== undefined) {
              store.feed.items.items[data.id].update(data);
            } else {
              const record = store.feed.records[data.id];
              if (!record) {
                store.feed.setRecord(new CompletedTask(api, data));
              } else {
                record.update(data);
              }
            }
          });
        } else {
          console.log("No completed tasks found");
        }
      }

      const loadMore = groups.length - 1 >= nextIndex;

      if (loadMore) {
        loadFeedForGroups(nextIndex);
      } else {
        store.feed.setRange(range);
        store.feed.items.setRefreshing(false);
      }
    }

    loadFeedForGroups(0);
  };

  loadComingUpForGroupIds = async (groups: string[], daysAhead: number) => {
    this.feed.items.setRefreshing(true);
    const api = this.api.db;
    const store: AppStore = this;

    async function loadFeedForGroups(startIndex: number) {
      // Firebase only supports a max of 10 in array
      // lets query the first 10 and continue later with the remaining

      const MAX_QUERY_IN_ARRAY = 10;
      const endIndex =
        startIndex + MAX_QUERY_IN_ARRAY > groups.length
          ? groups.length
          : startIndex + MAX_QUERY_IN_ARRAY;

      const queryGroups = groups.slice(startIndex, endIndex);
      const nextIndex = endIndex;

      let tasksComingUp;

      // fetch tasks coming up
      tasksComingUp = await Apis.fetchCompletedTasksComingUpForGroupIds(
        api,
        queryGroups,
        daysAhead
      );

      if (tasksComingUp) {
        tasksComingUp.forEach((doc) => {
          store.feed.setTask(doc.data() as CompletedTaskType);
        });
      }

      const loadMore = groups.length - 1 >= nextIndex;

      if (loadMore) {
        loadFeedForGroups(nextIndex);
      } else {
        store.feed.items.setRefreshing(false);
      }
    }

    loadFeedForGroups(0);
  };

  onAddedMembers = async (members: string[]) => {
    members.forEach((member) => {
      if (!this.userProfiles[member]) {
        this.userProfiles[member] = new UserProfile({
          email: member,
        });
        Apis.fetchProfile(this.api, member).then((profile) => {
          if (profile) {
            this.setUserProfile({
              email: profile.createdBy,
              displayName: profile.displayName,
              membership: profile.membership,
              supportModeEnabled: profile.supportModeEnabled,
              createdAt: profile.createdAt,
            });
          }
        });
      }
    });
  };

  updateGroup = async (groupId: string, updates: GroupType) => {
    const group = this.groups.records[groupId];
    group.setTitle(updates.title);
    group.setDescription(updates.description);
    group.setType(updates.type);
    group.setUnit(updates.unit || "");

    updates.members.forEach((member) => {
      // if member is already part of the group, ignore it
      // else add member as pending
      if (!isUserInGroup(member, group.members)) {
        this.groups.addPendingMemberToGroup(group.groupId, member);
      }
    });

    await this._updateGroup(group);
    await this._createProfileForInvitedMembers(updates, group.members);
  };

  _updateGroup = async (group: Group) => {
    await this.api.update("groups", group.groupId, {
      title: group.title,
      description: group.description || "",
      type: group.type,
      unit: group.unit || "",
    });
  };

  /**
   * addPendingGroupMember should be called when user adds a new member via email
   * in the group details page or when the user invites a tagged non member when
   * logging a task
   * @param groupId
   * @param memberEmail
   */
  addPendingGroupMember = async (groupId: string, memberEmail: string) => {
    const newMemberEmail = memberEmail.toLocaleLowerCase();
    const group = this.groups.addPendingMemberToGroup(groupId, newMemberEmail);
    if (group) {
      this.groups._addPendingMemberToGroup(newMemberEmail, group.groupId);
      this._createProfileForInvitedMembers(group, [newMemberEmail]);

      const currentUserEmail = this.user.email;
      currentUserEmail &&
        (await this._addPendingGroupToUser(memberEmail, {
          groupId: group.groupId,
          groupName: group.title,
          description: group.description,
          invitedBy: currentUserEmail,
          invitedOn: new Date().getTime(),
          groupCreatedBy: group.createdBy,
          groupCreatedOn: group.createdAt || new Date().getTime(),
        }));
    }
    this.refresh();
  };

  showGroupMembersEditor = (
    groupId: string,
    showUpdateGroupFormOnSubmit: boolean
  ) => {
    this.initAddMemberForm(groupId, showUpdateGroupFormOnSubmit);
  };

  createGroup = async (group: GroupType) => {
    const currentUserEmail = this.user.email;
    if (!currentUserEmail) {
      return;
    }
    this.groups.addGroup(group, currentUserEmail);
    await this.groups.createGroup(currentUserEmail, group);
    // let's add recently created group to list of recents
    this.feed.setRecentGroup(group.groupId);
    this._createProfileForInvitedMembers(group, group.members);
  };

  leaveGroup = async (groupId: string) => {
    const userEmail = this.user.email;
    if (userEmail) {
      await this.groups.removeGroupFromUser(groupId, userEmail);
      // if user is the only admin, and there are other members,
      // make all other members admins
      // TODO! Transfer ownership
      this.refresh();
    }
  };

  deleteGroup = (email: string, groupId: string) => {
    // remove group forever
    this.groups.permanentlyDeleteGroup(groupId);

    // remove tasks
    // remove all tasks associated with this group
    const tasks = this.tasks;
    const groupTasks = tasks.values;
    groupTasks.forEach((task) => {
      if (task.groupId === groupId) {
        tasks.deleteItem(task.taskId);
        tasks._removeTask(task.taskId);
      }
    });
  };

  _createProfileForInvitedMembers = (group: GroupType, members: string[]) => {
    const currentUserEmail = this.user.email;

    if (currentUserEmail) {
      members.forEach(async (member: string) => {
        // only create profile for members who are not the logged user
        if (member !== this.user.email) {
          await this._addPendingGroupToUser(member, {
            groupId: group.groupId,
            groupName: group.title,
            description: group.description,
            invitedBy: currentUserEmail,
            invitedOn: new Date().getTime(),
            groupCreatedBy: group.createdBy,
            groupCreatedOn: group.createdAt,
          });
        }
      });
    }
  };
  addUserToPendingGroupFromLink = async (groupId: string, userId: string) => {
    const group = await this.api.fetch("groups", groupId);

    if (
      group !== undefined &&
      group &&
      group !== null &&
      group.allowJoinWithLink === true
    ) {
      // check if user has not been invited already
      if (
        this.pendingGroupInvitations.filter(
          (invitation) => invitation.groupId === groupId
        ).length === 1
      ) {
        // halt here, user already has this invitation
        return;
      }
      // check if user is not already part of this group
      if (this.groups.records[groupId] !== undefined) {
        // halt here, user is already part of this group
        return;
      }
      const invitation: PendingGroupInvitationType = {
        groupId,
        groupName: group.title,
        description: group.description,
        invitedBy: CREATED_BY_SHARED_LINKED,
        invitedOn: new Date().getTime(),
        groupCreatedBy: group.createdBy,
        groupCreatedOn: group.createdAt,
      };

      const result = await this._addPendingGroupToUser(userId, invitation);

      if (result?.code !== undefined) {
        alert(strings.errors.unableToJoinGroup);
      } else {
        if (!this.groups.records[groupId]) {
          this.setPendingGroupInvitations([
            ...this.pendingGroupInvitations,
            invitation,
          ]);
        }
      }
    }
  };

  clearFromPendingInvitations = (groupId: string) => {
    const updatedPending = [
      ...this.pendingGroupInvitations.filter(
        (invitation) => invitation.groupId !== groupId
      ),
    ];
    this.clearPendingGroupInvitations();
    this.setPendingGroupInvitations(updatedPending);
  };

  getCompletedTaskFromDocumentData = (
    doc: DocumentData
  ): CompletedTaskType | undefined => {
    const loggedTask = doc.data();
    const task = this.tasks.items[loggedTask.taskId];
    let score = task?.score;
    if (task?.allowScoreOverwrite) {
      score = loggedTask?.score || task?.score;
    }

    if (!score) {
      score = loggedTask?.score || 0;
    }
    if (task) {
      const newTask: CompletedTaskType = {
        allowScoreOverwrite: task?.allowScoreOverwrite || false,
        createdAt: loggedTask?.createdAt || loggedTask.updatedAt,
        createdBy: loggedTask.createdBy,
        groupId: loggedTask.groupId,
        hashtags: loggedTask.hashtags,
        id: loggedTask.id,
        notes: loggedTask.notes,
        score: score || 0,
        tags: loggedTask.tags,
        taskId: loggedTask.taskId,
        updatedAt: loggedTask.updatedAt,
      };
      return newTask;
    }
    return loggedTask;
  };

  createTask = async (task: TaskType) => {
    this.tasks.setTask(task);
    this._addTask(task);
  };

  updateTask = async (taskId: string, updates: TaskType) => {
    const groupId = updates.groupId;
    if (!groupId) {
      console.warn("Attempt to update task but missing group Id");
      return undefined;
    }

    const task = this.tasks.items[taskId];
    if (task) {
      task.setTitle(updates.title);
      task.setDescription(updates.description);
      task.setScore(updates.score);
      task.setAllowScoreOverwrite(updates.allowScoreOverwrite);
      await this._updateTask(task);
    } else {
      alert("something went wrong!");
    }
  };

  updateCompletedTask = async (id: string, updates: LoggedTaskUpdatesType) => {
    this.feed.updateCompletedTask(id, updates);
    await this._updateLoggedTask(id, updates);
  };

  reassignCompletedTask = async (updates: CompletedTaskType) => {
    const id = updates.id;
    const feedItems = this.feed.items.items;
    const completedTask = feedItems[id];
    const record = this.feed.records[id];

    completedTask?.setTaskId(updates.taskId);
    completedTask?.setGroupId(updates.groupId);
    record?.setTaskId(updates.taskId);
    record?.setGroupId(updates.groupId);
    await this._updateLoggedTask(id, updates);
  };

  bulkReassignTasks = (
    updates: CompletedTaskType,
    completedTaskIds: string[]
  ) => {
    completedTaskIds.forEach((id) => {
      this.reassignCompletedTask({ ...updates, id });
    });
  };

  bulkDeleteTasks = (completedTaskIds: string[], groupId: string) => {
    completedTaskIds.forEach((id) => {
      this.removeCompletedTask(id, groupId);
    });
  };

  _addTask = async (task: TaskType) => {
    await this.api.set("tasks", task.taskId, task);
  };

  toggleLike = (completedTask: CompletedTask, userId: string) => {
    const taskId = completedTask.id;

    if (!completedTask) {
      return null;
    }
    if (completedTask.reactions.toggleLike(userId)) {
      completedTask._like(this.api, userId, taskId);
    } else {
      completedTask._removeLike(this.api, userId, taskId);
    }
  };

  markFavorite = (groupId: string) => {
    const group: Group = this.groups.records[groupId];
    if (group) {
      this.groups.setFavorite(groupId);
      if (this.groups.favorites[groupId]) {
        group.markFavorite(this.api, true);
      } else {
        group.markFavorite(this.api, false);
      }
    }
  };

  removeCompletedTask = async (id: string, groupId: string) => {
    const feedItems = this.feed.items;
    feedItems.setRefreshing(true);
    this.feed.removeCompletedTask(id);
    await this._removeCompletedTask(id);
    feedItems.setRefreshing(false);
  };

  _removeCompletedTask = async (id: string) => {
    await Apis.removeCompletedTask(this.api.db, id);
  };

  _updateTask = async (task: Task) => {
    await this.api.update("tasks", task.taskId, {
      title: task.title,
      description: task.description || "",
      score: task.score,
      allowScoreOverwrite: task.allowScoreOverwrite,
    });
  };

  _updateLoggedTask = async (id: string, updates: LoggedTaskUpdatesType) => {
    let normalizedUpdates: any = {
      notes: updates.notes,
      score: updates.score,
      tags: updates.tags,
      hashtags: updates.hashtags,
    };

    if (updates.createdAt !== undefined) {
      normalizedUpdates = {
        ...normalizedUpdates,
        createdAt: updates.createdAt,
      };
    }

    if (updates.endedAt !== undefined) {
      normalizedUpdates = {
        ...normalizedUpdates,
        endedAt: updates.endedAt,
      };
    }

    if (updates.createdAt !== undefined && updates.endedAt === undefined) {
      normalizedUpdates = {
        ...normalizedUpdates,
        endedAt: updates.createdAt,
      };
    }
    await this.api.update("completedTasks", id, normalizedUpdates);
  };

  _addPendingGroupToUser = async (
    userId: string,
    invitation: PendingGroupInvitationType
  ): Promise<void | {
    code: string;
    errorMessage: string;
  } | null> => {
    // check if user has already joined this group
    const group = await this.api.fetch(
      `users/${userId}/groups`,
      invitation.groupId
    );

    // only add pending group if group is not available in user groups
    if (!group) {
      await this.api.set(
        "users",
        `${userId}/pendingGroups/${invitation.groupId}`,
        invitation
      );
    }
  };

  _updateUserProfile = async (
    email: string,
    updates: { displayName: string }
  ) => {
    return await this.api.update("users", email, updates);
  };
}

// Instantiate the app store.
const appStore = new AppStore();
// Create a React Context with the app store instance.
export const AppStoreContext = React.createContext(appStore);
export const useAppStore = () => React.useContext(AppStoreContext);

export const isValidEmail = (inputText: string) => {
  const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (inputText.match(mailformat)) {
    return false;
  } else {
    return true;
  }
};
