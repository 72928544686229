import { useNavigation, useRoute } from "@react-navigation/native";
import { observer } from "mobx-react";
import { ScrollView, View } from "react-native";
import CalendarPicker from "react-native-calendar-picker";
import Button from "../components/common/Button";
import { appStyles, colors, spacings } from "../defaultStyles";
import { css } from "../lib/glamor-native";
import { useAppStore } from "../store/AppStore";
import { isGroupTrackedByCreationDate } from "../utils";

const PickDate: React.FC = observer(() => {
  const navigation = useNavigation();
  return (
    <View
      style={css({
        ...appStyles.viewWrapper,
        ...appStyles.container,
      })}
    >
      <ScrollView contentContainerStyle={css(appStyles.scrollview)}>
        <View style={css({ backgroundColor: "transparent" })}>
          <Calendar />

          <View
            style={css({
              backgroundColor: "transparent",
              flexDirection: "row",
              marginTop: spacings.regular,
              alignItems: "center",
              justifyContent: "center",
            })}
          >
            <Button
              label="Apply changes"
              onPress={() => {
                navigation.goBack();
              }}
            />
          </View>
        </View>
      </ScrollView>
    </View>
  );
});

const Calendar: React.FC = observer(() => {
  const store = useAppStore();
  const router = useRoute();
  //@ts-ignore
  const formId = router.params?.formId;
  if (!formId) {
    return null;
  }
  const form = store.forms.getForm(formId);
  if (!form) {
    return null;
  }

  const fields = form.fields;
  const groupId = fields.groupId.value;
  const group = store.groups.records[groupId];
  const groupType = group.type;

  return (
    <View style={css({ backgroundColor: "transparent" })}>
      <CalendarPicker
        scaleFactor={400}
        textStyle={{
          color: colors.onBackground,
        }}
        todayTextStyle={{ fontWeight: "bold", color: colors.BLACK }}
        allowRangeSelection={isGroupTrackedByCreationDate(groupType)}
        previousTitleStyle={{
          fontSize: 18,
          fontWeight: "bold",
          marginLeft: spacings.large,
        }}
        nextTitleStyle={{
          fontSize: 18,
          fontWeight: "bold",
          marginRight: spacings.large,
        }}
        initialDate={fields.createdAt.value}
        onDateChange={(date, type) => {
          if (!date) {
            return;
          }

          const timestamp = new Date(date.toString()).getTime();
          console.log("onDateChange", date, type);
          if (type === "END_DATE") {
            // EDITING END DATE
            if (timestamp > fields.createdAt.value) {
              // only update if end date is ahead of start date
              fields.endedAt.set(timestamp);
            } else {
              // if end date is before start date, reset end date and set create date
              fields.createdAt.set(timestamp);
              fields.endedAt.set(undefined);
            }
          } else {
            // EDITING START DATE
            fields.createdAt.set(timestamp);
            fields.endedAt.set(undefined);
          }
          console.log({
            startTime: fields.createdAt.value,
            endTime: fields.endedAt.value,
          });
        }}
      />
    </View>
  );
});

export default PickDate;
