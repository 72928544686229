import {
  collection,
  deleteDoc,
  doc,
  DocumentData,
  Firestore,
  getDocs,
  limit,
  orderBy,
  Query,
  query,
  where,
} from "firebase/firestore";
import moment from "moment";
import { StartEndTimeRangeType } from "./common/Time";
import { Firestore as FirebaseStore } from "./Firestore";
import User from "./User";
import { queryCounter } from "./utils/QueryCounter";

export default class Apis {
  static fetchUserProfile = async (
    api: FirebaseStore,
    currentUserId: string
  ) => {
    queryCounter.addFetch("fetchUserProfile");
    return await api.fetch("users", currentUserId);
  };

  static fetchProfile = async (api: FirebaseStore, email: string) => {
    queryCounter.addFetch("fetchProfile");
    return await api.fetch("users", email);
  };

  static fetchGroupsForUser = async (
    db: Firestore,
    user: User,
    lmt: number
  ) => {
    try {
      if (user.authorized) {
        queryCounter.addCall("fetchGroupsForUser");
        const c = collection(db, "groups");
        const w = where("createdBy", "==", user.email);
        const o = orderBy("updatedAt");
        const l = limit(lmt);
        const q = query(c, w, o);

        return await getDocs(q);
      }
    } catch (error) {
      console.log("fetchGroupsForUser", { error });
    }
  };

  static batchFetchGroups = async (
    db: Firestore,
    groupIds: string[],
    lmt: number
  ) => {
    try {
      queryCounter.addCall("batchFetchGroups");
      const c = collection(db, "groups");
      const w = where("groupId", "in", groupIds);
      const o = orderBy("createdAt");
      const l = limit(lmt);
      const q = query(c, w, o);

      return await getDocs(q);
    } catch (error) {
      console.log("fetchGroupsForUser", { error });
    }
  };

  static fetchCompletedTasksForGroupId = async (
    db: Firestore,
    groupId: string,
    range: StartEndTimeRangeType,
    startTime: number,
    endTime: number,
    lmt: number
  ) => {
    try {
      queryCounter.addCall("fetchCompletedTasksForGroupId");
      const c = collection(db, "completedTasks");
      const w = where("groupId", "==", groupId);
      const w2 = where("createdAt", ">=", startTime);
      const w3 = where("createdAt", "<=", endTime);
      const o = orderBy("createdAt", "asc");
      let q;
      if (range === "all") {
        q = query(c, w, o);
      } else {
        q = query(c, w, w2, w3, o);
      }
      return await getDocs(q);
    } catch (error) {
      console.log("fetchCompletedTasksForGroupId", { error });
    }
  };

  static fetchFeed = (
    db: Firestore,
    groupId: string,
    range: StartEndTimeRangeType,
    startTime: number,
    endTime: number
  ): Query<DocumentData> | undefined => {
    try {
      queryCounter.addCall("fetchFeed");

      const c = collection(db, "completedTasks");
      const w = where("groupId", "==", groupId);
      const w2 = where("createdAt", ">=", startTime);
      const w3 = where("createdAt", "<=", endTime);
      const orderByAsc = orderBy("createdAt", "asc");
      const orderByDesc = orderBy("createdAt", "desc");

      console.log(`=======FETCH ${range}======`, {
        groupId,
        range,
        startTime: moment(startTime).format("MMM DD, YYYY"),
        endTime: moment(endTime).format("MMM DD, YYYY"),
      });

      if (range === "all") {
        // all before today
        const allCompleted = where("createdAt", "<=", new Date().getTime());
        return query(c, w, allCompleted, orderByAsc);
      } else if (range === "comingUp") {
        // all after today
        const comingUp = where("createdAt", ">=", startTime);
        const inTheNextXDays = where("createdAt", "<=", endTime);

        return query(c, w, comingUp, inTheNextXDays, orderByDesc);
      } else if (range === "inRange") {
        return query(c, w, w2, w3, orderByAsc);
      } else {
        return query(c, w, w2, w3, orderByAsc);
      }
    } catch (error) {
      console.log("fetchCompletedTasksForGroupId", { error });
      return undefined;
    }
  };

  static fetchCompletedTasksForGroupIds = async (
    db: Firestore,
    groupIds: string[]
    // lmt: number
  ) => {
    try {
      if (groupIds.length === 0) {
        console.log(
          "fetchCompletedTasksForGroupIdInCreatedByRange NOT COMPLETED",
          { groupIds }
        );
        return undefined;
      }
      queryCounter.addCall("fetchCompletedTasksForGroupIdInCreatedByRange");
      console.log("fetchCompletedTasksForGroupIdInCreatedByRange", {
        groupIds,
      });
      const c = collection(db, "completedTasks");
      const w = where("groupId", "in", groupIds);
      const o = orderBy("createdAt", "asc");
      // const l = limit(lmt);
      const q = query(c, w, o);

      return await getDocs(q);
    } catch (error) {
      console.log("fetchCompletedTasksForGroupId", { error });
    }
  };

  static fetchCompletedTasksForUserIdInGroups = async (
    db: Firestore,
    userId: string,
    groupIds: string[],
    lmt: number
  ) => {
    try {
      if (groupIds.length === 0) {
        console.log("fetchCompletedTasksForUserIdInGroups NOT COMPLETED", {
          groupIds,
        });
        return undefined;
      }
      queryCounter.addCall("fetchCompletedTasksForUserIdInGroups");
      console.log("fetchCompletedTasksForUserIdInGroups", {
        groupIds,
      });
      // in the last 3 days
      const date = new Date();
      date.setDate(date.getDate() - 3);
      const c = collection(db, "completedTasks");
      const w = where("groupId", "in", groupIds);
      const w2 = where("createdBy", "==", userId);
      const w3 = where("firstCreatedAt", ">=", date.getTime());
      const o = orderBy("firstCreatedAt", "desc");
      // const l = limit(lmt);
      const q = query(c, w, w2, w3, o);

      return await getDocs(q);
    } catch (error) {
      console.log("fetchCompletedTasksForUserIdInGroups", { error });
    }
  };

  static fetchCompletedTasksTaggedForCurrentUserInGroups = async (
    db: Firestore,
    userId: string,
    groupIds: string[],
    lmt: number
  ) => {
    try {
      if (groupIds.length === 0) {
        console.log(
          "fetchCompletedTasksTaggedForCurrentUserInGroups NOT COMPLETED",
          {
            groupIds,
          }
        );
        return undefined;
      }
      queryCounter.addCall("fetchCompletedTasksTaggedForCurrentUserInGroups");
      console.log("fetchCompletedTasksTaggedForCurrentUserInGroups", {
        groupIds,
      });
      // created in the last 7 days
      const date = new Date();
      date.setDate(date.getDate() - 7);
      const c = collection(db, "completedTasks");
      const w = where("groupId", "in", groupIds);
      const w3 = where("firstCreatedAt", ">=", date.getTime());
      const w4 = where("tags", "array-contains", userId);
      const o = orderBy("firstCreatedAt", "desc");

      const q = query(c, w, w3, w4, o);

      return await getDocs(q);
    } catch (error) {
      console.log("fetchCompletedTasksForUserIdInGroups", { error });
    }
  };

  static fetchCompletedTasksForGroupIdInCreatedByRange = async (
    db: Firestore,
    groupIds: string[],
    startTime: number,
    endTime: number,
    lmt: number
  ) => {
    try {
      if (groupIds.length === 0) {
        console.log(
          "fetchCompletedTasksForGroupIdInCreatedByRange NOT COMPLETED",
          { groupIds }
        );
        return undefined;
      }
      queryCounter.addCall("fetchCompletedTasksForGroupIdInCreatedByRange");
      console.log("fetchCompletedTasksForGroupIdInCreatedByRange", {
        groupIds,
      });

      const c = collection(db, "completedTasks");
      const w = where("groupId", "in", groupIds);
      const w1 = where("createdAt", ">=", startTime); // Date starts
      const w2 = where("createdAt", "<=", endTime); // Day ends
      const o = orderBy("createdAt", "asc");
      const l = limit(lmt);
      const q = query(c, w, w1, w2, o);

      return await getDocs(q);
    } catch (error) {
      console.log("fetchCompletedTasksForGroupIdInCreatedByRange", {
        error,
      });
    }
  };

  static fetchCompletedTasksByTaskId = async (
    db: Firestore,
    taskId: string,
    lmt: number
  ) => {
    const c = collection(db, "completedTasks");
    const w = where("taskId", "==", taskId);
    const o = orderBy("createdAt", "desc");
    const l = limit(lmt);
    const q = query(c, w, o, l);

    return await getDocs(q);
  };

  static fetchCompletedTasksByGroupId = async (
    db: Firestore,
    groupId: string,
    lmt: number
  ) => {
    const c = collection(db, "completedTasks");
    const w = where("groupId", "==", groupId);
    const o = orderBy("createdAt", "desc");
    const l = limit(lmt);

    const q = query(c, w, o, l);

    return await getDocs(q);
  };

  static fetchCompletedTasksComingUpForGroupIds = async (
    db: Firestore,
    groupIds: string[],
    days: number
  ) => {
    try {
      if (groupIds.length === 0) {
        console.log("fetchCompletedTasksComingUpForGroupId NOT COMPLETED", {
          groupIds,
        });
        return undefined;
      }
      queryCounter.addCall("fetchCompletedTasksComingUpForGroupId");

      const date = new Date();
      const today = new Date();
      date.setDate(date.getDate() + days); // in the next year

      const now = today.getTime();
      const until = date.getTime();
      const c = collection(db, "completedTasks");
      const w = where("groupId", "in", groupIds);
      const w1 = where("createdAt", ">=", now);
      const w2 = where("createdAt", "<=", until);
      const o = orderBy("createdAt", "desc");

      const q = query(c, w, w1, w2, o);

      return await getDocs(q);
    } catch (error) {
      console.log("fetchCompletedTasksComingUpForGroupId", {
        error,
      });
    }
  };

  static fetchTasksInGroups = async (db: Firestore, groupIds: string[]) => {
    if (groupIds.length === 0) {
      console.log("fetchTasksInGroups NOT COMPLETED", { groupIds });
      return undefined;
    }

    queryCounter.addCall("fetchTasksInGroups");
    console.log("fetchTasksInGroups", { groupIds });

    const c = collection(db, "tasks");
    const w = where("groupId", "in", groupIds);
    const o = orderBy("createdAt", "asc");
    const q = query(c, w, o);
    return await getDocs(q);
  };

  static fetchCompletedTasksForUserId = async (
    db: Firestore,
    userId: string,
    lmt: number
  ) => {
    try {
      queryCounter.addCall("fetchCompletedTasksForUserId");
      const c = collection(db, "completedTasks");
      const w = where("createdBy", "==", userId);
      const o = orderBy("firstCreatedAt", "desc");
      const l = limit(lmt);
      const q = query(c, w, o, l);

      return await getDocs(q);
    } catch (err) {
      console.log({ err });
    }
  };

  static fetchCompletedTasksForUserIdForGroupId = async (
    db: Firestore,
    userId: string,
    groupId: string,
    lmt: number
  ) => {
    try {
      queryCounter.addCall("fetchCompletedTasksForUserId");
      const c = collection(db, "completedTasks");
      const w = where("createdBy", "==", userId);
      const w2 = where("groupId", "==", groupId);
      const o = orderBy("firstCreatedAt", "desc");
      const l = limit(lmt);
      const q = query(c, w, w2, o, l);

      return await getDocs(q);
    } catch (err) {
      console.log({ err });
    }
  };

  static fetchUserGroups = async (db: Firestore, user: User, lmt: number) => {
    if (user.authorized) {
      queryCounter.addCall("fetchUserGroups");
      const c = collection(db, "groups");
      const w = where("createdBy", "==", user.email);
      const o = orderBy("updatedAt");
      const l = limit(lmt);
      const q = query(c, w, o, l);

      return await getDocs(q);
    }
  };

  static fetchTasksForUserByGroupId = async (
    db: Firestore,
    user: User,
    groupId: string,
    lmt: number
  ) => {
    if (user.authorized) {
      queryCounter.addCall("fetchTasksForUserByGroupId");
      const c = collection(db, "tasks");
      const w = where("groupId", "==", groupId);
      const o = orderBy("updatedAt");
      const l = limit(lmt);
      const q = query(c, w, o, l);

      return await getDocs(q);
    }
  };

  static fetchTasksForGroupId = async (db: Firestore, groupId: string) => {
    queryCounter.addCall("fetchTasksForGroupId");
    const c = collection(db, "tasks");
    const w = where("groupId", "==", groupId);
    const o = orderBy("updatedAt");
    const q = query(c, w, o);

    return await getDocs(q);
  };

  static fetchAllTasksForUser = async (
    db: Firestore,
    userId: string,
    lmt: number
  ) => {
    queryCounter.addCall("fetchAllTasksForUser");
    const c = collection(db, "tasks");
    const w = where("createdBy", "==", userId);
    const o = orderBy("createdAt");
    const l = limit(lmt);
    const q = query(c, w, o, l);

    return await getDocs(q);
  };

  static removeGroup = async (db: Firestore, groupId: string) => {
    try {
      queryCounter.addCall("removeGroup");
      return await deleteDoc(doc(db, "groups", groupId));
    } catch (error) {
      console.log("removeGroup", {
        error,
      });
    }
  };

  static removeTask = async (db: Firestore, taskId: string) => {
    queryCounter.addCall("removeTask");
    return await deleteDoc(doc(db, "tasks", taskId));
  };

  static removeCompletedTask = async (db: Firestore, id: string) => {
    queryCounter.addCall("removeCompletedTask");
    try {
      return await deleteDoc(doc(db, "completedTasks", id));
    } catch (err) {
      console.log({ err });
      return false;
    }
  };

  static fetchStripMembershipOptions = async (db: Firestore) => {
    try {
      queryCounter.addCall("fetchStripMembershipOptions");
      const c = collection(db, "memberships");
      const w = where("active", "==", true);
      const q = query(c, w);

      return await getDocs(q);
    } catch (err) {
      console.log({ err });
      return undefined;
    }
  };
}
