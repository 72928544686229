import { TemplateTaskType } from "../templates";

export const medicineCabinet: TemplateTaskType[] = [
  {
    id: "took-pill-event",
    title: "Took a pill",
    description: "You took a pill",
  },
  {
    id: "forgot-pill-event",
    title: "Forgot to take pill",
    description: "You forgot to take a pill",
  },
];
