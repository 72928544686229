import { TemplateTaskType } from "../templates";

export const romance: TemplateTaskType[] = [
  {
    id: "romance-feel-good",
    score: 0,
    allowScoreOverwrite: true,
    title: "Happy",
    description: "You feel happy",
  },
  {
    id: "romance-feel-sad",
    score: 0,
    allowScoreOverwrite: true,
    title: "Sad",
    description: "You feel sad",
  },
  {
    id: "romance-feel-overhelmed",
    score: 0,
    allowScoreOverwrite: true,
    title: "Overhelmed",
    description: "You feel overhelmed",
  },
  {
    id: "romance-feel-concerned",
    score: 0,
    allowScoreOverwrite: true,
    title: "Concerned",
    description: "You feel concerned",
  },
  {
    id: "romance-goal",
    score: 0,
    allowScoreOverwrite: true,
    title: "Set a goal",
    description: "You set a goal",
  },
  {
    id: "romance-promise",
    score: 0,
    allowScoreOverwrite: true,
    title: "Made a promise",
    description: "Someone made a promise",
  },
  {
    id: "romance-broke-promise",
    score: 0,
    allowScoreOverwrite: true,
    title: "Broke promise",
    description: "Someone broke a promise",
  },
  {
    id: "romance-compromised",
    score: 0,
    allowScoreOverwrite: true,
    title: "We compromised",
    description: "You compromised",
  },
  {
    id: "romance-argued-no-resolution",
    score: 0,
    allowScoreOverwrite: true,
    title: "Argued without resolution",
    description: "You argued and there was no resolution",
  },
  {
    id: "romance-argued-with-resolution",
    score: 0,
    allowScoreOverwrite: true,
    title: "Argued with resolution",
    description: "You argued but you resolved the issue",
  },
  {
    id: "romance-controlled-anger",
    score: 0,
    allowScoreOverwrite: true,
    title: "Controlled anger",
    description: "Someone controlled their anger",
  },
  {
    id: "romance-angry",
    score: 0,
    allowScoreOverwrite: true,
    title: "Got angry",
    description: "Someone got angry",
  },
  {
    id: "romance-apologized",
    score: 0,
    allowScoreOverwrite: true,
    title: "Apologized",
    description: "Someone apologized",
  },
  {
    id: "romance-made-mistake",
    score: 0,
    allowScoreOverwrite: true,
    title: "Made mistake",
    description: "Someone made a mistake",
  },
];
