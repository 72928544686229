import { useNavigation, useRoute } from "@react-navigation/native";
import { StatusBar } from "expo-status-bar";
import { observer } from "mobx-react";
import { Platform, RefreshControl, ScrollView } from "react-native";
import { TertiaryButtonCreateTask } from "../components/Buttons";
import { Separator } from "../components/common/Separator";
import { Text, View } from "../components/Themed";
import { appStyles, colors, spacings } from "../defaultStyles";
import { css } from "../lib/glamor-native";
import { useAppStore } from "../store/AppStore";
import { FormOptions } from "../store/common/form/FormStore";
import Task, { getLabelForGroupType } from "../store/Task";
import EmptyTasksScreen from "./EmptyTasksScreen";
import RadioButton from "../components/common/RadioButton";
import Dev from "../components/Dev";
import { Time } from "../store/common/Time";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { Heading } from "../components/common/Heading";
import SearchBox from "../components/common/SearchBox";
import Search from "../store/utils/Search";
import { Tag } from "../components/common/Tag";

const LogTaskScreen: React.FC = () => {
  const store = useAppStore();
  const route = useRoute();
  //@ts-ignore
  const groupIdFromRoute = route.params?.groupId;
  const search: Search = store.tasks.search;

  return (
    <KeyboardAwareScrollView
      style={css({ ...appStyles.viewWrapper, ...appStyles.container })}
      enableAutomaticScroll={true}
      extraScrollHeight={100}
      refreshControl={
        <RefreshControl
          refreshing={store.tasks.refreshing}
          onRefresh={() => {
            if (groupIdFromRoute) {
              store.tasks.loadTasksForGroup(groupIdFromRoute);
            } else {
              store.tasks.loadTasksForGroups(store.groups.ids);
            }
          }}
        />
      }
    >
      <View style={css(appStyles.container)}>
        <SearchBox
          search={search}
          placeholder="Search available tasks"
          style={{ marginBottom: spacings.large }}
        />
        <ScrollView
          contentContainerStyle={css({
            ...appStyles.scrollview,
          })}
        >
          <LogTaskView groupId={groupIdFromRoute || store.ui.selectedGroupId} />
        </ScrollView>
      </View>
    </KeyboardAwareScrollView>
  );
};

export default observer(LogTaskScreen);

const LogTaskView: React.FC<{ groupId?: string }> = observer(({ groupId }) => {
  const store = useAppStore();
  const form = store.forms.getForm(FormOptions.completeTask);

  if (!form) {
    return null;
  }
  const fields = form.fields;
  if (!fields) {
    return null;
  }
  let tasks = store.tasks.values;
  const search = store.tasks.search;

  if (groupId) {
    const group = store.groups.records[groupId];
    if (group) {
      tasks = store.tasks.byGroup[groupId];
    }
  }

  const totalTasks = tasks?.length || 0;
  const updated = store.tasks.updated;
  if (totalTasks === 0 && !search.hasSearchTerm) {
    return <EmptyTasksScreen groupId={groupId} />;
  }

  return (
    <View
      key={updated}
      style={css({
        backgroundColor: "transparent",
      })}
    >
      <Heading
        level={2}
        title={`Events (${totalTasks})`}
        style={{ marginBottom: spacings.regular }}
      />

      {tasks?.map((task) => {
        const group = store.groups.records[task.groupId];
        return (
          <View
            key={`${task.taskId}-${groupId}`}
            style={css({
              backgroundColor: colors.surface,
              padding: spacings.regular,
              marginBottom: spacings.relaxed,
              borderRadius: spacings.roundBorderXl,
            })}
          >
            <View
              style={css({
                backgroundColor: "transparent",
                flexDirection: "row",
              })}
            >
              <Tag
                label={getLabelForGroupType(
                  task.score || 0,
                  group?.type,
                  undefined,
                  group?.unit
                )}
              />
            </View>

            <TaskSelector task={task} />

            <Dev
              messages={[
                { label: "task.id", value: task.id },
                { label: "task.taskId", value: task.taskId },
              ]}
            />
          </View>
        );
      })}
      <Separator />
      <View
        style={css({
          alignItems: "center",
          backgroundColor: "transparent",
          flexDirection: "column",
          justifyContent: "center",
          marginBottom: 100,
          marginTop: spacings.default,
        })}
      >
        <Heading
          level={2}
          title="Don't see the event you want to log?"
          style={{ marginBottom: spacings.default }}
        />
        <TertiaryButtonCreateTask groupId={groupId} />
      </View>

      {/* Use a light status bar on iOS to account for the black space above the modal */}
      <StatusBar style={Platform.OS === "ios" ? "light" : "auto"} />
    </View>
  );
});

export const TaskSelector: React.FC<{ task: Task; allowCheck?: boolean }> =
  observer(({ task, allowCheck = true }) => {
    const store = useAppStore();
    const navigation = useNavigation();
    const group = store.groups.records[task.groupId];
    if (!group) {
      return null;
    }

    const groupType = group.type;
    const form = store.forms.getForm(FormOptions.completeTask);
    if (!form) {
      return null;
    }
    const fields = form.fields;
    const time: Time = form.options.time;
    const isTaskSelected = fields.taskId.value === task.taskId;

    return (
      <View style={css({ backgroundColor: "transparent" })}>
        <View
          style={css({
            backgroundColor: "transparent",
            flexDirection: "column",
          })}
        >
          <View
            style={css({
              backgroundColor: "transparent",
              flexDirection: "row",
              alignItems: "center",
            })}
          >
            <RadioButton
              textStyle={{ ...appStyles.heading.level2, marginBottom: 0 }}
              label={task.title}
              selected={isTaskSelected}
              onPress={() => {
                if (allowCheck === false) {
                  return null;
                }
                if (store.ui.editTaskId === task.taskId) {
                  // deselect task
                  store.ui.setEditTaskId(undefined);
                  store.ui.setShowMenuForCompletedTaskId(undefined);
                  store.ui.setShowEditTaskMenuId(undefined);
                  fields.taskId.set(undefined);
                } else {
                  // select task
                  fields.title.set(task.title);
                  fields.description.set(task.description);
                  if (groupType === "time") {
                    time.setTimeInSeconds(task.score);
                    fields.score.set(time.seconds);
                  } else {
                    fields.score.set(task.score);
                  }
                  fields.taskId.set(task.taskId);
                  fields.groupId.set(task.groupId);
                  store.ui.setEditTaskId(task.taskId);
                  store.ui.setShowMenuForCompletedTaskId(undefined);
                  store.ui.setShowEditTaskMenuId(undefined);
                  navigation.navigate("CompleteTaskEditor", {
                    formId: FormOptions.completeTask,
                    taskId: task.taskId,
                    createOnSave: true,
                  });
                }
              }}
            />
          </View>
          <Text style={css(appStyles.text.body)}>{task.description}</Text>
        </View>
      </View>
    );
  });
