import { observer } from "mobx-react-lite";
import { RefreshControl, View } from "react-native";
import { appStyles, spacings } from "../defaultStyles";
import { useAppStore } from "../store/AppStore";
import { css } from "../lib/glamor-native";
import { NoAccessScreen } from "./NoAccessScreen";
import { useRoute } from "@react-navigation/native";
import {
  NoRecentEntriesInFeed,
  PaginationComponent,
} from "../components/FeedList";
import { QueryRangeSelector } from "./group/GroupSummary";
import { AdMobBanner } from "expo-ads-admob";
import { adUnitId } from "../constants/AppDefaults";
import { FeedCard } from "../store/common/FeedCard";
import { Heading } from "../components/common/Heading";
import { StartEndTimeRangeType } from "../store/common/Time";
import { ascending } from "d3";
import Dev from "../components/Dev";
import ScrollProvider from "../components/common/scroll/ScrollProvider";

// ROOT - OPENS FROM BOTTOM TAB
const ComingUp: React.FC<{ showAds?: boolean }> = ({ showAds = true }) => {
  const store = useAppStore();
  const route = useRoute();

  //@ts-ignore
  const groupId = route.params?.groupId;
  //@ts-ignore
  const email = route.params?.email;

  if (!store.user?.hasAccess) {
    return <NoAccessScreen />;
  }

  let hasTasks = false;
  let range: StartEndTimeRangeType = store.feed.range;
  let group = undefined;
  if (groupId) {
    // We want to filter tasks by group id

    range = store.groups.records[groupId]?.range || range;
    group = store.groups.records[groupId];
    const filteredTasksForGroup = store.tasks.byGroup[groupId];
    hasTasks = filteredTasksForGroup?.length > 0;
  } else {
    hasTasks = store.tasks?.length > 0;
  }

  if (!hasTasks) {
    return <NoRecentEntriesInFeed comingUp={true} />;
  }

  return (
    <View
      style={css({
        ...appStyles.viewWrapper,
        ...appStyles.container,
        paddingTop: 0,
      })}
    >
      {!store.user.isPremiumMember && showAds && (
        <View
          style={css({
            backgroundColor: "transparent",
            marginBottom: spacings.regular,
            justifyContent: "center",
            flexDirection: "row",
          })}
        >
          <AdMobBanner
            bannerSize="smartBannerPortrait"
            adUnitID={adUnitId}
            servePersonalizedAds // true or false
            onDidFailToReceiveAdWithError={(error) => {
              console.log("Error", error);
            }}
          />
        </View>
      )}

      {group && <QueryRangeSelector group={group} />}

      {hasTasks && (
        <ScrollProvider
          contentContainerStyle={appStyles.scrollview}
          refreshControl={
            <RefreshControl
              refreshing={store.feed.items.refreshing}
              onRefresh={() => {
                store.ui.setEditTaskId(undefined);
                store.loadFeedForDate(range, groupId);
              }}
            />
          }
        >
          <ComingUpFeedList userId={email} groupId={groupId} />
        </ScrollProvider>
      )}
    </View>
  );
};

export default observer(ComingUp);

const NUMBER_OF_FEEDCARDS_BEFORE_ANOTHER_AD_SHOWS = 10;
const ComingUpFeedList: React.FC<{ groupId?: string; userId?: string }> =
  observer(({ groupId, userId }) => {
    const store = useAppStore();
    const allComingUp = store.feed.allComingUp;

    const hasComingUp = allComingUp.length > 0;
    if (!hasComingUp && !store.feed.items.refreshing) {
      return <NoRecentEntriesInFeed comingUp={true} />;
    }

    return (
      <View style={css({ backgroundColor: "transparent" })}>
        <ComingUpFeedListHeader />
        {allComingUp
          .map((id) => store.feed.records[id])
          .sort((a, b) => ascending(a.createdAt, b.createdAt))
          .map((completedTask, i) => {
            if (!completedTask) {
              return null;
            }
            if (groupId && completedTask.groupId !== groupId) {
              return null;
            }

            if (userId && completedTask.createdBy !== userId) {
              return null;
            }
            if (
              i % NUMBER_OF_FEEDCARDS_BEFORE_ANOTHER_AD_SHOWS === 0 &&
              i !== 0
            ) {
              // show card with an ad
              return (
                <View
                  key={`coming-up-feed-${completedTask.id}`}
                  style={css({ backgroundColor: "transparent" })}
                >
                  <FeedCard index={i} completedTask={completedTask} />
                  {!store.user.isPremiumMember && (
                    <View
                      style={css({
                        backgroundColor: "transparent",
                        marginBottom: spacings.regular,
                        justifyContent: "center",
                        flexDirection: "row",
                      })}
                    >
                      <AdMobBanner
                        bannerSize="smartBannerPortrait"
                        adUnitID={adUnitId} // Test ID, Replace with your-admob-unit-id
                        servePersonalizedAds // true or false
                        onDidFailToReceiveAdWithError={(error) => {
                          console.log("Error", error);
                        }}
                      />
                    </View>
                  )}
                </View>
              );
            } else {
              return (
                <FeedCard
                  index={i}
                  key={`coming-up-feed-${completedTask.id}`}
                  completedTask={completedTask}
                />
              );
            }
          })}

        <PaginationComponent pagination={store.feed.items.pagination} />
      </View>
    );
  });

export const ComingUpFeedListHeader: React.FC = observer(() => {
  const store = useAppStore();
  const count = store.feed.allComingUp.length;
  if (count === 0) {
    return null;
  }

  return (
    <View
      style={css({
        backgroundColor: "transparent",
        marginBottom: spacings.regular,
        marginTop: spacings.regular,
      })}
    >
      <Dev messages={[{ value: count.toString(), label: "feed count" }]} />
      <Heading level={1} title={`Coming up`} />
    </View>
  );
});
