import { FontAwesome } from "@expo/vector-icons";
import { ReactNode } from "react";
import { Text, TouchableHighlight } from "react-native";
import { FontAwesomeIcon } from "../../@types/fontAwesomeIcons";
import {
  appStyles,
  colors,
  setAlpha,
  sizes,
  spacings,
} from "../../defaultStyles";
import { css } from "../../lib/glamor-native";
import { View } from "../Themed";

export type ButtonVariantType = "primary" | "secondary" | "tertiary";
export interface ButtonType {
  id?: string;
  onPress: () => void;
  onLongPress?: () => void;
  icon?: ReactNode;
  label?: string;
  iconProps?: {
    name: FontAwesomeIcon;
    size?: number;
    color?: string;
    style?: any;
    position?: "left" | "right";
  };
  variant?: ButtonVariantType;
  accessibilityLabel?: string;
  style?: any;
  disabled?: boolean;
  textStyle?: Object;
}
const Button: React.FC<ButtonType> = ({
  onPress,
  label,
  iconProps,
  variant = "primary",
  accessibilityLabel,
  style,
  icon,
  textStyle = {},
  onLongPress = () => {},
  disabled = false,
}) => {
  const underlayColorSecondary =
    variant !== "secondary" && variant !== "tertiary";
  let styles =
    variant === "secondary"
      ? appStyles.buttonVariant.secondary
      : appStyles.buttonVariant.primary;

  if (variant === "tertiary") {
    styles = {
      ...styles,
      backgroundColor: "transparent",
      borderColor: "transparent",
    };
  }
  if (disabled) {
    styles = {
      ...styles,
      ...{ opacity: 0.3 },
    };
  }

  const showIcon = iconProps !== undefined;
  const showIconOnLeft = iconProps && iconProps.position === "left";
  return (
    <TouchableHighlight
      activeOpacity={0.6}
      underlayColor={
        underlayColorSecondary ? setAlpha(colors.secondary, 0.7) : "transparent"
      }
      style={css({ ...appStyles.button, ...styles, ...style })}
      onLongPress={() => !disabled && onLongPress()}
      onPress={() => !disabled && onPress()}
      accessibilityLabel={accessibilityLabel || ""}
      disabled={disabled}
    >
      <View
        style={css({
          backgroundColor: "transparent",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
        })}
      >
        {icon && icon}
        {showIcon && showIconOnLeft && (
          <View
            style={css({
              backgroundColor: "transparent",
            })}
          >
            <FontAwesome
              name={iconProps.name}
              size={iconProps.size || 25}
              color={iconProps.color || getColorForVariant(variant)}
              style={iconProps.style || {}}
            />
          </View>
        )}
        {label !== undefined && (
          <Text
            style={css({
              marginLeft: spacings.slim,
              marginRight: spacings.slim,
              color: getColorForVariant(variant),
              fontSize: sizes.font.body,
              fontWeight: "bold",
              ...textStyle,
            })}
          >
            {label}
          </Text>
        )}
        {showIcon && !showIconOnLeft && (
          <View
            style={css({
              backgroundColor: "transparent",
            })}
          >
            <FontAwesome
              name={iconProps.name}
              size={iconProps.size || 25}
              color={iconProps.color || getColorForVariant(variant)}
              style={iconProps.style || {}}
            />
          </View>
        )}
      </View>
    </TouchableHighlight>
  );
};
export default Button;

function getColorForVariant(variant: ButtonVariantType) {
  switch (variant) {
    case "primary":
      return colors.onSecondary;
    case "secondary":
      return colors.secondary;
    case "tertiary":
      return colors.secondary;
    default:
      return colors.onBackground;
  }
}
