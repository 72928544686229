import { useNavigation } from "@react-navigation/native";
import { observer } from "mobx-react";
import { TouchableHighlight } from "react-native";
import { Text, View } from "../components/Themed";
import { appStyles, colors, setAlpha, spacings } from "../defaultStyles";
import { css } from "../lib/glamor-native";
import { useAppStore } from "../store/AppStore";
import Group from "../store/Group";
import { MembershipOptionsType } from "../store/UserProfile";
import { ConfirmRemovePendingInvitation } from "./alerts/Confirmation";
import Icon from "./common/Icon";
import { CachedProfileImage } from "./common/ImagePickerComponent";

export const GroupMembersList: React.FC<{ groupId: string }> = observer(
  ({ groupId }) => {
    const store = useAppStore();

    const group = store.groups.records[groupId];
    const members: string[] = group.members || [];
    const pendingMembers: string[] = group.pendingMembers || [];
    const currentUserEmail = store.user.email;
    if (!currentUserEmail) {
      return null;
    }
    return (
      <View
        style={css({
          backgroundColor: "transparent",
          flexDirection: "column",
        })}
      >
        <UserRow
          key={`${currentUserEmail}-me`}
          member={currentUserEmail}
          group={group}
        />
        <View
          style={css({
            flexDirection: "column",
            backgroundColor: colors.background,
          })}
        >
          {members.map((member, i) => {
            if (member === store.user.email) {
              // don't show current logged user in this list
              return null;
            }
            return (
              <UserRow
                key={`confirmed-${member}-${i}`}
                member={member}
                group={group}
              />
            );
          })}
        </View>

        <View
          style={css({
            flexDirection: "column",
            backgroundColor: colors.background,
          })}
        >
          {pendingMembers.map((member, i) => {
            if (member === store.user.email) {
              // don't show current logged user in this list
              return null;
            }
            return (
              <UserRow
                key={`pending-${member}-${i}`}
                member={member}
                group={group}
                pending={true}
              />
            );
          })}
        </View>
      </View>
    );
  }
);

export const UserRow: React.FC<{
  member: string;
  group: Group;
  pending?: boolean;
}> = observer(({ member, group, pending = false }) => {
  const store = useAppStore();
  const navigation = useNavigation();
  const userProfiles = store.userProfiles;
  const groupId = group.groupId;
  const userProfile = userProfiles[member];
  const name = userProfile?.displayName || member;

  return (
    <TouchableHighlight
      activeOpacity={0.6}
      underlayColor="transparent"
      onPress={() => {
        if (member === store.user.email) {
          // don't show current logged user in this list
          return null;
        } else {
          if (!pending) {
            navigation.navigate("GroupMemberActions", {
              groupId,
              email: member,
            });
          } else {
            if (pending) {
              ConfirmRemovePendingInvitation(
                async () => {
                  await store.groups.removePendingMemberFromGroup(
                    groupId,
                    member
                  );
                  await store.groups.removePendingInvitationForUser(
                    groupId,
                    member
                  );
                  await store.updateGroup;
                },
                () => {}
              );
            }
          }
        }
      }}
    >
      <View
        style={css({
          flexDirection: "row",
          backgroundColor: colors.background,
          borderBottomWidth: 1,
          borderBottomColor: setAlpha(colors.onBackground, 0.1),
          paddingTop: spacings.regular,
          paddingBottom: spacings.regular,
          paddingLeft: spacings.default,
          paddingRight: spacings.default,
          alignItems: "center",
          opacity: pending ? 0.5 : 1,
        })}
      >
        <CachedProfileImage imageUrl={userProfile?.profileImageUrl} size={30} />
        <View
          style={css({
            backgroundColor: "transparent",
            flexDirection: "column",
            flex: 1,
            marginLeft: spacings.slim,
          })}
        >
          <View
            style={css({
              backgroundColor: "transparent",
              flexDirection: "row",
              alignItems: "center",
              flex: 1,
              flexWrap: "wrap",
            })}
          >
            <Text
              style={css({
                fontWeight: "bold",
                marginRight: spacings.slim,
              })}
            >
              {name || ""}
            </Text>
            {member === store.user.email && (
              <Text
                style={css({
                  ...appStyles.text.bodyBold,
                  color: colors.secondary,

                  marginRight: spacings.slim,
                })}
              >
                (me)
              </Text>
            )}

            <PremiumTag membership={userProfile?.membership} />
          </View>

          <Text
            style={css({
              marginRight: spacings.relaxed,
              opacity: 0.7,
            })}
          >
            {member}
          </Text>
        </View>

        {group.isUserAdmin(member) && (
          <Text
            style={css({
              ...appStyles.text.body,
              color: colors.secondary,
              marginRight: spacings.relaxed,
              opacity: 0.7,
            })}
          >
            Admin
          </Text>
        )}

        {pending && (
          <Text style={css(appStyles.text.bodyBold)}>Pending accept</Text>
        )}
      </View>
    </TouchableHighlight>
  );
});

export const PremiumTag: React.FC<{
  membership?: MembershipOptionsType;
}> = observer(({ membership }) => {
  const isPremium = membership === "premium";
  if (!isPremium) {
    return null;
  }

  return (
    <View
      style={css({
        backgroundColor: colors.dark,
        paddingLeft: 4,
        paddingRight: 4,
        paddingTop: 2,
        paddingBottom: 2,
        borderRadius: spacings.roundBorderXl,
      })}
    >
      <Text
        style={css({
          ...appStyles.text.premium,
        })}
      >
        <Icon
          name="star"
          size={12}
          color={appStyles.text.premium.color}
          style={{ marginRight: spacings.slim }}
        />
        PREMIUM
      </Text>
    </View>
  );
});
