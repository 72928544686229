import { appName } from "../../../constants/AppDefaults";
import { StartEndTimeRangeType } from "../Time";

export const strings = {
  upgradeToPremium: {
    title: "Upgrade to Premium",
    maxGroupsReached: `You have reached the max amount of groups you can join or create. Upgrade to premium to be able to create an unlimited amount of groups.`,
  },
  buttons: {
    saveChanges: "Save changes",
    createGroup: "Create group",
    upgradeToPremium: "Upgrade to premium",
    cancel: "Cancel",
    removeInvitation: "Remove invitation",
  },
  screens: {
    title: {
      upgradeMembership: "Membership Upgrade",
    },
    empty: (range: StartEndTimeRangeType) => {
      switch (range) {
        case "today":
          return "in the last 24 hours";
        case "pastMonth":
          return "in the past month";
        case "inRange":
          return "in the selected date range";
        case "recents":
          return "recently";
        default:
          return "ever";
      }
    },
  },
  confirmation: {
    deleteGroup:
      "Are you sure you want to delete this group? All tasks and history will be deleted. You can't undo this later",
    leaveGroup:
      "Are you sure you want to leave this group? You will no longer be able to see data from this group",
    deleteCompletedTask: "Are you sure you want to delete this task?",
    deleteTask:
      "Are you sure you want to delete this task? Other users will no longer be able to see this event in their logs.",
    rejectInvitation: "Closing this will reject this invitation",
  },
  errors: {
    unableToJoinGroup: "Unable to join this group at this time",
  },
  notifications: {
    pendingGroupInvitation: "Group Invitation",
    noEntriesInFeed: "No events loged",
    noUpcomingEvents: "No upcoming events",
  },
  messages: {
    tracking: "How will this group to be tracked?",
    forgotPasswordLogin: `Enter the email address for your ${appName} account.`,
    login: `Sign in with your account details`,
    onlyInvitedUsersCanJoin: "Only invited users can join",
    anyoneWithLinkCanJoin: "Anyone with a link can join",
    successfully: {
      submittedReport: "You submitted this report successfully.",
      joinedGroup:
        "You have successfully joined this group. This group will appear on your groups list now.",
      updatedProfile:
        "Profile updated successfully. New profile picture will be avaiable next time you login into the app",
      updatedProfilePhoto:
        "New profile picture will be avaiable next time you login into the app",
    },
    removePendingInvitation:
      "This user has not yet accepted to join this group. Would you like to remove the invitation?",
    createGroup: "Create a group to start",
    groupInvitationLoginFirst:
      "It looks like someone is trying to invite you to join a group. Login to be able to accept their invitation.",
  },
};
