import { useNavigation, useRoute } from "@react-navigation/native";
import { observer } from "mobx-react";
import {
  RefreshControl,
  ScrollView,
  Text,
  TouchableHighlight,
  useWindowDimensions,
} from "react-native";
import { AlertupgradeToPremiumMaxGroupsLimitReached } from "../components/alerts/Confirmation";
import Button from "../components/common/Button";
import Icon from "../components/common/Icon";
import { ProTip } from "../components/common/ProTip";
import { SeparatorSlim } from "../components/common/Separator";
import { View } from "../components/Themed";
import { appStyles, spacings } from "../defaultStyles";
import { css } from "../lib/glamor-native";
import { useAppStore } from "../store/AppStore";
import { permissions } from "../store/auth/Permissions";
import { strings } from "../store/common/i18n/strings";
import {
  templatesById,
  TemplateTaskType,
  TemplateType,
} from "../store/data/templates";
import { getInputLabelForGroupType } from "../store/Task";

const TemplateDetailsScreen: React.FC = () => {
  const store = useAppStore();
  const route = useRoute();
  //@ts-ignore
  const templateId = route.params?.templateId;
  const template = templatesById[templateId];
  if (!template) {
    return null;
  }
  return (
    <View style={css(appStyles.container)}>
      <ScrollView
        contentContainerStyle={css(appStyles.scrollview)}
        refreshControl={
          <RefreshControl
            refreshing={store.groups.refreshing}
            onRefresh={() => store.refresh()}
          />
        }
      >
        <TemplateCard template={template} rightOffset={20} />
      </ScrollView>
    </View>
  );
};

export default observer(TemplateDetailsScreen);

export const TemplateCard: React.FC<{
  template: TemplateType;
  rightOffset?: number;
}> = observer(({ template, rightOffset = 50 }) => {
  const store = useAppStore();
  const dimensions = useWindowDimensions();
  const navigation = useNavigation();

  return (
    <View
      style={css({
        ...appStyles.card,
        flex: 1,
        width: dimensions.width - rightOffset,
      })}
    >
      <Text style={css(appStyles.heading.level1)}>
        {template?.title || "Unkonwn title"}
      </Text>
      <Text
        style={css({
          ...appStyles.text.bodyFaded,
          marginBottom: spacings.regular,
        })}
      >
        {template.description}
      </Text>
      <Button
        variant="secondary"
        iconProps={{ name: "download" }}
        label="Chose template"
        onPress={() => {
          if (permissions.canCreateGroup()) {
            store.processTemplate(template.id);
          } else {
            if (AlertupgradeToPremiumMaxGroupsLimitReached) {
              AlertupgradeToPremiumMaxGroupsLimitReached(
                () => {
                  // update to premium
                  navigation.navigate("UpdateMembership", {
                    email: store.user.email || "Guest",
                  });
                },
                () => {}
              );
            } else {
              alert(strings.upgradeToPremium.maxGroupsReached);
            }
          }
        }}
      />
      <SeparatorSlim />
      <TemplateCardMetadata template={template} />
      <SeparatorSlim />
      <TemplateCardTasks template={template} />
    </View>
  );
});

export const TemplateCardMetadata: React.FC<{ template: TemplateType }> =
  observer(({ template }) => {
    return (
      <View
        style={css({
          backgroundColor: "transparent",
          flexDirection: "row",
        })}
      >
        <View
          style={css({
            backgroundColor: "transparent",
            flex: 0.5,
          })}
        >
          <LabelBox
            title="Tracked by"
            value={getInputLabelForGroupType(template.type, template.unit)}
          />
        </View>

        <View
          style={css({
            backgroundColor: "transparent",
            flex: 0.5,
          })}
        >
          <LabelBox
            title="Recomended for"
            value={template.recommendedFor || "Anyone"}
          />
        </View>
      </View>
    );
  });

export const TemplateCardTasks: React.FC<{ template: TemplateType }> = observer(
  ({ template }) => {
    return (
      <View
        style={css({
          backgroundColor: "transparent",
          flex: 1,
        })}
      >
        <Text style={css(appStyles.heading.level2)}>Tasks</Text>
        <Text
          style={css({
            ...appStyles.text.bodyFaded,
            marginBottom: spacings.default,
          })}
        >
          There will be {template.tasks.length} tasks pre-filled for you.
          Unselect if not needed. You will be able to add more or edit these
          later.
        </Text>

        <ScrollView>
          {template.tasks.map((task) => {
            return <TemplateTaskRow key={task.id} task={task} />;
          })}
          {template.observations && <ProTip message={template.observations} />}
        </ScrollView>
      </View>
    );
  }
);

export const TemplateTaskRow: React.FC<{ task: TemplateTaskType }> = observer(
  ({ task }) => {
    const store = useAppStore();
    return (
      <TouchableHighlight
        activeOpacity={0.6}
        underlayColor="transparent"
        onPress={() => {
          store.templatePicker.onTaskPress(task);
        }}
      >
        <View
          style={css({
            backgroundColor: "transparent",
            flexDirection: "row",
            flex: 1,
            marginTop: spacings.slim,
            marginBottom: spacings.slim,
          })}
        >
          <Icon
            name={
              store.templatePicker.selectedTasks[task.id] === true
                ? "check-circle-o"
                : "circle-o"
            }
          />

          <View
            style={css({
              backgroundColor: "transparent",
              marginLeft: spacings.regular,
            })}
          >
            <Text style={css(appStyles.text.bodyBold)}>{task.title}</Text>
            <Text style={css(appStyles.text.smallFaded)}>
              {task.description}
            </Text>
          </View>
        </View>
      </TouchableHighlight>
    );
  }
);

export const LabelBox: React.FC<{ title: string; value: string }> = observer(
  ({ title, value }) => {
    return (
      <View
        style={css({
          backgroundColor: "transparent",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        })}
      >
        <Text style={css(appStyles.text.smallFaded)}>{title}</Text>
        <Text
          style={css({
            ...appStyles.heading.level2,
            flexWrap: "wrap",
            flexDirection: "row",
            textAlign: "center",
          })}
        >
          {value}
        </Text>
      </View>
    );
  }
);
