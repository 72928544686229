import { DocumentData } from "firebase/firestore";
import { makeAutoObservable } from "mobx";
import Comment, { CommentType } from "./common/Comment";
import Tags from "./common/Tags";
import { Firestore } from "./Firestore";
import Reactions from "./Reaction";

export interface CompletedTaskType {
  allowScoreOverwrite?: boolean;
  assignedTo?: string;
  createdAt: number;
  createdBy: string;
  endedAt?: number;
  groupId: string;
  id: string;
  notes: string;
  score: number;
  tags?: string[];
  hashtags?: string[];
  taskId: string;
  updatedAt?: string;
}

export default class CompletedTask {
  allowScoreOverwrite: boolean;
  api: Firestore;
  assignedTo?: string;
  createdAt: number;
  createdBy: string;
  endedAt?: number;
  fetched: boolean;
  groupId: string;
  id: string;
  notes?: string;
  reactions: Reactions;
  refreshing: boolean;
  score: number;
  tags: Tags;
  hashtags: Tags;
  taskId: string;
  updatedAt?: string;

  // ui helper
  commentText?: string;

  // as helpers for search
  title?: string;
  description?: string;
  comments: { [commentId: string]: Comment };

  constructor(
    api: Firestore,
    task: CompletedTaskType,
    title?: string,
    description?: string
  ) {
    this.assignedTo = task.assignedTo;
    this.id = task.id || task.taskId;
    this.groupId = task.groupId;
    this.tags = new Tags(task.tags);
    this.taskId = task.taskId;
    this.hashtags = new Tags(task.hashtags);
    this.api = api;
    this.score = 0;
    if (typeof task.score === "string") {
      this.score = parseFloat(task.score);
    } else {
      this.score = task.score || 0;
    }
    this.createdBy = task.createdBy;
    this.updatedAt = task.updatedAt;
    this.notes = task.notes;
    this.endedAt = task.endedAt;
    this.reactions = new Reactions(this.id);
    this.comments = {};
    this.allowScoreOverwrite = task.allowScoreOverwrite || false;
    this.createdAt = task.createdAt;
    this.fetched = false;
    this.refreshing = false;
    // helpers
    this.title = title;
    this.description = description;

    // comment editor
    this.commentText = "";
    makeAutoObservable(this);
  }

  get commentsList(): Comment[] {
    return Object.keys(this.comments).map((key) => this.comments[key]) || [];
  }

  setAssignedTo = (value: string) => {
    this.assignedTo = value;
  };

  setScore = (value: number) => {
    this.score = value;
  };

  setNotes = (value: string) => {
    this.notes = value;
  };

  setRefreshing = (value: boolean) => {
    this.refreshing = value;
  };

  setTaskId = (value: string) => {
    this.taskId = value;
  };

  setGroupId = (value: string) => {
    this.groupId = value;
  };

  setEndedAt = (value: number) => {
    this.endedAt = value;
  };

  setTitle = (value: string) => {
    this.title = value;
  };

  setCommentText = (value: string) => {
    this.commentText = value;
  };

  setDescription = (value: string) => {
    this.description = value;
  };

  loadTaskId = async (taskId: string) => {
    this.api.fetch("tasks", taskId).then((data) => {
      if (data?.description) {
        this.setDescription(data.description);
      }

      if (data?.title) {
        this.setTitle(data.title);
      }
    });
  };

  setAllowScoreOverwrite = (value?: boolean) => {
    if (value === undefined) {
      this.allowScoreOverwrite = false;
    } else {
      this.allowScoreOverwrite = value;
    }
  };

  setCreatedAt = (value: number) => {
    this.createdAt = value;
  };

  setFetched = (value: boolean) => {
    this.fetched = value;
  };

  update = (task: CompletedTaskType) => {
    this.assignedTo = task.assignedTo;
    this.id = task.id || task.taskId;
    this.groupId = task.groupId;
    this.taskId = task.taskId;

    this.score = 0;
    if (typeof task.score === "string") {
      this.score = parseFloat(task.score);
    } else {
      this.score = task.score || 0;
    }
    this.createdBy = task.createdBy;
    this.updatedAt = task.updatedAt;
    this.notes = task.notes;
    this.reactions = new Reactions(this.id);
    this.allowScoreOverwrite = task.allowScoreOverwrite || false;
    this.createdAt = task.createdAt;
    this.endedAt = task.endedAt;
  };

  fetchLikes = async (api: Firestore) => {
    const likes = await this._fetchLikes(api, this.id);
    const liked: string[] = [];
    likes?.forEach((doc: DocumentData) => {
      liked.push(doc.data().email);
    });
    this.reactions.setLikes(liked);
  };

  fetchComments = async (api: Firestore, force?: boolean) => {
    this.setRefreshing(true);
    console.log("fetch comments", this.id);
    if (!this.fetched || force) {
      const comments = await this._fetchComments(api, this.id);

      comments?.forEach((doc: DocumentData) => {
        this.addComment(api, doc.data());
      });
      this.setRefreshing(false);
      this.setFetched(true);
    } else {
      this.setRefreshing(false);
    }
  };

  addComment = (api: Firestore, comment: CommentType) => {
    this.comments[comment.id] = new Comment(comment);
    this._addComment(api, this.id, comment);
  };

  removeComment = (commentId: string) => {
    if (this.comments[commentId]) {
      delete this.comments[commentId];
    }
  };

  _fetchComments = async (api: Firestore, completedTaskId: string) => {
    return api?.fetch(`completedTasks/${completedTaskId}/comments`);
  };

  _addComment = async (
    api: Firestore,
    completedTaskId: string,
    comment: CommentType
  ) => {
    await api?.set(
      "completedTasks",
      `${completedTaskId}/comments/${comment.id}`,
      comment
    );
  };

  static _removeComment = async (
    api: Firestore,
    completedTaskId: string,
    commentId: string
  ) => {
    await api?.delete(
      "completedTasks",
      `${completedTaskId}/comments/${commentId}`
    );
  };

  _fetchLikes = async (api: Firestore, id: string) => {
    return await api.fetch(`completedTasks/${id}/likes`);
  };

  _like = async (api: Firestore, email: string, id: string) => {
    await api.set("completedTasks", `${id}/likes/${email}`, {
      email,
    });
  };

  _removeLike = async (api: Firestore, email: string, id: string) => {
    await api.delete("completedTasks", `${id}/likes/${email}`);
  };
}
