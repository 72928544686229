import { FontAwesome } from "@expo/vector-icons";
import { ReactNode } from "react";
import { Text, TouchableHighlight, View } from "react-native";
import { FontAwesomeIcon } from "../../@types/fontAwesomeIcons";
import { appStyles, borderRadius, colors, spacings } from "../../defaultStyles";
import { css } from "../../lib/glamor-native";

export const Tag: React.FC<{
  label: string;
  round?: boolean;
  backgroundColor?: string;
  color?: string;
  icon?: ReactNode;
  onDelete?: () => void;
  style?: Object;
  iconProps?: {
    name: FontAwesomeIcon;
    size?: number;
    color?: string;
    style?: object;
  };
}> = ({
  round = false,
  label,
  backgroundColor = colors.secondary,
  color = colors.onSecondary,
  style,
  iconProps,
  onDelete,
  icon,
}) => {
  return (
    <View
      style={css({
        flexDirection: "row",
        alignItems: "center",
        backgroundColor,
        justifyContent: "center",
        borderRadius: round ? borderRadius.size4 : spacings.roundBorderXl,
        borderWidth: 1,
        borderColor: backgroundColor,
        paddingLeft: spacings.regular,
        paddingRight: spacings.regular,
        paddingTop: spacings.small,
        paddingBottom: spacings.small,
        marginRight: spacings.regular,
        ...style,
      })}
    >
      {icon && icon}
      {iconProps && (
        <View
          style={css({
            backgroundColor: "transparent",
          })}
        >
          <FontAwesome
            name={iconProps.name}
            size={iconProps.size || 25}
            color={iconProps.color ? iconProps.color : color}
            style={iconProps.style || { marginRight: spacings.regular }}
          />
        </View>
      )}
      <Text
        style={css({
          ...appStyles.text.smallBold,
          color,
        })}
      >
        {label}
      </Text>
      {onDelete && (
        <TouchableHighlight onPress={() => onDelete()} accessibilityLabel={""}>
          <FontAwesome
            name="close"
            size={25}
            color={color}
            style={{ marginLeft: 15 }}
          />
        </TouchableHighlight>
      )}
    </View>
  );
};
