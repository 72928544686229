import { observer } from "mobx-react";
import { Pressable, Text, View } from "react-native";
import { appStyles, colors, PillStyle } from "../../defaultStyles";
import { css } from "../../lib/glamor-native";
import { Entypo } from "@expo/vector-icons";
import { ReactNode } from "react";

const FilterPill: React.FC<{
  icon?: ReactNode;
  label: string;
  onPress: () => void;
}> = observer(({ label, onPress, icon }) => {
  return (
    <View style={css({ backgroundColor: "transparent" })}>
      <Pressable onPress={onPress}>
        <View style={css(PillStyle)}>
          {icon && icon}
          <Text
            style={css({
              ...appStyles.text.smallBold,
              color: colors.onSurface200,
            })}
          >
            {label}
          </Text>
          <Entypo name="chevron-down" size={20} color={colors.onSurface200} />
        </View>
      </Pressable>
    </View>
  );
});

export default FilterPill;
