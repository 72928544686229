import { Text, View } from "../components/Themed";
import { observer } from "mobx-react";
import { useAppStore } from "../store/AppStore";
import { css } from "../lib/glamor-native";
import { appStyles, sizes, spacings } from "../defaultStyles";
import { Bubble } from "../components/common/graphics/Bubble";
import EmptyGroupsScreen from "./EmptyGroupsScreen";
import { ButtonCreateTask } from "../components/Buttons";

const EmptyTasksScreen: React.FC<{ userEmail?: string; groupId?: string }> = ({
  userEmail,
  groupId,
}) => {
  const store = useAppStore();
  const hasGroups = store.groups.length;

  if (!hasGroups) {
    return <EmptyGroupsScreen />;
  }

  let explainTheSituation = "Anyone in the group can";
  if (userEmail) {
    const displayName = store.userProfiles[userEmail]?.displayName || userEmail;
    explainTheSituation = `${displayName} will be able to`;
  }
  return (
    <View
      style={css({
        ...appStyles.viewWrapper,
        ...appStyles.container,
        alignItems: "center",
        justifyContent: "center",
      })}
    >
      <View
        style={css({
          alignItems: "center",
          justifyContent: "center",
          marginTop: spacings.default,
          marginBottom: spacings.default,
          backgroundColor: "transparent",
        })}
      >
        <Bubble
          message="Create"
          id="create-groups"
          width={200}
          height={200}
          fontSize={40}
        />
      </View>
      <Text
        style={css({
          ...appStyles.heading.level0,
          marginBottom: spacings.default,
          textAlign: "center",
        })}
      >
        There are no events yet
      </Text>
      <Text style={css({ fontSize: sizes.font.body })}>
        Create generic events
      </Text>
      <Text style={css({ fontSize: sizes.font.body })}>
        Assign default score or values
      </Text>
      <Text
        style={css({
          ...appStyles.text.body,
          textAlign: "center",
          marginTop: spacings.slim,
        })}
      >
        {explainTheSituation} select from the list and start tracking
      </Text>

      <ButtonCreateTask groupId={groupId} />
    </View>
  );
};

export default observer(EmptyTasksScreen);
