import { useNavigation, useRoute } from "@react-navigation/native";
import { observer } from "mobx-react-lite";
import {
  View,
  ScrollView,
  KeyboardAvoidingView,
  Platform,
  Text,
  Pressable,
} from "react-native";
import { appStyles, NEON_GREEN, spacings, tableStyles } from "../defaultStyles";
import { css } from "../lib/glamor-native";
import { AntDesign } from "@expo/vector-icons";
import { FilterSelectorOption } from "../types";

function FilterSelectorOptions() {
  return (
    <View style={css({ ...appStyles.viewWrapper, ...appStyles.container })}>
      <ScrollView contentContainerStyle={css(appStyles.scrollview)}>
        <KeyboardAvoidingView
          behavior={Platform.OS === "ios" ? "padding" : "height"}
          style={css(appStyles.container)}
        >
          <Options />
        </KeyboardAvoidingView>
      </ScrollView>
    </View>
  );
}

export default observer(FilterSelectorOptions);

const Options: React.FC = observer(() => {
  const route = useRoute();

  //@ts-ignore
  const options = route.params?.options;
  if (!options) {
    return null;
  }
  //@ts-ignore
  const selectedId = route.params?.selectedId;

  return (
    <View
      style={css({
        ...appStyles.card,
        flexDirection: "column",
        paddingLeft: 0,
        paddingRight: 0,
      })}
    >
      <Text
        style={css({
          ...appStyles.heading.level2,
          marginLeft: spacings.default,
        })}
      >
        Filter
      </Text>
      {options.map((item: FilterSelectorOption) => {
        return (
          <Row key={item.id} item={item} selected={selectedId === item.id} />
        );
      })}
    </View>
  );
});

const Row: React.FC<{
  selected: boolean;
  item: { id: string; label: string; action?: () => void };
}> = observer(({ item, selected }) => {
  const navigation = useNavigation();
  return (
    <Pressable
      style={({ pressed }) =>
        css({
          ...tableStyles.row(pressed),
        })
      }
      onPress={() => {
        if (item.action) {
          item.action();
        }

        navigation.goBack();
      }}
    >
      <Text
        style={css({ ...appStyles.text.body, marginRight: spacings.regular })}
      >
        {item.label}
      </Text>
      {selected && (
        <AntDesign name="checkcircle" size={24} color={NEON_GREEN} />
      )}
    </Pressable>
  );
});
