import { useNavigation } from "@react-navigation/native";
import { StatusBar } from "expo-status-bar";
import { observer } from "mobx-react";
import { KeyboardAvoidingView, Platform, ScrollView } from "react-native";
import "react-native-get-random-values";
import { v4 as uuid } from "uuid";
import Button from "../components/common/Button";
import Input from "../components/common/Input";
import { getInputLabelForGroupType } from "../store/Task";
import { Text, View } from "../components/Themed";
import { appStyles, colors, sizes, spacings } from "../defaultStyles";
import { OptionSelector, useAppStore } from "../store/AppStore";
import { FormOptions } from "../store/common/form/FormStore";
import { css } from "../lib/glamor-native";
import { Tag } from "../components/common/Tag";
import Dropdown from "../components/common/Dropdown";
import RadioButton from "../components/common/RadioButton";
import { useHeaderHeight } from "@react-navigation/elements";
import { isGroupTrackedByCreationDate, isGroupTrackedByTime } from "../utils";
import { ProTip } from "../components/common/ProTip";
import { TimeInput } from "../components/common/TimePicker";

const AddTaskScreen: React.FC = () => {
  const headerHeight = useHeaderHeight();

  return (
    <View style={css({ ...appStyles.viewWrapper, ...appStyles.container })}>
      <KeyboardAvoidingView
        keyboardVerticalOffset={headerHeight}
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        style={css(appStyles.container)}
      >
        <ScrollView contentContainerStyle={css(appStyles.scrollview)}>
          <AddTaskView />
        </ScrollView>
      </KeyboardAvoidingView>
    </View>
  );
};

export default observer(AddTaskScreen);

export const AddTaskView: React.FC = observer(() => {
  const store = useAppStore();
  const navigation = useNavigation();
  const form = store.forms.getForm(FormOptions.tasks);
  const isEditing = form.options.editMode;
  const fields = form.fields;
  if (!fields) {
    return null;
  }
  const groupId = fields.groupId.value;
  if (!groupId && isEditing) {
    return null;
  }

  const group = store.groups.records[groupId];
  const groupType = group?.type;
  const hasGroupSelected = form.options.hasGroupSelected;
  const time = form.options.time;
  const unit = group?.unit || "";
  return (
    <View style={css(appStyles.container)}>
      <ProTip message="Create a generic title and description. This allows anyone to select the same event multiple times" />
      <View
        style={css({
          backgroundColor: colors.surface,
          padding: spacings.regular,
          borderRadius: spacings.roundBorderXl,
        })}
      >
        {isEditing && (
          <View
            style={css({
              flexDirection: "row",
              backgroundColor: "transparent",
            })}
          >
            <Tag label={group.title} />
          </View>
        )}
        {/* users cannot update task group after created */}
        {!isEditing && (
          <View
            style={{
              flexDirection: "row",
              padding: spacings.slim,
              backgroundColor: "transparent",
            }}
          >
            {!hasGroupSelected && <GroupSelector groupId={groupId} />}
            {hasGroupSelected && <Tag label={group.title} />}
          </View>
        )}

        {/* Update title */}
        <View
          style={{
            flexDirection: "row",
            padding: spacings.slim,
            marginTop: spacings.relaxed,
            backgroundColor: "transparent",
          }}
        >
          <View
            style={{
              flex: 1,
              paddingRight: spacings.slim,
              backgroundColor: "transparent",
            }}
          >
            <Input
              label="Title"
              onChangeText={(value) => {
                fields.title.set(value);
              }}
              value={fields.title.value}
              placeholder="Title"
              required
            />
          </View>
        </View>

        <View
          style={{
            flexDirection: "row",
            padding: spacings.slim,
            backgroundColor: "transparent",
          }}
        >
          <View style={{ flex: 1, backgroundColor: "transparent" }}>
            <Input
              multiline={true}
              label="Description"
              onChangeText={(value) => {
                fields.description.set(value);
              }}
              value={fields.description.value}
              placeholder="Description"
              numberOfLines={4}
            />
          </View>
        </View>

        {/* Update score / points / currency */}
        {!isGroupTrackedByCreationDate(groupType) && (
          <View
            style={css({
              backgroundColor: "transparent",
              alignItems: "center",
              justifyContent: "flex-start",
              marginLeft: spacings.slim,
              flexDirection: "column",
            })}
          >
            <View
              style={{
                backgroundColor: "transparent",
                flexDirection: "row",
              }}
            >
              {!isGroupTrackedByTime(groupType) && (
                <Input
                  required
                  keyboardType={"decimal-pad"}
                  label={getInputLabelForGroupType(groupType, unit)}
                  onChangeText={(value) => {
                    fields.score.set(value);
                  }}
                  value={
                    fields.score.value
                      ? fields.score.value.toString()
                      : undefined
                  }
                  placeholder={getInputLabelForGroupType(groupType, unit)}
                  inputTextStyle={{
                    textAlign: "center",
                  }}
                />
              )}

              {isGroupTrackedByTime(groupType) && (
                <TimeInput
                  label="Default time"
                  time={time}
                  onChange={() => {}}
                />
              )}
            </View>

            <View
              style={{
                backgroundColor: "transparent",
                marginLeft: spacings.regular,
              }}
            >
              <RadioButton
                selected={fields.allowScoreOverwrite.value === true}
                onPress={() => {
                  fields.allowScoreOverwrite.set(
                    !fields.allowScoreOverwrite.value
                  );
                }}
                textStyle={{
                  fontSize: sizes.font.body,
                  fontWeight: "normal",
                }}
                label="Allow to be changed"
              />
            </View>
          </View>
        )}
        <View
          style={{
            marginTop: spacings.relaxed,
            backgroundColor: "transparent",
          }}
        >
          <Button
            disabled={!form.isValid}
            iconProps={{
              name: isEditing ? "check" : "plus-circle",
            }}
            label={isEditing ? "Save changes" : "Create"}
            variant="primary"
            onPress={() => {
              const data = form.getData;
              let score = data.score;

              if (groupType === "time") {
                score = form.options.time.seconds;
              }

              if (isEditing) {
                const updates = {
                  taskId: data.taskId,
                  title: data.title,
                  description: data.description || "",
                  score,
                  groupId: data.groupId,
                  createdBy: data.createdBy,
                  allowScoreOverwrite: data.allowScoreOverwrite,
                };
                store.updateTask(data.taskId, updates);
              } else {
                const taskId = uuid();

                store.createTask({
                  taskId,
                  groupId: fields.groupId.value,
                  title: fields.title.value,
                  description: fields.description.value,
                  score,
                  createdBy: store.user.email || "Unknown",
                  allowScoreOverwrite: fields.allowScoreOverwrite.value,
                });
              }
              if (navigation.canGoBack()) {
                navigation.goBack();
              } else {
                navigation.navigate("Root");
              }
            }}
          />
        </View>
      </View>

      {/* Use a light status bar on iOS to account for the black space above the modal */}
      <StatusBar style={Platform.OS === "ios" ? "light" : "auto"} />
    </View>
  );
});

export const GroupSelector: React.FC<{ groupId: string }> = observer(
  ({ groupId }) => {
    const store = useAppStore();
    const form = store.forms.getForm(FormOptions.tasks);
    const groupOptions: OptionSelector[] = Object.keys(
      store.groups.records
    ).map((key) => ({
      label: store.groups.records[key].title,
      value: store.groups.records[key].groupId,
    }));
    const fields = form.fields;
    if (!fields) {
      return null;
    }

    const group = store.groups.records[groupId];
    const groupType = group?.type;

    return (
      <View style={css({ backgroundColor: "transparent" })}>
        <View style={{ flex: 1, backgroundColor: "transparent" }}>
          <View
            style={{
              flexDirection: "row",
              marginBottom: spacings.relaxed,
              backgroundColor: "transparent",
            }}
          >
            <Text
              style={css({
                ...appStyles.heading.level2,
                marginBottom: spacings.regular,
              })}
            >
              Group
            </Text>
            <Text
              style={{
                marginLeft: spacings.slim,
                color: colors.required,
                fontWeight: "bold",
                fontSize: sizes.font.body,
              }}
            >
              *
            </Text>
          </View>
          <Dropdown
            defaultButtonText="Select group"
            defaultValue={fields.groupName.value || undefined}
            groupOptions={groupOptions}
            onSelect={(selectedItem, index) => {
              if (groupType === "timestamp") {
                // set default score to one
                fields.score.set(1);
              }
              fields.groupId.set(groupOptions[index].value);
              fields.groupName.set(groupOptions[index].label);
            }}
          />
        </View>
      </View>
    );
  }
);
