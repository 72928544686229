import { observer } from "mobx-react";
import { View } from "react-native";
import Svg, { G, Path } from "react-native-svg";
import { css } from "../../../lib/glamor-native";

const ColmLogo: React.FC<{ size: number; id: string }> = observer(
  ({ size, id }) => {
    const colors = [
      "#F4F406",
      "#005939",
      "#D92F98",
      "#003558",
      "#AC26D8",
      "#5E0B37",
      "#356EC4",
      "#170136",
      "#64D9EB",
      "#61120B",
      "#005400",
      "#9EF84D",
    ];
    return (
      <View
        style={css({
          backgroundColor: "transparent",
          width: size,
          height: size,
        })}
      >
        <Svg id={id} x="0px" y="0px" viewBox="0 0 284 266">
          <G>
            {/* <Path
              fill={colors[0]}
              d="M232,106.6c-10.6-15.2-22.9-25.4-23.8-26.2c-2.8-2.3-6.4-3.1-9.7-2.5c-0.8-0.7-1.7-1.4-2.7-2
		c-17.2-11-48.8-3.1-59.5,0.9c-5.6,2.1-11.7-0.3-14.6-5.2c-0.3-0.6-0.6-1.2-0.9-1.8c-1-2.8-1-5.7,0-8.3c7.7-2.8,16.5-1.7,26.9,3.3
		c6,2.9,13.1,0.3,16-5.6c2-4.2,1.4-8.9-1.2-12.4c1.1-0.1,2.2-0.2,3.4-0.3c-3.7-9.9,0.2-21.4,9.7-26.9c10.6-6.1,24.2-2.5,30.3,8.1
		c4.7,8.2,3.6,18.2-2.1,25.1c1.7,0.8,3.3,1.7,4.9,2.7c14.5,9.3,22.7,24.5,24.2,45.3C233.1,102.9,232.8,104.8,232,106.6z"
            />
            <Path
              fill={colors[1]}
              d="M232,106.6c-1.7,4-5.5,6.9-10.1,7.3c-6.6,0.5-12.4-4.4-12.9-11c-0.9-11.5-4.3-19.7-10.5-24.9
		c3.3-0.6,6.9,0.2,9.7,2.5C209.2,81.1,221.5,91.4,232,106.6z"
            />
            <Path
              fill={colors[2]}
              d="M270.2,134.6c-4.7,8.2-14,12.2-22.8,10.8c0.1,1.8,0.1,3.7,0.1,5.6c-0.8,17.2-9.9,31.9-27.1,43.7
		c-1.6,1.1-3.5,1.8-5.3,2c7.8-16.8,10.6-32.5,10.8-33.7c0.6-3.6-0.5-7.1-2.7-9.7c0.2-1.1,0.3-2.2,0.4-3.3
		c0.9-20.4-21.7-43.8-30.5-51.1c-4.6-3.8-5.6-10.3-2.7-15.2c0.3-0.6,0.7-1.1,1.2-1.6c1.9-2.3,4.4-3.7,7.1-4.2
		c6.2,5.2,9.7,13.4,10.5,24.9c0.5,6.6,6.3,11.6,12.9,11c4.6-0.3,8.4-3.3,10.1-7.3c0.6,0.9,1.3,1.9,1.9,2.8c6.8-8.2,18.6-10.5,28.1-5
		C272.7,110.5,276.3,124,270.2,134.6z"
            /> */}
            <Path
              fill={colors[3]}
              d="M225.8,163c-0.2,1.2-2.9,16.9-10.8,33.7c-4.3,0.5-8.7-1.3-11.4-5.1c-3.7-5.5-2.3-12.9,3.1-16.7
		c9.5-6.5,14.9-13.6,16.3-21.6C225.3,155.8,226.4,159.4,225.8,163z"
            />
            <Path
              fill={colors[4]}
              d="M232,221.6c0,12.3-9.9,22.2-22.2,22.2c-9.5,0-17.6-6-20.8-14.4c-1.5,1-3.1,2-4.8,2.9
		c-15.3,7.9-32.5,7.4-51.4-1.7c-1.8-0.9-3.3-2.1-4.4-3.6c18.4-1.6,33.5-7.1,34.6-7.5c3.4-1.3,5.9-4,7.1-7.2c1-0.4,2-0.8,3-1.3
		c18.1-9.4,27.1-40.7,29-52c1-5.8,6.1-10,11.8-10c0.7,0,1.3,0,2,0.2c2.9,0.5,5.4,2,7.2,4.1c-1.4,8-6.8,15.1-16.3,21.6
		c-5.5,3.7-6.9,11.2-3.1,16.7c2.6,3.8,7.1,5.7,11.4,5.1c-0.5,1-1,2-1.5,3C224,201.5,232,210.6,232,221.6z"
            />
            <Path
              fill={colors[5]}
              d="M170.1,212.3c-1.1,3.2-3.6,5.9-7.1,7.2c-1.1,0.4-16.1,5.9-34.6,7.5c-2.6-3.5-3.2-8.2-1.2-12.4
		c2.9-6,10-8.5,16-5.6C153.6,213.9,162.5,215,170.1,212.3z"
            />
            <Path
              fill={colors[6]}
              d="M170.1,212.3c-7.6,2.8-16.5,1.7-26.9-3.3c-6-2.9-13.1-0.3-16,5.6c-2,4.2-1.4,8.9,1.2,12.4
		c-1.1,0.1-2.2,0.2-3.4,0.3c3.7,10-0.2,21.4-9.7,26.9c-10.6,6.1-24.2,2.5-30.3-8.1c-4.7-8.2-3.6-18.2,2.1-25.1
		c-1.7-0.8-3.3-1.7-4.9-2.7c-14.5-9.2-22.7-24.5-24.3-45.3c-0.2-2,0.2-3.9,0.9-5.6c10.6,15.2,22.9,25.4,23.8,26.2
		c2.8,2.3,6.4,3.1,9.7,2.5c0.8,0.7,1.7,1.4,2.7,2c17.2,11,48.8,3.1,59.5-0.9c5.6-2.1,11.7,0.3,14.6,5.2c0.3,0.6,0.6,1.2,0.9,1.8
		C171.1,206.8,171.1,209.7,170.1,212.3z"
            />
            <Path
              fill={colors[7]}
              d="M92.5,195.9c-3.3,0.6-6.9-0.2-9.7-2.5c-0.9-0.8-13.2-11-23.8-26.2c1.7-4,5.5-6.9,10.1-7.3
		c6.6-0.5,12.4,4.4,12.9,11C82.8,182.5,86.3,190.7,92.5,195.9z"
            />
            <Path
              fill={colors[8]}
              d="M100.8,190.1c-0.3,0.6-0.7,1.1-1.1,1.6c-1.9,2.3-4.4,3.7-7.1,4.2c-6.2-5.2-9.7-13.4-10.5-24.9
		c-0.5-6.6-6.3-11.6-12.9-11c-4.6,0.3-8.4,3.3-10.1,7.3c-0.6-0.9-1.3-1.8-1.9-2.8c-6.8,8.2-18.6,10.5-28.1,5
		c-10.6-6.1-14.2-19.7-8.1-30.3c4.7-8.2,14-12.2,22.8-10.8c-0.1-1.8-0.1-3.7-0.1-5.6c0.8-17.2,9.9-31.9,27.1-43.7
		c1.6-1.1,3.5-1.8,5.3-2c-7.8,16.8-10.6,32.5-10.8,33.7c-0.6,3.6,0.5,7.1,2.7,9.7c-0.2,1.1-0.3,2.2-0.4,3.3
		c-0.9,20.4,21.7,43.8,30.5,51.1C102.6,178.6,103.6,185.1,100.8,190.1z"
            />
            <Path
              fill={colors[9]}
              d="M84.2,98.9c-9.5,6.5-14.9,13.6-16.3,21.6c-2.2-2.6-3.3-6.1-2.7-9.7c0.2-1.2,2.9-16.9,10.8-33.7
		c4.3-0.5,8.7,1.3,11.4,5.1C91.1,87.7,89.7,95.1,84.2,98.9z"
            />
            <Path
              fill={colors[10]}
              d="M163.8,59.2c-2.9,6-10,8.5-16,5.6c-10.4-5-19.2-6.1-26.9-3.3c1.1-3.2,3.6-5.9,7.1-7.2
		c1.1-0.4,16.1-5.9,34.6-7.5C165.1,50.2,165.8,55,163.8,59.2z"
            />
            <Path
              fill={colors[11]}
              d="M162.5,46.8c-18.4,1.6-33.5,7.1-34.6,7.5c-3.4,1.3-5.9,4-7.1,7.2c-1,0.4-2,0.8-3,1.3
		c-18.1,9.4-27.1,40.7-29,52c-1,5.9-6.1,10-11.8,10c-0.7,0-1.3,0-2-0.2c-2.9-0.5-5.4-2-7.2-4.1c1.4-8,6.8-15.1,16.3-21.6
		c5.5-3.7,6.9-11.2,3.1-16.7c-2.6-3.8-7.1-5.7-11.4-5.1c0.5-1,1-2,1.5-3.1C67,72.2,59,63.1,59,52.2C59,39.9,69,30,81.2,30
		c9.5,0,17.6,6,20.7,14.4c1.5-1,3.1-2,4.8-2.8c15.3-7.9,32.6-7.4,51.4,1.7C159.9,44,161.4,45.3,162.5,46.8z"
            />
          </G>
        </Svg>
      </View>
    );
  }
);

export default ColmLogo;
