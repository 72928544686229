import { makeAutoObservable, toJS } from "mobx";
import { getCurrentUTCTimeStamp } from "../../../utils";
import { FormField } from "./FormField";

export interface FormType {
  fields: Record<string, any>;
  id: string;
  options: object;
  required: Array<string>;
  title: string;
  validations: Record<string, (fieldId: string) => boolean>;
  onSubmissionCompleted?: (data?: any) => void;
}

interface ValidationsType {
  isInvalid: boolean;
  isMissing: boolean;
  isRequired: boolean;
  valid: boolean;
  validationStamp: number;
}

interface Options {
  [id: string]: any;
}

export class Form {
  fields: Record<string, any>;
  id: string;
  options: Options;
  required: Array<string>;
  title: string;
  validations: Record<string, (fieldId: string) => boolean>; //{ key: () => boolean}
  validationStamp: number;
  onSubmissionCompleted?: (data?: any) => void;

  constructor(props: FormType) {
    this.onSubmissionCompleted = props.onSubmissionCompleted;
    this.id = props.id;
    this.title = props.title;
    this.fields = {};
    this.createFields(props.fields);
    this.options = props.options;
    this.required = props.required; // required fields
    this.validations = props.validations;
    this.validationStamp = getCurrentUTCTimeStamp();

    makeAutoObservable(this);
  }

  get currentOptions() {
    return this.options || {};
  }

  get currentRequired() {
    if (this.validationStamp && this.required.length > 0) {
      return this.required || [];
    }
    return [];
  }
  get getData(): Record<string, any> {
    const data: Record<string, any> = {};
    Object.keys(this.fields).forEach((key) => {
      data[key] = toJS(this.fields[key].value);
      const currentData = data[key];

      if (currentData) {
        // remove typename from values;
        Object.keys(data[key]).forEach((key) => {
          if (key === "__typename") {
            delete currentData.__typename;
          }
        });
      }
    });
    return data;
  }

  get fieldsStatus() {
    const validationStamp = this.validationStamp;
    const fields = Object.keys(this.fields);
    const required = this.currentRequired.length > 0;
    const fieldsRequiringAttention: Record<string, ValidationsType> = {};
    fields.forEach((field) => {
      let isInvalid = false;
      let isMissing = false;
      const isRequired = required && this.currentRequired.includes(field);
      const fieldValue = this.fields[field].value;
      if (this.validations[field]) {
        isInvalid = this.validations[field](fieldValue);
      }

      isMissing =
        fieldValue === null || fieldValue === undefined || fieldValue === "";
      if (Array.isArray(fieldValue) && fieldValue.length === 0) {
        isMissing = true;
      }
      fieldsRequiringAttention[field] = {
        validationStamp,
        isMissing,
        isRequired,
        isInvalid,
        valid:
          (isRequired && !isMissing && !isInvalid) ||
          (!isRequired && !isInvalid),
      };
    });
    return fieldsRequiringAttention;
  }

  get isValid(): boolean {
    const fields = this.fieldsStatus;
    let invalidFields: any = [];
    if (
      this.validationStamp !== 0 &&
      this.required.length > 0 &&
      Object.keys(fields).length > 0
    ) {
      invalidFields = Object.keys(fields).filter(
        (key) => !this.fieldsStatus[key].valid && this.required.includes(key)
      );
    }
    return invalidFields.length === 0;
  }

  get requiredFields(): Array<string> {
    return this.required;
  }

  get invalidFields() {
    return this.validateRules();
  }

  setOptions = (options: Options) => {
    this.options = options;
  };

  updateStamp = () => {
    this.validationStamp = getCurrentUTCTimeStamp();
  };
  setRequiredFields = (requiredFields: string[]) => {
    this.required = requiredFields;
    this.updateStamp();
  };

  setAdditionalRequiredFields = (
    additionalFields: string[] | null,
    removeFields?: string[]
  ) => {
    if (additionalFields && additionalFields !== null) {
      this.required = [...this.required, ...additionalFields];
    }

    const lookup: Record<string, boolean> = {};
    // remove dups
    this.required = this.required.filter((required) => {
      if (!lookup[required]) {
        lookup[required] = true;
        return true;
      }
      return false;
    });

    removeFields &&
      removeFields.forEach((field: string, i: number) => {
        const found = this.required?.indexOf(field);
        if (found >= 0) {
          delete this.required[found];
        }
      });
  };

  createFields = (fields: Record<string, any>) => {
    Object.keys(fields).forEach((key: string) => {
      const value: any = fields[key];
      this.fields[key] = new FormField({ id: key, value });
    });
  };

  autofill(fields: Record<string, any>) {
    Object.keys(fields).forEach((key: string) => {
      this.fields[key].set(fields[key]);
    });
  }

  clearFields = (): void => {
    Object.keys(this.fields).forEach((key) => {
      this.fields[key].set(null);
    });
  };

  validate(): void {
    this.validationStamp = getCurrentUTCTimeStamp();
  }

  validateRules(): string[] {
    const invalidFields = Object.keys(this.validations).filter((key) => {
      return this.validations[key](this.fields[key].value);
    });
    // return an array of invalid field ids
    return invalidFields || [];
  }

  setOnSubmissionCompleted = (cb?: () => void) => {
    this.onSubmissionCompleted = cb;
  };
}
