import { observer } from "mobx-react";
import { ScrollView, useWindowDimensions } from "react-native";
import { View } from "../components/Themed";
import { appStyles } from "../defaultStyles";
import { css } from "../lib/glamor-native";
import { useAppStore } from "../store/AppStore";
import { templates } from "../store/data/templates";
import { TemplateCard } from "./TemplateDetailsScreen";

const TemplateBrowserScreen: React.FC = () => {
  return (
    <View style={css(appStyles.container)}>
      <ScrollView
        horizontal={true}
        contentContainerStyle={css(appStyles.scrollview)}
      >
        <TemplateBrowserView />
      </ScrollView>
    </View>
  );
};

export default observer(TemplateBrowserScreen);

const TemplateBrowserView: React.FC = observer(() => {
  const dimensions = useWindowDimensions();
  return (
    <View style={css({ ...appStyles.container, flexDirection: "row" })}>
      {templates.map((template) => {
        return (
          <View
            key={template.id}
            style={css({
              backgroundColor: "transparent",
              width: dimensions.width - 30,
            })}
          >
            <TemplateCard template={template} />
          </View>
        );
      })}
    </View>
  );
});
