import { Linking, Pressable, Text, View } from "react-native";
import { appStyles, colors, setAlpha, spacings } from "../../defaultStyles";
import { css } from "../../lib/glamor-native";
import Icon from "./Icon";

export const LinkyfiedText: React.FC<{ text: string }> = ({ text }) => {
  const sentences = text.split("\n");
  const links: string[] = [];
  sentences.forEach((sentence) => {
    const words = sentence.split(" ");
    words.forEach((word) => {
      if (validURL(word)) {
        links.push(word);
      }
    });
  });
  return (
    <View
      style={css({
        backgroundColor: "transparent",
        paddingTop: spacings.regular,
        marginBottom: spacings.slim,
      })}
    >
      {links.map((link, i) => {
        return (
          <Pressable
            key={`link${i}`}
            onPress={() => {
              Linking.openURL(link);
            }}
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Icon name="link" size={12} color={colors.secondary} />
            <Text
              style={css({
                ...appStyles.text.smallBoldFaded,
                marginLeft: spacings.slim,
              })}
            >
              {link}
            </Text>
          </Pressable>
        );
      })}
    </View>
  );
};

function validURL(str: string) {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
}
