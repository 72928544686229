import { FontAwesome } from "@expo/vector-icons";
import { FontAwesomeIcon } from "../../@types/fontAwesomeIcons";
import { colors } from "../../defaultStyles";
import { css } from "../../lib/glamor-native";

export interface IconPropsType {
  name: FontAwesomeIcon;
  size?: number;
  style?: object;
  color?: string;
  variant?: "secondary" | "primary";
}

const Icon: React.FC<IconPropsType> = ({
  name,
  size = 25,
  color,
  style = {},
  variant = "primary",
}) => {
  const isSecondary = variant === "secondary";
  let _color = colors.onBackground;
  if (isSecondary) {
    _color = colors.secondary;
  }
  if (color != undefined) {
    _color = color;
  }
  try {
    return <FontAwesome name={name} size={size} color={_color} style={style} />;
  } catch (err) {
    console.log({ err });
    return null;
  }
};

export default Icon;
