import { Text, View } from "../components/Themed";
import { useAppStore } from "../store/AppStore";
import { RootStackScreenProps } from "../types";
import Button from "../components/common/Button";
import { css } from "../lib/glamor-native";
import { appStyles } from "../defaultStyles";
import { useRoute } from "@react-navigation/native";
import { useEffect } from "react";

export default function NotFoundScreen({
  navigation,
}: RootStackScreenProps<"NotFound">) {
  const store = useAppStore();
  const route = useRoute();
  //@ts-ignore
  const groupId = route.params?.groupId;

  useEffect(() => {
    // if the user hits not found with a group id, it means someone
    // is trying to invite them to a group but they are not logged in
    // let's save this data in the linker so they can be redirected to it when they log in
    if (groupId) {
      store.linker.setData({
        redirectData: { path: "GroupInvitation", queryParams: { groupId } },
      });
      navigation.replace("Root");
    }
  }, [groupId, navigation]);

  return (
    <View
      style={css({
        ...appStyles.container,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      })}
    >
      <Text style={css(appStyles.heading.level0)}>404</Text>
      <Text style={css(appStyles.heading.level2)}>Page Not Found</Text>
      <Button
        label="Go home"
        onPress={() => {
          navigation.replace("Root");
        }}
      />
    </View>
  );
}
