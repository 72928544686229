import { observer } from "mobx-react-lite";
import moment from "moment";
import { Linking } from "react-native";
import Button from "../components/common/Button";
import Icon from "../components/common/Icon";
import { Text, View } from "../components/Themed";
import { appStyles, colors, NEON_GREEN, spacings } from "../defaultStyles";
import { css } from "../lib/glamor-native";
import { useAppStore } from "../store/AppStore";

import { GlobalNotificationType } from "../store/common/AppNotifications";

const GlobalAppNotificationsView: React.FC = observer(() => {
  const store = useAppStore();
  const globalNotifications = store.appNotifications.globalNotifiactions;
  const notificationsList = Object.keys(globalNotifications).map(
    (key) => globalNotifications[key]
  );
  const hasAppNotifications = notificationsList.length > 0;
  return (
    <View
      style={css({
        backgroundColor: "transparent",
      })}
    >
      {!hasAppNotifications && (
        <View
          style={css({
            backgroundColor: "transparent",
            alignItems: "center",
            justifyContent: "center",
          })}
        >
          <Icon name="check" color={NEON_GREEN} size={60} />
          <Text style={css(appStyles.heading.level0)}>
            You are all caught up!
          </Text>
        </View>
      )}
      {notificationsList.map((notification: GlobalNotificationType) => {
        return (
          <Notification key={notification.id} notification={notification} />
        );
      })}
    </View>
  );
});

export default GlobalAppNotificationsView;

const Notification: React.FC<{ notification: GlobalNotificationType }> =
  observer(({ notification }) => {
    const createdAt = notification.createdAt;
    return (
      <View
        key={notification.id}
        style={css({
          flexDirection: "row",
          backgroundColor: colors.surface,
          alignItems: "flex-end",
          marginBottom: spacings.regular,
          borderRadius: spacings.roundBorderXl,
          padding: spacings.default,
        })}
      >
        <View
          style={css({
            flexDirection: "column",
            flex: 1,
            backgroundColor: "transparent",
          })}
        >
          <Text style={css({ ...appStyles.heading.level1, flex: 1 })}>
            {notification.title}
          </Text>
          <Text
            style={css({
              ...appStyles.text.body,
              marginBottom: spacings.regular,
            })}
          >
            {notification.details}
          </Text>

          <Text style={css(appStyles.text.bodyFaded)}>
            {moment(createdAt).fromNow()}
          </Text>

          {notification.link && (
            <Button
              variant="tertiary"
              label="View details"
              onPress={() => {
                if (notification.link) {
                  Linking.openURL(notification.link);
                }
              }}
            />
          )}
        </View>
      </View>
    );
  });
