import { observer } from "mobx-react";
import { View } from "react-native";
import { Heading } from "../../components/common/Heading";
import GroupCard from "../../components/GroupCard";
import { appStyles } from "../../defaultStyles";
import { css } from "../../lib/glamor-native";
import { useAppStore } from "../../store/AppStore";

export const RecentGroupsLoggedByYou: React.FC = observer(() => {
  const store = useAppStore();
  const groups = store.groups.records;
  const popularGroups = store.feed.popularGroups;

  const popularGroupsNotInFavorites = popularGroups.filter(
    (popular) => store.groups.favorites[popular.groupId] === undefined
  );

  const showRecentlyCreatedGroups =
    popularGroupsNotInFavorites.length === 0 && popularGroups.length === 0;

  if (showRecentlyCreatedGroups) {
    return <RecentlyCreatedGroups />;
  }

  return (
    <View style={css({ ...appStyles.viewWrapper })}>
      <Heading
        title="Recent Groups"
        level={1}
        description="Recently most updated groups"
      />

      {popularGroups?.map((popularGroup) => {
        const group = groups[popularGroup.groupId];
        if (!group) {
          return null;
        }
        if (store.groups.favorites[group.groupId]) {
          // don't show here if it is already showing on favorites
          return null;
        }
        return (
          <GroupCard key={`recent-logged-${group.groupId}`} group={group} />
        );
      })}
    </View>
  );
});

export const RecentlyCreatedGroups: React.FC = observer(() => {
  const store = useAppStore();
  const groups = store.groups.ids;

  const groupsNotInFavorites = groups.filter(
    (popular) => store.groups.favorites[popular] === undefined
  );

  if (groupsNotInFavorites.length === 0) {
    return null;
  }
  return (
    <View style={css({ ...appStyles.viewWrapper })}>
      <Heading
        title="Recent Groups"
        level={2}
        description="Recently created groups"
      />

      {groups?.map((groupId: string) => {
        const group = store.groups.records[groupId];
        if (!group) {
          return null;
        }
        if (store.groups.favorites[group.groupId]) {
          // don't show here if it is already showing on favorites
          return null;
        }
        return (
          <GroupCard key={`recent-logged-${group.groupId}`} group={group} />
        );
      })}
    </View>
  );
});
