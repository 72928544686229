import { DocumentData, onSnapshot, Query } from "firebase/firestore";
import { action, computed, makeObservable, observable } from "mobx";
import { isInRange } from "../utils";
import Apis from "./Api";
import List, { matchFilterBy, matchSearchTerm, sortMe } from "./common/List";
import SubscriptionManager from "./common/SubscriptionManager";
import CompletedTask, { CompletedTaskType } from "./CompletedTask";
import { Firestore } from "./Firestore";
import TaskList from "./TasksList";

const SEARCH_FIELDS = ["title", "description", "notes"];

export default class CompletedTaskList extends List {
  api: Firestore;
  currentFilters: { [filterId: string]: any };
  subscriptions: SubscriptionManager;
  tasks: List;

  constructor(api: Firestore, tasks: TaskList) {
    super(SEARCH_FIELDS, { fieldId: "createdAt", value: "date" });
    this.api = api;
    this.currentFilters = {};
    this.subscriptions = new SubscriptionManager();
    this.tasks = tasks;

    makeObservable(this, {
      // observables
      api: observable,
      currentFilters: observable,
      subscriptions: observable,
      tasks: observable,

      // computed
      filteredValues: computed,

      // actions
      listenToListChanges: action,
      loadCompletedTasksForUserIdInGroup: action,
      resetFilters: action,
      setCurrentFilters: action,
    });
  }

  get filteredValues(): CompletedTask[] {
    const filteredGroupId = this.currentFilters["groupId"];
    const filteredUser = this.currentFilters["user"];
    const filteredByRange = this.currentFilters["dateRange"];
    const hasFilterBy = this.filterBy !== undefined;
    const hasTerm = this.search.hasSearchTerm;
    const isFilteredByGroup = this.currentFilters["groupId"] !== undefined;
    const isFilteredByUser = this.currentFilters["user"] !== undefined;
    const isFilteredByDateRange =
      this.currentFilters["dateRange"] !== undefined;
    const searchFieldKeys = this.registeredSearchFieldKeys;
    const searchTerm = this.search.term;
    const sortByFieldId = this.sortByField?.fieldId;
    const sortByFieldValue = this.sortByField?.value;
    const sortOrder = this.sortOrder;

    const items: CompletedTask[] = Object.keys(this.items).map(
      (key) => this.items[key]
    );

    if (
      hasTerm ||
      hasFilterBy ||
      isFilteredByUser ||
      isFilteredByGroup ||
      isFilteredByDateRange
    ) {
      return items
        .filter((item) => {
          if (isFilteredByDateRange) {
            if (!isInRange(filteredByRange, item.createdAt)) {
              return false;
            }
          }
          if (isFilteredByGroup) {
            if (item.groupId !== filteredGroupId) {
              return false;
            }
          }
          if (isFilteredByUser) {
            if (item.createdBy !== filteredUser) {
              return false;
            }
          }
          if (hasFilterBy) {
            return matchFilterBy(item, this.filterBy);
          }

          if (hasTerm) {
            return matchSearchTerm(item, searchTerm, searchFieldKeys);
          }

          return true;
        })
        .sort((a, b) =>
          sortMe(a, b, sortByFieldId, sortByFieldValue, sortOrder)
        );
    }

    return items.sort((a, b) =>
      sortMe(a, b, sortByFieldId, sortByFieldValue, sortOrder)
    );
  }

  loadCompletedTasksForUserIdInGroup = async (
    userId: string,
    groupId: string,
    limit: number
  ) => {
    this.setRefreshing(true);
    this.setCurrentFilters("user", userId);
    this.setCurrentFilters("groupId", groupId);
    const recentlyCompletedTasks =
      await Apis.fetchCompletedTasksForUserIdForGroupId(
        this.api.db,
        userId,
        groupId,
        limit
      );

    recentlyCompletedTasks?.forEach((doc) => {
      const data = doc.data() as CompletedTaskType;
      const task = this.tasks.items[data.taskId];

      const title = task?.title || "";
      const description = task?.description || "";

      const completedTask = new CompletedTask(
        this.api,
        data,
        title,
        description
      );
      this.setItem("id", completedTask);
    });
    this.setRefreshing(false);
  };

  setCurrentFilters = (
    key: "user" | "dateRange" | "groupId",
    value?: string
  ) => {
    this.currentFilters[key] = value;
  };

  listenToListChanges = (id: string, query: Query<DocumentData>) => {
    if (query) {
      const unsubscribe = onSnapshot(query, (querySnapshot) => {
        this.pagination.setListSize(querySnapshot.size);
        const needNormalization: string[] = [];
        querySnapshot?.forEach((doc) => {
          const loggedTask = doc.data();
          const newTask: CompletedTaskType = {
            id: loggedTask.id,
            taskId: loggedTask.taskId,
            score: loggedTask.score,
            groupId: loggedTask.groupId,
            createdBy: loggedTask.createdBy,
            updatedAt: loggedTask.updatedAt,
            allowScoreOverwrite: loggedTask?.allowScoreOverwrite || false,
            notes: loggedTask.notes,
            tags: loggedTask.tags,
            hashtags: loggedTask.hashtags,
            createdAt: loggedTask?.createdAt || loggedTask.updatedAt,
          };

          const task = this.tasks.items[loggedTask.taskId];

          const title = task?.title || "";
          const description = task?.description || "";

          if (!task) {
            needNormalization.push(loggedTask.id);
          }

          const completedTask = new CompletedTask(
            this.api,
            newTask,
            title,
            description
          );
          this.setItem("id", completedTask);
        });
        this.setRefreshing(false);
      });

      this.subscriptions.setSubscriptions(id, unsubscribe);
    }
  };

  resetFilters = () => {
    this.setCurrentFilters("groupId", undefined);
    this.setCurrentFilters("user", undefined);
    this.setCurrentFilters("dateRange", undefined);
  };
}
