import { AdMobBanner } from "expo-ads-admob";
import { observer } from "mobx-react";
import { Text, View } from "react-native";
import { adUnitId } from "../constants/AppDefaults";
import { appStyles, colors, setAlpha, spacings } from "../defaultStyles";
import { css } from "../lib/glamor-native";
import { useAppStore } from "../store/AppStore";
import { FeedCard } from "../store/common/FeedCard";
import { strings } from "../store/common/i18n/strings";
import { Pagination } from "../store/common/Pagination";
import { getLabelForGroupType } from "../store/Task";
import { getFeedTitleForMainGroup } from "../utils";
import Button from "./common/Button";
import { Tag } from "./common/Tag";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Heading } from "./common/Heading";
import { ReactNode } from "react";

const NUMBER_OF_FEEDCARDS_BEFORE_ANOTHER_AD_SHOWS = 10;
const FeedList: React.FC<{ groupId?: string; userId?: string }> = ({
  groupId,
  userId,
}) => {
  const store = useAppStore();
  const list = store.feed.items.filteredValues;

  if (list.length === 0 && !store.feed.items.refreshing) {
    return <NoRecentEntriesInFeed comingUp={store.feed.range === "comingUp"} />;
  }

  return (
    <View style={css({ backgroundColor: "transparent" })}>
      <FeedListHeader userId={userId} groupId={groupId} />
      {list.map((completedTask, i) => {
        if (i % NUMBER_OF_FEEDCARDS_BEFORE_ANOTHER_AD_SHOWS === 0 && i !== 0) {
          // show card with an ad
          return (
            <View
              key={`feed-${completedTask.id}`}
              style={css({ backgroundColor: "transparent" })}
            >
              <FeedCard
                index={i}
                completedTask={completedTask}
                selectedGroupId={groupId}
              />
              {!store.user.isPremiumMember && (
                <View
                  style={css({
                    backgroundColor: "transparent",
                    marginBottom: spacings.regular,
                    justifyContent: "center",
                    flexDirection: "row",
                  })}
                >
                  <AdMobBanner
                    bannerSize="smartBannerPortrait"
                    adUnitID={adUnitId} // Test ID, Replace with your-admob-unit-id
                    servePersonalizedAds // true or false
                    onDidFailToReceiveAdWithError={(error) => {
                      console.log("Error", error);
                    }}
                  />
                </View>
              )}
            </View>
          );
        } else {
          return (
            <FeedCard
              index={i}
              key={`feed-alt-${completedTask.id}`}
              completedTask={completedTask}
            />
          );
        }
      })}

      <PaginationComponent pagination={store.feed.items.pagination} />
    </View>
  );
};

export default observer(FeedList);

export const PaginationComponent: React.FC<{ pagination: Pagination }> =
  observer(({ pagination }) => {
    return (
      <View
        style={css({
          backgroundColor: "transparent",
          marginBottom: spacings.default,
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "center",
        })}
      >
        {pagination.showLoadMore && (
          <Button
            variant="secondary"
            label="Load more..."
            onPress={() => {
              pagination.loadMore();
            }}
          />
        )}
      </View>
    );
  });
export const FeedListHeader: React.FC<{
  userId?: string;
  groupId?: string;
}> = observer(({ userId, groupId }) => {
  const store = useAppStore();
  const total = store.feed.items.filteredValues.length;
  if (userId) {
    return <FeedListHeaderForUser userId={userId} groupId={groupId} />;
  }
  if (total === 0) {
    return null;
  }
  let headerTitle = "Recents";
  if (groupId) {
    headerTitle = getFeedTitleForMainGroup(store.feed.range);
  }

  return (
    <View
      style={css({
        backgroundColor: "transparent",
        marginBottom: spacings.regular,
      })}
    >
      <Heading level={2} title={`${headerTitle} (${total})`} />
    </View>
  );
});
export const FeedListHeaderForUser: React.FC<{
  userId?: string;
  groupId?: string;
}> = observer(({ userId, groupId }) => {
  const store = useAppStore();
  if (!userId) {
    return null;
  }

  if (!groupId) {
    return null;
  }
  const displayName = store.userProfiles[userId]?.displayName || userId;
  const usersRanking = store.userRankingByCompletedTasksByGroup[groupId];

  const totals = usersRanking?.filter((ranked) => ranked.user === userId) || [];
  if (totals.length === 0) {
    return null;
  }
  const group = store.groups.records[groupId];
  if (!group) {
    return null;
  }
  const groupType = group.type;

  const rank = totals[0];

  return (
    <View
      style={css({
        backgroundColor: "transparent",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: spacings.regular,
      })}
    >
      <Heading
        level={2}
        title={`${displayName} (${rank.count})`}
        style={{ flex: 1 }}
      />

      <Tag
        label={getLabelForGroupType(rank.points, groupType, true, group.unit)}
      />
    </View>
  );
});

export const NoRecentEntriesInFeed: React.FC<{
  icon?: ReactNode;
  message?: string;
  comingUp?: boolean;
}> = observer(({ icon, message, comingUp = false }) => {
  const text =
    comingUp === true
      ? strings.notifications.noUpcomingEvents
      : strings.notifications.noEntriesInFeed;
  return (
    <View
      style={css({
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        marginTop: spacings.large,
      })}
    >
      {!icon && (
        <MaterialCommunityIcons
          name="progress-clock"
          size={60}
          color={setAlpha(colors.onBackground, 0.4)}
        />
      )}
      {icon && icon}
      <Text
        style={css({
          ...appStyles.text.body,
          color: setAlpha(colors.onBackground, 0.4),
          marginBottom: spacings.large,
          marginTop: spacings.regular,
        })}
      >
        {message ? message : text}
      </Text>
    </View>
  );
});
