import React from "react";
import {
  TextInput,
  Text,
  KeyboardType,
  NativeSyntheticEvent,
  TextInputSelectionChangeEventData,
  TextInputKeyPressEventData,
} from "react-native";
import {
  appStyles,
  colors,
  setAlpha,
  sizes,
  spacings,
} from "../../defaultStyles";
import { css } from "../../lib/glamor-native";
import { View } from "../Themed";

const Input: React.FC<{
  disabled?: boolean;
  inputTextStyle?: Object;
  keyboardType?: KeyboardType;
  label?: string;
  multiline?: boolean;
  numberOfLines?: number;
  onBlur?: () => void;
  onChangeText: (text: string) => void;
  onFocus?: () => void;
  onKeyPress?: (
    event: NativeSyntheticEvent<TextInputKeyPressEventData>
  ) => void;
  onSelectionChange?:
    | ((e: NativeSyntheticEvent<TextInputSelectionChangeEventData>) => void)
    | undefined;
  placeholder?: string;
  required?: boolean;
  secureTextEntry?: boolean;
  style?: object;
  value: string;
}> = ({
  disabled = false,
  inputTextStyle = {},
  keyboardType,
  label,
  multiline = false,
  numberOfLines,
  onBlur = () => {},
  onChangeText,
  onFocus = () => {},
  onKeyPress = (event: NativeSyntheticEvent<TextInputKeyPressEventData>) => {},
  onSelectionChange = () => {},
  placeholder,
  required = false,
  secureTextEntry = false,
  style = {},
  value,
}) => {
  const inputStyle = { fontSize: sizes.font.inputLarge, ...style };
  return (
    <View
      style={css({
        flexDirection: "column",
        marginBottom: spacings.relaxed,
        backgroundColor: "transparent",
        width: "100%",
      })}
    >
      <View style={{ flexDirection: "row", backgroundColor: "transparent" }}>
        {label && (
          <Text
            style={css({
              fontWeight: "bold",
              fontSize: sizes.font.body,
              color: colors.onBackground,
              marginBottom: spacings.slim,
            })}
          >
            {label}
          </Text>
        )}
        {required && (
          <Text
            style={{
              marginLeft: spacings.slim,
              color: colors.required,
              fontWeight: "bold",
              fontSize: sizes.font.body,
            }}
          >
            *
          </Text>
        )}
      </View>

      {!disabled && (
        <View
          style={css({
            backgroundColor: "transparent",
            ...inputStyle,
          })}
        >
          <TextInput
            onFocus={onFocus}
            keyboardType={keyboardType}
            secureTextEntry={secureTextEntry}
            multiline={multiline}
            onSelectionChange={onSelectionChange}
            style={css({
              ...appStyles.input,
              ...inputTextStyle,
            })}
            onKeyPress={onKeyPress}
            onBlur={onBlur}
            placeholderTextColor={setAlpha(colors.onBackground, 0.4)}
            onChangeText={onChangeText}
            value={value}
            numberOfLines={numberOfLines}
            placeholder={placeholder || ""}
          />
        </View>
      )}
      {disabled && (
        <Text
          style={css({
            ...appStyles.input,
            ...inputStyle,
            color: colors.onBackground,
            opacity: 0.5,
          })}
        >
          {value}
        </Text>
      )}
    </View>
  );
};
export default Input;
