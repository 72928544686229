import * as d3 from "d3";

export default class Colorify {
  static darken = (color: string, k = 1) => {
    const { l, c, h } = d3.lch(color);
    return d3.lch(l - 18 * k, c, h);
  };

  static saturate = (color: string, k = 1) => {
    const { l, c, h } = d3.lch(color);
    return d3.lch(l, c + 18 * k, h);
  };

  static variantGradient = (color: string, color2: string, size: number) => {
    return d3.quantize(d3.interpolateHcl(color, color2), size);
  };

  static colorRanking = (t: number) => {
    return d3.interpolateCool(t);
  };
}

export function getLabelColorFromForegroundColor(foregroundColor: string) {
  //this can then be used to calculate the contrast ratio.
  const foreGround = parseRGBColors(foregroundColor);
  const bgLuminance = getLuminanceValue(
    foreGround.r,
    foreGround.g,
    foreGround.b
  );
  const whiteLuminance = getLuminanceValue(255, 255, 255);

  const luminance = whiteLuminance / bgLuminance;

  if (luminance > 4.5) {
    return "#FFFFFF";
  }

  return "#000000";
}

export function parseRGBColors(rgbString: string) {
  const chunks = rgbString.split("(");
  const values = chunks[1].split(")");
  const colors = values[0].split(",");
  const r = colors[0];
  const g = colors[1];
  const b = colors[2];
  return {
    r: parseInt(r),
    g: parseInt(g),
    b: parseInt(b),
  };
}

/**
 * Convert HEX color to RGB
 */
export function hex2Rgb(hex: string) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export function getLuminanceValue(r: number, g: number, b: number) {
  const colorArray = [r, g, b];
  let colorFactor;
  let i;
  for (i = 0; i < colorArray.length; i++) {
    colorFactor = colorArray[i] / 255;
    if (colorFactor <= 0.03928) {
      colorFactor = colorFactor / 12.92;
    } else {
      colorFactor = Math.pow((colorFactor + 0.055) / 1.055, 2.4);
    }
    colorArray[i] = colorFactor;
  }
  return (
    colorArray[0] * 0.2126 +
    colorArray[1] * 0.7152 +
    colorArray[2] * 0.0722 +
    0.05
  );
}

export function rgbToHex(red: number, green: number, blue: number) {
  const rgb = (red << 16) | (green << 8) | (blue << 0);
  return "#" + (0x1000000 + rgb).toString(16).slice(1);
}

export function toHex(rgbString: string) {
  const rgbValue = parseRGBColors(rgbString);
  return rgbToHex(rgbValue.r, rgbValue.g, rgbValue.b);
}
