import { makeAutoObservable } from "mobx";
import * as Linking from "expo-linking";
import * as WebBrowser from "expo-web-browser";
import { Platform, Share, ShareContent } from "react-native";

export const CREATED_BY_SHARED_LINKED = "public-shared-link";

export interface ShareOptionsType {
  message: string;
  title: string;
  url: string;
}
export default class Linker {
  data: any;
  url?: string;

  constructor() {
    this.url = undefined;
    this.data = undefined;
    this._addLinkingListener();

    makeAutoObservable(this);
  }

  setUrl = (value?: string) => {
    this.url = value;
  };

  setData = (value?: any) => {
    this.data = value;
  };

  openUrl = (url: string) => {
    this.setUrl(url);
    let { path, queryParams } = Linking.parse(url);
    alert(
      `Linked to app with path: ${path} and data: ${JSON.stringify(
        queryParams
      )}`
    );
  };

  clear = () => {
    this.url = undefined;
    this.data = undefined;
  };

  _handleRedirect = (event: any) => {
    if (Platform.OS === "ios") {
      WebBrowser.dismissBrowser();
    }

    let data = Linking.parse(event.url);
    this.setData({ redirectData: data });
  };

  _addLinkingListener = () => {
    Linking.addEventListener("url", this._handleRedirect);
  };

  _removeLinkingListener = () => {
    Linking.removeEventListener("url", this._handleRedirect);
  };

  static share = async (content: ShareContent, options?: ShareOptionsType) => {
    try {
      const result = await Share.share(content);
      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          // shared with activity type of result.activityType
        } else {
          // shared
        }
      } else if (result.action === Share.dismissedAction) {
        // dismissed
      }
    } catch (error: any) {
      alert(error.message);
    }
  };

  static parseLink = (url: string) => {};
}
