/**
 * Learn more about using TypeScript with React Navigation:
 * https://reactnavigation.org/docs/typescript/
 */

import { BottomTabScreenProps } from "@react-navigation/bottom-tabs";
import {
  CompositeScreenProps,
  NavigatorScreenParams,
} from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { ButtonType } from "./components/common/Button";
import CompletedTask from "./store/CompletedTask";

export type FilterSelectorOptions = FilterSelectorOption[];
export interface FilterSelectorOption {
  id: string;
  label: string;
  action: () => void;
}

declare global {
  namespace ReactNavigation {
    interface RootParamList extends RootStackParamList {}
  }
}

export type RootStackParamList = {
  Actions: { actions: ButtonType[] };
  AddMember: undefined;
  AddTask: undefined;
  AssignCompletedTaskToTask: {
    completedTask: CompletedTask;
    others?: string[];
  };
  Calendar: undefined;
  CommentView: { completedTaskId: string };
  CompletedTasksByTaskId: { taskId: string };
  CompleteTaskEditor: {
    formId: string;
    taskId: string;
    createOnSave: boolean;
    goBackOnSave?: boolean;
  };
  DeleteCompletedTask: { completedTask: CompletedTask };
  EditGroup: undefined;
  FavoriteGroupSelector: undefined;
  Filters: { groupId: string };
  FilterSelectorOptions: {
    options: FilterSelectorOptions;
    selectedId?: string;
  };
  Group: { groupId: string; tabIndex?: number };
  GroupDetails: { groupId: string };
  GroupInvitation: { groupId: string };
  GroupMemberActions: { groupId: string; email: string };
  GroupSummary: { groupId: string };
  Login: undefined;
  LogTask: { groupId: string } | undefined;
  Modal: undefined;
  NoAccessScreen: undefined;
  NotFound: undefined;
  Notifications: { option: "groupActivity" | "global" };
  PickDate: { formId: string };
  PickTime: { formId: string };
  Profile: undefined;
  RecoverCompletedTask: { completedTask: CompletedTask };
  ReportGroup: { groupId: string };
  ReportPost: { postId: string };
  ReportUser: { userId: string };
  Root: NavigatorScreenParams<RootTabParamList> | undefined;
  Settings: undefined;
  TemplateDetails: { templateId: string };
  TemplatesBrowser: undefined;
  UpdateMembership: { email: string };
  UserRecents: { groupId: string; email: string };
};

export type RootStackScreenProps<Screen extends keyof RootStackParamList> =
  NativeStackScreenProps<RootStackParamList, Screen>;

export type RootTabParamList = {
  Home: undefined;
  Recents: undefined;
  Feed: { groupId?: string; email?: string };
  NoAccessScreen: undefined;
  ComingUp: undefined;
  Groups: undefined;
  Tagged: undefined;
};

export type RootTabScreenProps<Screen extends keyof RootTabParamList> =
  CompositeScreenProps<
    BottomTabScreenProps<RootTabParamList, Screen>,
    NativeStackScreenProps<RootStackParamList>
  >;

export const TAB_INDEXES = ["Home", "Groups", "Recents", "ComingUp"];
