import { useNavigation, useRoute } from "@react-navigation/native";
import { observer } from "mobx-react";
import { ScrollView, View } from "react-native";
import Button from "../components/common/Button";
import { DayTimePicker } from "../components/common/DayTimePicker";
import { appStyles, spacings } from "../defaultStyles";
import { css } from "../lib/glamor-native";
import { useAppStore } from "../store/AppStore";

const PickTime: React.FC = observer(() => {
  const navigation = useNavigation();

  return (
    <View
      style={css({
        ...appStyles.viewWrapper,
        ...appStyles.container,
      })}
    >
      <ScrollView contentContainerStyle={css(appStyles.scrollview)}>
        <View style={css({ backgroundColor: "transparent" })}>
          <TimePicker />

          <View
            style={css({
              backgroundColor: "transparent",
              flexDirection: "row",
              marginTop: spacings.regular,
              alignItems: "center",
              justifyContent: "center",
            })}
          >
            <Button
              label="Apply changes"
              onPress={() => {
                navigation.goBack();
              }}
            />
          </View>
        </View>
      </ScrollView>
    </View>
  );
});

const TimePicker: React.FC = observer(() => {
  const store = useAppStore();
  const router = useRoute();
  //@ts-ignore
  const formId = router.params?.formId;
  if (!formId) {
    return null;
  }
  const form = store.forms.getForm(formId);
  if (!form) {
    return null;
  }

  const fields = form.fields;
  return (
    <View style={css({ backgroundColor: "transparent" })}>
      <DayTimePicker
        initialDate={fields.createdAt.value}
        onChange={(updatedDateTime) => {
          fields.createdAt.set(new Date(updatedDateTime).getTime());
        }}
      />
    </View>
  );
});

export default PickTime;
