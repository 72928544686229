import { FontAwesomeIcon } from "../../@types/fontAwesomeIcons";
import { GroupOptionsType } from "../Group";
import { autoMaintanance } from "./tasks/template-auto-maintanance";
import { babySitting } from "./tasks/template-baby-sitting";
import { businessExpenses } from "./tasks/template-business-expenses";
import { expenses } from "./tasks/template-expenses";
import { houseChores } from "./tasks/template-house-chores";
import { importantDates } from "./tasks/template-important-dates";
import { lifeMemoryTasks } from "./tasks/template-life-memories";
import { medicineCabinet } from "./tasks/template-medicine-cabinet";
import { mileageCount } from "./tasks/template-mileage-count";
import { romance } from "./tasks/template-romance";
import { weightTracking } from "./tasks/template-weight-tracking";

export interface TemplateTaskType {
  id: string;
  score?: number;
  title: string;
  description: string;
  allowScoreOverwrite?: boolean;
}
export interface TemplateType {
  id: string;
  iconName: FontAwesomeIcon;
  title: string;
  description: string;
  type: GroupOptionsType;
  tasks: TemplateTaskType[];
  observations?: string;
  recommendedFor?: string;
  unit?: string;
}

export const templatesById: { [templateId: string]: TemplateType } = {
  "template-life-memories": {
    id: "template-life-memories",
    iconName: "calendar",
    title: "Life Memories",
    description:
      "Tracked by dates, usually shared with family members to remember fun facts, major events, moving dates, start and end dates for jobs and etc.",
    type: "date",
    recommendedFor: "Family, Extended Family",
    tasks: lifeMemoryTasks,
  },
  "template-house-chores": {
    id: "template-house-chores",
    iconName: "home",
    title: "House Chores Gamefied",
    description:
      "Tracked by points, this group allows you to clearly see who does what around the house. It is super fun to try with your partner and or kids. Bring to the table who is actually doing the most work. You can also just share with your house keeper to let them inform what they did.",
    type: "points",
    recommendedFor: "Family, House Keeping",
    tasks: houseChores,
  },
  "baby-sitting": {
    id: "baby-sitting",
    iconName: "child",
    title: "Baby sitting / Child care",
    description:
      "Tracked by date and time, this group will help track your child's activities",
    type: "timestamp",
    tasks: babySitting,
    recommendedFor: "Baby Sitter, Moms, Dads, Caregivers",
    observations: "You will always be able to add more later",
  },
  "template-expenses": {
    id: "template-expenses",
    iconName: "money",
    title: "Expenses",
    description:
      "Tracked by currency, this group allows you to keep track of how much money you spend on specific things. Share with others for awareness or keep it solo.",
    type: "currency",
    recommendedFor: "Family, Couples, Roomates, Event Planners",
    tasks: expenses,
  },
  "template-business-expenses": {
    id: "template-business-expenses",
    iconName: "money",
    title: "Business Travel Expenses",
    description:
      "Tracked by currency, this group allows you to keep track of business expenses",
    type: "currency",
    recommendedFor: "Business travel",
    tasks: businessExpenses,
  },
  "template-medicine-cabinet": {
    id: "template-medicine-cabinet",
    iconName: "medkit",
    title: "The Medicine Cabinet",
    description:
      "Tracked by date and time, this group allows you to track when you took your pills. Share with others for awareness or keep it solo.",
    type: "timestamp",
    tasks: medicineCabinet,
    recommendedFor: "Caregivers, Elderly Care, Anyone",
    observations:
      "To make the traking easier, you can create an entry for each pill you take. It will be easier to log and keep track",
  },
  "important-dates": {
    id: "important-dates",
    iconName: "calendar",
    title: "Important Dates",
    description:
      "Tracked by dates, this group allows you to track important dates",
    type: "date",
    tasks: importantDates,
    observations: "You will always be able to add more later",
  },

  romance: {
    id: "romance",
    iconName: "heart",
    title: "Relationship",
    description:
      "Tracked by dates, this group will help you get a sense of how a certain relationship is going",
    type: "date",
    tasks: romance,
    recommendedFor: "Couples, Family, Friends",
    observations: "You will always be able to add more later",
  },
  "weight-tracker": {
    id: "weight-tracker",
    iconName: "anchor",
    title: "Weight tracker",
    unit: "Pounds (lbs)",
    description:
      "Tracked by measurement, this group allows you to track your weight",
    type: "measurement",
    tasks: weightTracking,
    observations:
      "If desired, will be able modify the unit of measurement after creating this group",
  },
  "mileage-count": {
    id: "mileage-count",
    iconName: "car",
    unit: "Miles",
    title: "Mileage Counter",
    description:
      "Tracked by measurement (you chose if km/miles), this group allows you to track mileage.",
    type: "measurement",
    tasks: mileageCount,
    observations:
      "If desired, will be able modify the unit of measurement after creating this group",
  },
  "auto-maintanance": {
    id: "auto-maintanance",
    iconName: "car",
    title: "Auto maintanance",
    description:
      "Tracked by dates, this group allows you to keep a log of your auto maintance",
    type: "date",
    tasks: autoMaintanance,
  },
};

export const templates = Object.keys(templatesById).map(
  (key) => templatesById[key]
);

export const templateTasks = templates
  .map((template) => template.tasks)
  .reduce((previousValue, currentValue) => {
    return [...previousValue, ...currentValue];
  }, []);
