import { makeAutoObservable } from "mobx";
import {
  updateDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  setDoc,
  deleteDoc,
  query,
  DocumentData,
  Query,
  DocumentReference,
} from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "./firebaseConfig";
import User from "./User";
import { queryCounter } from "./utils/QueryCounter";
import { getCurrentUTCTimeStamp } from "../utils";

export interface ApiError {
  code: "error" | "success";
  errorMessage?: string;
  data?: any;
}
export enum DocumentTypeOptions {
  groups = "groups",
  tasks = "tasks",
  users = "users",
  completedTasks = "completedTasks",
}

export type CollectionType =
  | "tasks"
  | "groups"
  | "users"
  | "completedTasks"
  | "reports";

export class Firestore {
  db?: any;
  user: User;

  constructor(user: User) {
    initializeApp(firebaseConfig);
    this.user = user;
    this.db = getFirestore();
    makeAutoObservable(this);
  }

  setDb(db: any) {
    this.db = db;
  }

  fetchDocSnapshot = (
    collectionName: string,
    docName: string
  ): DocumentReference<DocumentData> | undefined => {
    try {
      console.log("[FIREBASE]: [SNAPSHOT]: fetchDocSnapshot", {
        collectionName,
        docName,
      });
      queryCounter.addCall("fetchDocSnapshot");
      return doc(this.db, collectionName, docName);
    } catch (err) {
      console.log(JSON.stringify(err, null, 2));
      return undefined;
    }
  };

  fetchDocsQuery = (
    collectionName: string
  ): Query<DocumentData> | undefined => {
    console.log("[FIREBASE]: [SNAPSHOT]: FetchDocsQuery", { collectionName });
    try {
      return query(collection(this.db, collectionName));
    } catch (err) {
      console.log(JSON.stringify(err, null, 2));
      return undefined;
    }
  };

  fetch = async (collectionName: string, docName?: string) => {
    try {
      console.log("fetch", collectionName, docName);
      if (queryCounter.tooManyCalls) {
        console.log(
          "High query count, but it will query anyway",
          queryCounter.total
        );
      }
      if (docName) {
        const docRef = doc(this.db, collectionName, docName);
        queryCounter.addFetch(collectionName);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          return docSnap.data();
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document for", collectionName, docName);
          return undefined;
        }
      }
      const c = collection(this.db, collectionName);
      return await getDocs(c);
    } catch (error) {
      console.log(JSON.stringify(error, null, 2));
      return undefined;
    }
  };

  setWithoutCredentials = (
    collectionName: CollectionType,
    dcmt: string,
    data: any
  ) => {
    if (queryCounter.tooManyCalls) {
      console.log(
        "High query count, but it will query anyway",
        queryCounter.total
      );
    }
    try {
      console.log("db set without credentials", { collection, doc, data });
      queryCounter.addSet(collectionName);
      const docRef = doc(this.db, collectionName, dcmt);
      return setDoc(docRef, data);
    } catch (error) {
      return {
        code: "error",
        errorMessage: JSON.stringify(error),
      };
    }
  };

  set = (collectionName: CollectionType, dcmt: string, data: any) => {
    if (queryCounter.tooManyCalls) {
      console.log(
        "High query count, but it will query anyway",
        queryCounter.total
      );
    }
    console.log({ collectionName, dcmt, data });
    if (this.user.hasAccess) {
      try {
        console.log("db set", { collection, doc, data });
        queryCounter.addSet(collectionName);
        const docRef = doc(this.db, collectionName, dcmt);
        return setDoc(docRef, {
          ...data,
          createdBy: data.createdBy || this.user.email || "unknown",
          ownerUid: this.user.uid || "unknown",
          lastUpdatedBy: this.user.email || "unknown",
          updatedAt: getCurrentUTCTimeStamp(),
          createdAt: getCurrentUTCTimeStamp(data.createdAt), // users can change this date to reflect the time they actually want this task to be logged at
          // users should never be able to update this date, this date reflects the date this task was first created
          // documents in the database prior to March 1, 2022 will not have this value
          firstCreatedAt: getCurrentUTCTimeStamp(),
        });
      } catch (error) {
        return {
          code: "error",
          errorMessage: JSON.stringify(error),
        };
      }
    } else {
      return {
        code: "error",
        errorMessage: "USER DOES NOT HAVE ACCESS",
      };
    }
  };

  update = (collectionName: CollectionType, dcmt: string, data: any) => {
    if (queryCounter.tooManyCalls) {
      console.log(
        "High query count, but it will query anyway",
        queryCounter.total
      );
    }
    console.log("UPDATE", collectionName, dcmt, data);
    try {
      queryCounter.addUpdate(collectionName);
      const docRef = doc(this.db, collectionName, dcmt);

      if (data.createdAt) {
        return updateDoc(docRef, {
          ...data,
          lastUpdatedBy: this.user.email,
          updatedAt: getCurrentUTCTimeStamp(data.updatedAt),
          createdAt: getCurrentUTCTimeStamp(data.createdAt),
        });
      } else {
        return updateDoc(docRef, {
          ...data,
          lastUpdatedBy: this.user.email,
          updatedAt: getCurrentUTCTimeStamp(data.updatedAt),
        });
      }
    } catch (error) {
      console.log({ error });
      return {
        code: "error",
        errorMessage: JSON.stringify(error),
      };
    }
  };

  delete = (collectionName: CollectionType, dcmt: string) => {
    if (queryCounter.tooManyCalls) {
      console.log(
        "High query count, but it will query anyway",
        queryCounter.total
      );
    }
    try {
      console.log("DELETE", { collectionName, dcmt });
      queryCounter.addDelete(collectionName);
      const docRef = doc(this.db, collectionName, dcmt);
      return deleteDoc(docRef);
    } catch (error) {
      return {
        code: "error",
        errorMessage: JSON.stringify(error),
      };
    }
  };
}
