export const ReportOptions = [
  {
    id: "nudity",
    label: "Nudity",
  },
  {
    id: "violence",
    label: "Violence",
  },
  {
    id: "harassment",
    label: "Harassment",
  },
  // {
  //   id: "suicide-self-injury",
  //   label: "Suicide or self-injury",
  // },
  {
    id: "false-information",
    label: "False information",
  },
  // {
  //   id: "spam",
  //   label: "Spam",
  // },
  {
    id: "hate-speech",
    label: "Hate speech",
  },
  // {
  //   id: "racist-language-or-activity",
  //   label: "Racist language or Activity",
  // },
  // {
  //   id: "terrorism",
  //   label: "Terrorism",
  // },
  {
    id: "other",
    label: "Other",
  },
];

export const ReportReasonDetails: {
  [reasonId: string]: { id: string; label: string }[];
} = {
  nudity: [
    {
      id: "adult-nudity",
      label: "Adult nudity",
    },
    {
      id: "sexually-suggestive",
      label: "Sexually suggestive",
    },
    {
      id: "sexual-activity",
      label: "Sexual activity",
    },

    {
      id: "sexual-exploitation",
      label: "Sexual exploitation",
    },
    {
      id: "sexual-services",
      label: "Sexual services",
    },
    {
      id: "involves-child",
      label: "Involves a child",
    },
  ],
  violence: [
    {
      id: "violent-threat",
      label: "Violent threat",
    },
    {
      id: "something-else-violent",
      label: "Something else",
    },
  ],
  harassment: [
    {
      id: "harassment-me",
      label: "Me",
    },
    {
      id: "harassment-friend",
      label: "Friend",
    },
  ],
  spam: [],
  "suicide-self-injury": [],
  "false-information": [
    {
      id: "health",
      label: "Health",
    },
    {
      id: "politics",
      label: "Politics",
    },
    {
      id: "social-issue",
      label: "Social issue",
    },
    {
      id: "something-else-false",
      label: "Something else",
    },
  ],
  "hate-speech": [
    {
      id: "race-ethinicity",
      label: "Race or ethinicity",
    },
    {
      id: "religious-affiliation",
      label: "Religious affiliation",
    },
    {
      id: "sexual-orientation",
      label: "Sexual orientation",
    },
    {
      id: "sex-identity",
      label: "Sex or gender identity",
    },
    {
      id: "something-else-hate",
      label: "Something else",
    },
  ],
};

export const labelForReasonId = (reasonId: string) => {
  let label = "";
  const option = ReportOptions.filter((option) => option.id === reasonId);
  if (option.length === 1) {
    return option[0].label;
  }
  return label;
};
