import { TemplateTaskType } from "../templates";

export const houseChores: TemplateTaskType[] = [
  {
    id: "washed-dishes",
    score: 1,
    title: "Washed dishes",
    description: "You washed dishes",
  },
  {
    id: "laundry-wash",
    score: 1,
    title: "Put laundry in the washing machine",
    description: "Counts towards one load of laundry",
  },
  {
    id: "laundry-drier",
    score: 1,
    title: "Put laundry in the dryer",
    description: "Counts towards one load of laundry",
  },
  {
    id: "laundry-folded",
    score: 1,
    title: "Folded the laundry",
    description: "Counts towards one load of laundry",
  },
  {
    id: "cleaned-master-bedroom",
    score: 3,
    title: "Cleaned master bedroom",
    description:
      "It includes making the bed, cleaning the floors and wiping all the dust",
  },
  {
    id: "cleaned-bedroom",
    score: 3,
    title: "Cleaned bedroom",
    description:
      "It includes making the bed, cleaning the floors and wiping all the dust",
  },
  {
    id: "cleaned-guest-bedroom",
    score: 3,
    title: "Cleaned guest bedroom",
    description:
      "It includes making the bed, cleaning the floors and wiping all the dust",
  },
  {
    id: "cleaned-living-bedroom",
    score: 3,
    title: "Cleaned living room",
    description: "It includes cleaning the floors and wiping all the dust",
  },
  {
    id: "cleaned-kitchen",
    score: 3,
    title: "Cleaned the kitchen",
    description: "It includes cleaning the floors and wiping all the dust",
  },
  {
    id: "cleaned-fridge",
    score: 3,
    title: "Cleaned the fridge",
    description: "Removed stale food and wiped the dirt away",
  },
  {
    id: "cleaned-master-bathroom",
    score: 3,
    title: "Cleaned master bathroom",
    description: "It includes cleaning the toilet bowl",
  },
  {
    id: "cleaned-guest-bathroom",
    score: 3,
    title: "Cleaned guest bathroom",
    description: "It includes cleaning the toilet bowl",
  },
  {
    id: "cleaned-bathroom",
    score: 3,
    title: "Cleaned bathroom",
    description: "It includes cleaning the toilet bowl",
  },
  {
    id: "cleaned-garage",
    score: 5,
    title: "Cleaned the garage",
    description: "Put stuff away in the garage",
  },
  {
    id: "cleaned-backyard",
    score: 5,
    title: "Cleaned the backyard",
    description: "It includes mowing the lawn",
  },
  {
    id: "change-bedding",
    score: 2,
    title: "Changed and washed bedding",
    description: "Changed and washed bedding",
  },
  {
    id: "made-bed",
    score: 2,
    title: "Made the bed",
    description: "Made the bed",
  },
  {
    id: "Cleaned-windows",
    score: 2,
    title: "Cleaned windows",
    description: "Cleaned windows",
  },
  {
    id: "Took-out-trash",
    score: 2,
    title: "Took out the trash",
    description: "Took out the trash",
  },
  {
    id: "Washed-the-car",
    score: 3,
    title: "Washed the car",
    description: "Washed the car",
  },
  {
    id: "Prepared-and-cooked-meal",
    score: 3,
    title: "Prepared and cooked a meal",
    description: "Not just for me, but for everyone",
  },
  {
    id: "Cleaned-litter-boxes",
    score: 2,
    title: "Cleaned litter boxes",
    description: "Cleaned litter boxes",
  },
  {
    id: "Fed-the-pets",
    score: 1,
    title: "Fed the pets",
    description: "Fed the pets",
  },
];
