import { useNavigation } from "@react-navigation/native";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import moment from "moment";
import { View, Text, TouchableWithoutFeedback, Pressable } from "react-native";
import { ConfirmDeleteLoggedTask } from "../../components/alerts/Confirmation";
import Button, { ButtonType } from "../../components/common/Button";
import FloatingMenu from "../../components/common/FloatingMenu";
import Icon from "../../components/common/Icon";
import { CachedProfileImage } from "../../components/common/ImagePickerComponent";
import { Tag } from "../../components/common/Tag";
import Dev from "../../components/Dev";
import FeedCardStamp from "../../components/feed/FeedCartStamp";
import {
  appStyles,
  colors,
  ORANGE,
  setAlpha,
  spacings,
} from "../../defaultStyles";
import { css } from "../../lib/glamor-native";
import { Points } from "../../screens/TasksScreen";
import {
  getDaysDuration,
  getMeasurementsLabel,
  isGroupTrackedByCreationDate,
  revertProfileEmailMappingToDisplayName,
  showPointsTag,
} from "../../utils";
import { useAppStore } from "../AppStore";
import { permissions } from "../auth/Permissions";
import CompletedTask from "../CompletedTask";
import Task from "../Task";
import { FormOptions } from "./form/FormStore";
import { Time } from "./Time";
import { Ionicons } from "@expo/vector-icons";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { LinkyfiedText } from "../../components/common/LinkifyText";
import { Reactions } from "../../components/Reactions";
import ScrollReciever from "../../components/common/scroll/ScrollReceiver";

export const FeedCard: React.FC<{
  selectedGroupId?: string;
  completedTask: CompletedTask;
  index: number;
}> = observer(({ completedTask, index, selectedGroupId }) => {
  const store = useAppStore();
  const groupId = completedTask.groupId;
  const task = store.tasks.items[completedTask.taskId];
  const group = store.groups.records[groupId];

  // if (!task && completedTask.createdBy === store.user.email) {
  //   return <MissingParentTaskFeedCard completedTask={completedTask} />;
  // } else if (!task && completedTask.createdBy !== store.user.email) {
  //   return null;
  // }

  // DO NOT SHOW COMPLETED TASK IF TASK DOES NOT EXIST
  if (!task) {
    return null;
  }

  const isEditing = store.ui.editCompletedTaskId === completedTask.id;
  return (
    <ScrollReciever name="FeedCard" index={index} completedTask={completedTask}>
      <TouchableWithoutFeedback
        style={css({
          borderRadius: spacings.roundBorderXl,
          paddingBottom: spacings.regular,
          marginBottom: spacings.regular,
          padding: spacings.regular,
        })}
        onPress={() => {
          store.ui.setShowMenuForCompletedTaskId(undefined);
          store.ui.setShowEditTaskMenuId(undefined);
          store.ui.setEditCompletedTaskId(undefined);
        }}
      >
        <View
          style={css({
            ...appStyles.feedCard,
            borderWidth: isEditing ? 2 : 0,
            backgroundColor: isEditing ? colors.surface200 : colors.surface,
            minHeight: 140,
            flexDirection: "column",
            justifyContent: "space-between",
          })}
        >
          <Dev
            messages={[
              //@ts-ignore
              {
                value: JSON.stringify(toJS(completedTask)),
                label: "completedTask",
              },
              //@ts-ignore
              {
                value: store.ui.editCompletedTaskId,
                label: "store.ui.editCompletedTaskId",
              },

              {
                value: store.ui.showMenuForCompletedTaskId,
                label: "store.ui.showMenuForCompletedTaskId",
              },
            ]}
          />

          {group && group.members.length > 0 && (
            <Reactions completedTask={completedTask} />
          )}
          <View
            style={css({
              backgroundColor: "transparent",
              paddingRight: 80,
            })}
          >
            <FeedCardHeader
              completedTask={completedTask}
              task={task}
              selectedGroupId={selectedGroupId}
            />
            <FeedCardTitle completedTask={completedTask} task={task} />
            <FeedNote completedTask={completedTask} />
          </View>

          <Footer completedTask={completedTask} task={task} />
        </View>
      </TouchableWithoutFeedback>
      <AddComment completedTask={completedTask} />
    </ScrollReciever>
  );
});

const AddComment: React.FC<{ completedTask: CompletedTask }> = observer(
  ({ completedTask }) => {
    const store = useAppStore();
    const navigation = useNavigation();
    const currentUserId = store.user.email;
    if (!currentUserId) {
      return null;
    }
    return (
      <Pressable
        onPress={() => {
          navigation.navigate("CommentView", {
            completedTaskId: completedTask.id,
          });
        }}
      ></Pressable>
    );
  }
);

const AddCommentFakeButton = observer(() => {
  const store = useAppStore();
  const currentUserId = store.user.email;
  if (!currentUserId) {
    return null;
  }
  return (
    <View
      style={css({
        marginTop: spacings.regular,
        marginBottom: spacings.slim,
        backgroundColor: "transparent",
        flexDirection: "row",
        alignItems: "center",
      })}
    >
      <CachedProfileImage
        imageUrl={store.userProfiles[currentUserId]?.profileImageUrl}
        size={25}
      />
      <Text
        style={css({
          ...appStyles.text.bodyFaded,
          marginLeft: spacings.regular,
        })}
      >
        Add a comment...
      </Text>
    </View>
  );
});
const MissingParentTaskFeedCard: React.FC<{ completedTask: CompletedTask }> =
  observer(({ completedTask }) => {
    return (
      <View style={css({ ...appStyles.card, marginBottom: 0, opacity: 0.8 })}>
        <View
          style={css({
            backgroundColor: colors.surface200,
            flexDirection: "row",
            borderRadius: spacings.roundBorderXl,
            padding: spacings.regular,
            alignItems: "center",
          })}
        >
          <Warning />
          <RecoverButtons completedTask={completedTask} />
        </View>
        <CompletedTaskRawMetadata completedTask={completedTask} />
      </View>
    );
  });

export const CompletedTaskRawMetadata: React.FC<{
  completedTask: CompletedTask;
}> = ({ completedTask }) => {
  return (
    <View style={css({ opacity: 0.4 })}>
      <FeedNote completedTask={completedTask} />
      <FeedCardStamp completedTask={completedTask} />
    </View>
  );
};

export const Warning: React.FC = () => {
  return (
    <View
      style={css({
        backgroundColor: "transparent",
        flexDirection: "row",
        borderRadius: spacings.roundBorderXl,
        padding: spacings.regular,
        alignItems: "center",
        marginBottom: spacings.regular,
        flex: 1,
      })}
    >
      <Ionicons name="warning" size={24} color={ORANGE} />
      <Text
        style={css({
          ...appStyles.text.smallBoldFaded,
          marginLeft: spacings.regular,
          color: colors.onSurface200,
        })}
      >
        The main task for this entry has been deleted, if you belive this was
        done by mistake, you can still recover it. Otherwise you can choose to
        permanently delete it.
      </Text>
    </View>
  );
};

export const RecoverButtons: React.FC<{
  completedTask: CompletedTask;
}> = ({ completedTask }) => {
  const navigation = useNavigation();
  return (
    <View style={css({ backgroundColor: "transparent", flexDirection: "row" })}>
      <Button
        variant="tertiary"
        label="Delete"
        onPress={() => {
          navigation.navigate("DeleteCompletedTask", {
            completedTask: completedTask,
          });
        }}
      />
      <Button
        variant="secondary"
        label="Recover"
        onPress={() => {
          navigation.navigate("RecoverCompletedTask", {
            completedTask: completedTask,
          });
        }}
        style={{ marginLeft: spacings.regular }}
      />
    </View>
  );
};

export const FeedCardTitle: React.FC<{
  task: Task;
  completedTask: CompletedTask;
}> = observer(({ completedTask, task }) => {
  const store = useAppStore();
  const userProfiles = store.userProfiles;
  return (
    <View
      style={css({
        backgroundColor: "transparent",
        flexDirection: "column",

        marginBottom: spacings.regular,
      })}
    >
      <View
        style={css({
          backgroundColor: "transparent",
          flexDirection: "row",
          alignItems: "center",
          flex: 1,
        })}
      >
        <CachedProfileImage
          imageUrl={userProfiles[completedTask.createdBy]?.profileImageUrl}
        />
        <View
          style={css({
            backgroundColor: "transparent",
            flexDirection: "column",
            flex: 1,
          })}
        >
          <Text
            style={css({
              ...appStyles.heading.level3,
              marginBottom: 0,
              marginLeft: spacings.regular,
            })}
          >
            {task?.title || completedTask?.title || "Missing title"}
          </Text>

          <Text
            selectable
            style={css({
              ...appStyles.text.bodyFaded,
              marginBottom: 0,
              marginLeft: spacings.regular,
            })}
          >
            {task?.description || completedTask?.description || ""}
          </Text>
        </View>
      </View>
    </View>
  );
});

const FeedCardActionMenu: React.FC<{
  task: Task;
  completedTask: CompletedTask;
}> = observer(({ completedTask, task }) => {
  const store = useAppStore();
  const navigation = useNavigation();
  if (!completedTask) {
    return null;
  }
  const groupId = completedTask.groupId;
  const id = completedTask.id;
  const canEdit = permissions.canEditCompletedTask(
    completedTask,
    store.user.email
  );

  const options: ButtonType[] = [
    {
      label: "Report",
      onPress: () => {
        store.initReportForm("post", undefined, groupId, id);
        navigation.navigate("ReportPost", { postId: id });
      },
      iconProps: {
        name: "warning",
        size: 16,
        position: "left",
        color: colors.secondary,
      },
    },
  ];

  if (canEdit) {
    options.push({
      label: "Edit",
      onPress: () => {
        const ui = store.ui;
        store.initCompletedTaskForm(groupId, task, completedTask);
        ui.setEditCompletedTaskId(completedTask.id);
        ui.setShowMenuForCompletedTaskId(undefined);
        navigation.navigate("CompleteTaskEditor", {
          formId: FormOptions.completeTask,
          taskId: task.taskId,
          createOnSave: false, // we just want to edit the same
        });
      },
      iconProps: {
        name: "pencil",
        size: 16,
        position: "left",
        color: colors.secondary,
      },
    });

    options.push({
      label: "Delete",
      onPress: () => {
        store.groups.setRefreshing(true);
        store.ui.setShowMenuForCompletedTaskId(undefined);
        ConfirmDeleteLoggedTask(
          async () => {
            await store.removeCompletedTask(id, completedTask.groupId);
            store.groups.setRefreshing(false);
          },
          () => {}
        );
      },
      iconProps: {
        name: "trash",
        size: 16,
        position: "left",
        color: colors.secondary,
      },
    });
  }
  return (
    <View
      style={css({
        flexDirection: "row",
        alignItems: "center",
        marginLeft: spacings.default,
        padding: spacings.slim,
        backgroundColor: "transparent",
        position: "absolute",
        zIndex: 1000,
        right: 0,
        top: -10,
      })}
    >
      <FloatingMenu
        variant="horizontal"
        show={store.ui.showMenuForCompletedTaskId === completedTask.id}
        onPress={() => store.ui.setShowMenuForCompletedTaskId(completedTask.id)}
        options={options}
      />
    </View>
  );
});

export const FeedCardHeader: React.FC<{
  task: Task;
  completedTask?: CompletedTask;
  selectedGroupId?: string;
}> = observer(({ task, completedTask, selectedGroupId }) => {
  const store = useAppStore();
  const navigation = useNavigation();
  if (!task) {
    return null;
  }
  const group = store.groups.records[task.groupId];
  if (!group) {
    return null;
  }

  const isFeedInSelectedGroup = selectedGroupId === group.groupId;
  const groupType = group.type;
  const groupName = group.title || "Unknown";
  let score = 0;
  const completedTaskScore = completedTask?.score;
  const taskScore = task?.score;

  if (taskScore && taskScore >= 0) {
    score = taskScore;
  }
  if (completedTaskScore && completedTaskScore >= 0) {
    score = completedTaskScore;
  }

  const isTaskComingUp =
    completedTask && completedTask?.createdAt > new Date().getTime();

  const endedAt = completedTask?.endedAt;
  const createdAt = completedTask?.createdAt;
  const isRangeDate = endedAt && createdAt && createdAt !== endedAt;
  const tagColor = colors.surface200;
  const tagForegroundColor = colors.onSurface200;
  const oneDay = 1000 * 60 * 60 * 24;
  const isComingUpSoon =
    isTaskComingUp && completedTask?.createdAt < new Date().getTime() + oneDay;
  const durationDays = isRangeDate ? getDaysDuration(createdAt, endedAt) : 0;
  const duration = isRangeDate && durationDays > 1 ? durationDays : 0;
  const createdAtLabel = moment(completedTask?.createdAt).format(
    "MMMM DD, YYYY"
  );
  const endedAtLabel = moment(completedTask?.endedAt).format("MMMM DD, YYYY");
  const dateLabel = isRangeDate
    ? `${createdAtLabel}-${endedAtLabel}`
    : createdAtLabel;
  return (
    <View
      style={css({
        flexDirection: "column",
        backgroundColor: "transparent",
        marginBottom: spacings.slim,
        marginTop: spacings.slim,
        alignItems: "flex-start",
        justifyContent: "flex-start",
      })}
    >
      <Dev
        messages={[
          { value: score.toString(), label: "score" },
          { value: groupType, label: "groupType" },
        ]}
      />
      {isTaskComingUp && (
        <Tag
          round={true}
          icon={
            isComingUpSoon ? (
              <MaterialCommunityIcons
                name="clock-fast"
                size={12}
                color={isComingUpSoon ? colors.onError : colors.onError}
              />
            ) : (
              <MaterialCommunityIcons
                name="clock"
                size={12}
                color={isComingUpSoon ? colors.onError : colors.onSecondary}
                style={{ marginRight: spacings.tiny }}
              />
            )
          }
          label={`Coming up ${moment(completedTask?.createdAt).fromNow()}`}
          backgroundColor={isComingUpSoon ? colors.error : colors.secondary}
          color={isComingUpSoon ? colors.onError : colors.onSecondary}
          style={{ marginBottom: spacings.slim }}
        />
      )}

      <View
        style={css({
          flexDirection: "row",
          backgroundColor: "transparent",
          marginBottom: spacings.slim,
          marginTop: spacings.slim,
          alignItems: "flex-start",
          flex: 1,
          justifyContent: "flex-end",
        })}
      >
        {!isFeedInSelectedGroup && (
          <Pressable
            style={({ pressed }) => ({
              opacity: pressed ? 0.4 : 1,
            })}
            onPress={async () => {
              const feedItems = store.feed.items;
              feedItems.setRefreshing(true);
              const groupId = group.groupId;
              if (groupId !== selectedGroupId) {
                navigation &&
                  navigation.reset({
                    index: 1,
                    routes: [
                      { name: "Root" },
                      { name: "Group", params: { groupId: groupId } },
                    ],
                  });

                await store.tasks.loadTasksForGroup(groupId);
                feedItems.setRefreshing(false);
              }
            }}
          >
            <Tag
              label={groupName}
              backgroundColor={colors.surface300}
              color={colors.onSurface300}
            />
          </Pressable>
        )}

        <View
          style={css({
            backgroundColor: "transparent",
            flexDirection: "row",
            flex: 1,
            flexWrap: "wrap",
          })}
        >
          {showPointsTag(groupType) && (
            <Points
              groupType={groupType}
              points={score}
              unit={group.unit}
              backgroundColor={tagColor}
              color={tagForegroundColor}
            />
          )}

          {isGroupTrackedByCreationDate(groupType) && (
            <View
              style={css({
                backgroundColor: "transparent",
                flexDirection: "row",
              })}
            >
              <Tag
                backgroundColor={tagColor}
                color={tagForegroundColor}
                label={dateLabel}
              />
            </View>
          )}

          {isGroupTrackedByCreationDate(groupType) && duration > 1 && (
            <View
              style={css({
                backgroundColor: "transparent",
                flexDirection: "row",
              })}
            >
              <Tag
                backgroundColor={tagColor}
                color={tagForegroundColor}
                label={`${duration} days`}
              />
            </View>
          )}

          {groupType === "timestamp" && (
            <View
              style={css({
                backgroundColor: "transparent",
                flexDirection: "row",
              })}
            >
              <Tag
                backgroundColor={tagColor}
                color={tagForegroundColor}
                iconProps={{ name: "clock-o", size: 12 }}
                label={moment(completedTask?.createdAt).format("h:mm A")}
              />
            </View>
          )}

          {groupType === "time" && (
            <View
              style={css({
                backgroundColor: "transparent",
                flexDirection: "row",
              })}
            >
              <Tag
                color={tagForegroundColor}
                backgroundColor={tagColor}
                iconProps={{ name: "clock-o", size: 14 }}
                label={Time.getStringValue(score, true)}
              />
            </View>
          )}

          {groupType === "measurement" && (
            <View
              style={css({
                backgroundColor: "transparent",
                flexDirection: "row",
              })}
            >
              <Tag
                color={tagForegroundColor}
                backgroundColor={tagColor}
                iconProps={{ name: "rouble", size: 14 }}
                label={getMeasurementsLabel(group, completedTask?.score)}
              />
            </View>
          )}
        </View>
      </View>
    </View>
  );
});

export const FeedNote: React.FC<{ completedTask: CompletedTask }> = observer(
  ({ completedTask }) => {
    const store = useAppStore();
    let notes = completedTask.notes;
    if (notes === "" || notes === undefined) {
      return null;
    }

    notes = revertProfileEmailMappingToDisplayName(notes, store.userProfiles);

    return (
      <View style={css({ backgroundColor: "transparent" })}>
        <View
          style={css({
            backgroundColor: "transparent",
            flexDirection: "row",
            alignItems: "flex-start",
            marginBottom: spacings.relaxed,
          })}
        >
          <Icon name="asterisk" size={10} />
          <Text
            selectable
            style={css({
              ...appStyles.text.body,
              marginLeft: spacings.regular,
              marginBottom: spacings.slim,
              color: setAlpha(colors.onBackground, 0.8),
            })}
          >
            {notes}
          </Text>
        </View>
        <LinkyfiedText text={notes} />
      </View>
    );
  }
);

const Footer: React.FC<{ completedTask: CompletedTask; task: Task }> = observer(
  ({ completedTask, task }) => {
    return (
      <View
        style={css({
          backgroundColor: "transparent",
          borderTopWidth: 1,
          borderTopColor: colors.surface200,
          flexDirection: "row",
          alignItems: "center",
        })}
      >
        <FeedCardStamp completedTask={completedTask} />
        <FeedCardActionMenu completedTask={completedTask} task={task} />
      </View>
    );
  }
);
