import { useNavigation } from "@react-navigation/native";
import { observer } from "mobx-react";
import moment from "moment";
import { ReactNode } from "react";
import { Text, View } from "react-native";
import FilterPill from "../../components/common/FilterPill";
import { appStyles, spacings } from "../../defaultStyles";
import { css } from "../../lib/glamor-native";
import { useAppStore } from "../AppStore";

const QueryDateRangeSelector: React.FC = observer(() => {
  const store = useAppStore();
  const navigation = useNavigation();
  const from = store.feed.from;
  const to = store.feed.to;

  return (
    <View
      style={css({
        backgroundColor: "transparent",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: spacings.default,
        justifyContent: "center",
      })}
    >
      <FilterPill
        label={`From: ${moment(from).format("MMM DD, YYYY")}`}
        onPress={() => {
          store.ui.setShowCalendarType("from");
          navigation.navigate("Calendar");
        }}
      />

      <FilterPill
        label={`To: ${moment(to).format("MMM DD, YYYY")}`}
        onPress={() => {
          store.ui.setShowCalendarType("to");
          navigation.navigate("Calendar");
        }}
      />
    </View>
  );
});

export default QueryDateRangeSelector;

const DateButtonWithLabel: React.FC<{
  label: string;
  children: ReactNode;
  variant: "horizontal" | "vertical";
}> = observer(({ label, children, variant }) => {
  const isHorizontal = variant === "horizontal";
  return (
    <View
      style={css({
        backgroundColor: "transparent",
        flexDirection: isHorizontal ? "row" : "column",
        marginBottom: spacings.regular,
        alignItems: isHorizontal ? "center" : "flex-start",
      })}
    >
      <Text
        style={css({
          ...appStyles.text.smallBoldFaded,
          marginBottom: 0,
        })}
      >
        {label}
      </Text>
      {children}
    </View>
  );
});
