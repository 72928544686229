import { useNavigation } from "@react-navigation/native";
import { StatusBar } from "expo-status-bar";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { ScrollView, KeyboardAvoidingView, Platform } from "react-native";
import { ButtonLogout } from "../components/Buttons";
import Button from "../components/common/Button";
import { ImagePickerComponent } from "../components/common/ImagePickerComponent";
import Input from "../components/common/Input";
import RadioButton from "../components/common/RadioButton";
import { Separator } from "../components/common/Separator";
import { PremiumTag } from "../components/GroupMembersList";
import { Text, View } from "../components/Themed";
import { appStyles, spacings } from "../defaultStyles";
import { css } from "../lib/glamor-native";
import { useAppStore } from "../store/AppStore";
import { FormOptions } from "../store/common/form/FormStore";
import { strings } from "../store/common/i18n/strings";
import { NoAccessScreen } from "./NoAccessScreen";

function ProfileScreen() {
  return (
    <View style={css({ ...appStyles.viewWrapper, ...appStyles.container })}>
      <ScrollView contentContainerStyle={css(appStyles.scrollview)}>
        <KeyboardAvoidingView
          behavior={Platform.OS === "ios" ? "padding" : "height"}
          style={css(appStyles.container)}
        >
          <SettingsView />
        </KeyboardAvoidingView>
      </ScrollView>
    </View>
  );
}

export default observer(ProfileScreen);

const SettingsView: React.FC = observer(() => {
  const store = useAppStore();
  const navigation = useNavigation();
  const profileForm = store.forms.getForm(FormOptions.profile);
  if (!store.user.hasAccess) {
    return <NoAccessScreen />;
  }
  return (
    <View style={css(appStyles.container)}>
      {profileForm && (
        <View
          style={css({
            ...appStyles.container,
            alignItems: "center",
            flexDirection: "column",
            flex: 1,
            justifyContent: "center",
          })}
        >
          <View
            style={css({
              backgroundColor: "transparent",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flex: 1,
              flexDirection: "column",
            })}
          >
            <ImagePickerComponent
              imageUrl={store.user.profilePhotoImagePicker.imageUrl}
              imagePickerHandler={store.user.profilePhotoImagePicker}
            />
            <View
              style={css({
                backgroundColor: "transparent",
                zIndex: 2,
                bottom: 40,
                left: 0,
                position: "absolute",
              })}
            >
              <PremiumTag membership={store.user.profile.membership} />
            </View>
          </View>

          <View
            style={css({
              ...appStyles.card,
              width: "100%",
            })}
          >
            <Input
              label="Email"
              placeholder="email"
              value={store.user.email || ""}
              disabled={true}
              onChangeText={(text) => {}}
            />

            <Input
              label="Display Name"
              placeholder="Display Name"
              value={profileForm.fields.displayName.value}
              onChangeText={(text) => {
                profileForm.fields.displayName.set(text);
              }}
            />

            <Button
              style={{
                marginTop: spacings.default,
                marginBottom: spacings.default,
              }}
              label="Save Profile Updates"
              onPress={() => {
                const displayName = profileForm.fields.displayName.value;
                store.user.setDisplayName(displayName);
                const email = store.user.email;
                if (email) {
                  const profileImageUri =
                    store.user.profilePhotoImagePicker.imageUrl;
                  // let's see if we want to update the profile picture
                  if (
                    profileImageUri &&
                    store.user.profilePhotoImagePicker.hasUpdates
                  ) {
                    // let's upload new image to storage
                    store.user.uploadProfileImageAsync(profileImageUri);
                  }
                  store
                    ._updateUserProfile(email, {
                      displayName,
                    })
                    .then((response) => {
                      navigation.canGoBack() && navigation.goBack();
                      store.setUserProfile({
                        email,
                        displayName,
                      });
                      store.feedback.setFeedback({
                        message: strings.messages.successfully.updatedProfile,
                        variant: "success",
                      });
                    });
                }
              }}
            />
          </View>
          <Text
            style={css({
              ...appStyles.text.smallFaded,
              opacity: 0.5,
            })}
          >
            Member since{" "}
            {moment(store.user.profile.createdAt).format("MMM DD, YYYY")}
          </Text>
        </View>
      )}
      <Footer />

      {/* Use a light status bar on iOS to account for the black space above the modal */}
      <StatusBar style={Platform.OS === "ios" ? "light" : "auto"} />
    </View>
  );
});

export const Footer: React.FC = observer(() => {
  return (
    <View
      style={css({
        backgroundColor: "transparent",
        flexDirection: "column",
      })}
    >
      <View
        style={css({
          flexDirection: "column",
          backgroundColor: "transparent",
          alignItems: "center",
          justifyContent: "center",
        })}
      >
        <Separator />
        <View
          style={css({ flexDirection: "row", backgroundColor: "transparent" })}
        >
          <ButtonLogout />
        </View>
      </View>
      <SupportMode />
    </View>
  );
});

const SupportMode: React.FC = observer(() => {
  const store = useAppStore();
  if (!store.user.profile.supportModeEnabled) {
    return null;
  }
  return (
    <View
      style={css({
        backgroundColor: "transparent",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
      })}
    >
      <Text
        style={css({
          ...appStyles.heading.level2,
          marginTop: spacings.default,
          marginBottom: spacings.relaxed,
        })}
      >
        Support
      </Text>
      <View
        style={css({
          flexDirection: "column",
          backgroundColor: "transparent",
        })}
      >
        <RadioButton
          label="Support mode"
          selected={store.settings.devMode === true}
          onPress={() => {
            store.settings.setDevMode(!store.settings.devMode);
          }}
        />

        <RadioButton
          label="Query performance"
          selected={store.settings.showQueryPerformance === true}
          onPress={() => {
            store.settings.setShowQueryPerformance(
              !store.settings.showQueryPerformance
            );
          }}
        />
      </View>
    </View>
  );
});
