import { Unsubscribe } from "firebase/auth";
import { DocumentData, onSnapshot, Query } from "firebase/firestore";
import { makeAutoObservable } from "mobx";
import Apis from "./Api";
import { UserProfilesType } from "./AppStore";
import { Firestore } from "./Firestore";
import Group, { GroupType } from "./Group";

export type PendingProfileType = { [userId: string]: undefined };
export type BlockedGroupType = {
  groupId: string;
  title: string;
};
export default class Groups {
  api: Firestore;
  editing: boolean;
  records: { [groupId: string]: Group };
  refreshing: boolean;
  refreshed: number;
  blockedGroups: { [groupId: string]: BlockedGroupType };
  onNewMembersAdded: (members: string[]) => void;
  count: number;
  subscriptions: { [subscriptionId: string]: Unsubscribe };
  favorites: { [groupId: string]: true };
  userProfiles: UserProfilesType;

  constructor(
    api: Firestore,
    userProfiles: UserProfilesType,
    onNewMembersAdded: (members: string[]) => void,
    records?: { [id: string]: Group }
  ) {
    this.api = api;
    this.userProfiles = userProfiles;
    this.editing = false;
    this.records = records || {};
    this.refreshing = false;
    this.refreshed = new Date().getTime();
    this.blockedGroups = {};
    this.count = 0;
    this.onNewMembersAdded = onNewMembersAdded;
    this.subscriptions = {};
    this.favorites = {};

    makeAutoObservable(this);
  }

  get ids(): string[] {
    if (this.refreshed > 0) {
      return Object.keys(this.records);
    }
    return [];
  }

  get length(): number {
    if (this.refreshed > 0 && this.records) {
      return Object.keys(this.records)?.length;
    }
    return 0;
  }

  get memberCountByGroup() {
    const memberCountByGroup: { [groupId: string]: number } = {};
    Object.keys(this.records).forEach((groupId) => {
      memberCountByGroup[groupId] = this.records[groupId]?.members?.length || 0;
    });
    return memberCountByGroup;
  }

  unsubscribe = () => {
    // groups subscriptions
    Object.keys(this.subscriptions).forEach((key) => {
      const unsubscribe = this.subscriptions[key];
      unsubscribe();
    });
  };

  setSubscriptions = (id: string, subscription: Unsubscribe) => {
    this.subscriptions[id] = subscription;
  };

  setGroupCount = (count: number) => {
    this.count = count;
  };

  /**
   * load groups should only be called once when the app initializes
   * @param currentUserEmail
   * @param hardRefresh
   * @returns
   */
  loadUserGroupIds = async (currentUserEmail?: string): Promise<void> => {
    if (!currentUserEmail) {
      return undefined;
    }
    this.setRefreshing(true);

    // Get the group ids this user is part of
    const groupsQuery = this.api.fetchDocsQuery(
      `users/${currentUserEmail}/groups`
    );

    if (groupsQuery) {
      this.listenToUserGroupListChanges(groupsQuery, currentUserEmail);
    }

    this.setRefreshing(false);
  };

  listenToUserGroupListChanges = (
    groupsQuery: Query<DocumentData>,
    userEmail: string
  ) => {
    const store = this;
    if (groupsQuery) {
      const unsubscribe = onSnapshot(groupsQuery, (querySnapshot) => {
        this.setGroupCount(querySnapshot?.size || 0);
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          const grp = {
            admins: [],
            allowJoinWithLink: data.allowJoinWithLink || true,
            blocked: [],
            createdAt: data.createdAt,
            createdBy: data.createdBy,
            description: data.description,
            groupId: data.groupId,
            members: [],
            pendingMembers: [],
            public: data?.isPublic || false,
            title: data.title,
            type: data.type,
            unit: data.unit,
          };
          // let's load for the users in this group
          store.addGroup(grp, userEmail);
        });
      });

      this.setSubscriptions("groups", unsubscribe);
    }
  };

  listenToQueryChanges = (id: string, query: Query<DocumentData>) => {
    if (query) {
      const unsubscribe = onSnapshot(query, (querySnapshot) => {
        querySnapshot.forEach((doc) => {});
      });

      this.setSubscriptions(id, unsubscribe);
    }
  };

  setRecord = (group: Group) => {
    this.records[group.groupId] = group;
  };

  removePendingMemberFromGroup = async (groupId: string, userId: string) => {
    const group = this.records[groupId];
    if (group) {
      group.removePendingMember(userId);
    }

    await this._removePendingMemberFromGroup(userId, group.groupId);
    await this._removePendingGroupFromUser(userId, group.groupId);
  };

  addGroup = (group: GroupType, currentUserEmail: string) => {
    const groupId = group.groupId;
    this.records[groupId] = new Group(
      group,
      currentUserEmail,
      this.onNewMembersAdded
    );

    // this means you currently don't have information about the group and you need to fetch data
    this.records[groupId].load(this.api);
  };

  setRecords = (records: { [groupId: string]: Group }) => {
    this.records = records;
  };

  setRefreshed = () => {
    this.refreshed = new Date().getTime();
  };

  removeGroup = (groupId: string) => {
    const records = this.records;
    if (this.records[groupId]) {
      delete records[groupId];
    }
    // remove group from favorites as well
    this.removeFavorite(groupId);
    this.records = records;
  };

  setRefreshing = (value: boolean) => {
    this.refreshing = value;
  };

  reload = () => {
    this.setRefreshing(true);
    const setRefreshing = this.setRefreshing;
    setTimeout(() => {
      setRefreshing(false);
    }, 100);
  };

  setEditing = (value: boolean) => {
    this.editing = value;
  };

  addMemberToGroup = (
    groupId: string,
    memberEmail: string
  ): Group | undefined => {
    const group = this.records[groupId];
    if (group) {
      group.addMember(memberEmail);
      return group;
    }
    return undefined;
  };

  addPendingMemberToGroup = (
    groupId: string,
    memberEmail: string
  ): Group | undefined => {
    console.log("add pending member");
    const group = this.records[groupId];
    if (group) {
      group.addPendingMember(memberEmail);
      return group;
    }
    return undefined;
  };

  clear = () => {
    this.records = {};
  };

  giveAdminAccess = (groupId: string, members: string[]) => {
    const group = this.records[groupId];
    if (group) {
      members.forEach((member) => {
        group.addAdmin(member);
        this._addAdminToGroup(member, groupId);
      });
    }
  };

  revokeAdminAccess = (groupId: string, member: string) => {
    const group = this.records[groupId];
    if (group) {
      group.removeAdmin(member);
      this._removeAdminFromGroup(member, groupId);
    }
  };

  revokeAdminPreviledgesToAllMembersExceptOwner = (groupId: string) => {
    const group = this.records[groupId];
    if (group) {
      group.revokeAdminPreviledgesToAllMembersExceptOwner();

      group.members.forEach((member) => {
        if (group.createdBy !== member) {
          this._removeAdminFromGroup(member, groupId);
        }
      });
    }
  };

  removeUserFromGroup = (groupId: string, member: string) => {
    const group = this.records[groupId];
    if (group) {
      this._removeMemberFromGroup(member, groupId);
      this._removeAdminFromGroup(member, groupId);
      this._removeGroupFromUser(member, groupId);
      this._removeFavoriteGroupFromUser(member, groupId);
      this._removePendingMemberFromGroup(member, groupId);
      this._removePendingGroupFromUser(member, groupId);
    }
  };

  fetchGroupMembers = async (groupId: string) => {
    return this.api.fetch(`groups/${groupId}/members`);
  };

  fetchGroupAdmins = async (groupId: string) => {
    return this.api.fetch(`groups/${groupId}/admins`);
  };

  fetchBlockedUsersInGroup = async (groupId: string) => {
    return this.api.fetch(`groups/${groupId}/blocked`);
  };

  fetchPendingUsersInGroup = async (groupId: string) => {
    return this.api.fetch(`groups/${groupId}/pendingMembers`);
  };

  acceptGroupInvitation = async (groupId: string, currentUserEmail: string) => {
    if (currentUserEmail) {
      await this._removePendingGroupFromUser(currentUserEmail, groupId);
      await this._removePendingMemberFromGroup(currentUserEmail, groupId);
      await this._addUserToGroup(currentUserEmail, groupId);
      await this._addGroupForUser(currentUserEmail, groupId);
    }
  };

  denyGroupInvitation = async (groupId: string, currentUserEmail: string) => {
    if (currentUserEmail) {
      await this._removePendingGroupFromUser(currentUserEmail, groupId);
      await this._removeMemberFromGroup(currentUserEmail, groupId);
    }
  };

  removePendingInvitationForUser = async (groupId: string, userId: string) => {
    const group = this.records[groupId];

    if (group) {
      group.removeMember(userId);
    }

    await this._removeMemberFromGroup(userId, groupId);
    await this._removePendingGroupFromUser(userId, groupId);
  };

  removeGroupFromUser = async (groupId: string, userId: string) => {
    const group = this.records[groupId];

    if (group) {
      delete this.records[groupId];
    }

    await this._removeMemberFromGroup(userId, groupId);
    await this._removeAdminFromGroup(userId, groupId);
    await this._removeGroupFromUser(userId, groupId);
    await this._removeFavoriteGroupFromUser(userId, groupId);
  };

  permanentlyDeleteGroup = (groupId: string) => {
    // it will be removed from all members
    const group = this.records[groupId];
    const groupMembers = group.members;
    if (groupMembers) {
      // remove this group from all members
      this._removeMembersFromGroup(groupId, groupMembers);
      groupMembers.forEach((member) => {
        this._removePendingGroupFromUser(member, groupId);
        this._removeAdminFromGroup(member, groupId);
        this._removeMemberFromGroup(member, groupId);
        this._removeGroupFromUser(member, groupId);
        this._removeFavoriteGroupFromUser(member, groupId);
      });
    }

    // The group will be gone from the db
    this._removeGroup(groupId);

    // The group will be gone locally
    this.removeGroup(groupId);
  };

  loadFavorites = async (userId: string) => {
    const favorites = await this.api.fetch(`users/${userId}/favorites`);
    favorites?.forEach((doc: any) => {
      const data = doc.data();
      if (data.groupId) {
        const group = this.records[data.groupId];
        if (group) {
          this.setFavorite(data.groupId);
          group.setFavorite(true);
        }
      }
    });
  };

  setFavorite = (groupId: string) => {
    if (this.favorites[groupId]) {
      this.removeFavorite(groupId);
    } else {
      this.favorites[groupId] = true;
    }
  };

  removeFavorite = (groupId: string) => {
    if (this.favorites[groupId]) {
      delete this.favorites[groupId];
    }
  };

  createGroup = async (userId: string, group: GroupType) => {
    await this._createGroup(group, userId);
    await this._addUserToGroup(userId, group.groupId);
    await this._addAdminToGroup(userId, group.groupId);
    await this._addGroupForUser(userId, group.groupId);
  };

  private _createGroup = (group: GroupType, currentUserEmail: string) => {
    this.api.set("groups", group.groupId, group);
    group.members.forEach((member) => {
      if (member !== currentUserEmail) {
        this._addPendingMemberToGroup(member, group.groupId);
      }
    });
  };

  private _addGroupForUser = (email: string, groupId: string) => {
    this.api.set("users", `${email}/groups/${groupId}`, {
      groupId,
    });
  };

  private _addUserToGroup = async (userId: string, groupId: string) => {
    await this.api.set("groups", `${groupId}/members/${userId}`, { userId });
  };

  _addPendingMemberToGroup = async (userId: string, groupId: string) => {
    await this.api.set("groups", `${groupId}/pendingMembers/${userId}`, {
      userId,
    });
  };

  private _removePendingMemberFromGroup = async (
    userId: string,
    groupId: string
  ) => {
    await this.api.delete("groups", `${groupId}/pendingMembers/${userId}`);
  };

  private _addAdminToGroup = async (userId: string, groupId: string) => {
    await this.api.set("groups", `${groupId}/admins/${userId}`, { userId });
  };

  private _removeMembersFromGroup = (groupId: string, members: string[]) => {
    members.forEach((member) => {
      this._removeGroupFromUser(member, groupId);
      this._removeFavoriteGroupFromUser(member, groupId);
      this._removePendingGroupFromUser(member, groupId);
    });
  };

  private _removeAdminFromGroup = async (userId: string, groupId: string) => {
    await this.api.delete("groups", `${groupId}/admins/${userId}`);
  };

  private _removeMemberFromGroup = async (userId: string, groupId: string) => {
    await this.api.delete("groups", `${groupId}/members/${userId}`);
  };

  private _blockUserInGroup = async (userId: string, groupId: string) => {
    await this._removeAdminFromGroup(userId, groupId);
    await this._removeMemberFromGroup(userId, groupId);
    await this.api.set("groups", `${groupId}/blocked/${userId}`, { userId });
  };

  private _removeGroup = async (groupId: string) => {
    await Apis.removeGroup(this.api.db, groupId);
  };

  private _removeGroupFromUser = async (userEmail: string, groupId: string) => {
    await this.api.delete("users", `${userEmail}/groups/${groupId}`);
  };

  private _removeFavoriteGroupFromUser = async (
    userEmail: string,
    groupId: string
  ) => {
    await this.api.delete("users", `${userEmail}/favorites/${groupId}`);
  };

  private _removePendingGroupFromUser = async (
    userEmail: string,
    groupId: string
  ) => {
    await this.api.delete("users", `${userEmail}/pendingGroups/${groupId}`);
  };
}
