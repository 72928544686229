import { makeAutoObservable } from "mobx";
import AppStore from "../AppStore";
import CompletedTask from "../CompletedTask";
import Task from "../Task";

const MAX_GROUPS_FREE_ACCOUNT = 3;
export class Permissions {
  store?: AppStore;

  constructor() {
    this.store = undefined;
    makeAutoObservable(this);
  }
  setStore(store: AppStore) {
    this.store = store;
  }

  get canAddTask() {
    const store = this.store;
    if (store) {
      return (
        store.user.hasAccess &&
        store.tasks?.length > 0 &&
        store.groups.length > 0
      );
    }
    return false;
  }

  canCreateGroup = () => {
    if (this.store) {
      if (this.store?.user.profile.membership === "premium") {
        return true;
      } else {
        // default, in case can't find membership
        return this.store?.groups.length <= MAX_GROUPS_FREE_ACCOUNT;
      }
    }
    return false;
  };

  // only users who created the group can delete the group
  canDeleteGroup = (groupId: string): boolean => {
    const group = this.store?.groups.records[groupId];
    if (group) {
      return group.createdBy === this.store?.user.email;
    }
    return false;
  };
  // only users who created the group can edit the group
  canEditGroup = (groupId: string): boolean => {
    const group = this.store?.groups.records[groupId];
    if (group) {
      return group.createdBy === this.store?.user.email;
    }
    return false;
  };

  // only users who are not owners of groups can leave the group
  canLeaveGroup = (groupId: string): boolean => {
    const group = this.store?.groups.records[groupId];
    if (group) {
      return group.createdBy !== this.store?.user.email;
    }
    return false;
  };

  // can only delete group member if member the creator of the group
  canDeleteGroupMember = (groupId: string, member: string): boolean => {
    const group = this.store?.groups.records[groupId];
    if (group) {
      return group.createdBy === member;
    }
    return false;
  };

  canEditTask = (task: Task, userEmail?: string) => {
    if (task) {
      const isCreator = task.createdBy === userEmail;
      return isCreator;
    }
    return false;
  };

  canEditCompletedTask = (completedTask: CompletedTask, userEmail?: string) => {
    if (completedTask) {
      const isCreator = completedTask.createdBy === userEmail;
      return isCreator;
    }
    return false;
  };
}

export const permissions = new Permissions();
