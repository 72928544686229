import { useNavigation } from "@react-navigation/native";
import { observer } from "mobx-react";
import { ScrollView, TouchableHighlight } from "react-native";
import Button from "../components/common/Button";
import Input from "../components/common/Input";
import { Tag } from "../components/common/Tag";
import { Text, View } from "../components/Themed";
import { appName } from "../constants/AppDefaults";
import { appStyles, colors, spacings } from "../defaultStyles";
import { css } from "../lib/glamor-native";
import { useAppStore } from "../store/AppStore";
import { FormOptions } from "../store/common/form/FormStore";
import { RootStackScreenProps } from "../types";
import { GroupMembersList } from "../components/GroupMembersList";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

function AddMemberScreen({ navigation }: RootStackScreenProps<"Login">) {
  const store = useAppStore();
  const memberForm = store.forms.getForm(FormOptions.addMember);
  let hasMembers = false;
  const groupId = memberForm.fields.groupId.value;
  if (groupId !== undefined) {
    const group = store.groups.records[groupId];
    if (group) {
      hasMembers = (group.members && group.members?.length > 0) || false;
    }
  }

  return (
    <KeyboardAwareScrollView
      style={css({ ...appStyles.viewWrapper, ...appStyles.container })}
      enableAutomaticScroll={true}
      extraScrollHeight={100}
    >
      <ScrollView contentContainerStyle={css(appStyles.scrollview)}>
        <AddMemberForm groupId={groupId} />
        {hasMembers && <Members groupId={groupId} />}
      </ScrollView>
    </KeyboardAwareScrollView>
  );
}

export default observer(AddMemberScreen);

export const Members: React.FC<{ groupId: string }> = observer(
  ({ groupId }) => {
    const store = useAppStore();
    const group = store.groups.records[groupId];

    return (
      <View style={css(appStyles.container)}>
        <Text
          style={css({
            ...appStyles.heading.level1,
            marginBottom: spacings.relaxed,
            marginTop: spacings.default,
          })}
        >
          {group.title}'s Group Members
        </Text>
        <GroupMembersList groupId={groupId} />
      </View>
    );
  }
);

const AddMemberForm: React.FC<{ groupId: string }> = observer(({ groupId }) => {
  const store = useAppStore();
  const navigation = useNavigation();
  const addMemberForm = store.forms.getForm(FormOptions.addMember);
  const fields = addMemberForm.fields;

  return (
    <View
      style={css({
        backgroundColor: "transparent",
      })}
    >
      <Text
        style={css({
          ...appStyles.heading.level1,
          marginBottom: spacings.relaxed,
          marginTop: spacings.default,
        })}
      >
        Add Members
      </Text>

      <Text
        style={css({
          ...appStyles.text.body,
          marginBottom: spacings.default,
        })}
      >
        You can add members by entering their email address. This email address
        needs to be same email address they use for their {appName} account
      </Text>
      <View
        style={css({
          backgroundColor: colors.surface200,
          borderRadius: spacings.roundBorderXl,
          padding: spacings.default,
        })}
      >
        <Input
          label="Email"
          onChangeText={(value) => {
            fields.email.set(value);
          }}
          value={fields.email.value}
          placeholder="Email"
          required
        />
        <Suggestions groupId={groupId} />

        <View
          style={css({
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            marginTop: spacings.default,
            backgroundColor: "transparent",
          })}
        >
          <Button
            style={{ flex: 0.5 }}
            onPress={() => {
              navigation.canGoBack() && navigation.goBack();
            }}
            label="Cancel"
            variant="secondary"
          />
          <Button
            style={{ marginLeft: spacings.regular, flex: 0.5 }}
            disabled={!addMemberForm.isValid}
            onPress={() => {
              const formData = addMemberForm.getData;
              const { groupId, email } = formData;
              const showUpdateGroupFormOnSubmit =
                addMemberForm.options.showUpdateGroupFormOnSubmit || false;

              // if adding member from group card
              // just add mmeber straight to db
              if (!showUpdateGroupFormOnSubmit) {
                store.addPendingGroupMember(groupId, email);
                navigation.canGoBack() && navigation.goBack();
              } else {
                // transfer information from this form to edit group form
                const editGroupForm = store.forms.getForm(
                  FormOptions.editGroup
                );
                const currentMembers = editGroupForm.fields.members.value;
                editGroupForm.fields.members.set([
                  ...new Set([...currentMembers, email.toLowerCase()]),
                ]);
                editGroupForm.fields.groupId.set(groupId);
                // go back to form
                navigation.canGoBack() && navigation.goBack();
              }
            }}
            label="Add Member"
          />
        </View>
      </View>
    </View>
  );
});

export const Suggestions: React.FC<{ groupId: string }> = observer(
  ({ groupId }) => {
    const store = useAppStore();
    const addMemberForm = store.forms.getForm(FormOptions.addMember);
    const editGroupForm = store.forms.getForm(FormOptions.editGroup);
    const fields = addMemberForm.fields;
    const groupMembers = store.groups.records[groupId]?.members;
    return (
      <View style={css({ backgroundColor: "transparent" })}>
        <View
          style={css({
            backgroundColor: "transparent",
            flexDirection: "column",
          })}
        >
          <Text style={css(appStyles.text.bodyBold)}>Suggestions?</Text>
          <View
            style={css({
              backgroundColor: "transparent",
              marginTop: spacings.slim,
              flexDirection: "row",
              flexWrap: "wrap",
            })}
          >
            {Object.keys(store.userProfiles).map((email) => {
              if (
                (groupMembers && groupMembers?.indexOf(email) >= 0) ||
                editGroupForm.fields.members.value?.indexOf(email) >= 0 ||
                fields.email.value?.indexOf(email) >= 0 ||
                store.user.email === email
              ) {
                // this user is already invited
                return null;
              }
              return (
                <TouchableHighlight
                  key={`suggestion-${email}`}
                  activeOpacity={0.6}
                  underlayColor="transparent"
                  onPress={() => {
                    fields.email.set(email);
                  }}
                  style={{ marginBottom: spacings.slim }}
                >
                  <Tag
                    label={
                      store.userProfiles[email]?.displayName ||
                      email ||
                      "Unknown"
                    }
                    color={colors.onSurface}
                    backgroundColor={colors.surface}
                  />
                </TouchableHighlight>
              );
            })}
          </View>
        </View>
      </View>
    );
  }
);
